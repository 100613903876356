import React from 'react';
import clsx from 'clsx';
import { Well } from 'components/Well';
import { IoMdAlert, IoMdCheckmark } from 'react-icons/io';
import { ValidationErrorList } from 'components/ValidationErrorList';

export function FormField(props) {
  const {
    id,
    labelText,
    disableShowingErrorList = false,
    children,
    isSuccess,
  } = props;
  const fieldsValidation = props.fieldsValidation || {};

  const validationErrors = fieldsValidation[id];
  const isInvalid = validationErrors && validationErrors.length > 0;

  const className = clsx('FormField', { isInvalid, isSuccess });
  return (
    <div className={className}>
      <label className="FormLabel" htmlFor={id}>
        {labelText}
      </label>
      <div className="FormValue">
        {children}
        <div className="icon">
          {isSuccess ? <IoMdCheckmark /> : isInvalid ? <IoMdAlert /> : null}
        </div>
      </div>
      {isInvalid && !disableShowingErrorList && (
        <Well modifier="danger">
          <ValidationErrorList validationErrors={validationErrors} />
        </Well>
      )}
    </div>
  );
}
