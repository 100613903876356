import React from 'react';
import { IoMdOpen } from 'react-icons/io';

export function Outlink(props) {
  const { href } = props;
  return (
    <a
      className="Outlink"
      href={href}
      rel="noreferrer noopener"
      target="_blank"
    >
      {props.children} <IoMdOpen />
    </a>
  );
}
