import React, { useContext, useRef, useCallback } from 'react';
import clsx from 'clsx';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { GameUiContext } from 'components/PlayGamePage/GameUiContext';
import { PopupMenuContext } from 'components/PlayGamePage/PopupMenuContext';
import { useTap } from 'utils/useTap';
import { getIsLeftSide } from 'utils';
import { useIsUpToTablet } from 'hooks';

// Allow multiple results of useRef() to hold a reference to a single element.
export function combineRefs(...refs) {
  const nonEmptyRefs = refs.filter((ref) => !!ref);
  return (el) => {
    nonEmptyRefs.forEach((ref) => {
      ref.current = el;
    });
  };
}

export function CardCrop(props) {
  const {
    card,
    index = 0,
    actionsAreNonRequest,
    cardRef,
    style,
    isHoverable,
  } = props;
  // null is also accepted, so cannot use default-value syntax
  let cardActions = props.cardActions || [];

  const gameUiCtx = useContext(GameUiContext);
  const { pgpState, pgpDispatch, gd } = gameUiCtx;

  const popupMenuCtx = useContext(PopupMenuContext);

  const isActive = actionsAreNonRequest ? false : cardActions.length > 0;

  const ownRef = useRef();

  const { isChosen, renderSmallCardImg, handleNonStandardClick } =
    gd.getCardCropParts(gameUiCtx, card, cardActions, ownRef, popupMenuCtx);

  const isUpToTablet = useIsUpToTablet();

  const handleTap = useCallback(() => {
    if (isUpToTablet) {
      pgpDispatch({
        type: 'openMobilePreview',
        previewType: 'card',
        card,
        actions: cardActions,
      });
    } else {
      if (cardActions.length === 0) {
        return;
      }

      if (actionsAreNonRequest) {
        popupMenuCtx.open(ownRef.current, {
          popupMenuKind: 'cardActions',
          choices: cardActions,
        });
      } else {
        const handled = handleNonStandardClick();
        if (!handled) {
          console.warn('Unrecognized click with actions', card, cardActions);
        }
      }
    }
  }, [card, cardActions, pgpDispatch, isUpToTablet]);

  function handleMouseEnter(event) {
    pgpDispatch({
      type: 'setCursorOnCardCrop',
      card,
      value: true,
      isLeftSide: getIsLeftSide(event.clientX),
    });
  }

  function handleMouseLeave(event) {
    pgpDispatch({ type: 'setCursorOnCardCrop', card, value: false });
  }

  function handleKeyDown(event) {
    if (event.keyCode === 27) {
      pgpDispatch({ type: 'setCursorOnCardCrop', card, value: false });
    } else if (event.keyCode === 32) {
      pgpDispatch({ type: 'toggleCursorOnCardCrop', card });
    } else if (event.keyCode === 13) {
      handleTap();
    }
  }

  function handleFocus(event) {
    pgpDispatch({
      type: 'setCursorOnCardCrop',
      card,
      value: true,
      isLeftSide: getIsLeftSide(event.target.getBoundingClientRect().left),
    });
  }

  function handleBlur(event) {
    pgpDispatch({ type: 'setCursorOnCardCrop', card, value: false });
  }

  const { cardPreview } = pgpState;

  // TODO getKeyOfCard
  const isPointOfAttention =
    cardPreview.card === card && cardPreview.cursorOnCardCrop;
  const tapHandlers = useTap(handleTap);

  const className = clsx('CardCrop', {
    isActive,
    isChosen,
    isHoverable,
    isPointOfAttention,
    [`stagger${index}`]: true,
  });
  return (
    <div
      className={className}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      onBlur={handleBlur}
      ref={combineRefs(ownRef, cardRef)}
      style={style}
    >
      <div className="inner">{renderSmallCardImg()}</div>
      <div className="chosenIndicator">
        <IoMdCheckmarkCircle />
      </div>
      <div
        className="overlay"
        {...tapHandlers}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </div>
  );
}
