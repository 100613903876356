import { createSelector } from '@reduxjs/toolkit';

import {
  selectLobbyShortNames,
  selectLobbyRecentFinishedShortNames,
  selectLobbyFilters,
  gameFilterDefinitions,
} from 'slices/lobbySlice';
import { selectUser } from 'slices/userSlice';
import { selectGamesSlice } from 'slices/gamesSlice';
import { gameHasPlayer, getCanUseSeki } from 'utils';

function addAll(aSet, iterable) {
  for (let x of iterable) {
    aSet.add(x);
  }
}

function lobbyGameComparator(a, b) {
  // descending
  const firstCreatedAt = new Date(b.createdAt);
  const secondCreatedAt = new Date(a.createdAt);

  if (firstCreatedAt < secondCreatedAt) {
    return -1;
  } else if (firstCreatedAt === secondCreatedAt) {
    // Probably shouldn't ever happen, but the comparator pattern is strong.
    return 0;
  } else {
    return 1;
  }
}

export const selectLobbyYourGames = createSelector(
  selectLobbyShortNames,
  selectLobbyRecentFinishedShortNames,
  selectUser,
  selectGamesSlice,
  (commonShortNames, recentFinishedShortNames, user, games) => {
    if (!user) {
      return [];
    }

    const setOfShortNames = new Set();

    const { username } = user;
    const canUseSeki = getCanUseSeki(username);

    if (commonShortNames) {
      const relevantShortNames = commonShortNames.filter((shortName) => {
        const game = games[shortName];
        if (!game) {
          return false;
        }
        if (!canUseSeki && game.code === 'seki') {
          return false;
        }
        return gameHasPlayer(game, username);
      });
      addAll(setOfShortNames, relevantShortNames);
    }

    if (recentFinishedShortNames) {
      addAll(setOfShortNames, recentFinishedShortNames);
    }

    const result = [...setOfShortNames]
      .map((shortName) => {
        return games[shortName];
      })
      .sort(lobbyGameComparator);

    return result;
  }
);

export const selectAllLobbyOtherGames = createSelector(
  selectLobbyShortNames,
  selectUser,
  selectGamesSlice,
  (shortNames, user, games) => {
    let resultingShortNames;
    if (!shortNames) {
      return null;
    }

    const canUseSeki = getCanUseSeki(user ? user.username : null);

    if (!user) {
      resultingShortNames = shortNames;
    } else {
      const { username } = user;
      resultingShortNames = shortNames.filter((shortName) => {
        const game = games[shortName];
        if (!game) {
          return false;
        }
        return !gameHasPlayer(game, username);
      });
    }
    const result = resultingShortNames
      .filter((shortName) => {
        const game = games[shortName];
        if (!canUseSeki && game.code === 'seki') {
          return false;
        }
        return true;
      })
      .map((shortName) => {
        return games[shortName];
      })
      .sort(lobbyGameComparator);
    return result;
  }
);

export const selectFilteredLobbyOtherGames = createSelector(
  selectAllLobbyOtherGames,
  selectGamesSlice,
  selectLobbyFilters,
  (otherGames, games, filters) => {
    if (!otherGames) {
      return null;
    }
    return otherGames
      .filter((game) => {
        let matches = false;

        for (const [filterName, definition] of Object.entries(
          gameFilterDefinitions
        )) {
          if (filters[filterName]) {
            const { checkFn } = definition;
            if (checkFn(game)) {
              matches = true;
              break;
            }
          }
        }

        return matches;
      })
      .sort(lobbyGameComparator);
  }
);
