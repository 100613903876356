import React from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'components/Loader';
import { selectConnected } from 'slices/connectionStatusSlice';
import { IoIosWifi } from 'react-icons/io';

export function ConnectionStatus(props) {
  const connected = useSelector(selectConnected);

  if (connected) {
    return (
      <span className="content success" title="Connected to game server">
        <IoIosWifi />
      </span>
    );
  }

  return (
    <span className="content loader" title="No connection">
      <Loader isInNavbar />
    </span>
  );
}
