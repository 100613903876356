import React, { useState, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { TopLevelPage } from 'components/TopLevelPage';
import {
  selectUserInitialized,
  logOut,
  selectIsLoggedIn,
  selectUser,
} from 'slices/userSlice';
import { Avatar } from 'components/Avatar';
import { Button } from 'components/Button';
import { Loader } from 'components/Loader';
import { Well } from 'components/Well';
import { AllowGameEmailsField } from 'components/AllowGameEmailsField';
import { text } from 'texts';
import { getClientSocketId } from 'connector';

function Logout(props) {
  const [state, setState] = useState('start');

  const dispatch = useDispatch();

  async function handleClick() {
    if (state === 'loading') {
      return;
    }
    setState('loading');
    try {
      const response = await fetch('/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientSocketId: getClientSocketId(),
        }),
      });
      if (response.status === 200) {
        setState('start');
        dispatch(logOut());
      } else {
        setState('error');
      }
    } catch (e) {
      setState('error');
    }
  }

  return (
    <>
      <div className="buttons">
        <Button isDisabled={state === 'loading'} onClick={handleClick}>
          Log out
        </Button>
      </div>
      <div>
        {state === 'loading' ? (
          <Loader />
        ) : state === 'error' ? (
          <Well modifier="danger">{text('serverError')}</Well>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

function FormStatus(props) {
  const { state } = props;
  if (state === 'start') {
    return null;
  } else if (state === 'loading') {
    return <Loader />;
  } else if (state === 'serverError') {
    return <Well modifier="danger">{text('serverError')}</Well>;
  } else if (state === 'updated') {
    return <Well modifier="success">Updated</Well>;
  } else {
    console.warn('Invalid state', state);
    return null;
  }
}

function AccountSettings(props) {
  const { user } = props;
  let { accountSettings } = user;

  if (!accountSettings) {
    accountSettings = {};
  }

  const initialAllowGameEmails = accountSettings['allow-game-emails'] || false;

  const [state, setState] = useState('start');
  const [allowGameEmails, setAllowGameEmails] = useState(
    initialAllowGameEmails
  );

  async function handleSubmit(e) {
    e.preventDefault();
    if (state === 'loading') {
      return;
    }

    setState('loading');

    const response = await fetch('/change-account-settings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        allowGameEmails,
      }),
    });
    if (response.status === 200) {
      setState('updated');
    } else {
      setState('serverError');
    }
  }

  return (
    <>
      <h2>Account settings</h2>
      <form onSubmit={handleSubmit}>
        <AllowGameEmailsField
          allowGameEmails={allowGameEmails}
          setAllowGameEmails={setAllowGameEmails}
        />
        <div className="buttons">
          <input
            type="submit"
            className="button isPrimary"
            disabled={state === 'loading'}
            value="Apply"
          />
        </div>
        <FormStatus state={state} />
      </form>
    </>
  );
}

function UploaderFormStatus(props) {
  const { state } = props;
  if (state === 'start') {
    return null;
  } else if (state === 'loading') {
    return <Loader />;
  } else if (state === 'failure') {
    return (
      <Well modifier="danger">
        Sorry, there was a problem uploading your avatar.
      </Well>
    );
  } else if (state === 'success') {
    return <Well modifier="success">Uploaded</Well>;
  } else {
    console.warn('Invalid state', state);
    return null;
  }
}

function AvatarUploader(props) {
  const { dispatch } = props;
  function handleSubmit(e) {
    e.preventDefault();
  }

  const [state, setState] = useState('start');

  async function handleChange(e) {
    setState('loading');
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('avatar', file);

    try {
      await fetch('/upload-avatar', {
        method: 'POST',
        body: formData,
      });
      setState('success');
      dispatch('uploaded');
    } catch (e) {
      setState('failure');
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Upload avatar:&nbsp;
        <input
          type="file"
          onChange={handleChange}
          accept="image/png, image/jpeg"
        />
      </label>
      <UploaderFormStatus state={state} />
    </form>
  );
}

export function AccountPage(props) {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUser);
  const userInitialized = useSelector(selectUserInitialized);

  const [timestamp, dispatch] = useReducer((state, action) => {
    if (action === 'uploaded') {
      return new Date();
    } else {
      console.warn('action must be "uploaded"', action);
    }
  }, new Date());

  if (!userInitialized) {
    return null;
  }

  if (!isLoggedIn) {
    return <Redirect to="/lobby" />;
  }

  const { username } = user;
  return (
    <TopLevelPage additionalClassName="AccountPage" title="Account">
      <h1>{username}</h1>
      <div>
        <div className="avatarContainer">
          <Avatar name={username} timestamp={timestamp} />
        </div>
        <AvatarUploader dispatch={dispatch} />
      </div>
      <hr />
      <AccountSettings user={user} />
      <hr />
      <Logout />
    </TopLevelPage>
  );
}
