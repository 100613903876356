import React, { useContext, useRef } from 'react';
import clsx from 'clsx';
import {
  tdDefconSpaceNames,
  getTrackerStyle,
  getYourRequest,
  getVar,
  getMarkerDataByName,
} from 'data/tdData';
import { GameUiContext } from 'components/PlayGamePage/GameUiContext';
import { arrayContains, inBounds } from 'utils';
import { useTap } from 'utils/useTap';
import { usePutAnswer } from 'utils/playGamePageUtils';

function DefconSpaceMarker(props) {
  const { name, markerData, where = 'board', index } = props;
  const { cs, shortName, answer } = useContext(GameUiContext);
  const { track, value } = markerData;

  const style = getTrackerStyle(name, 'defconSpace', {
    bigSizeProp: where,
    rectProp: where === 'defconCloseup' ? 'closeupRect' : 'rect',
  });

  const ref = useRef(null);

  const putAnswer = usePutAnswer(shortName);

  let action = null;
  let isChosen = false;

  const yourRequest = getYourRequest(cs);
  const requestKind = yourRequest ? yourRequest[0] : null;
  if (requestKind === 'choose-change-defcon') {
    const whose = yourRequest[2];
    const maxTracks = yourRequest[3];
    const allowedTracks = yourRequest[4];
    const lower = yourRequest[5];
    const higher = yourRequest[6];
    const exactAmount = yourRequest[7];

    const current = getVar(cs, `d:${whose}:${track}`);

    if (
      arrayContains(allowedTracks, track) &&
      inBounds(current + lower, value, current + higher, exactAmount)
    ) {
      action = () => {
        const change = value - current;
        if (maxTracks === 1) {
          putAnswer({ [track]: change });
        } else {
          const newAnswer = answer ? { ...answer } : {};
          const n = Object.entries(newAnswer).length;
          if (newAnswer[track]) {
            newAnswer[track] = change;
          } else if (n < maxTracks) {
            newAnswer[track] = change;
          } else {
            let firstKey;
            for (const k of Object.keys(answer)) {
              firstKey = k;
              break;
            }
            delete newAnswer[firstKey];
            newAnswer[track] = change;
          }
          putAnswer(newAnswer);
        }
      };

      // TODO draw svg arrow instead
      if (answer && value === current + answer[track]) {
        isChosen = true;
      }
    }
  }

  function handleKeyDown(event) {
    if (!action) {
      return;
    }
    if (event.keyCode === 13) {
      action();
    }
  }

  function doAction() {
    if (!action) {
      return;
    }
    action();
  }

  const tapHandlers = useTap(doAction);

  const isActive = !!action;
  const hideSmall = where === 'board';
  const className = clsx('marker', {
    isTrack: true,
    isActive,
    hideSmall,
    isChosen,
    [`stagger${index}`]: true,
  });
  return (
    <div className={className} style={style} ref={ref}>
      <div
        className="overlay"
        {...tapHandlers}
        tabIndex={isActive ? 0 : null}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}

export function DefconSpaces(props) {
  const { where } = props;
  return tdDefconSpaceNames.map((name, index) => {
    const markerData = getMarkerDataByName(name);
    return (
      <DefconSpaceMarker
        key={name}
        name={name}
        markerData={markerData}
        where={where}
        index={index}
      />
    );
  });
}
