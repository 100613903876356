import React from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

export function NavbarItem(props) {
  const { isBrand, href } = props;
  const className = clsx({
    NavbarItem: !isBrand,
    Brand: isBrand,
  });
  return (
    <li className={className}>
      <NavLink className="content" activeClassName="isActive" to={href}>
        {props.children}
      </NavLink>
    </li>
  );
}
