import io from 'socket.io-client';
import { setConnected } from 'slices/connectionStatusSlice';
import { setUserFromSocket } from 'slices/userSlice';
import { history } from 'utils/history';
import { getRoomIdFromPath } from 'utils';
import {
  setCurrentRoomId,
  setInitialRoomData,
  userEnters,
  userLeaves,
  newRoomMessage,
  applyGameActionSuccess,
  applyGameActionError,
  selectCurrentRoomId,
} from 'slices/roomSlice';
import { createGameError } from 'slices/lobbySlice';
import { createSandboxGameError } from 'slices/sandboxSlice';
import {
  gameCreated,
  createGameSuccess,
  gameByShortNameCanceled,
  gameByShortNameDeleted,
  gameByShortNameChangedPlayers,
  gameByShortNameStarted,
  gameByShortNameFinished,
  gameByShortNameUpdated,
} from 'slices/gamesSlice';
import { onGameByShortNameDeleted } from 'slices/sandboxSlice';
import {
  runtimeStateAvailable,
  deltasAvailable,
  ackSaveCheckpoint,
} from 'slices/runtimeStatesSlice';

function getSocketIoUrl() {
  const location = window.location;

  if (process.env.NODE_ENV !== 'development') {
    return location.origin;
  }
  return `//${location.hostname}:${4001}`;
}

let prev = null;
export function onLocationChanged(location, dispatch, force = false) {
  const roomId = getRoomIdFromPath(location.pathname);
  if (prev === roomId && !force) {
    return;
  }

  dispatch(setCurrentRoomId(roomId));
  sendWsMessage({
    tag: 'enterRoom',
    roomId,
  });
  prev = roomId;
}

function onSocketMessage(message, dispatch, getState) {
  console.debug('onSocketMessage', message);

  const { tag } = message;

  switch (tag) {
    case 'hello': {
      const { user } = message;
      dispatch(setUserFromSocket(user));
      onLocationChanged(history.location, dispatch, true);
      break;
    }
    case 'initialRoomData': {
      const { roomId, roomData } = message;
      dispatch(setInitialRoomData({ roomId, roomData }));
      break;
    }
    case 'userEnters': {
      const { roomId, username } = message;
      dispatch(userEnters({ roomId, username }));
      break;
    }
    case 'userLeaves': {
      const { roomId, username } = message;
      dispatch(userLeaves({ roomId, username }));
      break;
    }
    case 'newRoomMessage': {
      const { roomId, roomMessage } = message;
      dispatch(newRoomMessage({ roomId, roomMessage }));
      break;
    }
    case 'createGameSuccess': {
      const { game } = message;
      const { shortName } = game;
      history.push(`/game/${shortName}`);
      dispatch(createGameSuccess(game));
      break;
    }
    case 'createGameError': {
      const { text } = message;
      dispatch(createGameError(text));
      break;
    }
    case 'createSandboxGameSuccess': {
      const { game } = message;
      const { shortName } = game;
      history.push(`/play/${shortName}`);
      dispatch(createGameSuccess(game));
      break;
    }
    case 'createSandboxGameError': {
      const { text } = message;
      dispatch(createSandboxGameError(text));
      break;
    }
    case 'lobbyRoom/gameCreated': {
      const { game } = message;
      dispatch(gameCreated(game));
      break;
    }
    case 'lobbyRoom/gameByShortNameCanceled': {
      const { shortName } = message;
      dispatch(gameByShortNameCanceled(shortName));
      break;
    }
    case 'lobbyRoom/gameByShortNameDeleted': {
      // Only sandbox games can be deleted.
      const { shortName } = message;
      dispatch(onGameByShortNameDeleted({ shortName }));
      break;
    }
    case 'gameRoom/thisGameCanceled': {
      const { shortName } = message;
      dispatch(gameByShortNameCanceled(shortName));
      break;
    }
    case 'gameRoom/thisGameDeleted': {
      const { shortName } = message;
      dispatch(gameByShortNameDeleted(shortName));
      break;
    }
    case 'lobbyRoom/gameBydShortNameChangedPlayers': {
      const { shortName, newPlayers } = message;
      dispatch(gameByShortNameChangedPlayers({ shortName, newPlayers }));
      break;
    }
    case 'gameRoom/thisGameChangedPlayers': {
      const { shortName, newPlayers } = message;
      dispatch(gameByShortNameChangedPlayers({ shortName, newPlayers }));
      break;
    }
    case 'lobbyRoom/gameByShortNameStarted': {
      const { shortName } = message;
      dispatch(gameByShortNameStarted(shortName));
      break;
    }
    case 'lobbyRoom/gameByShortNameFinished': {
      const { shortName, outcome } = message;
      dispatch(gameByShortNameFinished({ shortName, outcome }));
      break;
    }
    case 'lobbyRoom/gameByShortNameUpdated': {
      const { shortName, newGame } = message;
      dispatch(gameByShortNameUpdated({ shortName, newGame }));
      break;
    }
    case 'gameRoom/thisGameStarted': {
      const { shortName } = message;
      history.push(`/play/${shortName}`);
      dispatch(gameByShortNameStarted(shortName));
      break;
    }
    case 'gameRoom/thisGameFinished': {
      const { shortName, outcome } = message;
      dispatch(gameByShortNameFinished({ shortName, outcome }));
      break;
    }
    case 'gameRoom/thisGameUpdated': {
      const { shortName, newGame } = message;
      dispatch(gameByShortNameUpdated({ shortName, newGame }));
      break;
    }
    case 'applyGameActionSuccess': {
      const { shortName, actionId } = message;
      if (selectCurrentRoomId(getState()) === 'lobbyRoom') {
        if (actionId === 'join') {
          history.push(`/game/${shortName}`);
        } else if (actionId === 'start') {
          history.push(`/play/${shortName}`);
        }
      }

      dispatch(applyGameActionSuccess({ shortName, actionId }));
      break;
    }
    case 'applyGameActionError': {
      const { shortName, text } = message;
      dispatch(applyGameActionError({ shortName, text }));
      break;
    }
    case 'initialClientStates': {
      const { shortName, data } = message;
      dispatch(runtimeStateAvailable({ shortName, data }));
      break;
    }
    case 'deltas': {
      const { shortName, data } = message;
      dispatch(deltasAvailable({ shortName, data }));
      break;
    }
    case 'ackSaveCheckpoint': {
      const { shortName, data } = message;
      dispatch(ackSaveCheckpoint({ shortName, data }));
      break;
    }
    default: {
      console.warn('Unhandled message from server', message);
    }
  }
}

class WebsocketHandler {
  constructor(store) {
    this.store = store;
    this.dispatch = store.dispatch;
    this.getState = store.getState;
    this.connect();
  }

  connect() {
    const socketIoUrl = getSocketIoUrl();
    var socket = io(socketIoUrl, {
      auth: {
        cookie: document.cookie,
      },
    });
    socket.on('connect', this.onConnect.bind(this));
    socket.on('message', this.onMessage.bind(this));
    socket.on('disconnect', this.onDisconnect.bind(this));

    this.socket = socket;
  }

  onConnect() {
    this.dispatch(setConnected(true));
  }

  onMessage(message) {
    onSocketMessage(message, this.dispatch, this.getState);
  }
  onLobbyBroadcast(message) {
    console.log('onLobbyBroadcast', message);
  }

  onRoomBroadcast(message) {
    console.log('onRoomBroadcast', message);
  }

  onDisconnect() {
    this.dispatch(setConnected(false));
  }

  sendMessage = (message) => {
    console.log('sendMessage', message);
    this.socket.emit('message', message);
  };
}

export const createWsHandler = (store) => new WebsocketHandler(store);

export let globalWsHandler = null;

export function setGlobalWsHandler(value) {
  globalWsHandler = value;
}

export function sendWsMessage(message) {
  if (globalWsHandler === null) {
    console.warn('Trying to send message with null globalWsHandler', message);
    return;
  }

  globalWsHandler.sendMessage(message);
}

export function getClientSocketId() {
  return globalWsHandler.socket.id;
}
