import { createSlice, createSelector } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isLoggedIn: false,
    user: null,
    initialized: false,
  },
  reducers: {
    logIn(state, action) {
      const user = action.payload;
      state.user = user;
      state.isLoggedIn = true;
    },
    logOut(state) {
      state.user = null;
      state.isLoggedIn = false;
    },
    setUserFromSocket(state, action) {
      const user = action.payload;
      if (user) {
        state.user = user;
        state.isLoggedIn = true;
      } else {
        state.user = null;
        state.isLoggedIn = false;
      }
      state.initialized = true;
    },
  },
});

export const { logIn, logOut, setUserFromSocket } = userSlice.actions;

const selectUserSlice = (state) => state.user;

export const selectIsLoggedIn = createSelector(
  selectUserSlice,
  (state) => state.isLoggedIn
);

export const selectUserInitialized = createSelector(
  selectUserSlice,
  (state) => state.initialized
);

export const selectUser = createSelector(selectUserSlice, (state) => {
  return state.user;
});

export const selectYourUsername = createSelector(selectUser, (user) => {
  return user === null ? null : user.username;
});
