import React from 'react';
import clsx from 'clsx';
import FocusTrap from 'focus-trap-react';
import { DismissButton } from 'components/DismissButton';

export class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dismissedByButton: false,
    };
  }

  componentDidUpdate(oldProps, oldState) {
    const { isActive } = this.props;
    if (isActive && !oldProps.isActive) {
      this.setState({ dismissedByButton: false });
    }
  }

  render() {
    const {
      isActive,
      children,
      className,
      onDismiss,
      hasDismissButton = true,
      look = 'window',
      isFullHeight = false,
    } = this.props;

    const focusTrapOptions = {
      onDeactivate: () => {
        const { dismissedByButton } = this.state;
        if (!dismissedByButton) {
          onDismiss();
        }
      },
      allowOutsideClick: true,
    };
    const handleDismiss = () => {
      this.setState({ dismissedByButton: true }, () => {
        onDismiss();
      });
    };
    if (!isActive) {
      return null;
    } else {
      const isWindow = look === 'window';
      const isMobileFullscreen = look === 'mobileFullscreen';
      const isPgp = look === 'pgp';

      const finalClassName = clsx('Modal', className, {
        isActive,
        isWindow,
        isMobileFullscreen,
        isFullHeight,
        isPgp,
      });
      return (
        <FocusTrap focusTrapOptions={focusTrapOptions}>
          <div className={finalClassName}>
            <div className="container">
              {hasDismissButton && <DismissButton onDismiss={handleDismiss} />}
              {children}
            </div>
          </div>
        </FocusTrap>
      );
    }
  }
}
