import React from 'react';
import { clientServerCommonData } from '@cr/engine';

const { maxCommentLength } = clientServerCommonData.validationConstants;

export function GameCommentInput(props) {
  const { comment, setComment } = props;

  return (
    <div className="FormField">
      <label className="FormLabel">Comment:</label>
      <div className="FormValue">
        <input
          autoComplete="off"
          type="text"
          name="comment"
          maxLength={maxCommentLength}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>
    </div>
  );
}
