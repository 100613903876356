import React from 'react';

function translateValidationError(tag, validationError) {
  const tags = {
    noPendingRegistration:
      "You don't seem to be coming from a valid link from the confirmation email.",
    wrongPendingRegistrationEmail: "The email doesn't match the signup code.",
    passwordTooWeak: 'Please choose a stronger password.',
    passwordsDoNotMatch: 'Please enter the same password twice',
    usernameIllegalCharacters: `Please use only Latin letters and numbers.`,
    usernameTooShort: `Please enter at least ${validationError.req} characters.`,
    usernameTooLong: `Please enter no more than ${validationError.req} characters.`,
    usernameTaken: 'This username is already taken.',
    passwordRecovery_noPasswordRecoveryInProgress:
      'No password recovery is in progress for this user',
    passwordRecovery_invalidCode:
      'Invalid password recovery code: you might be using an outdated password-reset link.',
    passwordRecovery_userNotFound:
      'No password recovery is in progress for this user',
  };
  return tags[tag] || validationError.message;
}

export function ValidationErrorList(props) {
  const { validationErrors } = props;

  return (
    <ul>
      {validationErrors.map((validationError) => {
        const { tag } = validationError;
        return (
          <li key={tag}>{translateValidationError(tag, validationError)}</li>
        );
      })}
    </ul>
  );
}
