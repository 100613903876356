import { tdSideSelectionOptionChoices } from './tdData';
import { sekiSideSelectionOptionChoices } from '@cr/engine';

export const gameConfigByCode = {
  td: {
    displayName: '13 Days',
    sideSelectionOptionChoices: tdSideSelectionOptionChoices,
  },
  seki: {
    displayName: 'Sekigahara',
    sideSelectionOptionChoices: sekiSideSelectionOptionChoices,
  },
};
