import React from 'react';
import { Outlink } from 'components/Outlink';
import { Footer } from 'components/Footer';
import { StartSignupForm } from 'components/StartSignupForm';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'slices/userSlice';
import { usePageTitle } from 'hooks';
import { Well } from 'components/Well';

function Thirteen(props) {
  return <em className="gameName">13 Days</em>;
}

function CallToAction(props) {
  return (
    <section className="cta topLevel">
      <div className="container">
        <p>Join our small friendly community!</p>
        <StartSignupForm />
      </div>
    </section>
  );
}

export function IntroPage(props) {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  usePageTitle('');
  return (
    <>
      <main className="IntroPage topLevel">
        <div className="container">
          <Well modifier="info">
            <div>
              This is a redesigned version. If you like the old one, it's still
              available at{' '}
              <Outlink href="https://old.chantry-games.com">
                https://old.chantry-games.com
              </Outlink>
              .
            </div>
          </Well>
          <h1>
            Play <Thirteen /> online
          </h1>
          <section>
            <div className="picture">
              <img alt="Game box front" src="/td-logo-big.jpg" />
            </div>
            <div>
              <p>
                <i>13 Days: The Cuban Missile Crisis</i> is a two-player
                historical strategy boardgame. This site lets you play it in
                your browser against other people.
              </p>
              <p>Game rules are enforced by the software.</p>
              <p>
                The site supports live or async play. A live game will take
                about 20 minutes. An async game will take about 2 weeks, with
                people typically making one move per day. (You can subscribe to
                emails to get notified when it&apos;s your turn.)
              </p>
              <p>The site is free, supported by donations.</p>
              <p>
                It uses cookies to save your login state. No tracking, no ads.
              </p>
            </div>
          </section>
          <section>
            <div className="picture hideSmall">
              <img alt="Gameplay screenshot" src="/td-gameplay-desktop.jpg" />
            </div>
            <div className="picture hideBig">
              <img alt="Gameplay screenshot" src="/td-gameplay-mobile.jpg" />
            </div>
            <div>
              <p>
                Here is how it looks. Works best on the screens of laptop size
                and larger.
              </p>
              {/* <p>
                                You can try{' '}
                                <Link to="/sandbox">the sandbox mode</Link> to
                                get a feel for the user interface: play against
                                yourself in a single browser window.
                                </p> */}
              <p>
                Learn more about <Thirteen />:
              </p>
              <ul>
                <li>
                  Publisher&apos;s site:{' '}
                  <Outlink href="http://jollyrogergames.com/game/13-days/">
                    Jolly Roger Games
                  </Outlink>
                  {',  '}
                  <Outlink href="https://www.ultrapro.com/product_info.php?products_id=4170">
                    Ultra Pro
                  </Outlink>
                </li>
                <li>
                  <Outlink href="https://www.boardgamegeek.com/boardgame/177590/13-days-cuban-missile-crisis">
                    BoardGameGeek
                  </Outlink>
                </li>
              </ul>
              <p>
                The site has no affiliation to Jolly Roger Games or Ultra Pro,
                the publishers of
                <Thirteen />, though it&apos;s made available to the public with
                their knowledge and permission.
              </p>
            </div>
          </section>
        </div>
      </main>
      {!isLoggedIn && <CallToAction />}
      <Footer />
    </>
  );
}
