import React from 'react';
import clsx from 'clsx';
import { InteractiveCardTitle } from 'components/InteractiveCardTitle';
import { pluralize, interpose, boldedList, warnOnce } from 'utils';
import { getGdForCode } from 'data/gdRegistry';

function getHoleReplacementComponent(
  holePrefix,
  holeName,
  holeType,
  args,
  template,
  roleToPlayerName,
  gd,
  pgpDispatch
) {
  let holeValue;
  if (holePrefix[0] === '%') {
    holeValue = args[holeName];
  } else {
    holeValue = args[holePrefix];
  }

  if (
    holeValue === undefined &&
    !(holeType === 'hr' || holeType === 'z' || holeType === 'br')
  ) {
    warnOnce(
      'getHoleReplacementComponent: holeValue not found',
      template,
      args,
      holeName,
      holePrefix
    );
    return '??';
  }

  switch (holeType) {
    case 'r': {
      return <b>{roleToPlayerName[holeValue]}</b>;
    }
    case 'role': {
      return <b>{gd.getRoleForHumans(holeValue)}</b>;
    }
    case 'headerL': {
      return <h2>{holeValue}</h2>;
    }
    case 'header': {
      return <h3>{holeValue}</h3>;
    }
    case 'headerS': {
      return <h4>{holeValue}</h4>;
    }
    case 'hr':
    case 'z': {
      return <hr />;
    }
    case 'br': {
      return <br />;
    }
    case 'n': {
      return <b>{holeValue}</b>;
    }
    case 'nn': {
      const rawValues = holeValue;
      return boldedList(holeValue);
    }
    case 'mode': {
      return <b>{gd.getModeForHumans(holeValue)}</b>;
    }
    case 'c': {
      const card = holeValue;
      const { title, isKnown, unknownWordSg } =
        gd.getTextRepresentationOfCard(card);
      return (
        <InteractiveCardTitle
          card={card}
          cardTitle={isKnown ? title : unknownWordSg}
          pgpDispatch={pgpDispatch}
        />
      );
    }
    case 'cc': {
      const cards = holeValue;

      if (!cards) {
        return '??';
      }

      const unknownBuckets = {};
      const knownTitles = cards
        .map((card, index) => {
          const { title, isKnown, unknownWord } =
            gd.getTextRepresentationOfCard(card);

          if (!isKnown) {
            if (!unknownBuckets[unknownWord]) {
              unknownBuckets[unknownWord] = 0;
            }
            unknownBuckets[unknownWord]++;

            return null;
          }

          return (
            <InteractiveCardTitle
              key={index}
              card={card}
              cardTitle={title}
              pgpDispatch={pgpDispatch}
            />
          );
        })
        .filter((ict) => !!ict);

      const unknownParts = Object.entries(unknownBuckets).map(
        (entry, index) => {
          const [word, amount] = entry;
          const emphaticWord =
            knownTitles.length === 0
              ? word
              : // "Maeda Normal and 3 unknown cards"
                `unknown ${word}`;
          const part = <b key={index}>{pluralize(amount, word)}</b>;
          return part;
        }
      );

      const titles = [...knownTitles, ...unknownParts];
      return interpose(titles, ', ', ' and ');
    }
    case 'cards': {
      const n = holeValue;
      return (
        <>
          <b>{pluralize(n, 'card')}</b>
        </>
      );
    }
    default: {
      const component = gd.getMftHoleReplacementComponent(holeType, holeValue);
      if (!component) {
        console.warn('Unrecognized holeType', holeType);
        return <b>{holeValue}</b>;
      }
      return component;
    }
  }
}

export const MessageFromTemplate = React.memo(function (props) {
  const {
    code,
    template,
    args,
    roleToPlayerName,
    pgpDispatch,
    highlightClass,
  } = props;

  const gd = getGdForCode(code);

  const regexp = /(%|@\d+)(([a-zA-Z\/]+)(\d+)?)/g;

  const parts = [];

  let shouldWrapInDiv = true;

  function addLiteralPart(part) {
    if (part.length === 0) {
      return;
    }
    parts.push(part);
  }

  function addComponentPart(part) {
    if (
      part.type === 'h1' ||
      part.type === 'h2' ||
      part.type === 'h3' ||
      part.type === 'h4'
    ) {
      shouldWrapInDiv = false;
    }
    parts.push(<React.Fragment key={parts.length}>{part}</React.Fragment>);
  }

  let currentIndex = 0;
  let match;
  while ((match = regexp.exec(template)) !== null) {
    const { index } = match;
    addLiteralPart(template.substring(currentIndex, index));
    const comp = getHoleReplacementComponent(
      match[1],
      match[2],
      match[3],
      args,
      template,
      roleToPlayerName,
      gd,
      pgpDispatch
    );

    addComponentPart(comp);
    currentIndex = regexp.lastIndex;
  }
  addLiteralPart(template.substring(currentIndex));

  const className = clsx('MessageFromTemplate', {
    [highlightClass]: !!highlightClass,
  });
  const result = shouldWrapInDiv ? (
    <div className={className}>{parts}</div>
  ) : (
    parts
  );
  return result;
});
MessageFromTemplate.displayName = 'MessageFromTemplate';
