import React from 'react';
import { IoMdAlert } from 'react-icons/io';
import clsx from 'clsx';

export function YourTurnIndicator(props) {
  const { game, yourUsername } = props;
  if (!game) {
    return null;
  }

  const { status, runtimeInfo } = game;
  if (status !== 'running') {
    return null;
  }

  if (!runtimeInfo) {
    return null;
  }

  const { 'current-players': currentPlayers } = runtimeInfo;
  if (!currentPlayers) {
    return null;
  }

  const isYourTurn = !!currentPlayers.find((name) => name === yourUsername);
  if (!isYourTurn) {
    return null;
  }

  return (
    <div className={clsx('labelWithIcon', 'YourTurnIndicator')}>
      <IoMdAlert />
      Your turn
    </div>
  );
}
