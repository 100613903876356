import produce from 'immer';
import { registerCardChange } from './CardRegistry';
import {
  Zone,
  Card,
  CardInRegion,
  CardInBunch,
  CardFacing,
  Context,
  State,
  CardLocation,
  CardRegistry,
  MoveCardOptions,
} from './types';
import { getZone, createAttachmentsRegion } from './utils';
import { EError } from './errors';

export function removeCardAtLocation(
  state: State,
  location: CardLocation
): { next: State; card: Card } {
  const { zoneName, index, attachmentIndex } = location;
  const zone = getZone(state, zoneName);
  let card = zone.cards[index];

  let newZone;
  if (attachmentIndex === undefined) {
    newZone = produce(zone, (draftZone) => {
      draftZone.cards.splice(index, 1);
    });
  } else {
    if (card.tag !== 'cardInRegion') {
      throw new Error(
        `cannot remove attachment from a host which is not in region`
      );
    }
    const hostCard = card as CardInRegion;
    card = hostCard.attachments.cards[attachmentIndex];
    const newHostCard = produce(hostCard, (draftHostCard) => {
      draftHostCard.attachments.cards.splice(attachmentIndex, 1);
    });
    newZone = produce(zone, (draftZone) => {
      draftZone.cards[index] = newHostCard;
    });
  }

  const next = produce(state, (draftState) => {
    draftState.zones[zoneName] = newZone;
  });

  return {
    next,
    card,
  };
}

export function addCardToLocation(
  state: State,
  card: Card,
  location: CardLocation
): { next: State } {
  const { zoneName, index, attachmentIndex } = location;
  const zone = getZone(state, zoneName);

  let newZone;
  if (attachmentIndex === undefined) {
    newZone = produce(zone, (draftZone) => {
      draftZone.cards.splice(index, 0, card);
    });
  } else {
    const hostCard = zone.cards[index] as CardInRegion;
    if (card.tag !== 'cardInRegion') {
      throw new Error(
        `cannot add as attachment a card which is not cardInRegion`
      );
    }
    if (hostCard.tag !== 'cardInRegion') {
      throw new Error(
        `cannot add as attachment to a host which is not cardInRegion`
      );
    }
    const newHostCard = produce(hostCard, (draftHostCard) => {
      draftHostCard.attachments.cards.splice(
        attachmentIndex,
        0,
        card as CardInRegion
      );
    });
    newZone = produce(zone, (draftZone) => {
      draftZone.cards[index] = newHostCard;
    });
  }

  const next = produce(state, (draftState) => {
    draftState.zones[zoneName] = newZone;
  });

  return {
    next,
  };
}

export function removeCardAtLocationCtx(
  ctx: Context,
  location: CardLocation
): Card {
  const { state } = ctx;

  const { next, card } = removeCardAtLocation(state, location);
  ctx.state = next;

  return card;
}

export function addCardToLocationCtx(
  ctx: Context,
  card: Card,
  location: CardLocation
): Card {
  const { state } = ctx;

  const { next } = addCardToLocation(state, card, location);
  ctx.state = next;

  return card;
}

export function promoteToCardInRegion(
  card: CardInBunch,
  options: MoveCardOptions = {}
): CardInRegion {
  return {
    ...card,
    tag: 'cardInRegion',
    facing: options.facing || 'faceup',
    rotation: options.rotation || 'ready',
    vars: {},
    attachments: createAttachmentsRegion(card.sid, card.owner),
  };
}

export function ensureCardInRegion(
  card: Card,
  options: MoveCardOptions = {}
): CardInRegion {
  if (card.tag === 'cardInBunch') {
    return promoteToCardInRegion(card, options);
  } else if (card.tag === 'cardInRegion') {
    const result = produce(card, (draft) => {
      if (options.facing !== undefined) {
        draft.facing = options.facing;
      }
      if (options.rotation !== undefined) {
        draft.rotation = options.rotation;
      }
    });
    return result;
  } else {
    throw new EError(`card must be cardInBunch or cardInRegion`, card);
  }
}

export function demoteToCardInBunch(card: CardInRegion): CardInBunch {
  if (card.attachments.cards.length > 0) {
    throw new EError(`Cannot move demote card with attachments`, card);
  }

  const { sid, owner, roleToKnowledge, cardBack } = card;
  return {
    tag: 'cardInBunch',
    sid,
    owner,
    roleToKnowledge,
    cardBack,
  };
}

export function ensureCardInBunch(card: Card): CardInBunch {
  if (card.tag === 'cardInBunch') {
    return card;
  } else {
    return demoteToCardInBunch(card);
  }
}

export function updateCardAtLocation(
  state: State,
  location: CardLocation,
  updateFn: (card: Card) => Card
): { next: State; newCard: Card } {
  const ctx = { state };

  // Note: this is slightly wasteful as it does remove-add, creating
  // unnecessary states in the iterim.  But it's simple.

  const card = removeCardAtLocationCtx(ctx, location);

  const newCard = updateFn(card);

  // Note: since we allow arbitrary updater, we might want to catch
  // (newCard.facing !== card.facing) and register this change if this is the
  // case.  As it stands, we use a separate changeCardFacing entry point with
  // its own implementation to account for it.

  addCardToLocationCtx(ctx, newCard, location);

  return {
    next: ctx.state,
    newCard,
  };
}

export function registerCardChangeCtx(
  ctx: Context,
  card: Card,
  facing: CardFacing | null,
  zone: Zone,
  options = {
    replaceLimitedKnowledge: false,
  }
): Card {
  const { state } = ctx;

  const { roleToKnowledge } = card;

  const { roleToCardRegistry } = state;
  const next = produce({ roleToCardRegistry, roleToKnowledge }, (draft) => {
    for (const [role, registry] of Object.entries(roleToCardRegistry)) {
      const previousKnowledge = roleToKnowledge[role] || null;

      const [newCardRegistry, knowledge] = registerCardChange(
        registry as CardRegistry,
        card,
        facing,
        zone,
        options
      );
      if (newCardRegistry !== registry) {
        draft.roleToCardRegistry[role] = newCardRegistry;
      }
      if (knowledge !== previousKnowledge) {
        if (knowledge === null) {
          delete draft.roleToKnowledge[role];
        } else {
          draft.roleToKnowledge[role] = knowledge;
        }
      }
    }
  });

  if (next.roleToCardRegistry !== roleToCardRegistry) {
    ctx.state = produce(state, (draftState) => {
      draftState.roleToCardRegistry = next.roleToCardRegistry;
    });
  }

  const newCard =
    next.roleToKnowledge === roleToKnowledge
      ? card
      : {
          ...card,
          roleToKnowledge: next.roleToKnowledge,
        };

  return newCard;
}

export function saveToStashCtx(ctx: Context, key: string, value: any) {
  ctx.state = produce(ctx.state, (draftState) => {
    draftState.stash = {
      ...draftState.stash,
      [key]: value,
    };
  });
}
