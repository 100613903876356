import { createSlice, createSelector } from '@reduxjs/toolkit';
import { setInitialRoomData } from 'slices/roomSlice';
import { gameCreated, gameByShortNameCanceled } from 'slices/gamesSlice';

export const gameFilterDefinitions = {
  open: {
    label: 'Open',
    checkFn: (game) => game.status === 'open',
  },
  running: {
    label: 'Running',
    checkFn: (game) => game.status === 'running',
  },
  finished: {
    label: 'Finished',
    checkFn: (game) => game.status === 'finished',
  },
  other: {
    label: 'Other',
    checkFn: (game) => {
      const { status } = game;
      return !(
        status === 'open' ||
        status === 'running' ||
        status === 'finished'
      );
    },
  },
};

function getShortNamesOfGames(games) {
  return games.map((game) => game.shortName);
}

export const lobbySlice = createSlice({
  name: 'lobby',
  initialState: {
    createGameErrorText: null,
    shortNames: [],
    recentFinishedShortNames: [],
    filters: {
      open: true,
      running: true,
    },
  },
  reducers: {
    createGameError(state, action) {
      const text = action.payload;
      state.createGameErrorText = text;
    },
    clearCreateGameErrorText(state) {
      state.createGameErrorText = null;
    },
    toggleFilter(state, action) {
      const filterName = action.payload;
      state.filters[filterName] = !state.filters[filterName];
    },
  },
  extraReducers: {
    [setInitialRoomData]: function (state, action) {
      const { roomId, roomData } = action.payload;
      if (roomId !== 'lobbyRoom') {
        return;
      }
      const { games, recentFinishedGames } = roomData;

      state.shortNames = getShortNamesOfGames(games);
      state.recentFinishedShortNames =
        getShortNamesOfGames(recentFinishedGames);
    },
    [gameCreated](state, action) {
      const game = action.payload;
      state.shortNames.unshift(game.shortName);
    },
    [gameByShortNameCanceled](state, action) {
      const shortName = action.payload;
      state.shortNames = state.shortNames.filter(
        (thatShortName) => thatShortName !== shortName
      );
    },
  },
});

export const { createGameError, clearCreateGameErrorText, toggleFilter } =
  lobbySlice.actions;

export const selectLobbySlice = (state) => state.lobby;

export const selectCreateGameErrorText = createSelector(
  selectLobbySlice,
  (state) => state.createGameErrorText
);

export const selectLobbyShortNames = createSelector(
  selectLobbySlice,
  (state) => state.shortNames
);

export const selectLobbyRecentFinishedShortNames = createSelector(
  selectLobbySlice,
  (state) => state.recentFinishedShortNames
);

export const selectLobbyFilters = createSelector(
  selectLobbySlice,
  (state) => state.filters
);
