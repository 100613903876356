import {
  sekiGetCardInfoByBase,
  sekiGetUnitInfoByBase,
  getBaseFromCid,
  sekiFindLocationById,
} from '@cr/engine';
import { pluralize, warnOnce, boldedList } from 'utils';

const dashSeparator = ' – ';

export function sekiFormatTiming(timing) {
  const { week, step } = timing;

  const parts = [`Week ${week}`];

  switch (step) {
    case 'reinforcements': {
      parts.push('Reinforcements');
      break;
    }
    case 'turnOrder': {
      parts.push('Turn order');
      break;
    }
    default: {
      const { letter, phasingPlayerLabel, phase } = timing;
      parts.push(`turn ${letter === 'a' ? 'A' : 'B'}`);

      parts.push(
        `${phasingPlayerLabel === 'first' ? 'first' : 'second'} player ${
          phase === 'movement' ? 'movement' : 'combat'
        }`
      );
    }
  }

  return parts.join(dashSeparator);
}

export function sekiGetLocationForHumans(locationId) {
  const location = sekiFindLocationById(locationId);
  if (!location) {
    console.error(
      'sekiGetLocationForHumans: unrecognized locationId',
      locationId
    );
    return `?${locationId}?`;
  }
  return location.name;
}

export function sekiGetRoleForHumans(role) {
  if (role === 'i') {
    return 'Ishida';
  } else {
    return 'Tokugawa';
  }
}

export function sekiGetModeForHumans(mode) {
  switch (mode) {
    // buyMovement
    case 'zero': {
      return 'Zero';
    }

    case 'no': {
      // This is only for text in button: the log item case is avoided in
      // sekiOrders by issuing a separate `buyMovement/buyNone` text.
      return 'none';
    }
    case 'minimal': {
      return 'minimal';
    }
    case 'limited': {
      return 'limited';
    }
    case 'total': {
      return 'total';
    }
    // phasingPlayerLabel
    case 'first': {
      return '1';
    }
    case 'second': {
      return '2';
    }
    // letter
    case 'a': {
      return 'A';
    }
    case 'b': {
      return 'B';
    }
    // usedBonuses
    case 'h': {
      return 'highway bonus';
    }
    case 'al': {
      return 'territorial leadership';
    }
    case 'dl': {
      return 'declared leadership';
    }
    case 'fm': {
      return 'forced march';
    }
    // movingControls
    case 'all': {
      return 'All';
    }
    case 'mori': {
      return 'Mōri';
    }
    case 'koba': {
      return 'Kobayakawa';
    }
    case 'uesu': {
      return 'Uesugi';
    }
    case 'ukit': {
      return 'Ukita';
    }
    case 'toku': {
      return 'Tokugawa';
    }
    case 'date': {
      return 'Date';
    }
    case 'fuku': {
      return 'Fukushima';
    }
    case 'maed': {
      return 'Maeda';
    }
    case 'arq': {
      return 'Arquebusiers';
    }
    case 'cav': {
      return 'Cavalry';
    }
    case 'leader': {
      return 'Leaders';
    }
    case 'none': {
      return 'Fighters';
    }

    // skipControls
    case 'movementPhase/skipFollowOnMovements': {
      return 'skip';
    }
    case 'movementPhase/skipRemainingActivations': {
      return 'End movements';
    }
    case 'combatPhase/skipMyCastles': {
      return 'Skip castles';
    }
    case 'combatPhase/doneDeployingUnits': {
      return 'Finish deploying';
    }

    // mustering confirm
    case 'openMustering': {
      return 'Muster openly';
    }
    case 'hiddenMustering': {
      return 'Muster secretly';
    }
    case 'cannotMuster': {
      return 'Muster';
    }
    // bringMori confirm
    case 'bringMori': {
      return 'Bring into play';
    }
    // declareCombat
    case 'stayInCastle': {
      return 'Stay in castle';
    }
    case 'declareFieldBattle': {
      return 'Field battle';
    }
    case 'declareCombatHere': {
      return 'Declare combat';
    }
    // chooseCastleDefense
    case 'defenseStayInCastle': {
      return 'Stay in castle';
    }
    case 'defenseFieldCombat': {
      return 'Battle';
    }
    // attackType
    case 'sa-arq': {
      return 'gun';
    }
    case 'sa-cav': {
      return 'cavalry';
    }
    case 'na': {
      console.warn(
        'did not expect anything to output na, included for completeness'
      );
      return 'normal attack';
    }

    // submode of game outcome
    case 'toyotomiHideyoriCaptured': {
      return 'Toyotomi Hideyori is captured';
    }
    case 'ishidaMitsunariKilled': {
      return 'Ishida Mitsunari is killed';
    }
    case 'tokugawaIeyasuKilled': {
      return 'Tokugawa Ieyasu is killed';
    }
    case 'bothSidesInstantVistory': {
      return 'Both side achieve instant victory conditions';
    }
    case 'morePoints': {
      return 'More points';
    }
    case 'tieInPoints': {
      return 'Tie in points';
    }

    default: {
      console.warn('sekiGetModeForHumans: unrecognized mode', mode);
      return null;
    }
  }
}

export function sekiGetActionLineContentTemplate(templateCode) {
  switch (templateCode) {
    case 'reinforcementsStep/youChooseCards': {
      return 'Choose %cards to discard';
    }
    case 'reinforcementsStep/anotherPlayerChoosesCards': {
      return '%r chooses cards to discard';
    }
    case 'reinforcementsStep/bothPlayersChooseCards': {
      return 'Both players choose cards to discard';
    }
    case 'turnOrder/anotherPlayerChoosesCard': {
      return '%r chooses turn order card';
    }
    case 'turnOrder/youChooseCard': {
      return 'Choose turn order card';
    }
    case 'turnOrder/bothPlayersChooseCard': {
      return 'Both players choose turn order card';
    }
    case 'turnOrder/youChooseFirstPlayer': {
      return 'Choose first player';
    }
    case 'turnOrder/anotherPlayerChoosesFirstPlayer': {
      return '%r chooses first player';
    }
    case 'movementPhase/youBuyMovement': {
      return 'Buy movement';
    }
    case 'movementPhase/anotherPlayerBuysMovement': {
      return '%r buys movement';
    }
    case 'movementPhase/youReplenishCards': {
      return 'Replenish cards';
    }
    case 'movementPhase/anotherPlayerReplenishesCards': {
      return '%r replenishes cards';
    }
    case 'movementPhase/youActivateLocation': {
      return 'Choose location to activate (%n1/%n2)';
    }
    case 'movementPhase/anotherPlayerActivatesLocation': {
      return '%r activates a location (%n1/%n2)';
    }
    case 'movementPhase/youChooseDestinationOrDropOffPoint': {
      return 'Choose destination or drop-off point';
    }
    case 'movementPhase/youChooseDestination': {
      return 'Choose destination';
    }
    case 'movementPhase/youChooseFollowOnMovement': {
      return 'Choose follow-on movement';
    }
    case 'combatPhase/youDeclareCombat': {
      return 'Declare combat (%n1/%n2)';
    }
    case 'combatPhase/anotherPlayerDeclaresCombat': {
      return '%r declares combat (%n1/%n2)';
    }
    case 'combatPhase/youChooseCastleDefense': {
      return 'Defend %l';
    }
    case 'combatPhase/anotherPlayerChoosesCastleDefense': {
      return '%r chooses defense at the castle of %l';
    }
    case 'combatPhase/youDeployUnit': {
      return '%l: deploy unit';
    }
    case 'combatPhase/anotherPlayerDeploysUnit': {
      return '%l: %r deploys unit';
    }
    case 'combatPhase/youSelectLosses': {
      return 'Select losses at %l';
    }
    case 'combatPhase/anotherPlayerSelectsLosses': {
      return '%r selects losses at %l';
    }
    case 'combatPhase/youRetreatUnits': {
      return 'Retreat from %l';
    }
    case 'combatPhase/anotherPlayerRetreatsUnits': {
      return '%r retreats from %l';
    }
    default: {
      warnOnce(
        'sekiGetActionLineContentTemplate: Unrecognized templateCode',
        templateCode
      );
      return `?${templateCode}?`;
    }
  }
}

function processPassingLocationIds(locationIds) {
  if (locationIds.length === 0) {
    return {
      viaPart: '',
    };
  } else {
    return {
      viaPart: ' via %ll',
    };
  }
}

function processUsedBonuses(usedBonuses) {
  const usedBonusesArray = ['h', 'al', 'dl', 'fm']
    .filter((bonus) => usedBonuses[bonus])
    .map((bonus) => sekiGetModeForHumans(bonus));

  // fm is last, so it's ok to insert the payment card right after; it's
  // reported in the cc1 array.
  const fmPart = usedBonuses.fm ? ' (%cc1)' : '';
  const usedBonusesPart =
    usedBonusesArray.length > 0 ? `, using %nn10${fmPart}` : '';

  return {
    usedBonusesPart,
    // nn to force list expansion; 10 to avoid clashing with previous numbers
    nn10: usedBonusesArray,
  };
}

export function sekiGetOutcomeTranslation(outcome, gameUiCtx) {
  const { mode, submode, winnerRole } = outcome;

  const { cs } = gameUiCtx;
  const { roleToPlayerName } = cs;
  const playerName = roleToPlayerName[winnerRole];

  const submodeTranslation = sekiGetModeForHumans(submode);

  return ['%n wins the game: %n2', { n: playerName, n2: submodeTranslation }];
}

export function sekiGetLogItemTemplate(templateCode, args) {
  switch (templateCode) {
    case 'gameStructure/startWeek': {
      return {
        template: '%headerL',
        addArgs: {
          headerL: `Week ${args.n}`,
        },
      };
    }
    case 'gameStructure/endWeek': {
      return {
        template:
          '%header%r1 controls %n11 and %n12; %r2 controls %n21 and %n22.',
        addArgs: {
          header: `End of week`,
          n11: pluralize(args.n11, 'castle'),
          n12: pluralize(args.n12, 'resource location'),
          n21: pluralize(args.n21, 'castle'),
          n22: pluralize(args.n22, 'resource location'),
        },
      };
    }
    case 'gameStructure/mainCharacterCaptured': {
      return {
        template: '%c is captured.',
      };
    }
    case 'gameStructure/gameEndedByTimeLimit': {
      return {
        template: '%header%r1 scores %n13 points, %r2 scores %n23 points.',
        addArgs: {
          header: 'Final scoring',
        },
      };
    }
    case 'gameStructure/winnerDeclared': {
      return {
        template: '%r wins the game.',
        highlightClass: args.r,
      };
    }
    case 'gameStructure/mainCharacterKilled': {
      return {
        template: '%c is killed.',
      };
    }
    case 'reinforcementsStep/start': {
      return {
        template:
          '%header%r1 controls %n11 and will draw %n12; %r2 controls %n13 and will draw %n14.%br%r1 controls %n15 and will recruit %n16; %r2 controls %n17 and will recruit %n18.',
        addArgs: {
          header: `Reinforcements step`,
          r1: 'i',
          r2: 't',
          n11: pluralize(args.n1, 'castle'),
          n12: pluralize(args.n2, 'card'),
          n13: pluralize(args.n3, 'castle'),
          n14: pluralize(args.n4, 'card'),
          n15: pluralize(args.n5, 'resource location'),
          n16: pluralize(args.n6, 'unit'),
          n17: pluralize(args.n7, 'resource location'),
          n18: pluralize(args.n8, 'unit'),
        },
      };
    }
    case 'reinforcementsStep/cardsDiscarded': {
      return {
        template: '%r discards %cc.',
        highlightClass: args.r,
      };
    }
    case 'reinforcementsStep/noCardsDiscarded': {
      return {
        template: '%r does not discard any cards.',
        highlightClass: args.r,
      };
    }
    case 'reinforcementsStep/reinforcingDelayed': {
      return {
        template:
          '%r is ready with discards, but delaying draws until the opponent is ready.',
        highlightClass: args.r,
      };
    }
    case 'reinforcementsStep/cardsDiscardedForReference': {
      return {
        template: `%r's discarded cards were %cc.`,
      };
    }
    case 'turnOrderStep/start': {
      return {
        template: '%header',
        addArgs: {
          header: `Turn order step`,
        },
      };
    }
    case 'turnOrderStep/playerChoseCard': {
      return {
        template: '%r chooses %c for turn order.',
        highlightClass: args.r,
      };
    }
    case 'turnOrderStep/cardsRevealed': {
      return {
        template:
          '%r1 reveals %c1\u202F(%n1); %r2 reveals %c2\u202F(%n2).  %r3 will choose the first player.',
      };
    }
    case 'turnOrderStep/firstPlayerChosen': {
      return {
        template: '%r chooses %role to go first.',
        highlightClass: args.r,
      };
    }
    case 'movementPhase/startPhase': {
      return {
        template: '%header',
        addArgs: {
          header: `${sekiGetModeForHumans(args.mode1)}${sekiGetModeForHumans(
            args.mode2
          )} movement${dashSeparator}${sekiGetRoleForHumans(args.r)}`,
        },
      };
    }
    case 'buyMovement/noneBought': {
      return {
        template: '%r buys %n movement, replenishing cards instead.',
        highlightClass: args.r,
        addArgs: { n: 'no' }, // many contortions to make "no" bold
      };
    }
    case 'buyMovement/someBought': {
      const { cc } = args;
      if (cc.length === 0) {
        return {
          template: '%r gets %mode movement.',
          highlightClass: args.r,
        };
      } else {
        return {
          template: '%r discards %cc to buy %mode movement.',
          highlightClass: args.r,
        };
      }
    }
    case 'replenishCards/cardsDiscarded': {
      const { cc } = args;
      if (cc.length === 0) {
        return {
          template: '%r does not discard any cards.',
          highlightClass: args.r,
        };
      } else {
        return {
          template: '%r discards %cc to replenish.',
          highlightClass: args.r,
        };
      }
    }
    case 'cardsDrawn': {
      return {
        template: '%r draws %cc.',
        highlightClass: args.r,
      };
    }
    case 'unitsDrawn': {
      return {
        template: '%r recruits %cc.',
        highlightClass: args.r,
      };
    }
    case 'cardsDrawnWithReshuffling': {
      return {
        template: '%r draws %cc1, reshuffles their deck, and draws %cc2.',
        highlightClass: args.r,
      };
    }
    case 'cardsDrawnWithOnlyReshuffling': {
      return {
        template: '%r reshuffles their deck and draws %cc2.',
        highlightClass: args.r,
      };
    }
    case 'combatPhase/start': // XXX remove me
    case 'combatPhase/startPhase': {
      return {
        template: '%header',
        addArgs: {
          header: `${sekiGetModeForHumans(args.mode1)}${sekiGetModeForHumans(
            args.mode2
          )} combat`,
        },
      };
    }
    case 'combatPhase/noContestedLocations': {
      return {
        template: 'There are no contested locations.',
      };
    }
    case 'combatPhase/someContestedLocations': {
      return {
        template: 'Contested locations: %ll.',
      };
    }
    case 'movementPhase/startMovement': {
      const { viaPart } = processPassingLocationIds(args['ll']);

      const { usedBonusesPart, nn10 } = processUsedBonuses(
        args['usedBonuses'] || {}
      );

      return {
        template: `%r activates %l1 and moves %cc${viaPart} to %l2${usedBonusesPart}.`,
        highlightClass: args.r,
        addArgs: {
          nn10,
        },
      };
    }
    case 'movementPhase/continueMovement': {
      const { viaPart } = processPassingLocationIds(args['ll']);

      const { usedBonusesPart, nn10 } = processUsedBonuses(
        args['usedBonuses'] || {}
      );

      return {
        template: `%r continues moving${viaPart} to %l2${usedBonusesPart}.`,
        highlightClass: args.r,
        addArgs: {
          nn10,
        },
      };
    }
    case 'movementPhase/followOnMovement': {
      const { viaPart } = processPassingLocationIds(args['ll']);

      const { usedBonusesPart, nn10 } = processUsedBonuses(
        args['usedBonuses'] || {}
      );

      return {
        template: `%r follows on from %l1 and moves %cc${viaPart} to %l2${usedBonusesPart}.`,
        highlightClass: args.r,
        addArgs: {
          nn10,
        },
      };
    }
    case 'movementPhase/overrun': {
      return {
        template: '%r overruns %cc at %l.',
        highlightClass: args.r,
      };
    }
    case 'movementPhase/dropOff': {
      return {
        template: '%r drops off %cc.',
        highlightClass: args.r,
      };
    }
    case 'cardsAwardedForLosses': {
      return {
        template: '%r receives %cards for losing %units.',
        highlightClass: args.r,
        addArgs: {
          cards: args.n1,
          units: args.n2,
        },
      };
    }
    case 'movementPhase/skippedRemainingActivations': {
      return {
        template: '%r skips remaining activations.',
        highlightClass: args.r,
      };
    }
    case 'movementPhase/activationStarted': {
      return {
        template: '%headerS',
        addArgs: {
          headerS: `Activation ${args.n1}/${
            args.n2
          }${dashSeparator}${sekiGetLocationForHumans(args['l'])}`,
        },
      };
    }
    case 'movementPhase/musteringActivationStarted': {
      return {
        template: '%headerS',
        addArgs: {
          headerS: `Activation ${args.n1}/${args.n2}${dashSeparator}mustering`,
        },
      };
    }
    case 'movementPhase/mustered': {
      return {
        template: `%r musters %cc into %l.`,
        highlightClass: args.r,
      };
    }
    case 'movementPhase/moriBrought': {
      return {
        template: `%r brings into play %cc at %l by sacrificing %cc1.`,
        highlightClass: args.r,
      };
    }
    case 'rc/playerClaims': {
      return {
        template: '%r1 claims %l.',
        highlightClass: args.r1,
      };
    }
    case 'rc/playerClaimsFrom': {
      return {
        template: '%r1 reclaims %l from %r2.',
        highlightClass: args.r1,
      };
    }
    case 'cc/playerReclaims': {
      return {
        template: '%r1 takes control over the castle at %l from %r2.',
        highlightClass: args.r1,
      };
    }
    case 'cc/playerCedes': {
      return {
        template: '%r1 cedes control over the castle at %l to %r2.',
        highlightClass: args.r1,
      };
    }
    case 'combatPhase/combatDeclared': {
      return {
        template: '%headerS',
        highlightClass: args.r,
        addArgs: {
          headerS: `Combat ${args.n1}/${
            args.n2
          }${dashSeparator}${sekiGetLocationForHumans(args['l'])}`,
        },
      };
    }
    case 'combatPhase/unpredictedCombatStarted': {
      return {
        template: '%headerS%r is the attacker.',
        highlightClass: args.r,
        addArgs: {
          headerS: `Follow-on combat${dashSeparator}${sekiGetLocationForHumans(
            args['l']
          )}`,
        },
      };
    }
    case 'combatPhase/stayedInCastle': {
      return {
        template: '%r stays in the castle.',
        highlightClass: args.r,
      };
    }
    case 'combatPhase/wentOutToBattle': {
      return {
        template: '%r chooses to battle outside the castle.',
        highlightClass: args.r,
      };
    }
    case 'combatPhase/defenseStayedInCastle': {
      return {
        template: '%r stays in the castle.',
        highlightClass: args.r,
      };
    }
    case 'combatPhase/defenseAcceptedBattle': {
      return {
        template: '%r chooses to battle outside the castle.',
        highlightClass: args.r,
      };
    }
    case 'combatPhase/playerIsDoneDeployingUnits': {
      return {
        template: '%r will deploy no more units.',
        highlightClass: args.r,
      };
    }
    case 'combatPhase/deployedCardlessly': {
      return {
        template: '%r deploys %c without a card for +%n1 impact, now %n2.',
        highlightClass: args.r,
      };
    }
    case 'combatPhase/deployedUnits': {
      const howPart = args.cc20.length === 0 ? 'without a card' : 'with %cc20';
      const explanationChunks = [
        args.n1 > args.n3 ? `%n3 strength` : null,
        args.n4 > 0 ? `%n4 clan bonus` : null,
        args.n5 > 0 ? `%n5 %mode bonus` : null,
      ];
      const explanation = explanationChunks.filter((p) => !!p).join(', ');
      const explanationPart = explanation !== '' ? ` (${explanation})` : '';
      return {
        template: `%r deploys %cc ${howPart} for +%n1 impact${explanationPart}, now %n2.`,
        highlightClass: args.r,
      };
    }
    case 'combatPhase/battleOutcome': {
      return {
        template: `%r1 wins the battle with %n11 impact vs %r2's %n21 impact. %r1 will take %n12, %r2 will take %n22.`,
        addArgs: {
          n12: pluralize(args.n12, 'loss', 'losses'),
          n22: pluralize(args.n22, 'loss', 'losses'),
        },
      };
    }
    case 'combatPhase/siegeOutcome': {
      return {
        template:
          '%r1 finishes the siege, scoring %n11 impact. %r2 will take %n22.',
        addArgs: {
          n22: pluralize(args.n22, 'loss', 'losses'),
        },
      };
    }
    case 'combatPhase/lossesApplied': {
      return {
        template: '%r loses %cc.',
        highlightClass: args.r,
      };
    }
    case 'combatPhase/retreatedToCastle': {
      return {
        template: '%r retreats %cc into the castle at %l.',
        highlightClass: args.r,
      };
    }
    case 'combatPhase/retreatedToAdjacentLocation': {
      return {
        template: '%r retreats %cc to %l.',
        highlightClass: args.r,
      };
    }
    default: {
      warnOnce(
        `sekiGetLogItemTemplate: Unrecognized templateCode: ${templateCode}`,
        args
      );
      return {
        template: `?${templateCode}?`,
      };
    }
  }
}
