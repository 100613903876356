import {
  createOrderGroup,
  registerOrderGroup,
  generalSource,
  makeCardSource,
  makePlayerSource,
  makeZoneSourceFromRoleAndSlug,
} from '../ordersInfrastructure';
import { applyAction } from '../actionsInfrastructure';
import { fa } from '../foundationActions';
import {
  getStateVar,
  getStateNumberVar,
  walkCards,
  getStateStashedValue,
  buildZoneName,
  getCardsInPlayerZone,
  findCardsBySids,
  qualifiedName,
  removeAll,
  getBaseFromSid,
} from '../utils';
import {
  Role,
  OrderSourceCard,
  OrderSourcePlayer,
  OrderSourceZone,
  CardInRegion,
} from '../types';
import { MovementTarget, RetreatConfiguration } from './sekiTypes';
import {
  bothRoles,
  sekiFindLocationById,
  sekiGetUnitInfoByBase,
  sekiClanToMusteringLocationId,
  sekiGetOpponentRole,
  sekiLocationData,
} from './sekiData';
import {
  determineMovementParameters,
  collectMovementTargets,
  getMovableUnitsInLocation,
  collectAvailableMusteringLocations,
  sekiGetUnitInfoBySid,
  sekiGetCardInfoOfCard,
  getIsPlayerWalled,
  sekiCalculateAddedImpact,
  getEnemyRelationshipOnMovingTo,
  collectUndeployedUnitCards,
} from './sekiUtils';
import { sekia } from './sekiActions';
import { EError, ActionError } from '../errors';

export type FollowOnStack = {
  locationId: string;
  sids: string[];
  movementState?: any;
};

export const sekiReinforcementsStepOrders = createOrderGroup(
  'seki',
  'reinforcementsStep',
  {
    precondition(state) {
      return getStateVar(state, 'step') === 'reinforcements';
    },
  },
  {
    discardCards: {
      collect(state) {
        return bothRoles.map((role) => {
          if (getStateVar(state, `${role}:discardedForReinforcements`)) {
            return null;
          }

          const cards = getCardsInPlayerZone(state, role, 'hand');

          const n = Math.floor(cards.length / 2);
          if (n === 0) {
            return null;
          }
          return {
            role,
            orderType: 'chooseZoneCards',
            source: makeZoneSourceFromRoleAndSlug(role, 'hand'),
            additionalData: {
              cards,
              minimum: n,
              maximum: n,
            },
          };
        });
      },
      execute(state, order, filledOrder) {
        const { role } = order;
        const { cids } = filledOrder;

        const sids = findSidsByCids(state, cids, role);
        const cards = findCardsBySids(state, sids);

        return applyAction(
          state,

          sekia.onReinforcementsCardsChosen(role, cards)
        );
      },
    },
  }
);
registerOrderGroup(sekiReinforcementsStepOrders);

export const sekiTurnOrderOrders = createOrderGroup(
  'seki',
  'turnOrder',
  {
    precondition(state) {
      return getStateVar(state, 'step') === 'turnOrder';
    },
  },
  {
    chooseTurnOrderCard: {
      collect(state) {
        return walkCards(
          state,
          {
            roles: bothRoles,
            slugs: 'hand',
          },
          (card, location, role) => {
            if (getCardsInPlayerZone(state, role, 'chosen').length === 1) {
              return null;
            }
            return {
              role,
              orderType: 'simpleOnCard',
              source: makeCardSource(card),
              additionalData: {
                location,
              },
            };
          }
        );
      },
      execute(state, order, filledOrder) {
        let { role, source } = order;
        source = source as OrderSourceCard;
        const { sid } = source.card;

        return applyAction(
          state,
          fa.seq(
            fa.moveCard(sid, buildZoneName(role, 'chosen'), {
              facing: 'facedown',
              sourceLocation: order.additionalData.location,
              saveAs: 'card',
            }),
            fa.text('turnOrderStep/playerChoseCard', {
              r: { value: role },
              c: { fromStash: 'card' },
            }),
            sekia.proceedToChoosingFirstPlayerIfTurnOrderCardsAreReady()
          )
        );
      },
    },
    chooseFirstPlayer: {
      collect(state) {
        const chooser = getStateVar(
          state,
          'whoChoosesFirstPlayer',
          null
        ) as Role | null;
        if (!chooser) {
          return null;
        }

        return bothRoles.map((chosenRole) => {
          return {
            role: chooser,
            orderType: 'simpleOnPlayer',
            source: makePlayerSource(chosenRole),
          };
        });
      },
      execute(state, order, filledOrder) {
        const { role: chooserRole } = order;
        const chosenRole = (order.source as OrderSourcePlayer).role;

        return applyAction(
          state,
          fa.seq(
            fa.text('turnOrderStep/firstPlayerChosen', {
              r: { value: chooserRole },
              role: { value: chosenRole },
            }),
            fa.moveCard(
              getCardsInPlayerZone(state, 'i', 'chosen')[0].sid,
              'i:discard'
            ),
            fa.moveCard(
              getCardsInPlayerZone(state, 't', 'chosen')[0].sid,
              't:discard'
            ),
            fa.setVars({
              whoChoosesFirstPlayer: null,
              firstPlayerRole: chosenRole,
              step: 'turns',
              letter: 'a',
              phasingPlayerLabel: 'first',
            }),
            sekia.startMovementPhase()
          )
        );
      },
    },
  }
);
registerOrderGroup(sekiTurnOrderOrders);

export function findSidByCid(state, cid, role) {
  const registry = state.roleToCardRegistry[role];
  return registry.cidToSid[cid];
}

export function findSidsByCids(state, cids, role) {
  return cids.map((cid) => {
    return findSidByCid(state, cid, role);
  });
}

export function sekiGetLocationIdFromZone(zoneName: string): string {
  // TODO check if it's indeed a location
  return zoneName.split(':')[1];
}

export function getActivateLocationOrderState(state) {
  const role = getStateVar(state, 'whoActivatesLocation', null) as Role | null;

  let isCurrent = true;

  if (!role) {
    isCurrent = false;
  }
  // If in the middle of a subsequence, don't offer to activate.
  else if (
    !!getStateVar(state, 'whoChoosesMovementTarget', null) ||
    !!getStateVar(state, 'whoChoosesFollowOnMovement', null)
  ) {
    isCurrent = false;
  }

  return { isCurrent, role };
}

export const sekiMovementPhaseOrders = createOrderGroup(
  'seki',
  'movementPhase',
  {
    precondition(state) {
      return (
        getStateVar(state, 'step') === 'turns' &&
        getStateVar(state, 'phase') === 'movement'
      );
    },
  },
  {
    buyMovement: {
      collect(state) {
        const role = getStateVar(state, 'whoBuysMovement', null) as
          | string
          | null;
        if (!role) {
          return null;
        }

        const cards = getCardsInPlayerZone(state, role, 'hand');
        return {
          role,
          orderType: 'chooseZoneCardsAndMode',
          source: makeZoneSourceFromRoleAndSlug(role, 'hand'),
          additionalData: {
            modes: ['no', 'minimal', 'limited', 'total'],
            cards,
            minimum: 0,
            maximum: 2,
          },
        };
      },
      execute(state, order, filledOrder) {
        const { role } = order;
        const { cids, mode } = filledOrder;

        const sids = findSidsByCids(state, cids, role);

        if (
          !(
            (cids.length === 0 && (mode === 'no' || mode === 'minimal')) ||
            (cids.length === 1 && mode === 'limited') ||
            (cids.length === 2 && mode === 'total')
          )
        ) {
          throw new EError('buyMovement: order mode&cids do not obey rules', {
            filledOrder,
          });
        }

        const cards = findCardsBySids(state, sids);
        return applyAction(
          state,
          fa.seq(
            fa.setVars({
              whoBuysMovement: null,
            }),
            mode === 'no'
              ? fa.seq(
                  fa.text('buyMovement/noneBought', {
                    r: { value: role },
                  }),
                  fa.setVars({
                    whoReplenishesCards: role,
                  })
                )
              : fa.seq(
                  sekia.discardCardsLowLevel(role, cards, {
                    saveAs: 'discarded',
                  }),
                  fa.text('buyMovement/someBought', {
                    r: { value: role },
                    mode: { value: mode },
                    cc: { fromStash: 'discarded' },
                  }),
                  sekia.onMovementsBought(role, mode)
                )
          )
        );
      },
    },
    replenishCards: {
      collect(state) {
        const role = getStateVar(state, 'whoReplenishesCards', null) as
          | string
          | null;
        if (!role) {
          return null;
        }

        const cards = getCardsInPlayerZone(state, role, 'hand');
        return {
          role,
          orderType: 'chooseZoneCards',
          source: makeZoneSourceFromRoleAndSlug(role, 'hand'),
          additionalData: {
            cards,
            minimum: 0,
            maximum: cards.length,
          },
        };
      },
      execute(state, order, filledOrder) {
        const { role } = order;
        const { cids, mode } = filledOrder;

        const sids = findSidsByCids(state, cids, role);
        const cards = findCardsBySids(state, sids);
        return applyAction(
          state,
          fa.seq(
            fa.setVars({
              whoReplenishesCards: null,
            }),
            sekia.discardCardsLowLevel(role, cards, {
              saveAs: 'discarded',
            }),
            fa.text('replenishCards/cardsDiscarded', {
              r: { value: role },
              cc: { fromStash: 'discarded' },
            }),
            ...(cards.length === 0
              ? []
              : [sekia.drawCardsReporting(role, cards.length)]),
            sekia.endMovementPhase()
          )
        );
      },
    },
    activateLocation: {
      collect(state) {
        const { role, isCurrent } = getActivateLocationOrderState(state);
        if (!isCurrent) {
          return null;
        }

        const activatableLocationIds = getStateVar(
          state,
          'activatableLocationIds'
        ) as string[];

        return activatableLocationIds.map((locationId) => {
          if (locationId === `${role}box`) {
            // Mustering is in another order.
            return null;
          }
          const units = getMovableUnitsInLocation(state, role, locationId);

          return {
            role,
            orderType: 'chooseZoneCards',
            source: makeZoneSourceFromRoleAndSlug(role, locationId),
            additionalData: {
              cards: units,
              minimum: 1,
              maximum: Math.min(units.length, 16),
            },
          };
        });
      },
      execute(state, order, filledOrder) {
        const { role } = order;
        const zoneName = (order.source as OrderSourceZone).zoneName;
        const locationId = sekiGetLocationIdFromZone(zoneName);

        const { cids } = filledOrder;
        const sids = findSidsByCids(state, cids, role);

        const movementParameters = determineMovementParameters(
          state,
          role,
          locationId,
          sids
        );

        const targets = collectMovementTargets(
          state,
          role,
          locationId,
          sids,
          movementParameters,
          'start'
        );

        if (targets.length === 0) {
          // TODO error or something?
          return applyAction(
            state,
            fa.seq(
              fa.setVars({
                whoChoosesMovementTarget: null,
              })
            )
          );
        }

        return applyAction(
          state,
          fa.seq(
            fa.setVars({
              activeLocationId: locationId,
              whoChoosesMovementTarget: role,
            }),
            fa.setStashedValues({
              // Must be in stash because vars are shared.
              movingUnitSids: sids,
              movementParameters,
              movementTargets: targets,
              movementMode: 'start',
            })
          )
        );
      },
    },
    skipRemainingActivations: {
      collect(state) {
        const { role, isCurrent } = getActivateLocationOrderState(state);
        if (!isCurrent) {
          return null;
        }

        return {
          role,
          orderType: 'simple',
          source: generalSource,
        };
      },
      execute(state, order, filledOrder) {
        const { role } = order;

        return applyAction(
          state,
          fa.seq(
            fa.text('movementPhase/skippedRemainingActivations', {
              r: role,
            }),
            fa.setVars({
              activationNumber: null,
              whoActivatesLocation: null,
              activatableLocationIds: null,
            }),
            sekia.endMovementPhase()
          )
        );
      },
    },
    chooseFollowOnMovement: {
      collect(state) {
        const role = getStateVar(
          state,
          'whoChoosesFollowOnMovement',
          null
        ) as Role | null;

        if (!role) {
          return null;
        }

        const followOnStacks = getStateStashedValue(
          state,
          'followOnStacks'
        ) as FollowOnStack[];

        return followOnStacks.map((followOnStack) => {
          const { locationId, sids } = followOnStack;
          const cards = findCardsBySids(state, sids);
          return {
            role,
            orderType: 'chooseZoneCards',
            source: makeZoneSourceFromRoleAndSlug(role, locationId),
            additionalData: {
              cards,
              minimum: 1,
              maximum: cards.length,
            },
          };
        });
      },
      execute(state, order, filledOrder) {
        const { role, source } = order;

        const { cids } = filledOrder;
        const sids = findSidsByCids(state, cids, role);

        const zoneName = (source as OrderSourceZone).zoneName;
        const locationId = sekiGetLocationIdFromZone(zoneName);

        return applyAction(
          state,
          fa.seq(
            fa.setStashedValues({
              followOnLocationId: locationId,
            }),
            fa.setVars({
              whoChoosesFollowOnMovement: null,
            }),
            sekia.startFollowOnMovement(role, locationId, sids)
          )
        );
      },
    },
    skipFollowOnMovements: {
      collect(state) {
        const role = getStateVar(
          state,
          'whoChoosesFollowOnMovement',
          null
        ) as Role | null;

        if (!role) {
          return null;
        }

        return {
          role,
          orderType: 'simple',
          source: generalSource,
        };
      },
      execute(state, order, filledOrder) {
        const followOnStacks = getStateStashedValue(
          state,
          'followOnStacks'
        ) as FollowOnStack[];

        return applyAction(
          state,
          fa.seq(
            fa.setStashedValues({
              followOnStacks: null,
            }),
            // Those units that remain in active location technically haven't
            // moved, but we cannot activate the same location twice so it's ok
            // to treat them equally.
            sekia.setUnitsInStacksTired(followOnStacks),
            fa.setVars({
              whoChoosesFollowOnMovement: null,
            }),
            sekia.endActivation()
          )
        );
      },
    },
    changeMovingUnitsOnActivation: {
      collect(state) {
        const role = getStateVar(
          state,
          'whoChoosesMovementTarget',
          null
        ) as Role | null;
        if (!role) {
          return null;
        }

        const movementMode = getStateStashedValue(state, 'movementMode', null);
        if (!(movementMode === 'start')) {
          return null;
        }

        const movingUnitSids = getStateStashedValue(state, 'movingUnitSids');

        const locationId = getStateVar(state, 'activeLocationId');

        const units = getMovableUnitsInLocation(state, role, locationId);

        return {
          role,
          orderType: 'chooseZoneCards',
          source: makeZoneSourceFromRoleAndSlug(role, locationId),
          additionalData: {
            cards: units,
            defaultChosenCards: findCardsBySids(state, movingUnitSids),
            minimum: 0,
            maximum: Math.min(units.length, 16),
          },
        };
      },
      execute(state, order, filledOrder) {
        const { role } = order;
        const { cids } = filledOrder;

        const locationId = getStateVar(state, 'activeLocationId');
        const sids = findSidsByCids(state, cids, role);

        if (sids.length === 0) {
          // thought better of this activation
          return applyAction(
            state,
            fa.seq(
              fa.setVars({
                whoChoosesMovementTarget: null,
                activeLocationId: null,
              }),
              fa.setStashedValues({
                movingUnitSids: null,
                movementTargets: null,
                movementParameters: null,
              })
            )
          );
        } else {
          const movementParameters = determineMovementParameters(
            state,
            role,
            locationId,
            sids
          );
          const targets = collectMovementTargets(
            state,
            role,
            locationId,
            sids,
            movementParameters,
            'start'
          );

          return applyAction(
            state,
            fa.seq(
              fa.setStashedValues({
                movingUnitSids: sids,
                movementTargets: targets,
                movementParameters,
              })
            )
          );
        }
      },
    },
    chooseMovementTarget: {
      collect(state) {
        const role = getStateVar(
          state,
          'whoChoosesMovementTarget',
          null
        ) as Role | null;

        if (!role) {
          return null;
        }

        const movementTargets = getStateStashedValue(
          state,
          'movementTargets',
          null
        ) as MovementTarget[] | null;

        if (movementTargets === null) {
          return null;
        }

        const movingUnitSids = getStateStashedValue(
          state,
          'movingUnitSids',
          null
        ) as string[] | null;

        if (movingUnitSids === null) {
          throw new EError(
            'collecting chooseMovementTarget: must have movingUnitSids',
            { role, movementTargets }
          );
        }

        return movementTargets.map((movementTarget) => {
          const { targetLocationId } = movementTarget;
          const source = makeZoneSourceFromRoleAndSlug(role, targetLocationId);
          return {
            role,
            // cards are units to drop off. Choosing all will end movement
            // (allowing to continue movement with previously-dropped-off
            // units or those left behind at activated location, or if none,
            // then end activation).
            orderType: 'chooseZoneCards',
            source,
            additionalData: {
              cards: findCardsBySids(state, movingUnitSids),
              minimum: movementTarget.isFinal ? movingUnitSids.length : 0,
              maximum: movingUnitSids.length,
              movementTarget,
            },
          };
        });
      },
      execute(state, order, filledOrder) {
        const { role } = order;

        const { cids } = filledOrder;
        const droppingOffSids = findSidsByCids(state, cids, role);

        const { movementTarget } = order.additionalData;

        let usesDeclaredLeader = false;
        let usesForcedMarch = false;

        if (movementTarget.dlfmChoice) {
          const { dlfmAnswer } = filledOrder.additionalData;
          if (dlfmAnswer === 'chooseDl') {
            usesDeclaredLeader = true;
          } else if (dlfmAnswer === 'chooseFm') {
            usesForcedMarch = true;
          } else {
            throw new ActionError(
              'when movementTarget has dlfmChoice, the filledOrder must contain dlfmAnswer',
              {
                order,
                filledOrder,
              }
            );
          }
        } else {
          if (movementTarget.dl) {
            usesDeclaredLeader = true;
          }
          if (movementTarget.fm) {
            usesForcedMarch = true;
          }
        }

        const usedBonuses = {
          h: movementTarget.h,
          al: movementTarget.al,
          dl: usesDeclaredLeader,
          fm: usesForcedMarch,
        };

        const previousMovementState = getStateStashedValue(
          state,
          'movementState',
          null
        ) as any;

        const allUsedBonuses = previousMovementState
          ? {
              h: movementTarget.h || previousMovementState.allUsedBonuses.h,
              al: movementTarget.al || previousMovementState.allUsedBonuses.al,
              dl: usesDeclaredLeader || previousMovementState.allUsedBonuses.dl,
              fm: usesForcedMarch || previousMovementState.allUsedBonuses.fm,
            }
          : usedBonuses;

        const committedDropOff = previousMovementState
          ? {
              sids: previousMovementState.droppingOffSids,
              movementState: previousMovementState,
            }
          : null;

        let forcedMarchPaymentSid = null;
        if (usesForcedMarch) {
          const { fmCids } = filledOrder;
          if (!(fmCids && fmCids.length === 1)) {
            throw new ActionError(
              'when forced march is used, must supply cid in fmCids',
              {
                filledOrder,
                order,
              }
            );
          }

          const sid = findSidByCid(state, fmCids[0], role);

          if (
            !(
              sid &&
              getCardsInPlayerZone(state, role, 'hand').find(
                (card) => card.sid === sid
              )
            )
          ) {
            throw new ActionError(
              'when forced march is used, must supply known cid from hand',
              {
                filledOrder,
                order,
              }
            );
          }

          forcedMarchPaymentSid = sid;
        }

        return applyAction(
          state,
          fa.seq(
            fa.setVars({
              whoChoosesMovementTarget: null,
            }),
            forcedMarchPaymentSid
              ? sekia.discardCardsLowLevel(
                  role,
                  findCardsBySids(state, [forcedMarchPaymentSid]),
                  { saveAs: 'fmPayment' }
                )
              : fa.nop(),

            fa.setStashedValues({
              movementState: {
                role,
                movementTarget,
                droppingOffSids,
                usedBonuses,
                allUsedBonuses,
                committedDropOff,
              },
            }),
            sekia.resolveMovement()
          )
        );
      },
    },
    changeMovingUnitsOnContinuing: {
      collect(state) {
        const role = getStateVar(
          state,
          'whoChoosesMovementTarget',
          null
        ) as Role | null;
        if (!role) {
          return null;
        }

        const movementMode = getStateStashedValue(state, 'movementMode', null);
        if (!(movementMode === 'continue')) {
          return null;
        }

        const movementState = getStateStashedValue(
          state,
          'movementState'
        ) as any;
        const { movementTarget, droppingOffSids } = movementState;

        const movingUnitSids = getStateStashedValue<string[]>(
          state,
          'movingUnitSids'
        );

        const units = findCardsBySids(state, [
          ...droppingOffSids,
          ...movingUnitSids,
        ]);

        const locationId = movementTarget.targetLocationId;

        return {
          role,
          orderType: 'chooseZoneCards',
          source: makeZoneSourceFromRoleAndSlug(role, locationId),
          additionalData: {
            cards: units,
            defaultChosenCards: findCardsBySids(state, droppingOffSids),
            // movementTarget cannot be isFinal because if it is, then we've
            // already stopped.
            minimum: 0,
            maximum: units.length,
          },
        };
      },
      execute(state, order, filledOrder) {
        const { role } = order;
        const { cids } = filledOrder;

        const chosenSids = findSidsByCids(state, cids, role);

        const movingUnitSids = getStateStashedValue(
          state,
          'movingUnitSids'
        ) as string[];

        const movementState = getStateStashedValue(
          state,
          'movementState'
        ) as any;

        const { movementTarget, droppingOffSids, allUsedBonuses } =
          movementState;

        const allMovingSids = [...droppingOffSids, ...movingUnitSids];

        const newMovingUnitSids = removeAll(allMovingSids, chosenSids);

        if (newMovingUnitSids.length === 0) {
          return applyAction(state, fa.seq(sekia.endOneMovement()));
        } else {
          const movementParameters = getStateStashedValue(
            state,
            'movementParameters'
          );

          const targets = collectMovementTargets(
            state,
            role,
            movementTarget.targetLocationId,
            newMovingUnitSids,
            movementParameters,
            'continue',
            movementTarget,
            allUsedBonuses
          );

          return applyAction(
            state,
            fa.seq(
              fa.setVars({
                whoChoosesMovementTarget: role,
              }),
              fa.setStashedValues({
                movementState: {
                  ...movementState,
                  droppingOffSids: chosenSids,
                },
                movingUnitSids: newMovingUnitSids,
              })
            )
          );
        }
      },
    },
    changeMovingUnitsOnFollowOn: {
      collect(state) {
        const role = getStateVar(
          state,
          'whoChoosesMovementTarget',
          null
        ) as Role | null;
        if (!role) {
          return null;
        }

        const movementMode = getStateStashedValue(state, 'movementMode', null);
        if (!(movementMode === 'followOn')) {
          return null;
        }

        const locationId = getStateStashedValue(state, 'followOnLocationId');
        const followOnStacks = getStateStashedValue<FollowOnStack[]>(
          state,
          'followOnStacks'
        );
        const stack = followOnStacks.find(
          (stack) => stack.locationId === locationId
        );
        if (!stack) {
          throw new ActionError('must have stack at followOnLocationId', {
            locationId,
            followOnStacks,
          });
        }
        const units = findCardsBySids(state, stack.sids);

        const movingUnitSids = getStateStashedValue(state, 'movingUnitSids');

        return {
          role,
          orderType: 'chooseZoneCards',
          source: makeZoneSourceFromRoleAndSlug(role, locationId),
          additionalData: {
            cards: units,
            defaultChosenCards: findCardsBySids(state, movingUnitSids),
            minimum: 0,
            maximum: units.length,
          },
        };
      },
      execute(state, order, filledOrder) {
        const { role } = order;
        const { cids } = filledOrder;

        const chosenSids = findSidsByCids(state, cids, role);

        const locationId = getStateStashedValue(state, 'followOnLocationId');

        if (chosenSids.length === 0) {
          return applyAction(state, fa.seq(sekia.endOneMovement()));
        } else {
          return applyAction(
            state,
            fa.seq(
              fa.setVars({
                whoChoosesMovementTarget: null,
              }),
              sekia.startFollowOnMovement(role, locationId, chosenSids)
            )
          );
        }
      },
    },
    musterUnits: {
      collect(state) {
        const { role, isCurrent } = getActivateLocationOrderState(state);
        if (!isCurrent) {
          return null;
        }

        const activatableLocationIds = getStateVar(
          state,
          'activatableLocationIds'
        ) as string[];

        const boxLocationId = activatableLocationIds.find(
          (locationId) => locationId === `${role}box`
        );

        if (!boxLocationId) {
          return null;
        }

        const unitCards = getCardsInPlayerZone(state, role, boxLocationId);
        const musteringLocations = collectAvailableMusteringLocations(
          state,
          role
        );

        if (musteringLocations.length === 0) {
          return null;
        }

        return {
          role,
          orderType: 'chooseZoneCardsAndMode',
          source: makeZoneSourceFromRoleAndSlug(role, boxLocationId),
          additionalData: {
            cards: unitCards,
            minimum: 1,
            maximum: unitCards.length,
            modes: ['hiddenMustering', 'openMustering'],
            musteringLocationIds: musteringLocations,
          },
        };
      },
      execute(state, order, filledOrder) {
        const { role } = order;
        const { cids, locationId, mode } = filledOrder;
        const sids = findSidsByCids(state, cids, role);

        const { musteringLocationIds } = order.additionalData;
        if (!musteringLocationIds.includes(locationId)) {
          throw new ActionError('must muster into available location', {
            order,
            filledOrder,
          });
        }

        if (mode === 'hiddenMustering') {
          if (!(sids.length === 1)) {
            throw new ActionError(
              'hiddenMustering must occur with a single unit',
              {
                order,
                filledOrder,
              }
            );
          }
        } else {
          if (!(sids.length > 1)) {
            throw new ActionError('openMustering must be with 2+ units', {
              order,
              filledOrder,
            });
          }

          const clans = {};
          const units = sids.map((sid) => {
            const unitInfo = sekiGetUnitInfoBySid(sid);
            const { clan } = unitInfo;
            clans[clan] = 1;
          });

          if (Object.keys(clans).length !== 1) {
            throw new ActionError('with openMustering, all clans must match', {
              order,
              filledOrder,
            });
          }

          if (
            !(
              locationId ===
              sekiClanToMusteringLocationId[Object.keys(clans)[0]]
            )
          ) {
            throw new ActionError(
              'with openMustering, location must be the clan home',
              {
                order,
                filledOrder,
              }
            );
          }
        }

        const activationNumber = getStateNumberVar(state, 'activationNumber');
        const nActivations = getStateNumberVar(state, 'nActivations');

        return applyAction(
          state,
          fa.seq(
            sekia.moveUnitsBySids(sids, qualifiedName(role, locationId), {
              facing: mode === 'hiddenMustering' ? 'facedown' : 'faceup',
              saveAs: 'mustered',
            }),
            sekia.setUnitsHaveMoved(sids),
            fa.text('movementPhase/musteringActivationStarted', {
              n1: activationNumber,
              n2: nActivations,
            }),
            fa.text('movementPhase/mustered', {
              r: role,
              cc: { fromStash: 'mustered' },
              l: locationId,
              n1: mode,
            }),
            sekia.endActivation()
          )
        );
      },
    },
    bringMori: {
      collect(state) {
        const { role, isCurrent } = getActivateLocationOrderState(state);
        if (!isCurrent) {
          return null;
        }
        if (!(role === 'i')) {
          return null;
        }

        const locationId = 'moribox';

        const unitCards = getCardsInPlayerZone(state, role, locationId);
        if (unitCards.length === 0) {
          return null;
        }
        const handCards = getCardsInPlayerZone(state, role, 'hand');
        if (handCards.length === 0) {
          return null;
        }

        return {
          role,
          orderType: 'chooseZoneCards',
          source: makeZoneSourceFromRoleAndSlug(role, locationId),
          additionalData: {
            cards: handCards,
            minimum: 1,
            maximum: Math.min(handCards.length, unitCards.length),
          },
        };
      },
      execute(state, order, filledOrder) {
        const { role } = order;
        const { cids, mode } = filledOrder;
        const sids = findSidsByCids(state, cids, role);
        const cards = findCardsBySids(state, sids);
        const locationId = 'moribox';

        const unitCards = [
          ...getCardsInPlayerZone(state, role, locationId),
        ].sort((aCard, bCard) => {
          const aUnitInfo = sekiGetUnitInfoBySid(aCard.sid);
          const bUnitInfo = sekiGetUnitInfoBySid(bCard.sid);
          if (aUnitInfo.kind === 'leader' && bUnitInfo.kind !== 'leader') {
            return 1;
          } else if (
            aUnitInfo.kind !== 'leader' &&
            bUnitInfo.kind === 'leader'
          ) {
            return -1;
          }
          return 0;
        });

        const movedUnitCards = unitCards.slice(0, cards.length);

        return applyAction(
          state,
          fa.seq(
            sekia.discardCardsLowLevel(role, cards, { saveAs: 'discarded' }),
            sekia.moveUnits(movedUnitCards, 'i:osaka', {
              facing: 'faceup',
              saveAs: 'brought',
            }),
            sekia.setUnitsHaveMoved(movedUnitCards.map((card) => card.sid)),
            fa.text('movementPhase/moriBrought', {
              r: role,
              cc: { fromStash: 'brought' },
              ['l']: 'osaka',
              cc1: { fromStash: 'discarded' },
            })
          )
        );
      },
    },
  }
);
registerOrderGroup(sekiMovementPhaseOrders);

export function getDeclareCombatOrderState(state) {
  const role = getStateVar(state, 'whoDeclaresCombat', null) as Role | null;

  let isCurrent = true;

  if (!role) {
    isCurrent = false;
  }
  // TODO subsequence check
  else if (false) {
    isCurrent = false;
  }

  return { isCurrent, role };
}

export function collectSkippableMyCastles(state, role) {
  const contestedLocationIds = getStateVar(
    state,
    'contestedLocationIds'
  ) as string[];
  return contestedLocationIds.filter((locationId) => {
    if (
      getIsPlayerWalled(state, role, locationId) &&
      !getStateVar(state, qualifiedName('fought', locationId), false)
    ) {
      return true;
    }

    return false;
  });
}

export const sekiCombatPhaseOrders = createOrderGroup(
  'seki',
  'combatPhase',
  {
    precondition(state) {
      return (
        getStateVar(state, 'step') === 'turns' &&
        getStateVar(state, 'phase') === 'combat'
      );
    },
  },
  {
    declareCombat: {
      collect(state) {
        const { role, isCurrent } = getDeclareCombatOrderState(state);
        if (!isCurrent) {
          return null;
        }

        const contestedLocationIds = getStateVar(
          state,
          'contestedLocationIds'
        ) as string[];
        const locationIds = contestedLocationIds.filter((locationId) => {
          if (getStateVar(state, qualifiedName('fought', locationId), false)) {
            return false;
          }
          return true;
        });

        return locationIds.map((locationId) => {
          const modes = getIsPlayerWalled(state, role, locationId)
            ? ['stayInCastle', 'declareFieldBattle']
            : ['declareCombatHere'];

          return {
            role,
            orderType: 'chooseMode',
            source: makeZoneSourceFromRoleAndSlug(role, locationId),
            additionalData: {
              modes,
            },
          };
        });
      },
      execute(state, order, filledOrder) {
        const { role } = order;
        const zoneName = (order.source as OrderSourceZone).zoneName;
        const combatLocationId = sekiGetLocationIdFromZone(zoneName);

        const { mode } = filledOrder;

        const opponentRole = sekiGetOpponentRole(role);

        return applyAction(
          state,
          fa.seq(
            fa.setVars({
              whoDeclaresCombat: null,
              combatLocationId,
            }),
            fa.text('combatPhase/combatDeclared', {
              r: role,
              l: combatLocationId,
              n1: getStateVar(state, 'combatNumber'),
              n2: getStateVar(state, 'nCombats'),
            }),
            mode === 'stayInCastle'
              ? fa.seq(
                  fa.text('combatPhase/stayedInCastle', {
                    r: role,
                  }),
                  sekia.endOneCombat()
                )
              : mode === 'declareFieldBattle'
              ? fa.seq(
                  fa.text('combatPhase/wentOutToBattle', {
                    r: role,
                  }),
                  sekia.startOneCombat('battle')
                )
              : getIsPlayerWalled(state, opponentRole, combatLocationId)
              ? fa.seq(
                  fa.setVars({
                    whoChoosesCastleDefense: opponentRole,
                  })
                )
              : fa.seq(sekia.startOneCombat('battle'))
          )
        );
      },
    },
    skipMyCastles: {
      collect(state) {
        const { role, isCurrent } = getDeclareCombatOrderState(state);
        if (!isCurrent) {
          return null;
        }

        const locationIds = collectSkippableMyCastles(state, role);

        if (locationIds.length === 0) {
          return null;
        }

        return {
          role,
          orderType: 'simple',
          source: generalSource,
        };
      },
      execute(state, order, filledOrder) {
        const { role } = order;

        const locationIds = collectSkippableMyCastles(state, role);

        let combatNumber = getStateNumberVar(state, 'combatNumber');
        const nCombats = getStateNumberVar(state, 'nCombats');

        const actions = [];
        locationIds.forEach((locationId) => {
          const action = fa.seq(
            fa.text('combatPhase/combatDeclared', {
              r: role,
              l: locationId,
              n1: combatNumber,
              n2: nCombats,
            }),
            fa.text('combatPhase/stayedInCastle', {
              r: role,
            }),
            fa.setVars({
              [qualifiedName('fought', locationId)]: true,
            })
          );
          actions.push(action);

          combatNumber += 1;
        });

        return applyAction(
          state,
          fa.seq(
            ...actions,
            fa.setVars({
              combatLocationId: null,
            }),
            sekia.checkCombatPhaseEnd(combatNumber)
          )
        );
      },
    },
    chooseCastleDefense: {
      collect(state) {
        const role = getStateVar(
          state,
          'whoChoosesCastleDefense',
          null
        ) as Role | null;
        if (!role) {
          return null;
        }
        const combatLocationId = getStateVar(
          state,
          'combatLocationId'
        ) as string;

        return {
          role,
          orderType: 'chooseMode',
          source: makeZoneSourceFromRoleAndSlug(role, combatLocationId),
          additionalData: {
            modes: ['defenseStayInCastle', 'defenseFieldCombat'],
          },
        };
      },
      execute(state, order, filledOrder) {
        const { role } = order;
        const { mode } = filledOrder;

        return applyAction(
          state,
          fa.seq(
            fa.setVars({
              whoChoosesCastleDefense: null,
            }),
            mode === 'defenseStayInCastle'
              ? fa.seq(
                  fa.text('combatPhase/defenseStayedInCastle', {
                    r: role,
                  }),
                  sekia.startOneCombat('siege')
                )
              : fa.seq(
                  fa.text('combatPhase/defenseAcceptedBattle', {
                    r: role,
                  }),
                  sekia.startOneCombat('battle')
                )
          )
        );
      },
    },
    deployCardlessly: {
      collect(state) {
        const role = getStateVar(state, 'whoDeploysUnit', null) as Role | null;
        if (!role) {
          return null;
        }

        const deployedWithCard = getStateVar(
          state,
          qualifiedName('deployedWithCard', role),
          false
        );
        if (deployedWithCard) {
          return null;
        }

        const locationId = getStateVar(state, 'combatLocationId');
        const unitCards = collectUndeployedUnitCards(state, role, locationId);
        const handCards = getCardsInPlayerZone(state, role, 'hand');

        const deployableLeaders = unitCards.filter((unitCard) => {
          const { sid, facing } = unitCard as CardInRegion;

          if (facing !== 'facedown') {
            return false;
          }
          const unitInfo = sekiGetUnitInfoBySid(sid);

          const { clan, kind } = unitInfo;

          if (kind !== 'leader') {
            return false;
          }
          return true;
        });

        if (deployableLeaders.length === 0) {
          return null;
        }

        return {
          role,
          orderType: 'chooseZoneCards',
          source: makeZoneSourceFromRoleAndSlug(role, locationId),
          additionalData: {
            cards: deployableLeaders,
            minimum: 1,
            maximum: 1,
          },
        };
      },
      execute(state, order, filledOrder) {
        const { role, source } = order;
        const { cids } = filledOrder;
        const unitSids = findSidsByCids(state, cids, role);

        return applyAction(
          state,
          fa.seq(sekia.deployUnits(role, unitSids, []))
        );
      },
    },
    deploySingleUnitWithCard: {
      collect(state) {
        const role = getStateVar(state, 'whoDeploysUnit', null) as Role | null;
        if (!role) {
          return null;
        }

        const locationId = getStateVar(state, 'combatLocationId');
        const unitCards = collectUndeployedUnitCards(state, role, locationId);
        const handCards = getCardsInPlayerZone(state, role, 'hand');

        return handCards.map((handCard) => {
          const cardInfo = sekiGetCardInfoOfCard(handCard);

          if (!['normal', 'special', 'double'].includes(cardInfo.cardKind)) {
            return null;
          }

          const matchingUnitCards = unitCards.filter((unitCard) => {
            const unitInfo = sekiGetUnitInfoBySid(unitCard.sid);

            if (cardInfo.cardKind === 'double') {
              // Two units with one card is a separate order.
              return unitInfo.joker;
            } else {
              return unitInfo.joker || unitInfo.clan === cardInfo.clan;
            }
          });

          if (matchingUnitCards.length === 0) {
            return null;
          }

          return {
            role,
            orderType: 'chooseCards',
            source: makeCardSource(handCard),
            additionalData: {
              cards: matchingUnitCards,
              minimum: 1,
              maximum: 1,
            },
          };
        });
      },
      execute(state, order, filledOrder) {
        const { role, source } = order;
        const { sid } = (source as OrderSourceCard).card;
        const { cids } = filledOrder;

        const deployingCards = findCardsBySids(state, [sid]);

        const unitSids = findSidsByCids(state, cids, role);
        return applyAction(
          state,
          fa.seq(sekia.deployUnits(role, unitSids, deployingCards))
        );
      },
    },
    deployUnitsWithDoubleCard: {
      collect(state) {
        const role = getStateVar(state, 'whoDeploysUnit', null) as Role | null;
        if (!role) {
          return null;
        }

        const locationId = getStateVar(state, 'combatLocationId');
        const unitCards = collectUndeployedUnitCards(state, role, locationId);
        const handCards = getCardsInPlayerZone(state, role, 'hand');

        return handCards.map((handCard) => {
          const cardInfo = sekiGetCardInfoOfCard(handCard);

          if (!(cardInfo.cardKind === 'double')) {
            return null;
          }

          const matchingUnitCards = unitCards.filter((unitCard) => {
            const unitInfo = sekiGetUnitInfoBySid(unitCard.sid);

            return unitInfo.clan === cardInfo.clan;
          });

          if (matchingUnitCards.length === 0) {
            return null;
          }
          const limit = matchingUnitCards.length === 1 ? 1 : 2;

          return {
            role,
            orderType: 'chooseCards',
            source: makeCardSource(handCard),
            additionalData: {
              cards: matchingUnitCards,
              minimum: 1,
              maximum: limit,
            },
          };
        });
      },
      execute(state, order, filledOrder) {
        const { role, source } = order;
        const { sid } = (source as OrderSourceCard).card;
        const { cids } = filledOrder;

        console.log('deployUnitsWithDoubleCard execute', order, filledOrder);
        const deployingCards = findCardsBySids(state, [sid]);

        const unitSids = findSidsByCids(state, cids, role);
        return applyAction(
          state,
          fa.seq(sekia.deployUnits(role, unitSids, deployingCards))
        );
      },
    },
    doneDeployingUnits: {
      collect(state) {
        const role = getStateVar(state, 'whoDeploysUnit', null) as Role | null;
        if (!role) {
          return null;
        }

        return {
          role,
          orderType: 'simple',
          source: generalSource,
        };
      },
      execute(state, order, filledOrder) {
        const { role } = order;

        const opponentRole = sekiGetOpponentRole(role);
        return applyAction(
          state,
          fa.seq(
            fa.setVars({
              whoDeploysUnit: null,
            }),
            sekia.resolveDoneDeployingUnits(role)
          )
        );
      },
    },
    selectLosses: {
      collect(state) {
        const role = getStateVar(
          state,
          'whoSelectsLosses',
          null
        ) as Role | null;
        if (!role) {
          return null;
        }

        const selectLossesChoice = getStateStashedValue<any>(
          state,
          'selectLossesChoice'
        );

        const { mandatoryLossUnitCards, nLosses, group } = selectLossesChoice;
        const locationId = getStateVar(state, 'combatLocationId');
        return {
          role,
          orderType: 'chooseZoneCards',
          source: makeZoneSourceFromRoleAndSlug(role, locationId),
          additionalData: {
            cards: group.unitCards,
            minimum: nLosses,
            maximum: nLosses,
            mandatoryLossUnitCards,
          },
          conversions: [
            {
              key: 'mandatoryLossUnitCards',
              conversionType: 'cardsToCids',
              out: 'mandatoryLossCids',
            },
          ],
        };
      },
      execute(state, order, filledOrder) {
        const { role } = order;
        const { cids } = filledOrder;
        const sids = findSidsByCids(state, cids, role);
        const chosenUnitCards = findCardsBySids(state, sids);

        const selectLossesChoice = getStateStashedValue<any>(
          state,
          'selectLossesChoice'
        );

        const allLostUnitCards = [
          ...selectLossesChoice.mandatoryLossUnitCards,
          ...chosenUnitCards,
        ];

        return applyAction(
          state,
          fa.seq(
            fa.setVars({
              whoSelectsLosses: null,
            }),
            fa.setStashedValues({
              selectLossesChoice: null,
              whoSelectsLosses: null,
            }),
            sekia.resolveLosses(role, allLostUnitCards)
          )
        );
      },
    },
    retreatToCastle: {
      collect(state) {
        const role = getStateVar(
          state,
          'whoRetreatsUnits',
          null
        ) as Role | null;
        if (!role) {
          return null;
        }

        const retreatConfiguration = getStateStashedValue<RetreatConfiguration>(
          state,
          'retreatConfiguration'
        );

        const { isCastleRetreatPossible } = retreatConfiguration;

        if (!isCastleRetreatPossible) {
          return null;
        }

        const locationId = getStateVar(state, 'combatLocationId');

        const unitCards = getCardsInPlayerZone(state, role, locationId);

        if (!(unitCards.length <= 2)) {
          return null;
        }

        return {
          role,
          orderType: 'simple',
          source: makeZoneSourceFromRoleAndSlug(role, locationId),
        };
      },
      execute(state, order, filledOrder) {
        const { role } = order;

        return applyAction(
          state,
          fa.seq(
            fa.setVars({
              whoRetreatsUnits: null,
            }),
            sekia.resolveRetreatToCastle(role)
          )
        );
      },
    },
    retreatToAdjacentLocation: {
      collect(state) {
        const role = getStateVar(
          state,
          'whoRetreatsUnits',
          null
        ) as Role | null;
        if (!role) {
          return null;
        }

        const retreatConfiguration = getStateStashedValue<RetreatConfiguration>(
          state,
          'retreatConfiguration'
        );

        const { unitCards, locationIds, isCastleRetreatPossible } =
          retreatConfiguration;

        const n = unitCards.length;
        const maximum = n;
        let minimum;
        if (isCastleRetreatPossible) {
          minimum = Math.max(n - 2, 1);
        } else {
          minimum = n;
        }

        const opponentRole = sekiGetOpponentRole(role);

        return locationIds.map((locationId) => {
          // For icons, consider the maximum possible moving amount: actually
          // there might be combat instead of overrun if some units retreat to
          // castle.
          const enemyRelationship = getEnemyRelationshipOnMovingTo(
            state,
            role,
            locationId,
            n
          );
          const combatLocationId = getStateVar<string>(
            state,
            'combatLocationId'
          );
          const movementTarget: MovementTarget = {
            targetLocationId: locationId,
            h: false,
            al: false,
            dl: false,
            fm: false,
            dlfmChoice: false,
            hApplicable: false,
            sourceLocationId: combatLocationId,
            passingLocations: [],
            ms:
              enemyRelationship === 'walled' || enemyRelationship === 'meeting',
            hasOverrun: enemyRelationship === 'overrun',
            isFinal: true,
          };

          return {
            role,
            orderType: 'chooseCards',
            source: makeZoneSourceFromRoleAndSlug(role, locationId),
            additionalData: {
              cards: unitCards,
              minimum,
              maximum,
              movementTarget,
            },
          };
        });
      },
      execute(state, order, filledOrder) {
        const { role } = order;
        const { cids } = filledOrder;
        const sids = findSidsByCids(state, cids, role);
        const retreatingUnitCards = findCardsBySids(state, sids);

        const zoneName = (order.source as OrderSourceZone).zoneName;
        const locationId = sekiGetLocationIdFromZone(zoneName);

        return applyAction(
          state,
          fa.seq(
            fa.setVars({
              whoRetreatsUnits: null,
            }),
            sekia.resolveRetreatToAdjacentLocation(
              role,
              retreatingUnitCards,
              locationId
            )
          )
        );
      },
    },
  }
);
registerOrderGroup(sekiCombatPhaseOrders);
