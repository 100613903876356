import React from 'react';
import { useSelector } from 'react-redux';
import { selectYourUsername } from 'slices/userSlice';

export function pluralize(n, sg, pl = null) {
  if (pl === null) {
    pl = `${sg}s`;
  }
  if (n === 1) {
    return `${n} ${sg}`;
  } else {
    return `${n} ${pl}`;
  }
}

function isThisYear(date, today) {
  return today.getFullYear() === date.getFullYear();
}

function isToday(date, today) {
  return (
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate()
  );
}

const monthNames = 'Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec'.split(' ');

export function formatTimestamp(timestampStringOrDate) {
  let date;
  if (timestampStringOrDate instanceof Date) {
    date = timestampStringOrDate;
  } else {
    date = new Date(timestampStringOrDate);
  }

  function pad(n) {
    return n.toString().padStart(2, '0');
  }

  const today = new Date();

  const year = isThisYear(date, today) ? '' : `${date.getFullYear()}, `;

  const monthDay = isToday(date, today)
    ? ''
    : `${monthNames[date.getMonth()]} ${pad(date.getDate())} `;

  return `${monthDay}${year}${pad(date.getHours())}:${pad(date.getMinutes())}`;
}

export function getGameSlotsFromPlayers(players) {
  const slots = players.map((player) => {
    return {
      key: player,
      hasPlayer: true,
      display: player,
    };
  });
  while (slots.length < 2) {
    slots.push({
      key: slots.length,
      display: '(empty)',
      className: 'empty',
    });
  }
  return slots;
}

const actions = {
  play: {
    actionId: 'play',
    kind: 'primary',
    text: 'Play',
    navigateToPlay: true,
  },
  start: {
    actionId: 'start',
    kind: 'primary',
    text: 'Start',
    sendToServer: true,
  },
  startDisabled: {
    actionId: 'startDisabled',
    kind: 'primary',
    text: 'Start',
    isDisabled: true,
  },
  cancel: {
    actionId: 'cancel',
    kind: 'danger',
    text: 'Cancel',
    sendToServer: true,
  },
  watch: {
    actionId: 'watch',
    kind: 'secondary',
    text: 'Watch',
    navigateToPlay: true,
  },
  join: {
    actionId: 'join',
    kind: 'primary',
    text: 'Join',
    sendToServer: true,
  },
  joinDisabled: {
    actionId: 'joinDisabled',
    kind: 'primary',
    text: 'Join',
    isDisabled: true,
  },
  leave: {
    actionId: 'leave',
    kind: 'danger',
    text: 'Leave',
    sendToServer: true,
  },
  finalView: {
    actionId: 'finalView',
    kind: 'secondary',
    text: 'Final view',
    navigateToPlay: true,
  },
  delete: {
    actionId: 'delete',
    kind: 'danger',
    sendToServer: true,
    text: 'Delete',
  },
};

export function gameHasPlayer(game, username) {
  return !!game.players.find((x) => x === username);
}

export function getGameActions(game, yourUsername, options = {}) {
  const { kind, ownerUsername, players, status } = game;
  const youInside = gameHasPlayer(game, yourUsername);
  const youOwner = ownerUsername === yourUsername;
  const enoughPlayers = players.length === 2;
  if (kind === 'sandbox') {
    if (youOwner) {
      return [actions.play, actions.delete];
    } else {
      return [actions.watch];
    }
  } else {
    if (status === 'open') {
      if (youOwner) {
        if (enoughPlayers) {
          return [actions.start, actions.cancel];
        } else {
          return [actions.startDisabled, actions.cancel];
        }
      } else {
        if (youInside) {
          return [actions.leave];
        } else if (!enoughPlayers && !!yourUsername) {
          return [actions.join];
        } else {
          return [actions.joinDisabled];
        }
      }
    } else if (status === 'canceled' || status === 'expired') {
      return [];
    } else if (status === 'running') {
      return youInside ? [actions.play] : [actions.watch];
    } else if (
      status === 'abandoned' ||
      status === 'voided' ||
      status === 'finished' ||
      status === 'r-expired'
    ) {
      return [actions.finalView];
    } else {
      console.error('Unrecognized game status', status);
    }
  }
}

export function getRoomIdFromPath(path) {
  const regexp = new RegExp('(game|play)/(.+)$');
  const matches = path.match(regexp);
  if (matches) {
    return `gameRoom:${matches[2]}`;
  } else {
    return `lobbyRoom`;
  }
}

export function repeat(n, value) {
  const result = [];
  for (let i = 0; i < n; ++i) {
    result.push(value);
  }
  return result;
}

export function rangeInclusive(start, finish) {
  const result = [];
  for (let i = start; i <= finish; ++i) {
    result.push(i);
  }
  return result;
}

export function memoize(f, resolver) {
  const cache = new Map();
  return (...args) => {
    const key = resolver(...args);
    if (cache.has(key)) {
      return cache.get(key);
    }
    const result = f(...args);
    cache.set(key, result);
    return result;
  };
}

// Given a cid of a form `baopi:2`, return only the base cid: baopi.
export function chopHead(cid) {
  if (!cid) {
    return null;
  }
  if (!cid.split) {
    return null;
  }
  return cid.split(':')[0];
}

export function arrayContains(haystack, needle) {
  return !!haystack.find((element) => element === needle);
}

export function getIsLeftSide(x) {
  const width = document.documentElement.clientWidth;
  return x < width / 2;
}

export function getConnectionStatusText(connectionStatus) {
  switch (connectionStatus) {
    case 'hgameOnline':
      return 'Online';
    case 'hgameOffline':
      return 'Offline';
    case 'sgameCurrentPov':
      return 'Current pov';
    case 'sgameNonCurrentPov':
      return 'Not current pov';
    default: {
      console.warn('Unrecognized connectionStatus', connectionStatus);
      return '??';
    }
  }
}

// Return array of numbers from 0 to n (excluding n).
export function numbers(n) {
  const result = [];
  for (let i = 0; i < n; ++i) {
    result.push(i);
  }
  return result;
}

export function sumValues(hash) {
  let result = 0;
  for (const value of Object.values(hash)) {
    result += value;
  }
  return result;
}

export function inBounds(lower, n, higher, exactAmount = false) {
  if (exactAmount) {
    return n === lower || n === higher;
  }

  return lower <= n && n <= higher;
}

export function halfRoundUp(n) {
  return Math.round(n / 2);
}

export function getCanUseSeki(username) {
  const canUseSeki =
    username !== null && username.match(/^(arry)|(deer)|(chga.*)$/);
  return canUseSeki;
}

export function useCanUseSeki() {
  const yourUsername = useSelector(selectYourUsername);
  const canUseSeki = getCanUseSeki(yourUsername);
  return canUseSeki;
}

export function getCodeFromShortName(shortName) {
  return shortName.split('-')[0];
}

export function getTitleFromLines(titleLines) {
  const title = titleLines
    .map((titleLine) => {
      return titleLine.filter((x) => !!x).join(' - ');
    })
    .filter((line) => line.length > 0)
    .join('\n');
  return title;
}

// Return new array which has `joiner` in-between every pair of original
// elements; and `lastJoiner` between the elements of the last pair.
export function interpose(array, joiner, lastJoiner = null) {
  const result = [];
  array.forEach((el, index) => {
    result.push(el);
    if (index < array.length - 2) {
      result.push(joiner);
    } else if (index === array.length - 2) {
      result.push(lastJoiner || joiner);
    }
  });
  return result;
}

export function boldedList(rawValues) {
  const boldedValues = rawValues.map((value, index) => (
    <b key={index}>{value}</b>
  ));
  const result = interpose(boldedValues, ', ', ' and ');
  return result;
}

const warnOnceCache = {};
export function warnOnce(primary, secondary, ...others) {
  const key = `${primary}_${secondary}`;
  if (warnOnceCache[key]) {
    return;
  }
  warnOnceCache[key] = 1;

  console.warn(primary, secondary, ...others);
}

export function pickTwo(array) {
  const result = [];
  const copy = [...array];
  for (let i = 0; i < 2; ++i) {
    const index = Math.floor(Math.random() * copy.length);
    result.push(copy[index]);
    copy.splice(index, 1);
  }
  return result;
}
