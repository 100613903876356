import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'slices/userSlice';

export function ChatForm(props) {
  const { onChatLineSent, onChatInputFocusChanged } = props;

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const inputRef = useRef(null);

  if (!isLoggedIn) {
    return null;
  }

  function handleSubmit(e) {
    e.preventDefault();
    const userInput = inputRef.current.value.trim();
    if (userInput.length > 0) {
      onChatLineSent(userInput);
    }
    inputRef.current.value = '';
  }

  let onFocus, onBlur;

  if (onChatInputFocusChanged) {
    onFocus = function () {
      onChatInputFocusChanged(true);
    };
    onBlur = function () {
      onChatInputFocusChanged(false);
    };
  }
  return (
    <form onSubmit={handleSubmit}>
      <input
        ref={inputRef}
        placeholder="Say something"
        autoComplete="off"
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={200}
      />
    </form>
  );
}
