import type {
  ZoneKind,
  State,
  CardRegistry,
  Role,
  Hash,
  InitialAccess,
} from './types';
import { createCardRegistry } from './CardRegistry';

export type ZoneDescriptors = {
  [slug: string]: {
    initAccess: InitialAccess;
    zoneKind: ZoneKind;
  };
};

export type PlayerDescriptor = {
  playerName: string;
  deck?: any;
};

export type StartGameDescriptor = {
  players: PlayerDescriptor[];
  zones?: ZoneDescriptors;
  skipInitialVars?: boolean;
};

function createRoleToCardRegistryMapping(): Hash<Role, CardRegistry> {
  const roleToCardRegistry = {};
  roleToCardRegistry['spec'] = createCardRegistry('spec');
  return roleToCardRegistry;
}

export function createEmptyState(code: string): State {
  return {
    code,
    roleToPlayerName: {},
    roleToCardRegistry: createRoleToCardRegistryMapping(),
    roles: [],
    zones: {},
    vars: {},
    baseToCounter: {},
    historyEntries: [],
    stash: {},
    outcome: null,
  };
}
