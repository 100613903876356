import { createSlice, createSelector } from '@reduxjs/toolkit';

export const roomSlice = createSlice({
  name: 'room',
  initialState: {
    currentRoomId: null,
    roomIdToData: {},
    shortNameToGameActionLoading: {},
  },
  reducers: {
    setCurrentRoomId(state, action) {
      const roomId = action.payload;
      state.currentRoomId = roomId;
    },
    setInitialRoomData(state, action) {
      const { roomId, roomData } = action.payload;
      state.roomIdToData[roomId] = roomData;
    },
    userEnters(state, action) {
      const { roomId, username } = action.payload;
      state.roomIdToData[roomId].usernames.push(username);
    },
    userLeaves(state, action) {
      const { roomId, username } = action.payload;
      const usernames = state.roomIdToData[roomId].usernames;

      state.roomIdToData[roomId].usernames = usernames.filter(
        (u) => u !== username
      );
    },
    newRoomMessage(state, action) {
      const { roomId, roomMessage } = action.payload;
      state.roomIdToData[roomId].roomMessages.push(roomMessage);
    },
    onBeforeGameActionSent(state, action) {
      const { shortName } = action.payload;
      state.shortNameToGameActionLoading[shortName] = true;
    },
    applyGameActionSuccess(state, action) {
      const { shortName } = action;
      state.shortNameToGameActionLoading[shortName] = false;
    },
    applyGameActionError(state, action) {
      const { shortName } = action;
      state.shortNameToGameActionLoading[shortName] = false;
    },
    setCurrentRoomPersonalNotesContent(state, action) {
      const { content } = action.payload;
      state.roomIdToData[state.currentRoomId].personalNotesContent = content;
    },
  },
});

const nullRoomData = {
  usernames: [],
  roomMessages: [],
};

export const {
  setCurrentRoomId,
  setInitialRoomData,
  userEnters,
  userLeaves,
  newRoomMessage,
  onBeforeGameActionSent,
  applyGameActionSuccess,
  applyGameActionError,
  setCurrentRoomPersonalNotesContent,
} = roomSlice.actions;

export const selectRoomSlice = (state) => state.room;

export const selectCurrentRoomId = createSelector(
  selectRoomSlice,
  (state) => state.currentRoomId
);

export const selectRoomIdToData = createSelector(
  selectRoomSlice,
  (state) => state.roomIdToData
);

export const selectCurrentRoomData = createSelector(
  selectCurrentRoomId,
  selectRoomIdToData,
  (roomId, roomIdToData) => {
    return roomIdToData[roomId] || nullRoomData;
  }
);

export const selectCurrentRoomUsernames = createSelector(
  selectCurrentRoomData,
  (roomData) => {
    const { usernames } = roomData;
    const result = [...usernames];
    result.sort();

    return result;
  }
);

export const selectCurrentRoomMessages = createSelector(
  selectCurrentRoomData,
  (roomData) => {
    return roomData.roomMessages;
  }
);

export const createSelectGameActionLoading = (shortName) => {
  return createSelector(selectRoomSlice, (state) => {
    const loading = state.shortNameToGameActionLoading[shortName];
    return !!loading;
  });
};

export const selectCurrentRoomPersonalNotesContent = createSelector(
  selectCurrentRoomData,
  (roomData) => {
    return roomData.personalNotesContent;
  }
);
