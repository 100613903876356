import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Well } from 'components/Well';
import { Loader } from 'components/Loader';
import { LinkSeparator } from 'components/LinkSeparator';
import { FormField } from 'components/FormField';

function StartSignupStatus(props) {
  const { signupState } = props;
  if (signupState === 'start' || signupState === 'loading') {
    return null;
  } else if (signupState === 'success') {
    return (
      <Well modifier={'success'}>
        Thank you for registering! Please check your email.
      </Well>
    );
  } else if (signupState === 'emailTaken') {
    return (
      <Well modifier={'danger'}>
        <div>This address is already taken.</div>
        <br />
        <div>
          Is it your address? <Link to="/login">Log in</Link>
          <LinkSeparator />
          <Link to="/request-password-reset">Request password reset</Link>
        </div>
      </Well>
    );
  } else if (signupState === 'pendingRegistrationExists') {
    return (
      <Well modifier={'danger'}>
        <div>
          We've already sent a confirmation email to this address, and we won't
          send another in case it's not really yours.
        </div>
        <br />
        <div>
          If you're sure it's yours and you haven't received an email, please
          contact the site author.
        </div>
      </Well>
    );
  } else if (signupState === 'emailError') {
    return (
      <Well modifier={'danger'}>
        <div>
          We couldn't send an email to this address. Please check that it's
          correct.
        </div>
      </Well>
    );
  } else {
    return (
      <Well modifier={'danger'}>
        Sorry, there was some trouble connecting to the server. Please try again
        later.
      </Well>
    );
  }
}

export function StartSignupForm(props) {
  const [state, setState] = useState('start');
  const [email, setEmail] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();

    if (state === 'loading') {
      return;
    }

    if (!(email.length > 0)) {
      return;
    }

    setState('loading');
    try {
      const response = await fetch('/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      });
      if (response.status === 200) {
        setState('success');
      } else if (response.status === 400) {
        setState('emailError');
      } else if (response.status === 401) {
        setState('serverError');
      } else if (response.status === 403) {
        const obj = await response.json();
        if (obj.error === 'emailTaken') {
          setState('emailTaken');
        } else if (obj.error === 'pendingRegistrationExists') {
          setState('pendingRegistrationExists');
        } else {
          console.warn('Unrecognized error of a 403 response', obj);
          setState('serverError');
        }
      } else {
        setState('serverError');
      }
    } catch (e) {
      setState('serverError');
    }
  }

  return (
    <>
      <form className="StartSignupForm" onSubmit={handleSubmit}>
        <FormField id="email" labelText="Email:">
          <input
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="your.name@example.org"
          />
        </FormField>
        <div className="buttons">
          <input
            type="submit"
            className="button isPrimary"
            disabled={state === 'loading'}
            value="Sign up"
          />
        </div>
      </form>
      {state === 'loading' ? (
        <Loader isCentered />
      ) : (
        <StartSignupStatus signupState={state} />
      )}
      <p className="smaller">
        We will send the confirmation email to this address in order to create
        your account. Additionally, you'll be able to subscribe to notifications
        saying when it&apos;s your turn. Apart from that, we won&apos;t use your
        email address in any way.
      </p>
    </>
  );
}
