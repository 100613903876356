import {
  Card,
  Role,
  VCard,
  CardInRegion,
  Zone,
  VZone,
  State,
  VState,
  HistoryEntry,
} from './types';
import { getYourKnowledge, mapValues, getZoneYourAccess } from './utils';

export function cardToVCard(card: Card, pov: Role): VCard {
  const { tag, cardBack } = card;
  const yourKnowledge = getYourKnowledge(card, pov);

  if (tag === 'cardInBunch') {
    return {
      tag,
      yourKnowledge,
      cardBack,
    };
  } else if (tag === 'cardInRegion') {
    const { facing, rotation, vars, attachments } = card as CardInRegion;
    return {
      tag,
      yourKnowledge,
      cardBack,
      facing,
      rotation,
      vars,
      attachedCards: attachments.cards.map((attachment) =>
        cardToVCard(attachment, pov)
      ),
    };
  } else {
    throw new Error(`tag must be cardInBunch or cardInRegion but is ${tag}`);
  }
}

export function zoneToVZone(zone: Zone, pov: Role): VZone {
  const { zoneKind, access, cards, owner, slug } = zone;

  const yourAccess = getZoneYourAccess(access, pov);

  const shouldTranslateCards =
    zoneKind === 'region' || yourAccess === 'accessible';

  return {
    zoneKind,
    yourAccess,
    access,
    owner,
    slug,
    nCards: cards.length,
    cards: shouldTranslateCards
      ? cards.map((card) => cardToVCard(card, pov))
      : null,
    topCardBack: cards.length > 0 ? cards[0].cardBack : null,
  };
}

export function getVCardLikeObjectFromCard(card, pov) {
  const yourKnowledge = getYourKnowledge(card, pov);

  if (!yourKnowledge) {
    return {
      yourKnowledge: null,
      cardBack: card.cardBack,
    };
  } else {
    const vCard = cardToVCard(card, pov);
    return {
      yourKnowledge,
      cardBack: vCard.cardBack,
    };
  }
}

export function historyEntryToVHistoryEntry(
  historyEntry: HistoryEntry,
  pov: Role
) {
  const { entryType } = historyEntry;
  if (entryType === 'logLine') {
    const { logLine } = historyEntry;
    const { args } = logLine;
    //console.log('historyEntryToVHistoryEntry logLine', logLine, pov);

    const replacedArgs = mapValues(args, (value, argName) => {
      const regexp = new RegExp(/^([^\d]+)(\d+)?/);
      const matches = (argName as string).match(regexp);

      if (!matches) {
        console.error(
          'historyEntryToVHistoryEntry: logLine contains invalid argument',
          logLine
        );
        return null;
      }
      const head = matches[1];

      if (head === 'c') {
        const card = value;
        // Note: we need only limited representation of a card; but should
        // preserve the entry as a card-like object for InteractiveCardTitle
        // and such.
        const result = getVCardLikeObjectFromCard(card, pov);

        return result;
      } else if (head === 'cc') {
        const cards = value;
        if (!cards) {
          return null;
        }
        const result = cards.map((card) => {
          return getVCardLikeObjectFromCard(card, pov);
        });
        return result;
      } else {
        return value;
      }
    });

    return {
      ...historyEntry,
      logLine: {
        ...logLine,
        args: replacedArgs,
      },
    };
  } else {
    return historyEntry;
  }
}

export function stateToVState(state: State, pov: Role): VState {
  if (!(pov === 'spec' || state.roleToPlayerName[pov])) {
    throw new Error(
      `Must convert state to vState only for spectator or a player: ${pov}`
    );
  }

  return {
    pov,
    roleToPlayerName: state.roleToPlayerName,
    roles: state.roles,
    zones: mapValues(state.zones, (zone) => zoneToVZone(zone, pov)),
    vars: state.vars,
    historyEntries: state.historyEntries.map((historyEntry) =>
      historyEntryToVHistoryEntry(historyEntry, pov)
    ),
    outcome: state.outcome,
  };
}
