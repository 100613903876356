import { Card, Role, CardInRegion } from '../types';
import {
  MovementTargetInBuilding,
  MovementTarget,
  CombatOutcome,
} from './sekiTypes';
import {
  getBaseFromSid,
  getStateVar,
  getStateNumberVar,
  getCardsInPlayerZone,
  getStateStashedValue,
  qualifiedName,
  sum,
} from '../utils';
import { EError, ActionError } from '../errors';
import {
  bothRoles,
  sekiGetCardInfoByBase,
  sekiGetUnitInfoByBase,
  sekiFindLocationById,
  sekiGetMovementPenaltyForUnitAmount,
  sekiLocationData,
  sekiGetOpponentRole,
  sekiGetConnectionName,
  clanToFaction,
} from './sekiData';

export function sekiGetCardInfoOfCard(card: Card) {
  const { sid } = card;
  const base = getBaseFromSid(sid);

  return sekiGetCardInfoByBase(base);
}

export function sekiGetPhasingPlayer(state): Role {
  const firstPlayerRole = getStateVar(state, 'firstPlayerRole') as Role;
  const phasingPlayerLabel = getStateVar(state, 'phasingPlayerLabel');

  if (phasingPlayerLabel === 'first') {
    return firstPlayerRole;
  } else {
    return sekiGetOpponentRole(firstPlayerRole);
  }
}

export function sekiGetAttackerRole(state): Role {
  return getStateVar(state, 'attackerRole') as Role;
}

export function getIsLocationContested(state, locationId) {
  const iUnits = getCardsInPlayerZone(state, 'i', locationId);
  const tUnits = getCardsInPlayerZone(state, 't', locationId);
  return iUnits.length > 0 && tUnits.length > 0;
}

export function sekiCollectContestedLocationIds(state): string[] {
  return sekiLocationData
    .filter((location) => {
      const { locationId } = location;
      return getIsLocationContested(state, locationId);
    })
    .map((location) => location.locationId);
}

export function playerHasUnitsAtLocation(state, role, locationId) {
  const cards = getCardsInPlayerZone(state, role, locationId);
  return cards.length > 0;
}

/*

 After choosing the initial marching units, we highlight all the eligible
 targets using nice symbols.  If there are multiple paths, then there are
 rows for the options.

 There can be:
 - no symbol, like we move 4 units to an adjacent location

 - highway symbol, if by moving this, we invoke the highway bonus (e.g. 8b to
   adj by highway)

 - highway + al: e.g. 12b to adj by hw from capital

 - al: 8b to adj with no hw from capital

 - dl: 8b to adj with no hw from no al source but with leader, no cards in hand

 - fm: 8b to adj with no ho, no al source and no leader

 - dl/fm: 8b to adj with no hw from no al source but with leader and cards in hand

 - dl+fm: 8b to d=2 with no hw from no al source

 - hw+(dl/fm)

 dl/fm is the only "choice" option: we always prefer highways to automatic
 leadership to declared leadership to forced march; but when we don't have
 highway bonus (either at all, or anymore), and don't have automatic
 leadership, but do have the declared leadership or forced march, then we
 choose between them.  This is important, because sometimes we have no trouble
 declaring the leader, but sometimes we want to conceal him and do the FM
 instead.

 Determining moving targets is deep, i.e. we show as many possible targets as
 we can.  If the user wants to drop off some units, they choose the
 non-farthest location, and the next question-answer cycle will allow them to
 continue the movement.

 */

export function getConnections(locationId) {
  const location = sekiFindLocationById(locationId);
  return [
    ...location.roads.map((targetLocationId) => {
      return {
        connectionType: 'road',
        targetLocationId,
      };
    }),
    ...location.highways.map((targetLocationId) => {
      return {
        connectionType: 'highway',
        targetLocationId,
      };
    }),
  ];
}

export function getCastleController(state, locationId): Role {
  return getStateVar(state, `cc:${locationId}`) as string;
}

export function getHasAutomaticLeadership(state, role, locationId) {
  const location = sekiFindLocationById(locationId);
  if (location.cap === role) {
    return true;
  }

  if (
    location.castle === role &&
    getCastleController(state, locationId) === role
  ) {
    return true;
  }

  return false;
}

export function sekiGetUnitInfoBySid(sid: string) {
  const base = getBaseFromSid(sid);
  return sekiGetUnitInfoByBase(base);
}

export function getHasDeclaredLeadership(state, role, locationId) {
  const unitCards = getCardsInPlayerZone(state, role, locationId);

  return unitCards.some((unitCard) => {
    const { sid } = unitCard;
    const unitInfo = sekiGetUnitInfoBySid(sid);
    return unitInfo.kind === 'leader';
  });
}

export function getCanForceMarch(state, role) {
  return getCardsInPlayerZone(state, role, 'hand').length > 0;
}

export function countTruthyValues(obj) {
  let result = 0;
  Object.values(obj).forEach((value) => {
    if (value) {
      ++result;
    }
  });
  return result;
}

function getIsConnectionCovered(state, locationId1, locationId2) {
  const connectionName = sekiGetConnectionName(locationId1, locationId2);
  return !!getStateVar(state, connectionName, null);
}

export function getLocationHasCastle(locationId) {
  const location = sekiFindLocationById(locationId);
  return !!location.castle;
}

export function getIsPlayerWalled(state, role, locationId) {
  const nUnits = getCardsInPlayerZone(state, role, locationId).length;

  return (
    nUnits <= 2 &&
    getLocationHasCastle(locationId) &&
    getCastleController(state, locationId) === role
  );
}

export function getEnemyRelationshipOnMovingTo(
  state,
  role,
  targetLocationId,
  nMovingUnits
) {
  const opponentRole = sekiGetOpponentRole(role);

  const opponentUnits = getCardsInPlayerZone(
    state,
    opponentRole,
    targetLocationId
  );

  const presentUnits = getCardsInPlayerZone(state, role, targetLocationId);

  const nPresentUnits = presentUnits.length;
  const nOpponentUnits = opponentUnits.length;

  if (nOpponentUnits === 0) {
    return 'clear';
  } else if (getIsPlayerWalled(state, opponentRole, targetLocationId)) {
    return 'walled';
  } else if (
    nOpponentUnits > 0 &&
    nMovingUnits + nPresentUnits >= nOpponentUnits * 4
  ) {
    return `overrun`;
  } else {
    return 'meeting';
  }
}

function getAreAllFurtherConnectionsCovered(
  state,
  locationId,
  targetLocationId
) {
  const connections = getConnections(targetLocationId);

  const result = !connections.some((connection) => {
    const { targetLocationId: thatLocationId } = connection;
    if (thatLocationId === locationId) {
      return false;
    }

    if (!getIsConnectionCovered(state, targetLocationId, thatLocationId)) {
      return true;
    }

    return false;
  });

  return result;
}

// Disregard bonuses, but take into account covered connections and enemy
// units.
function collectReachableLocations(
  state,
  role,
  sourceLocationId,
  maxDistance,
  nMovingUnits
) {
  if (maxDistance === 0) {
    console.warn('cannot');
    return [];
  }

  const queue = [
    {
      locationId: sourceLocationId,
      path: [],
      hApplicable: true,
      enemyRelationship: 'whatever',
    },
  ];

  const result = [];

  while (queue.length > 0) {
    const { locationId, path, hApplicable, enemyRelationship } = queue.shift();

    const connections = getConnections(locationId);

    connections.forEach((connection) => {
      const { targetLocationId, connectionType } = connection;

      // no there-n-back
      if (
        path.some((pathItem) => {
          return pathItem.locationId === targetLocationId;
        })
      ) {
        return;
      }

      if (getIsConnectionCovered(state, locationId, targetLocationId)) {
        return;
      }

      const targetEnemyRelationship = getEnemyRelationshipOnMovingTo(
        state,
        role,
        targetLocationId,
        nMovingUnits
      );
      const mustStop =
        targetEnemyRelationship === 'walled' ||
        targetEnemyRelationship === 'meeting';

      const isFinal = mustStop || path.length + 1 === maxDistance;

      const added = {
        locationId: targetLocationId,
        path: [
          ...path,
          { locationId, hasOverrun: enemyRelationship === 'overrun' },
        ],
        hApplicable: hApplicable && connectionType === 'highway',
        enemyRelationship: targetEnemyRelationship,
        isFinal,
      };
      result.push(added);

      if (isFinal) {
        return;
      }

      queue.push(added);
    });
  }

  return result;
}

export const DEFAULT_ALL_USED_BONUSES = {
  h: false,
  al: false,
  dl: false,
  fm: false,
};

export function collectMovementTargets(
  state,
  role,
  sourceLocationId,
  movingUnitSids,
  movementParameters,
  movementMode,
  intermediateMovementTarget = null,
  allUsedBonuses = DEFAULT_ALL_USED_BONUSES
) {
  const {
    movementPenalty,
    hasAutomaticLeadership,
    hasDeclaredLeadership,
    canForceMarch,
  } = movementParameters;

  const movedDistance = intermediateMovementTarget
    ? intermediateMovementTarget.passingLocations.length + 1
    : 0;

  const maxDistance = 4 - movedDistance + movementPenalty;

  const reachableLocations = collectReachableLocations(
    state,
    role,
    sourceLocationId,
    maxDistance,
    movingUnitSids.length
  );

  const hasLeadership = hasAutomaticLeadership || hasDeclaredLeadership;

  const movementTargets = [];

  const isFollowOn = movementMode === 'followOn';

  reachableLocations.forEach((reachableLocation) => {
    const { locationId, path, hApplicable, enemyRelationship, isFinal } =
      reachableLocation;

    function c(value) {
      return value ? 1 : 0;
    }

    const ready = {
      h:
        hApplicable &&
        !allUsedBonuses.h &&
        (intermediateMovementTarget
          ? intermediateMovementTarget.hApplicable
          : true),
      al: hasAutomaticLeadership && !allUsedBonuses.al,
      dl:
        !hasAutomaticLeadership && hasDeclaredLeadership && !allUsedBonuses.dl,
      fm: canForceMarch && !allUsedBonuses.fm,
    };
    if (isFollowOn) {
      if (canForceMarch) {
        // Ignore used forced march, because a new one is required.
        ready.fm = true;
      }
    }

    const nAvailableBonuses =
      c(ready.h) + c(ready.al) + c(ready.dl) + c(ready.fm);

    let nNeededBonuses =
      movedDistance === 0
        ? // When just starting, take into account size of moving force:
          // e.g. you'll need 2 bonuses to walk 1 space if force is 9-12.
          path.length - 1 - movementPenalty
        : // When continuing, each next space will usually require one bonus,
          // because force size penalty has already been counted.
          path.length;

    if (movedDistance >= 1) {
      // If we used highway bonus and are considering the road path; then to
      // compensate we use two bonuses.
      if (allUsedBonuses.h && !hApplicable) {
        nNeededBonuses += 1;
      }

      // Similar to follow-on movement: forced march applies to groups that
      // start, move and stop together; hence if a group ABCD used forced
      // march, dropped-off CD and moved on, and we're considering a follow-on
      // movement of AB, then the used forced march must be replaced (including
      // maybe by the new forced march).
      if (allUsedBonuses.fm && isFollowOn) {
        nNeededBonuses += 1;
      }
    }

    if (nNeededBonuses > nAvailableBonuses) {
      // too much
      return;
    }

    // Enough, let's eat.
    const target: any = {
      sourceLocationId,
      targetLocationId: locationId,
      // path includes source but excludes destination; passing includes
      // neither source nor destination.
      passingLocations: path.slice(1),
      ms: enemyRelationship === 'walled' || enemyRelationship === 'meeting',
      hasOverrun: enemyRelationship === 'overrun',
      // if we have just enough, then we won't be able to consume more to
      // go further.
      isFinal: isFinal || nNeededBonuses === nAvailableBonuses,

      h: false,
      al: false,
      dlfmChoice: false,
      dl: false,
      fm: false,
    };
    movementTargets.push(target);

    const eat = {
      h: false,
      al: false,
      dlfmChoice: false,
      dl: false,
      fm: false,
    };

    function consumeBonus(n) {
      if (!eat.h && ready.h) {
        eat.h = true;
      } else if ((eat.h || allUsedBonuses.h) && !hApplicable) {
        if (!((ready.al || ready.dl) && ready.fm)) {
          throw new ActionError(
            'walking highway -> non-highway, but does not have the other two bonuses',
            {
              reachableLocation,
              ready,
              eat,
            }
          );
        }
        eat.h = false;
        if (ready.al) {
          eat.al = true;
        } else {
          eat.dl = true;
        }
        eat.fm = true;
      } else if (!eat.al && ready.al) {
        eat.al = true;
      } else if (!eat.dlfmChoice && ready.dl && ready.fm) {
        eat.dlfmChoice = true;
      } else if (eat.dlfmChoice) {
        if (!(ready.dl && ready.fm)) {
          throw new ActionError(
            'consuming after dlfmChoice, but does not have both bonuses',
            {
              reachableLocation,
              ready,
              eat,
            }
          );
        }
        eat.dlfmChoice = false;
        eat.dl = true;
        eat.fm = true;
      } else if (!eat.dl && ready.dl) {
        eat.dl = true;
      } else if (!eat.fm && ready.fm) {
        eat.fm = true;
      } else {
        throw new ActionError('nothing left to consume', {
          reachableLocation,
          ready,
          eat,
        });
      }
    }

    for (let i = 0; i < nNeededBonuses; ++i) {
      consumeBonus(i);
    }

    Object.entries(eat).forEach(([key, value]) => {
      if (value) {
        target[key] = value;
      }
    });
    target.hApplicable = hApplicable;
  });

  // So far we determined isFinal based on the requirements that are observable
  // on coming into the location.  But we'd like to mark isFinal for those
  // seemingly-intermediate locations for which no actual continuation is
  // possible: e.g. all connections from it are covered; or only non-covered
  // connections are roads and we must use the highway bonus.

  const leafTargets = movementTargets.filter(
    (movementTargets) => movementTargets.isFinal
  );

  const result = movementTargets.map((movementTarget) => {
    const { isFinal, targetLocationId } = movementTarget;
    if (isFinal) {
      return movementTarget;
    }

    const actuallyIsIntermediate = leafTargets.some((leafTarget) => {
      const { passingLocations } = leafTarget;
      return passingLocations.some((pl) => pl.locationId === targetLocationId);
    });

    if (actuallyIsIntermediate) {
      return movementTarget;
    }

    return {
      ...movementTarget,
      isFinal: true,
    };
  });

  return result;
}

export function determineMovementParameters(
  state,
  role,
  sourceLocationId,
  movingUnitSids
) {
  const movementPenalty = sekiGetMovementPenaltyForUnitAmount(
    movingUnitSids.length
  );

  if (movementPenalty === null) {
    throw new EError(
      'determineMovementTargets: movingUnitSids has too many units',
      { role, sourceLocationId, movingUnitSids }
    );
  }

  const hasAutomaticLeadership = getHasAutomaticLeadership(
    state,
    role,
    sourceLocationId
  );
  const hasDeclaredLeadership = getHasDeclaredLeadership(
    state,
    role,
    sourceLocationId
  );
  const canForceMarch = getCanForceMarch(state, role);

  const movementParameters = {
    movementPenalty,
    hasAutomaticLeadership,
    hasDeclaredLeadership,
    canForceMarch,
  };

  return movementParameters;
}

export function determineMovementTargets(
  state,
  role,
  sourceLocationId,
  movingUnitSids
) {
  const movementParameters = determineMovementParameters(
    state,
    role,
    sourceLocationId,
    movingUnitSids
  );
  const targets = collectMovementTargets(
    state,
    role,
    sourceLocationId,
    movingUnitSids,
    movementParameters,
    'start'
  );

  return targets;
}

export function getMovableUnitsInLocation(state, role, locationId) {
  const allUnits = getCardsInPlayerZone(state, role, locationId);

  return allUnits.filter((unitCard: CardInRegion) => {
    const unitInfo = sekiGetUnitInfoBySid(unitCard.sid);
    if (unitInfo.kind === 'disc') {
      return false;
    }
    return !unitCard.vars.hasMoved;
  });
}

export function collectActivatableLocationIds(state, role) {
  const locationIds = sekiLocationData
    .filter((location) => {
      const { locationId } = location;
      if (locationId === 'moribox') {
        return false;
      }
      if (location.policy === 'box') {
        if (getStateVar(state, qualifiedName(role, 'mustered'), false)) {
          return false;
        }
        const units = getCardsInPlayerZone(state, role, locationId);
        if (units.length === 0) {
          return false;
        }

        // We return true even in the case where all mustering locations are
        // taken by the enemy, because the result of this function is used to
        // count/limit activations.  If we add the restriction "has free
        // mustering location" here, then we might be incorrect in the scenario
        // where a player starts out with no free mustering locations but then
        // brings in units to one.

        return true;
      }

      const units = getMovableUnitsInLocation(state, role, locationId);
      if (units.length === 0) {
        return false;
      }

      const opponentRole = sekiGetOpponentRole(role);
      const opponentUnits = getCardsInPlayerZone(
        state,
        opponentRole,
        locationId
      );

      if (
        getLocationHasCastle(locationId) &&
        units.length <= 2 &&
        opponentUnits.length > 0
      ) {
        return false;
      }

      const potentialTargets = determineMovementTargets(
        state,
        role,
        locationId,
        units.slice(0, 1)
      );
      if (potentialTargets.length === 0) {
        return false;
      }

      return true;
    })
    .map((location) => {
      const { locationId } = location;
      return locationId;
    });

  return locationIds;
}

export function determineActivations(state, role, mode) {
  const locationIds = collectActivatableLocationIds(state, role);

  const maximum = locationIds.length;

  let nActivations;
  if (mode === 'minimal') {
    nActivations = Math.min(1, maximum);
  } else if (mode === 'limited') {
    nActivations = Math.min(3, maximum);
  } else if (mode === 'total') {
    nActivations = maximum;
  } else {
    throw new ActionError(
      'determineActivations: illegal or unrecognized mode',
      { mode }
    );
  }
  return {
    nActivations,
    locationIds,
  };
}

export function collectAvailableMusteringLocations(state, role) {
  const opponentRole = sekiGetOpponentRole(role);

  return sekiLocationData
    .filter((location) => {
      const { locationId, musters } = location;
      if (!(musters && clanToFaction[musters[0]] === role)) {
        return false;
      }

      const yourUnits = getCardsInPlayerZone(state, role, locationId);
      const opponentUnits = getCardsInPlayerZone(
        state,
        opponentRole,
        locationId
      );

      if (opponentUnits.length > 0 && yourUnits.length === 0) {
        return false;
      }

      return true;
    })
    .map((location) => location.locationId);
}

export function sekiCalculateAddedImpact(
  currentImpact,
  alreadyDeployedUnitInfos,
  newlyDeployedUnitInfos,
  deployingCardBase,
  combatType
) {
  if (![1, 2].includes(newlyDeployedUnitInfos.length)) {
    throw new EError('sekiCalculateAddedImpact only supports 1 or 2 units', {
      currentImpact,
      alreadyDeployedUnitInfos,
      newlyDeployedUnitInfos,
      deployingCardBase,
    });
  }

  const first = newlyDeployedUnitInfos[0];
  const clan = first.clan;

  const baseBonus = sum(
    newlyDeployedUnitInfos.map((unitInfo) => {
      return unitInfo.strength;
    })
  );

  const clanBonus =
    alreadyDeployedUnitInfos.filter((thatUnitInfo) => {
      return thatUnitInfo.clan === clan;
    }).length + (newlyDeployedUnitInfos.length === 2 ? 1 : 0);

  const cardInfo = deployingCardBase
    ? sekiGetCardInfoByBase(deployingCardBase)
    : null;
  const specialAttackKind =
    combatType === 'battle' &&
    (cardInfo && cardInfo.cardKind) === 'special' &&
    first.special
      ? first.special
      : 'none';

  let specialAttackBonus = 0;

  if (specialAttackKind !== 'none') {
    specialAttackBonus =
      2 +
      alreadyDeployedUnitInfos.filter((unitInfo) => {
        return unitInfo.special === specialAttackKind;
      }).length *
        2;
  }

  const addedImpact = baseBonus + clanBonus + specialAttackBonus;

  return {
    addedImpact,
    newImpact: currentImpact + addedImpact,
    baseBonus,
    clanBonus,
    specialAttackBonus,
    attackType:
      specialAttackKind === 'arq'
        ? `sa-arq`
        : specialAttackKind === 'cav'
        ? `sa-cav`
        : 'na',
  };
}

export function sekiGetNLosses(impact, isLoser, combatType) {
  const baseLosses = Math.floor(impact / 7);

  if (combatType === 'battle' && isLoser) {
    return baseLosses + 1;
  }

  return baseLosses;
}

export function sekiGetCombatOutcome(
  state,
  options = { addedImpactRole: null, addedImpact: null }
): CombatOutcome {
  const iImpact =
    getStateNumberVar(state, 'impact:i') +
    (options.addedImpactRole === 'i' ? options.addedImpact : 0);
  const tImpact =
    getStateNumberVar(state, 'impact:t') +
    (options.addedImpactRole === 't' ? options.addedImpact : 0);
  const attackerRole = sekiGetAttackerRole(state);
  const whoIsLosing =
    iImpact < tImpact ? 'i' : iImpact === tImpact ? attackerRole : 't';
  const whoIsWinning = sekiGetOpponentRole(whoIsLosing);
  const combatType = getStateVar(state, 'combatType');

  return {
    iImpact,
    tImpact,
    whoIsWinning,
    whoIsLosing,
    iLosses: sekiGetNLosses(tImpact, whoIsLosing === 'i', combatType),
    tLosses: sekiGetNLosses(iImpact, whoIsLosing === 't', combatType),
  };
}

export function sekiGetNAwardsForLosses(nLosses, combatType) {
  return combatType === 'siege' ? nLosses : Math.floor(nLosses / 2);
}

export function collectUndeployedUnitCards(state, role, locationId) {
  return getCardsInPlayerZone(state, role, locationId).filter(
    (unitCard: CardInRegion) => {
      return unitCard.facing === 'facedown';
    }
  );
}
