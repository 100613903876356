import {
  CardFacing,
  CardRotation,
  VarValues,
  VarChangers,
  Action,
  CreateCardOptions,
  MoveCardOptions,
  AAResult,
  CardTag,
  CardInRegion,
  LogLineArgsDescriptor,
  LogLineArgDescriptor,
  HistoryEntry,
  ActionApplicatorsMapObject,
} from './types';
import {
  removeCardAtLocationCtx,
  addCardToLocationCtx,
  ensureCardInBunch,
  ensureCardInRegion,
  updateCardAtLocation,
  registerCardChangeCtx,
  saveToStashCtx,
} from './actionHelpers';
import { dc } from './deltas';
import { ActionError } from './errors';
import { cloneCardRegistryForRole } from './CardRegistry';
import {
  getZoneOfLocation,
  createAttachmentsRegion,
  getZone,
  findCardLocation,
  shuffleArray,
  mapValues,
  getCardByLocation,
  findCardsBySids,
} from './utils';
import produce from 'immer';
import {
  createActionGroup,
  registerActionGroup,
  applyAction,
} from './actionsInfrastructure';

export const foundationGroup = createActionGroup('fa', {
  nop(state) {
    return {
      next: state,
      delta: dc.empty(),
    };
  },

  addPlayer(state, playerName, role) {
    if (state.roles.includes(role)) {
      throw new ActionError(
        `Cannot add player with duplicate role: ${{ playerName, role }}`
      );
    }

    return {
      next: produce(state, (draft) => {
        draft.roleToPlayerName[role] = playerName;
        draft.roles.push(role);
        draft.roleToCardRegistry[role] = cloneCardRegistryForRole(
          state.roleToCardRegistry['spec'],
          role
        );
      }),
      delta: dc.playerAdded(playerName, role),
    };
  },

  addHistoryEntry(state, historyEntry) {
    const next = produce(state, (draftState) => {
      draftState.historyEntries.push(historyEntry);
    });
    const delta = dc.historyEntryAdded(historyEntry);
    return {
      next,
      delta,
    };
  },

  createZone(state, zoneKind, owner, slug, access) {
    return {
      next: produce(state, (draft) => {
        const zoneName = owner ? `${owner}:${slug}` : slug;

        const zone = {
          zoneKind,
          owner,
          slug,
          access,
          cards: [],
        };
        draft.zones[zoneName] = zone;
      }),
      delta: dc.zoneCreated(zoneKind, owner, slug, access),
    };
  },

  seq(state, ...actions) {
    let currentState = state;
    const childDeltas = [];

    for (let i = 0; i < actions.length; ++i) {
      const childAction = actions[i];

      const { next, delta } = applyAction(currentState, childAction);
      currentState = next;
      if (delta.deltaType !== 'empty') {
        childDeltas.push(delta);
      }
      if (currentState.outcome) {
        break;
      }
    }

    return {
      next: currentState,
      delta:
        childDeltas.length === 0
          ? dc.empty()
          : childDeltas.length === 1
          ? childDeltas[0]
          : dc.seq(childDeltas),
    };
  },

  repeat(state, childAction, repeatCount) {
    const childActions = [];
    for (let i = 0; i < repeatCount; ++i) {
      childActions.push(childAction);
    }
    return applyAction(state, fa.seq(...childActions));
  },

  createCard(state, base, cardBack, zoneName, options: CreateCardOptions = {}) {
    const zone = getZone(state, zoneName);

    if (!zone) {
      throw new ActionError(`zone not found: ${zoneName}`);
    }

    const { owner } = zone;
    if (!owner) {
      throw new ActionError(`cannot create card in zone without owner`);
    }

    const tag: CardTag =
      zone.zoneKind === 'bunch' ? 'cardInBunch' : 'cardInRegion';

    const facing = tag === 'cardInBunch' ? null : options.facing || 'faceup';
    const rotation = tag === 'cardInBunch' ? null : options.rotation || 'ready';

    const roleToKnowledge = {};
    const { baseToCounter } = state;
    const counter = (baseToCounter[base] || 0) + 1;
    const sid = `${base}:${counter}`;

    const card =
      tag === 'cardInBunch'
        ? {
            tag,
            sid,
            owner,
            cardBack,
            roleToKnowledge,
          }
        : {
            tag,
            sid,
            owner,
            cardBack,
            roleToKnowledge,
            vars: {},
            facing,
            rotation,
            attachments: createAttachmentsRegion(sid, owner),
          };

    const newState = produce(state, (draftState) => {
      draftState.baseToCounter[base] = counter;
    });

    const ctx = { state: newState };

    const newCard = registerCardChangeCtx(ctx, card, facing, zone);

    const index = zone.cards.length;
    const location = { zoneName, index };
    addCardToLocationCtx(ctx, newCard, location);

    const delta = dc.cardCreated(newCard, location);
    return {
      next: ctx.state,
      delta,
    };
  },

  changeCardFacing(state, sid, facing: CardFacing, options = {}) {
    const location = options.location || findCardLocation(state, sid);
    if (!location) {
      throw new ActionError(`card not found`);
    }

    const ctx = { state };

    let card = removeCardAtLocationCtx(ctx, location);

    if (card.tag !== 'cardInRegion') {
      throw new ActionError(`card must be cardInRegion to change facing`);
    }

    card = registerCardChangeCtx(
      ctx,
      card,
      facing,
      getZoneOfLocation(state, location)
    );

    const newCard = {
      ...card,
      facing,
    };

    addCardToLocationCtx(ctx, newCard, location);

    if (options.saveAs) {
      saveToStashCtx(ctx, options.saveAs, newCard);
    }

    const delta = dc.cardChangedFacing(newCard, facing, location);
    return {
      next: ctx.state,
      delta,
    };
  },

  moveCard(state, sid, targetZoneName, options = {}) {
    const sourceLocation =
      options.sourceLocation || findCardLocation(state, sid);
    if (!sourceLocation) {
      throw new ActionError(`card not found`);
    }

    const targetZone = getZone(state, targetZoneName);

    if (!targetZone) {
      throw new ActionError(`target zone "${targetZoneName}" not found`);
    }

    const targetIndex = targetZone.cards.length;

    const targetLocation = {
      zoneName: targetZoneName,
      index: targetIndex,
    };

    const ctx = { state };

    let card = removeCardAtLocationCtx(ctx, sourceLocation);

    let facing;
    if (targetZone.zoneKind === 'bunch') {
      facing = null;
    } else {
      facing =
        options.facing ||
        (card.tag === 'cardInRegion' ? (card as CardInRegion).facing : null) ||
        'faceup';
    }

    card = registerCardChangeCtx(ctx, card, facing, targetZone);

    let newCard;
    if (targetZone.zoneKind === 'bunch') {
      newCard = ensureCardInBunch(card);
    } else {
      newCard = ensureCardInRegion(card, {
        facing,
        rotation: options.rotation,
      });
    }

    addCardToLocationCtx(ctx, newCard, targetLocation);

    if (options.saveAs) {
      saveToStashCtx(ctx, options.saveAs, newCard);
    }
    if (options.saveTargetLocationAs) {
      saveToStashCtx(ctx, options.saveTargetLocationAs, targetLocation);
    }

    const delta = dc.cardMoved(newCard, sourceLocation, targetLocation);
    return {
      next: ctx.state,
      delta,
    };
  },

  moveCardsBySids(state, sids, targetZoneName, options = {}) {
    if (sids.length === 0) {
      return applyAction(
        state,
        fa.seq(
          options.saveAs
            ? fa.updateStash(options.saveAs, (state) => {
                return [];
              })
            : fa.nop()
        )
      );
    }
    return applyAction(
      state,
      fa.seq(
        ...sids.map((sid) => {
          return fa.moveCard(sid, targetZoneName, { facing: options.facing });
        }),
        options.saveAs
          ? fa.updateStash(options.saveAs, (state) => {
              return findCardsBySids(state, sids);
            })
          : fa.nop()
      )
    );
  },

  moveCards(state, cards, targetZoneName, options = {}) {
    return applyAction(
      state,
      fa.moveCardsBySids(
        cards.map((card) => card.sid),
        targetZoneName,
        options
      )
    );
  },

  changeCardRotation(state, sid, rotation, options = {}) {
    const location = options.location || findCardLocation(state, sid);
    if (!location) {
      throw new ActionError(`card not found`);
    }

    const { next, newCard } = updateCardAtLocation(state, location, (card) => {
      if (card.tag !== 'cardInRegion') {
        throw new ActionError(`card must be in a region`);
      }
      return {
        ...card,
        rotation,
      };
    });

    const delta = dc.cardChangedRotation(newCard, rotation, location);

    return {
      next,
      delta,
    };
  },

  attachCard(state, cardSid, hostSid, options = {}) {
    const location = options.cardLocation || findCardLocation(state, cardSid);

    if (!location) {
      throw new ActionError(`card not found`);
    }

    const hostLocation = findCardLocation(state, hostSid);
    if (!hostLocation) {
      throw new ActionError(`host card not found`);
    }

    const { zoneName: hostZoneName, index: hostCardIndex } = hostLocation;

    if (hostLocation.attachmentIndex !== undefined) {
      throw new ActionError('attaching to an attachment is not supported');
    }

    const hostZone = getZone(state, hostZoneName);
    if (hostZone.zoneKind !== 'region') {
      throw new ActionError('cannot attach to a non-region card');
    }

    const hostCard = hostZone.cards[hostCardIndex] as CardInRegion;

    const attachmentIndex = hostCard.attachments.cards.length;

    const targetLocation = {
      zoneName: hostZoneName,
      index: hostCardIndex,
      attachmentIndex,
    };

    const ctx = { state };

    let card = removeCardAtLocationCtx(ctx, location);
    card = registerCardChangeCtx(
      ctx,
      card,
      'faceup',
      getZoneOfLocation(state, targetLocation)
    );

    const newCard = ensureCardInRegion(card);

    addCardToLocationCtx(ctx, newCard, targetLocation);

    if (options.saveCardAs) {
      saveToStashCtx(ctx, options.saveCardAs, newCard);
    }
    if (options.saveHostAs) {
      const newHostCard = getCardByLocation(ctx.state, hostLocation);
      saveToStashCtx(ctx, options.saveHostAs, newHostCard);
    }

    const delta = dc.cardMoved(newCard, location, targetLocation);

    return {
      next: ctx.state,
      delta,
    };
  },

  setCardVars(state, sid, setters, options = {}) {
    const location = options.location || findCardLocation(state, sid);
    if (!location) {
      throw new ActionError(`card not found`);
    }

    const { next, newCard } = updateCardAtLocation(state, location, (card) => {
      if (card.tag !== 'cardInRegion') {
        throw new ActionError(`card must be in region to have vars`);
      }
      return produce(card, (draftCard) => {
        Object.entries(setters).forEach(
          ([varName, value]: [string, number]) => {
            draftCard.vars[varName] = value;
          }
        );
      });
    });

    const ctx = { state: next };
    if (options.saveAs) {
      saveToStashCtx(ctx, options.saveAs, newCard);
    }

    const delta = dc.cardVarsSet(newCard, location, setters);
    return {
      next: ctx.state,
      delta,
    };
  },

  changeCardVars(state, sid, changers, options = {}) {
    const location = options.location || findCardLocation(state, sid);
    if (!location) {
      throw new ActionError(`card not found`);
    }

    const ctx = { state };

    const { next, newCard } = updateCardAtLocation(state, location, (card) => {
      if (card.tag !== 'cardInRegion') {
        throw new ActionError(`card must be in region to have vars`);
      }
      return produce(card, (draftCard) => {
        Object.entries(changers).forEach(
          ([varName, value]: [string, number]) => {
            const current = draftCard.vars[varName];
            if (typeof current !== 'number') {
              throw new ActionError(
                `cannot change non-number var ${varName}: ${current}`
              );
            }
            const newValue = (current as number) + value;
            draftCard.vars[varName] = newValue;
          }
        );
      });
    });
    ctx.state = next;

    if (options.saveAs) {
      saveToStashCtx(ctx, options.saveAs, newCard);
    }
    if (options.saveNewValueAs) {
      const [varName, stashVarName] = options.saveNewValueAs;
      saveToStashCtx(
        ctx,
        stashVarName,
        (newCard as CardInRegion).vars[varName]
      );
    }

    const delta = dc.cardVarsChanged(newCard, location, changers);
    return {
      next: ctx.state,
      delta,
    };
  },

  setVars(state, setters) {
    if (Object.entries(setters).length === 0) {
      return {
        next: state,
        delta: dc.empty(),
      };
    }

    const next = produce(state, (draftState) => {
      Object.entries(setters).forEach(([varName, value]: [string, number]) => {
        draftState.vars[varName] = value;
      });
    });
    return {
      next,
      delta: dc.stateVarsSet(setters),
    };
  },

  setStashedValues(state, setters) {
    const next = produce(state, (draftState) => {
      Object.entries(setters).forEach(([varName, value]: [string, number]) => {
        draftState.stash[varName] = value;
      });
    });
    return {
      next,
      delta: dc.empty(),
    };
  },

  changeVars(state, changers, options = {}) {
    const newValues = {};

    const next = produce(state, (draftState) => {
      Object.entries(changers).forEach(
        ([varName, changer]: [string, number]) => {
          const current = draftState.vars[varName];
          if (typeof current !== 'number') {
            throw new ActionError(`cannot change non-number var ${varName}`);
          }
          const value = (current as number) + changer;
          newValues[varName] = value;
          draftState.vars[varName] = value;
        }
      );
    });
    const ctx = { state: next };
    if (options.saveNewValueAs) {
      const [varName, stashVarName] = options.saveNewValueAs;
      saveToStashCtx(ctx, stashVarName, newValues[varName]);
    }

    return {
      next: ctx.state,
      delta: dc.stateVarsChanged(changers, newValues),
    };
  },

  setSingleVar(state, varName, value) {
    return applyAction(
      state,
      fa.setVars({
        [varName]: value,
      })
    );
  },

  changeSingleVar(state, varName, changer, options = {}) {
    const changeVarsOptions = options.saveNewValueAs
      ? {
          saveNewValueAs: [varName, options.saveNewValueAs],
        }
      : undefined;
    return applyAction(
      state,
      fa.changeVars(
        {
          [varName]: changer,
        },
        changeVarsOptions
      )
    );
  },

  setZoneAccess(state, zoneName, access) {
    const zone = getZone(state, zoneName);

    if (!zone) {
      throw new ActionError(`no zone by name`);
    }
    let newZone = {
      ...zone,
      access,
    };

    const ctx = { state };

    const newCards = zone.cards.map((card) => {
      const facing = card.tag === 'cardInBunch' ? null : card.facing;
      return registerCardChangeCtx(ctx, card, facing, newZone);
    });

    newZone = {
      ...newZone,
      cards: newCards,
    };

    const next = produce(ctx.state, (draftState) => {
      draftState.zones[zoneName] = newZone;
    });

    const { zoneKind } = zone;

    const delta = dc.zoneAccessSet(
      zoneName,
      zoneKind,
      access,
      newZone.cards,
      zone.access
    );

    return {
      next,
      delta,
    };
  },

  shuffleZone(state, zoneName) {
    const initialZone = getZone(state, zoneName);
    if (!initialZone) {
      throw new ActionError(`shuffleZone: no zone by name`, { zoneName });
    }

    const ctx = { state };

    const newCards = shuffleArray(initialZone.cards).map((card) => {
      const facing = card.tag === 'cardInBunch' ? null : card.facing;
      return registerCardChangeCtx(ctx, card, facing, initialZone, {
        replaceLimitedKnowledge: true,
      });
    });

    const zone = getZone(ctx.state, zoneName);
    const newZone = produce(zone, (draftZone) => {
      draftZone.cards = newCards;
    });

    const next = produce(ctx.state, (draftState) => {
      draftState.zones[zoneName] = newZone;
    });
    const delta = dc.zoneShuffled(newZone);
    return {
      next,
      delta,
    };
  },

  text(state, templateCode, argsDescriptor = {}) {
    const args = mapValues(
      argsDescriptor,
      (argDescriptor: LogLineArgDescriptor, argName) => {
        let argValue;
        if (typeof argDescriptor !== 'object') {
          argValue = argDescriptor;
        } else if ('value' in argDescriptor) {
          argValue = argDescriptor.value;
        } else if ('fromStash' in argDescriptor) {
          argValue = state.stash[argDescriptor.fromStash];
        } else {
          throw new ActionError(
            `argDescriptor for ${argName} must be either value or fromStash`,
            {
              templateCode,
              argName,
              argDescriptor,
            }
          );
        }
        return argValue;
      }
    );
    const logLine = {
      templateCode,
      args,
    };
    const historyEntry = {
      entryType: 'logLine',
      logLine,
      // XXX getting timestamp should be in addHistoryEntry, but since it's the
      // only place where they're created, it's fine to do it here.
      createdAt: new Date().getTime(),
    };
    return applyAction(state, fa.addHistoryEntry(historyEntry));
  },
  updateStash(state, key, valueObtainer) {
    const value = valueObtainer(state);
    const next = produce(state, (draftState) => {
      draftState.stash[key] = value;
    });
    return {
      next,
      delta: dc.empty(),
    };
  },
  endGame(state, mode, submode, winnerRole) {
    const outcome = { mode, submode, winnerRole };

    return {
      next: produce(state, (draftState) => {
        draftState.outcome = outcome;
      }),
      delta: dc.gameEnded(outcome),
    };
  },
});

registerActionGroup(foundationGroup);

export const fa = foundationGroup.actionCreators;

export function getStateAfterActions(state, ...actions) {
  const { next } = applyAction(state, fa.seq(...actions));
  return next;
}
