import React, { Fragment, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { ChatForm } from 'components/ChatForm';
import { Avatar } from 'components/Avatar';
import { MessageFromTemplate } from 'components/MessageFromTemplate';
import { formatTimestamp } from 'utils';
import { tdLogItemTemplates } from 'data/tdData';
import { sekiGetLogItemTemplate } from 'data/sekiData';

function doScrollToBottom(el) {
  el.scrollTop = el.scrollHeight;
}

function ServiceMessage(props) {
  const { createdAt } = props;
  return (
    <div className="ServiceMessage">
      <span className="timestamp">{formatTimestamp(createdAt)}</span>
      {props.children}
    </div>
  );
}

function Timestamp(props) {
  const { createdAt } = props;
  return <span className="timestamp">{formatTimestamp(createdAt)}</span>;
}

const ThmTdLogItem = React.memo((props) => {
  const { logItem, pgpDispatch, roleToPlayerName } = props;
  const [deltaType, ...more] = logItem;

  let textCode, textArgs;
  if (deltaType === 'deck-shuffled' || deltaType === 'game-finished') {
    textCode = deltaType;
    textArgs = more;
  } else if (deltaType === 'text') {
    [textCode, ...textArgs] = more;
  } else {
    console.warn('cannot translate logItem', logItem);
    return (
      <>
        ?{deltaType} {textCode}?
      </>
    );
  }
  const templateStringOrFn = tdLogItemTemplates[textCode];

  let template, args;
  if (!templateStringOrFn) {
    template = 'unrecognized text code: %n';
    console.warn('unrecognized text code: ', textCode, logItem);
    args = {
      n: textCode,
    };
  } else {
    [template, args = {}] =
      typeof templateStringOrFn === 'string'
        ? [templateStringOrFn, {}]
        : templateStringOrFn(textArgs);
    textArgs.forEach((el, i) => {
      args[`@${i + 1}`] = el;
    });

    // This is a hack (better would be to change all tdLogItemTemplates to
    // include the coloring information in each.)
    if (template.includes('@1r')) {
      args.highlightClass = textArgs[0];
    }
  }

  return (
    <MessageFromTemplate
      code="td"
      template={template}
      args={args}
      roleToPlayerName={roleToPlayerName}
      pgpDispatch={pgpDispatch}
      highlightClass={args.highlightClass}
    />
  );
});

export function SekiLogLine(props) {
  const { logLine, pgpDispatch, roleToPlayerName } = props;
  const { templateCode, args } = logLine;

  const descriptor = sekiGetLogItemTemplate(templateCode, args);

  if (!(descriptor && descriptor.template)) {
    return (
      <MessageFromTemplate
        code="seki"
        template="unrecognized templateCode: %n"
        args={{ n: templateCode }}
        roleToPlayerName={roleToPlayerName}
        pgpDispatch={pgpDispatch}
      />
    );
  }

  const { template, highlightClass, addArgs } = descriptor;
  const updatedArgs = addArgs ? { ...args, ...addArgs } : args;

  return (
    <MessageFromTemplate
      code="seki"
      template={template}
      args={updatedArgs}
      roleToPlayerName={roleToPlayerName}
      pgpDispatch={pgpDispatch}
      highlightClass={highlightClass}
    />
  );
}

function RoomMessage(props) {
  const { message, pgpDispatch, roleToPlayerName } = props;

  const { messageCode, createdAt, args } = message;
  if (messageCode === 'chat') {
    const { author, text } = args;
    return (
      <div className="ChatMessage">
        <Avatar name={author} />

        <div className="main">
          <div className="top">
            <span className="author">{author}</span>
            &nbsp;
            <Timestamp createdAt={createdAt} />
          </div>
          <div className="text">{text}</div>
        </div>
      </div>
    );
  } else if (messageCode === 'gameCanceled') {
    return (
      <ServiceMessage createdAt={createdAt}>
        The game was canceled by the owner.
      </ServiceMessage>
    );
  } else if (messageCode === 'playerJoins') {
    const { username } = args;
    return (
      <ServiceMessage createdAt={createdAt}>
        <b>{username}</b> joins the game.
      </ServiceMessage>
    );
  } else if (messageCode === 'playerLeaves') {
    const { username } = args;
    return (
      <ServiceMessage createdAt={createdAt}>
        <b>{username}</b> leaves the game.
      </ServiceMessage>
    );
  } else if (messageCode === 'gameStarted') {
    return (
      <ServiceMessage createdAt={createdAt}>The game started.</ServiceMessage>
    );
  } else if (messageCode === 'gameFinished') {
    return (
      <ServiceMessage createdAt={createdAt}>The game finished.</ServiceMessage>
    );
  } else if (messageCode === 'thmTdLogItem') {
    const { logItem } = args;
    return (
      <ThmTdLogItem
        logItem={logItem}
        pgpDispatch={pgpDispatch}
        roleToPlayerName={roleToPlayerName}
      />
    );
  } else if (messageCode === 'sekiLogLine') {
    const { logLine } = args;
    return (
      <SekiLogLine
        logLine={logLine}
        pgpDispatch={pgpDispatch}
        roleToPlayerName={roleToPlayerName}
      />
    );
  } else {
    console.warn('Unrecognized messageCode:', messageCode, message);
    return <>??{messageCode}??</>;
  }
}

export function ChatComponent(props) {
  const { chat, onChatInputFocusChanged, isCondensed, pgpDispatch } = props;

  const { messages, onChatLineSent, roleToPlayerName } = chat;

  const scrollerRef = useRef(null);
  const atBottomRef = useRef(true);
  const nowFocusedRef = useRef(false);

  // Scroll chat to bottom when the software keyboard appears as a result of
  // focusing the chat input.
  function onResize() {
    if (nowFocusedRef.current) {
      doScrollToBottom(scrollerRef.current);
      nowFocusedRef.current = false;
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  useEffect(() => {
    if (!atBottomRef.current) {
      return;
    }
    doScrollToBottom(scrollerRef.current);
  }, [messages, scrollerRef]);

  const handleScroll = () => {
    const el = scrollerRef.current;
    const atBottom = el.scrollTop + el.offsetHeight >= el.scrollHeight - 4;
    atBottomRef.current = atBottom;
  };

  function handleInputFocusChanged(nowFocused) {
    nowFocusedRef.current = nowFocused;
    if (onChatInputFocusChanged) {
      onChatInputFocusChanged(nowFocused);
    }
  }

  const className = clsx('ChatComponent', { isCondensed });
  return (
    <div className={className}>
      <div className="Messages" ref={scrollerRef} onScroll={handleScroll}>
        {messages.map((message, index) => {
          return (
            <Fragment key={index}>
              <RoomMessage
                message={message}
                pgpDispatch={pgpDispatch}
                roleToPlayerName={roleToPlayerName}
              />
            </Fragment>
          );
        })}
      </div>
      <ChatForm
        onChatLineSent={onChatLineSent}
        onChatInputFocusChanged={handleInputFocusChanged}
      />
    </div>
  );
}
