import React, { useState, useRef } from 'react';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Footer } from 'components/Footer';
import { ListOfGameCards } from 'components/ListOfGameCards';
import { CreateGameModal } from 'components/CreateGameModal';
import { LinkSeparator } from 'components/LinkSeparator';
import { selectIsLoggedIn } from 'slices/userSlice';
import {
  selectLobbyYourGames,
  selectFilteredLobbyOtherGames,
} from 'slices/dependentSelectors';
import {
  selectLobbyFilters,
  toggleFilter,
  gameFilterDefinitions,
} from 'slices/lobbySlice';
import { usePageTitle } from 'hooks';
import { useCanUseSeki } from 'utils';
import { gameConfigByCode } from 'data/gameConfig';
import { Well } from 'components/Well';

function YourGameList(props) {
  const games = useSelector(selectLobbyYourGames);
  return <ListOfGameCards games={games} />;
}

function OtherGameList(props) {
  const games = useSelector(selectFilteredLobbyOtherGames);
  return <ListOfGameCards games={games} />;
}

function LobbyGameFilters(props) {
  const dispatch = useDispatch();

  const filters = useSelector(selectLobbyFilters);
  function handleChange(filterName) {
    dispatch(toggleFilter(filterName));
  }

  return (
    <div className="LobbyGameFilters">
      <div className="checkboxes">
        {Object.entries(gameFilterDefinitions).map(
          ([filterName, definition]) => {
            const filterChecked = !!filters[filterName];
            return (
              <div className="filterContainer" key={filterName}>
                <label>
                  <input
                    type="checkbox"
                    checked={filterChecked}
                    onChange={() => handleChange(filterName)}
                  />
                  {definition.label}
                </label>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
}

function NewGameButton(props) {
  const { newGameButtonRefs, showCreateGameModal, code, label } = props;
  return (
    <button
      className="button isPrimary"
      onClick={() => showCreateGameModal(code)}
      ref={newGameButtonRefs[code]}
    >
      <span className="labelWithIcon">
        <IoMdAddCircleOutline />
        {label}
      </span>
    </button>
  );
}

export function LobbyPage(props) {
  const [createNewGameCode, setCreateNewGameCode] = useState(null);
  const newGameButtonRefs = {
    td: useRef(null),
    seki: useRef(null),
  };
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const canUseSeki = useCanUseSeki();

  usePageTitle('Lobby');

  function showCreateGameModal(code) {
    setCreateNewGameCode(code);
  }

  function dismissCreateGameModal(code) {
    setCreateNewGameCode(null);
    newGameButtonRefs[code].current.focus();
  }

  return (
    <>
      <main className="LobbyPage topLevel">
        <CreateGameModal
          isActive={createNewGameCode !== null}
          onDismiss={() => dismissCreateGameModal(createNewGameCode)}
          code={createNewGameCode}
        />
        <div className="container">
          {isLoggedIn ? (
            <>
              <section>
                <h2>Your games</h2>
                <div className="pastGamesLinkContainer">
                  <Link to="/past-games">Past games</Link>
                  <LinkSeparator />
                  <Link to="/sandbox">Sandbox</Link>
                </div>
                <YourGameList />
              </section>
              <section className="create">
                {!canUseSeki ? (
                  <NewGameButton
                    code="td"
                    newGameButtonRefs={newGameButtonRefs}
                    showCreateGameModal={showCreateGameModal}
                    label="New game"
                  />
                ) : (
                  <>
                    <div>New game:</div>
                    {Object.entries(gameConfigByCode).map(([code, config]) => {
                      const { displayName } = config;
                      return (
                        <NewGameButton
                          key={code}
                          code={code}
                          newGameButtonRefs={newGameButtonRefs}
                          showCreateGameModal={showCreateGameModal}
                          label={displayName}
                        />
                      );
                    })}
                  </>
                )}
              </section>
            </>
          ) : null}
          <section>
            <h2>Active games</h2>
            <LobbyGameFilters />
            <OtherGameList />
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
}
