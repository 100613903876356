import { createSlice, createSelector } from '@reduxjs/toolkit';
import { createSelectGameByShortName } from 'slices/gamesSlice';
import { arrayContains, getCodeFromShortName } from 'utils';
import { applyVDelta, mapValues } from '@cr/engine';

function tdApplyDelta(cs, delta) {
  console.log('applying delta', delta);

  const [kind, ...rest] = delta;
  switch (kind) {
    case 'request-put': {
      const [roleToRequest] = rest;
      const requestMap = cs['request-map'];
      for (const [role, request] of Object.entries(roleToRequest)) {
        requestMap[role] = request;
      }
      break;
    }
    case 'request-answered': {
      const [role] = rest;
      const requestMap = cs['request-map'];
      delete requestMap[role];
      break;
    }
    case '-can-cancel-request?': {
      const [value] = rest;
      cs['can-cancel?'] = value;
      break;
    }
    case 'text': {
      cs['log'].push(delta);
      break;
    }
    case 'remove-last-text': {
      cs['log'].pop();
      break;
    }
    case 'deck-shuffled': {
      cs['log'].push(delta);
      break;
    }
    case 'var-changed': {
      const [varName, change, newValue] = rest;
      const unused = change; // eslint-disable-line no-unused-vars
      cs['vars'][varName] = newValue;
      break;
    }
    case 'vars-set': {
      const [nameValueTuples] = rest;
      for (const [varName, newValue] of nameValueTuples) {
        cs['vars'][varName] = newValue;
      }
      break;
    }
    case 'var-set': {
      const [varName, newValue, oldValue] = rest;
      const unused = oldValue; // eslint-disable-line no-unused-vars
      cs['vars'][varName] = newValue;
      break;
    }
    case 'var-unset': {
      const [varName] = rest;
      delete cs['vars'][varName];
      break;
    }
    case 'pause': {
      // do nothing - maybe in the future handle it when animations are here
      break;
    }
    case 'game-finished': {
      const [mode, submode, winner] = rest;
      cs['log'].push(delta);
      cs['outcome'] = [mode, submode, winner];
      break;
    }
    case 'cards-moved-vv': {
      const [from, to, cids] = rest;
      const retained = [];
      const moved = [];
      const zone = cs['zones'][from];
      for (const card of zone['cards']) {
        if (arrayContains(cids, card)) {
          moved.push(card);
        } else {
          retained.push(card);
        }
      }
      zone.cards = retained;
      cs['zones'][to].cards = cs['zones'][to].cards.concat(moved);
      break;
    }
    case 'cards-moved-iv': {
      const [from, to, cids] = rest;
      cs['zones'][from].cards -= cids.length;
      cs['zones'][to].cards = cs['zones'][to].cards.concat(cids);
      break;
    }
    case 'cards-moved-vi': {
      const [from, to, cids] = rest;
      const zone = cs['zones'][from];

      const retained = [];
      for (const card of zone['cards']) {
        if (!arrayContains(cids, card)) {
          retained.push(card);
        }
      }
      zone.cards = retained;
      cs['zones'][to].cards += cids.length;
      break;
    }
    case 'cards-moved-ii': {
      const [from, to, count] = rest;
      cs['zones'][from].cards -= count;
      cs['zones'][to].cards += count;
      break;
    }
    case 'reveal-zone': {
      const [zoneName] = rest;
      cs['shouldRevealZone'] = zoneName;
      break;
    }
    default: {
      console.warn('Unrecognized kind of delta', kind, delta);
      return;
    }
  }
}

export function determineVSourceToVOrders(vOrders) {
  const vSourceToVOrders = {};
  vOrders.forEach((vOrder) => {
    const { source } = vOrder;
    if (!vSourceToVOrders[source]) {
      vSourceToVOrders[source] = [];
    }
    vSourceToVOrders[source].push(vOrder);
  });

  return vSourceToVOrders;
}

export const runtimeStatesSlice = createSlice({
  name: 'runtimeStates',
  /*
    shortName -> rsCollection = {
      currentPov,
      nCheckpoints,
      perRole -> runtimeState = {
        yourRole,
        cs, globals, answer, answerSent
      }
    }
  */
  initialState: {},
  reducers: {
    runtimeStateAvailable: function (state, action) {
      const { shortName, data } = action.payload;

      const { yourRole, clientStates, nCheckpoints } = data;

      const code = getCodeFromShortName(shortName);

      const rsCollection = {
        currentPov: yourRole,
        nCheckpoints,
        perRole: mapValues(clientStates, (cs, role) => {
          let result = {
            yourRole: role,
            cs,
            answer: null,
            answerSent: false,
          };
          if (code === 'seki') {
            result = {
              ...result,
              globals: {},
              cs: {
                ...cs,
                vSourceToVOrders: determineVSourceToVOrders(cs.vOrders),
              },
            };
          }
          return result;
        }),
      };

      state[shortName] = rsCollection;
    },
    deltasAvailable: function (state, action) {
      const { shortName, data } = action.payload;

      const code = getCodeFromShortName(shortName);

      const { currentPov, perRole } = state[shortName];

      if (code === 'td') {
        const { deltas } = data;
        const cs = perRole[currentPov].cs;
        for (const delta of deltas) {
          tdApplyDelta(cs, delta);
        }
      } else if (code === 'seki') {
        const povToVDeltaUpdate = data;

        let povsNeedingAttention = [];

        Object.entries(povToVDeltaUpdate).forEach(([pov, vDeltaUpdate]) => {
          const { vDelta, vOrders } = vDeltaUpdate;
          const runtimeState = perRole[pov];

          const { cs } = runtimeState;

          runtimeState.cs = {
            ...applyVDelta(cs, vDelta),
            vOrders,
            vSourceToVOrders: determineVSourceToVOrders(vOrders),
          };
          runtimeState.answer = null;
          runtimeState.answerSent = false;

          if (vOrders.length > 0) {
            // TODO maybe not really, as there might be orders that don't mean
            // 'it is your turn'.
            povsNeedingAttention.push(pov);
          }
        });
        if (
          povsNeedingAttention.length > 0 &&
          !povsNeedingAttention.includes(currentPov)
        ) {
          state[shortName].currentPov = povsNeedingAttention[0];
        }
      } else {
        console.error(
          'Unrecognized game code for deltas',
          code,
          shortName,
          data
        );
      }
    },
    setAnswer: function (state, action) {
      const { shortName, answer } = action.payload;
      const { currentPov, perRole } = state[shortName];
      perRole[currentPov].answer = answer;
    },
    setGlobalValue: function (state, action) {
      const { shortName, key, value } = action.payload;
      const { currentPov, perRole } = state[shortName];
      perRole[currentPov].globals[key] = value;
    },
    answerSent: function (state, action) {
      const { shortName } = action.payload;
      const { currentPov, perRole } = state[shortName];

      const runtimeState = perRole[currentPov];

      const code = getCodeFromShortName(shortName);
      if (code === 'td') {
        const { cs } = runtimeState;

        const requestMap = cs['request-map'];

        delete requestMap[currentPov];
        delete cs['can-cancel?'];
        runtimeState.answer = null;
      } else if (code === 'seki') {
        runtimeState.answerSent = true;
        runtimeState.globals.forcedOpeningModal = false;
      } else {
        console.error('Unrecognized game code for answerSent', shortName);
      }
    },
    zoneRevealed: function (state, action) {
      const { shortName } = action.payload;
      const { currentPov, perRole } = state[shortName];
      const runtimeState = perRole[currentPov];

      const { cs } = runtimeState;

      delete cs['shouldRevealZone'];
    },
    takePov: function (state, action) {
      const { shortName, role } = action.payload;
      state[shortName].currentPov = role;
    },
    ackSaveCheckpoint(state, action) {
      const { shortName, data } = action.payload;
      const { nCheckpoints } = data;
      state[shortName].nCheckpoints = nCheckpoints;
    },
  },
});

export const {
  runtimeStateAvailable,
  deltasAvailable,
  setAnswer,
  setGlobalValue,
  answerSent,
  zoneRevealed,
  takePov,
  ackSaveCheckpoint,
} = runtimeStatesSlice.actions;

export const selectRuntimeStatesSlice = (state) => state.runtimeStates;

export const createSelectRsCollectionByShortName = (shortName) =>
  createSelector(selectRuntimeStatesSlice, (rsSlice) => rsSlice[shortName]);

export const createSelectCurrentPovByShortName = (shortName) =>
  createSelector(
    createSelectRsCollectionByShortName(shortName),
    (rsCollection) => {
      if (!rsCollection) {
        return null;
      }
      return rsCollection.currentPov;
    }
  );

export const createSelectNCheckpointsByShortName = (shortName) =>
  createSelector(
    createSelectRsCollectionByShortName(shortName),
    (rsCollection) => {
      if (!rsCollection) {
        return null;
      }
      return rsCollection.nCheckpoints;
    }
  );

export const createSelectRsPerRoleByShortName = (shortName) =>
  createSelector(
    createSelectRsCollectionByShortName(shortName),
    (rsCollection) => {
      if (!rsCollection) {
        return null;
      }
      return rsCollection.perRole;
    }
  );

export const createSelectRuntimeStateByShortName = (shortName) =>
  createSelector(
    createSelectRsCollectionByShortName(shortName),
    (rsCollection) => {
      if (!rsCollection) {
        return null;
      }
      const { currentPov, perRole } = rsCollection;
      return perRole[currentPov];
    }
  );

export const createSelectGameUiCtxByShortName = (
  shortName,
  gd,
  pgpState,
  pgpDispatch
) =>
  createSelector(
    createSelectGameByShortName(shortName),
    createSelectCurrentPovByShortName(shortName),
    createSelectRuntimeStateByShortName(shortName),
    (game, currentPov, runtimeState) => {
      if (!game) {
        return null;
      }
      const { code, status } = game;
      if (code === 'td' || code === 'seki') {
        if (!runtimeState) {
          return null;
        }
        const { yourRole, cs, answer, globals, answerSent } = runtimeState;
        const result = {
          shortName,
          yourRole,
          cs:
            code === 'td'
              ? {
                  ...cs,
                  // This is a hack because otherwise getYourRequest, used in
                  // lots of places, would be hard to modify.
                  status,
                }
              : cs,
          status,
          answer: answer === undefined ? null : answer,
          answerSent,
          gd,
          pgpState,
          pgpDispatch,
          globals,
          game,
          currentPov,
        };

        return result;
      } else {
        console.error(
          'createSelectGameUiCtx: Unrecognized game code',
          code,
          game
        );
        return null;
      }
    }
  );

export const createSelectYourClientState = (shortName) =>
  createSelector(
    createSelectRuntimeStateByShortName(shortName),
    (runtimeState) => {
      if (!runtimeState) {
        return null;
      }
      return runtimeState.cs;
    }
  );

export const createSelectGameLog = (shortName) =>
  createSelector(createSelectYourClientState(shortName), (cs) => {
    if (!cs) {
      return null;
    }

    const code = getCodeFromShortName(shortName);

    if (code === 'td') {
      const logItems = cs['log'] || [];
      const roleToPlayerName = cs['role->player-name'];

      let currentTimestamp = null;
      const messages = logItems
        .map((logItem) => {
          if (
            logItem[0] === 'text' &&
            (logItem[1] === '*commit-timestamp*' ||
              logItem[1] === 'commit-timestamp')
          ) {
            currentTimestamp = logItem[2];
            return null;
          }
          return {
            createdAt: currentTimestamp,
            messageCode: 'thmTdLogItem',
            args: {
              logItem,
            },
          };
        })
        .filter((x) => !!x);
      return {
        messages,
        roleToPlayerName,
      };
    } else if (code === 'seki') {
      const { roleToPlayerName, historyEntries } = cs;

      return {
        messages: historyEntries.map((historyEntry) => {
          const { createdAt, logLine } = historyEntry;

          return {
            createdAt,
            messageCode: 'sekiLogLine',
            args: {
              logLine,
            },
          };
        }),
        roleToPlayerName,
      };
    } else {
      console.error('Unrecognized game code in getting game log', code);
      return null;
    }
  });
