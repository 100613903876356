import React from 'react';
import { IoMdAlert, IoMdCheckmark } from 'react-icons/io';
import clsx from 'clsx';

export function Well(props) {
  const { modifier } = props;

  let icon;
  if (modifier === 'success') {
    icon = <IoMdCheckmark />;
  } else if (modifier === 'danger') {
    icon = <IoMdAlert />;
  }

  const className = clsx('Well', {
    [modifier]: true,
    withIcon: !!icon,
  });

  if (icon) {
    return (
      <div className={className}>
        {icon}
        <div>{props.children}</div>
      </div>
    );
  } else {
    return (
      <div className={className}>
        <div>{props.children}</div>
      </div>
    );
  }
}
