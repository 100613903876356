import React from 'react';
import clsx from 'clsx';
import { NavbarItem } from 'components/NavbarItem';
import { ConnectionStatus } from 'components/ConnectionStatus';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'slices/userSlice';

export function Navbar(props) {
  const { hideSmall } = props;
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const className = clsx('NavbarContainer', { hideSmall });
  return (
    <div className={className}>
      <nav>
        <div className="container">
          <ul>
            <div className="wrapper">
              <NavbarItem isBrand href="/">
                Chantry
              </NavbarItem>
              <NavbarItem href="/chat">Chat</NavbarItem>
              <NavbarItem href="/lobby">Play</NavbarItem>
              <NavbarItem href="/stats">Stats</NavbarItem>
              {isLoggedIn ? (
                <NavbarItem href="/account">Account</NavbarItem>
              ) : (
                <NavbarItem href="/login">Log in</NavbarItem>
              )}
            </div>
            <div className="wrapper">
              <div className="NavbarItem">
                <ConnectionStatus />
              </div>
            </div>
          </ul>
        </div>
      </nav>
    </div>
  );
}
