import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { connectionStatusSlice } from './slices/connectionStatusSlice';
import { userSlice } from './slices/userSlice';
import { roomSlice } from './slices/roomSlice';
import { gamesSlice } from './slices/gamesSlice';
import { lobbySlice } from './slices/lobbySlice';
import { sandboxSlice } from './slices/sandboxSlice';
import { runtimeStatesSlice } from './slices/runtimeStatesSlice';

const chattyLogger = (store) => (next) => (action) => {
  console.log('dispatching', action);
  let result = next(action);
  console.log('next state', store.getState());
  return result;
};

const silentLogger = (store) => (next) => (action) => {
  let result = next(action);
  return result;
};

const logger = false ? chattyLogger : silentLogger;

const rootReducer = combineReducers({
  connectionStatus: connectionStatusSlice.reducer,
  user: userSlice.reducer,
  room: roomSlice.reducer,
  games: gamesSlice.reducer,
  lobby: lobbySlice.reducer,
  sandbox: sandboxSlice.reducer,
  runtimeStates: runtimeStatesSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
