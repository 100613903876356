import React from 'react';
import { TopLevelPage } from 'components/TopLevelPage';
import { StartSignupForm } from 'components/StartSignupForm';

export function StartSignupPage(props) {
  return (
    <TopLevelPage title="Signup">
      <h1>Sign up</h1>
      <StartSignupForm />
    </TopLevelPage>
  );
}
