import React from 'react';
import { useTap } from 'utils/useTap';
import { getIsLeftSide } from 'utils';

export function InteractiveCardTitle(props) {
  const { card, cardTitle, pgpDispatch } = props;

  function handleMouseEnter(event) {
    if (!pgpDispatch) {
      return;
    }
    pgpDispatch({
      type: 'setCursorOnCardCrop',
      card,
      value: true,
      isLeftSide: getIsLeftSide(event.clientX),
    });
  }

  function handleMouseLeave() {
    if (!pgpDispatch) {
      return;
    }
    pgpDispatch({ type: 'setCursorOnCardCrop', card, value: false });
  }

  function handleTap() {
    if (!pgpDispatch) {
      return;
    }
    pgpDispatch({ type: 'openMobilePreview', previewType: 'card', card });
  }

  const tapHandlers = useTap(handleTap);

  return (
    <b
      className="InteractiveCardTitle"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...tapHandlers}
    >
      {cardTitle}
    </b>
  );
}
