import React from 'react';

export function Checkbox(props) {
  const { isChecked, name, value, onChange, children } = props;

  return (
    <label className="checkbox">
      <input
        type="checkbox"
        name={name}
        value={value}
        onChange={onChange}
        checked={isChecked}
      />
      {children}
    </label>
  );
}
