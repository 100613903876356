import React, { useRef, useLayoutEffect, useContext } from 'react';
import clsx from 'clsx';
import { useDebouncedCallback } from 'use-debounce';
import { IoMdFlash } from 'react-icons/io';
import { setAnswer, zoneRevealed } from 'slices/runtimeStatesSlice';
import { Separator } from 'components/Separator';
import { InteractiveCardTitle } from 'components/InteractiveCardTitle';
import { CardActionChoice } from 'components/CardActionChoice';
import { MessageFromTemplate } from 'components/MessageFromTemplate';
import { Button } from 'components/Button';
import { GameUiContext } from 'components/PlayGamePage/GameUiContext';
import { PopupMenuContext } from 'components/PlayGamePage/PopupMenuContext';
import { DefconDiscs } from 'components/PlayTdPage/DefconDiscs';
import { DefconSpaces } from 'components/PlayTdPage/DefconSpaces';
import { Tracker } from 'components/PlayTdPage/Tracker';
import { CardCrop } from 'components/PlayGamePage/CardCrop';
import {
  bothPlayersRequestTemplates,
  defconSymbol,
  getCommandSpread,
  getCubeImgSrc,
  getMarkerDataByName,
  getNCardsInZone,
  getVar,
  getYourRequest,
  modeForHumans,
  otherRequestTemplates,
  parseTextDescr,
  playerInWarDanger,
  roleForHumans,
  roles,
  tdBattlegroundWidgetNames,
  tdEffectDescriptors,
  tdGetOutcomeTranslation,
  tdWidgetNames,
  yourRequestTemplates,
  getYourHandZoneName,
  getInPlayCids,
  getStrategyDiscardPileCids,
  getYourHandCids,
  getZoneCids,
  getInvisibleCardCids,
  tdGetCardTitleOfCard,
  cidToTitle,
} from 'data/tdData';
import {
  arrayContains,
  getConnectionStatusText,
  numbers,
  pluralize,
  rangeInclusive,
  repeat,
  sumValues,
  halfRoundUp,
  chopHead,
  getTitleFromLines,
} from 'utils';
import { usePutAnswer, useSendAnswer } from 'utils/playGamePageUtils';
import { useTap } from 'utils/useTap';
import { registerGd } from 'data/gdRegistry';

function getPlayerBadgeInfo(cs, playerName, connectionStatus) {
  const role = cs['player-name->role'][playerName];
  const nCubes = cs['vars'][`c:${role}`];
  const nCards = getNCardsInZone(cs, `${role}:hand`);
  const phase = cs['vars']['phase'];
  const hasInitiative = cs['vars']['initiative-player'] === role;
  const warDanger = playerInWarDanger(role, cs);
  const hasLetter = getNCardsInZone(cs, `${role}:letter`) === 1;

  const currentEffects = cs['vars'][`ef:${role}`] || [];
  const effects = tdEffectDescriptors.filter((descriptor) => {
    return arrayContains(currentEffects, descriptor.name);
  });
  return {
    role,
    nCards,
    nCubes,
    phase,
    hasInitiative,
    warDanger,
    hasLetter,
    effects,
    // Pass through so that all info is in one place - useful for
    // ExpandedPlayerBadge
    playerName,
    connectionStatus,
  };
}

function CubeChangeChoice(props) {
  const { choice, whose } = props;
  const { change } = choice;
  if (change === 0) {
    return <span className="label">No change</span>;
  }

  let url;
  const n = Math.abs(change);
  if (change < 0) {
    url = `/td/markers/${whose}-cube-minus.png`;
  } else {
    url = `/td/markers/${whose}-cube-plus.png`;
  }
  return (
    <>
      <span className="label">{change < 0 ? `Remove ${n}` : `Add ${n}`}</span>
      {` `}
      {numbers(n).map((index) => {
        return <img alt="cube" key={index} src={url} />;
      })}
    </>
  );
}

function DefconCloseup(props) {
  const { isShown } = props;

  const imgRef = useRef();
  const widgetsRef = useRef();

  const [fixSize] = useDebouncedCallback(() => {
    const img = imgRef.current;
    const widgets = widgetsRef.current;
    widgets.style.width = `${img.width}px`;
  }, 200);

  useLayoutEffect(() => {
    fixSize();
    window.addEventListener('resize', fixSize);
    return () => {
      window.removeEventListener('resize', fixSize);
    };
  });

  function handleTouchStart(e) {
    // Don't close the closeup if clicked within the widgets area - either
    // on an active DefconSpaceMarker or nearby.
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <div className={clsx('DefconCloseup', { isShown })}>
      <img alt="defcon closeup" ref={imgRef} src="/td/defcon-closeup.jpg" />
      <div ref={widgetsRef} className="widgets" onTouchStart={handleTouchStart}>
        <DefconDiscs where="defconCloseup" />
        <DefconSpaces where="defconCloseup" />
      </div>
    </div>
  );
}

function Marker(props) {
  const { name, markerData } = props;

  const { style } = markerData;

  const className = clsx('marker', {
    [name]: true,
  });

  return <div className={className} style={style} />;
}

function BgCubes(props) {
  const { name, tentativeCubes } = props;
  const { cs } = useContext(GameUiContext);
  return (
    <>
      {roles.map((role) => {
        const className = clsx('cubes', { [role]: true });

        const n = getVar(cs, `i:${role}:${name}`);
        const change =
          tentativeCubes && tentativeCubes.role === role
            ? tentativeCubes.change
            : 0;
        const straight = change < 0 ? n - Math.abs(change) : n;
        const plus = change > 0 ? change : 0;
        const minus = change < 0 ? Math.abs(change) : 0;
        return (
          <div key={role} className={className}>
            {numbers(straight).map((i) => {
              return (
                <img alt="" key={i} src={getCubeImgSrc(role, 'straight')} />
              );
            })}
            {numbers(plus).map((i) => {
              return <img alt="" key={i} src={getCubeImgSrc(role, 'plus')} />;
            })}
            {numbers(minus).map((i) => {
              return <img alt="" key={i} src={getCubeImgSrc(role, 'minus')} />;
            })}
          </div>
        );
      })}
    </>
  );
}

function BattlegroundMarker(props) {
  const { name, markerData, index } = props;

  const { cs, answer } = useContext(GameUiContext);
  const popupMenuCtx = useContext(PopupMenuContext);
  const { style, track } = markerData;

  let action = null;

  const yourRequest = getYourRequest(cs);

  const yourRole = cs['your-role'];

  let tentativeCubes = null;

  const ref = useRef();

  function handleClick() {
    if (!action) {
      return;
    }
    action();
  }

  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      if (!action) {
        return;
      }
      action();
    }
  }

  if (yourRequest) {
    const requestKind = yourRequest[0];
    if (requestKind === 'choose-command') {
      const cubes = yourRequest[2];
      const spread = getCommandSpread(cs, yourRole, name, cubes, 'simple');

      const [lower, higher] = spread;
      const choices = rangeInclusive(lower, higher)
        .map((n) => {
          return {
            key: `${name}:${n}`,
            onSelected: (putAnswer) => {
              putAnswer([name, n]);
            },
            name,
            change: n,
          };
        })
        .reverse(); // Adding maximum is more common, so put it up front.

      action = () => {
        popupMenuCtx.open(ref.current, {
          popupMenuKind: 'cubeChange',
          choices,
          whose: yourRole,
        });
      };

      if (answer && answer[0] === name) {
        tentativeCubes = { role: yourRole, change: answer[1] };
      }
    } else if (requestKind === 'choose-command-onto') {
      const bgs = yourRequest[3];
      if (arrayContains(bgs, name)) {
        const cubes = yourRequest[2];
        const spread = getCommandSpread(cs, yourRole, name, cubes, 'onto');

        const [lower, higher] = spread;
        const choices = rangeInclusive(lower, higher)
          .map((n) => {
            return {
              key: `${name}:${n}`,
              onSelected: (putAnswer) => {
                putAnswer([name, n]);
              },
              name,
              change: n,
            };
          })
          .reverse();

        action = () => {
          popupMenuCtx.open(ref.current, {
            popupMenuKind: 'cubeChange',
            choices,
            whose: yourRole,
          });
        };

        if (answer && answer[0] === name) {
          tentativeCubes = { role: yourRole, change: answer[1] };
        }
      }
    } else if (requestKind === 'choose-place-influence') {
      const role = yourRole;
      const max = yourRequest[2];
      const maxPer = yourRequest[3];
      const bgs = yourRequest[5];
      const reserve = getVar(cs, `c:${role}`);

      if (arrayContains(bgs, name)) {
        const maxAllowed = Math.min(
          max,
          maxPer ? maxPer : 5,
          reserve,
          5 - getVar(cs, `i:${role}:${name}`)
        );

        const choices = rangeInclusive(0, maxAllowed)
          .map((n) => {
            return {
              key: `${name}:${n}`,
              onSelected: (putAnswer, answer) => {
                if (!answer) {
                  if (n === 0) {
                    putAnswer({});
                  } else {
                    putAnswer({ [name]: n });
                  }
                } else {
                  const newAnswer = { ...answer };
                  if (n === 0) {
                    delete newAnswer[name];
                  } else {
                    newAnswer[name] = n;
                  }
                  putAnswer(newAnswer);
                }
              },
              name,
              change: n,
            };
          })
          .reverse();

        action = () => {
          popupMenuCtx.open(ref.current, {
            popupMenuKind: 'cubeChange',
            choices,
            whose: yourRole,
          });
        };

        if (answer) {
          const change = answer[name];
          if (change) {
            tentativeCubes = {
              role: yourRole,
              change,
            };
          }
        }
      }
    } else if (requestKind === 'choose-remove-influence') {
      const whose = yourRequest[2];
      const max = yourRequest[3];
      const policy = yourRequest[4];
      const bgs = yourRequest[6];

      const current = getVar(cs, `i:${whose}:${name}`);
      if (arrayContains(bgs, name)) {
        let choices;
        if (policy === 'half') {
          const change = -halfRoundUp(current);

          choices = [
            {
              key: name,
              answer: { [name]: 1 },
              name,
              change,
            },
          ];
        } else {
          choices = rangeInclusive(-Math.min(current, max), 0).map((n) => {
            return {
              key: `${name}:${n}`,
              onSelected: (putAnswer, answer) => {
                if (!answer || policy === 'single-bg') {
                  if (n === 0) {
                    putAnswer({});
                  } else {
                    putAnswer({ [name]: -n });
                  }
                } else if (policy === 'many-bgs') {
                  const newAnswer = { ...answer };
                  if (n === 0) {
                    delete newAnswer[name];
                  } else {
                    newAnswer[name] = -n;
                  }
                  putAnswer(newAnswer);
                } else {
                  console.warn(
                    'unrecognized policy in choose-remove-influence',
                    yourRequest
                  );
                }
              },
              name,
              change: n,
            };
          });
        }

        action = () => {
          popupMenuCtx.open(ref.current, {
            popupMenuKind: 'cubeChange',
            choices,
            whose,
          });
        };

        if (answer) {
          let removal = answer[name];
          if (policy === 'half' && removal) {
            removal = halfRoundUp(current);
          }
          if (removal) {
            tentativeCubes = {
              role: whose,
              change: -removal,
            };
          }
        }
      }
    }
  }

  const isActive = !!action;
  const className = clsx('marker bg', {
    [name]: true,
    isTrack: !!track,
    isActive,
    popupMenuVisible: popupMenuCtx.isOpen,
    [`stagger${index}`]: true,
  });

  return (
    <div ref={ref} className={className} style={style}>
      <BgCubes name={name} tentativeCubes={tentativeCubes} />
      <div
        className="overlay"
        onClick={handleClick}
        tabIndex={isActive ? 0 : null}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}

function BoardWidgets(props) {
  return tdWidgetNames.map((name) => {
    const markerData = getMarkerDataByName(name);
    return <Marker key={name} name={name} markerData={markerData} />;
  });
}

function Battlegrounds(props) {
  return tdBattlegroundWidgetNames.map((name, index) => {
    const markerData = getMarkerDataByName(name);
    return (
      <BattlegroundMarker
        key={name}
        name={name}
        markerData={markerData}
        index={index}
      />
    );
  });
}

function PrestigeDisc(props) {
  const imgSrc = '/td/markers/prestige-marker.png';
  return (
    <Tracker
      imgSrc={imgSrc}
      trackerKind="disc"
      varName="prestige"
      markerPrefix="pr"
    />
  );
}

function RoundDisc(props) {
  const imgSrc = '/td/markers/round-marker.png';
  return (
    <Tracker
      imgSrc={imgSrc}
      trackerKind="disc"
      varName="round"
      markerPrefix="r"
    />
  );
}

function WholeDefcon(props) {
  const { cs, pgpDispatch } = useContext(GameUiContext);

  const markerData = getMarkerDataByName('wholeDefcon');

  const { style } = markerData;

  let action = null;

  const yourRequest = getYourRequest(cs);
  const requestKind = yourRequest ? yourRequest[0] : null;
  if (requestKind === 'choose-change-defcon') {
    action = () => {
      const actions = [
        {
          key: `defconCloseupDone`,
          onInvokeMobile() {
            // do nothing, will close the preview.
          },
          onInvokeDesktop() {
            console.warn(
              'defconCloseupDone not supposed to be called in desktop design'
            );
            return;
          },
          label: `Done`,
        },
      ];
      pgpDispatch({
        type: 'openMobilePreview',
        previewType: 'defconTracks',
        actions,
      });
    };
  }

  function handleTap() {
    if (!action) {
      return;
    }
    action();
  }

  const tapHandlers = useTap(handleTap);

  const isActive = !!action;
  const className = clsx('marker WholeDefcon', {
    isActive,
    hideBig: true,
  });

  return (
    <div className={className} style={style}>
      <div className="overlay" {...tapHandlers} />
    </div>
  );
}

function AgendaFlags(props) {
  const { cs } = useContext(GameUiContext);

  return (
    <>
      {roles.map((role) => {
        const imgSrc = `/td/markers/${role}-flag.png`;
        return (
          <React.Fragment key={role}>
            {[0, 1, 2].map((flagNumber) => {
              const varName = `f:${role}:${flagNumber}`;
              const value = getVar(cs, varName);
              if (!value) {
                return null;
              }

              const markerData = getMarkerDataByName(value);
              const offset = markerData.isBg
                ? {
                    x: role === 'u' ? 1.5 : -1.5,
                    y: -1.5,
                  }
                : {
                    x: -0.2 + 0.2 * flagNumber,
                    y: role === 'u' ? -0.4 : 0.4,
                  };

              return (
                <Tracker
                  key={flagNumber}
                  trackerKind="flag"
                  imgSrc={imgSrc}
                  varName={varName}
                  markerPrefix=""
                  offset={offset}
                />
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
}

function AgendaZones(props) {
  const { cs } = useContext(GameUiContext);

  return (
    <>
      {roles.map((role) => {
        const zoneName = `${role}:agenda`;
        const zone = cs['zones'][zoneName];
        const cards = zone['cards'];

        let cids;
        if (Number.isInteger(cards)) {
          // Agenda back crop is just called 'b'.
          cids = repeat(cards, 'b');
        } else {
          cids = cards;
        }

        return (
          <div key={role} className={`AgendaZone cards ${role}`}>
            {cids.map((cid) => {
              return <CardCrop key={cid} card={cid} />;
            })}
          </div>
        );
      })}
    </>
  );
}

function ClickableAftermath(props) {
  const { pgpDispatch } = useContext(GameUiContext);
  const markerData = getMarkerDataByName('af');
  const { style } = markerData;
  const className = clsx('marker', 'aftermath');
  const openAftermath = () => {
    pgpDispatch({
      type: 'showModal',
      modalType: 'expandedPile',
      pileType: 'aftermaths',
    });
  };

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      openAftermath();
    }
  }

  return (
    <div
      className={className}
      style={style}
      tabIndex={0}
      onKeyPress={handleKeyPress}
      onClick={openAftermath}
    ></div>
  );
}

function Board(props) {
  return (
    <div className="boardOuter">
      <div className="board">
        <div className="wrapper">
          <img className="boardImage" src="/td/board.jpg" alt="game board" />
          <div className="widgets">
            <BoardWidgets />
            <Battlegrounds />
            <PrestigeDisc />
            <RoundDisc />
            <DefconDiscs />
            <DefconSpaces />
            <WholeDefcon />
            <AgendaFlags />
            <AgendaZones />
            <ClickableAftermath />
          </div>
        </div>
      </div>
    </div>
  );
}

function YourHand(props) {
  const { cs, shortName, answer, yourRole } = useContext(GameUiContext);

  const yourRequest = getYourRequest(cs);

  const sendAnswer = useSendAnswer(yourRole, shortName);
  const putAnswer = usePutAnswer(shortName);

  function createChooseThisCard(cid) {
    return {
      key: 'choose-this-card',
      isPrimary: true,
      onInvokeMobile() {
        sendAnswer(cid);
      },
      onInvokeDesktop() {
        putAnswer(cid);
      },
      label: 'Choose this card',
    };
  }

  function createChooseCardAndMode(cid, mode) {
    return {
      key: `choose-card-and-mode:${cid}:${mode}`,
      answer: [cid, mode],
      onInvokeMobile() {
        putAnswer(this.answer);
      },
      onInvokeDesktop() {
        putAnswer(this.answer);
      },
      label: `${modeForHumans(mode)}`,
    };
  }

  function createToggleThisCard(cid) {
    const cids = answer || [];
    const isChosen = arrayContains(cids, cid);
    return {
      key: `toggle-this-card:${cid}`,
      onInvoke() {
        let newAnswer;
        if (isChosen) {
          newAnswer = cids.filter((c) => c !== cid);
        } else {
          newAnswer = [...cids, cid];
        }
        putAnswer(newAnswer);
      },
      onInvokeMobile() {
        this.onInvoke();
      },
      onInvokeDesktop() {
        this.onInvoke();
      },
      label: isChosen ? 'Unmark' : 'Mark',
    };
  }

  function getCardActions(cid) {
    if (!yourRequest) {
      return [];
    }

    const requestKind = yourRequest ? yourRequest[0] : null;
    if (requestKind === 'choose-card') {
      const zoneToCids = yourRequest[2];
      const cids = zoneToCids[getYourHandZoneName(cs)];
      if (arrayContains(cids, cid)) {
        return [createChooseThisCard(cid)];
      }
    } else if (requestKind === 'choose-card-and-mode') {
      const cidToModes = yourRequest[3];
      if (cidToModes[cid]) {
        const modes = cidToModes[cid];
        return modes.map((mode) => createChooseCardAndMode(cid, mode));
      }
    } else if (requestKind === 'choose-cards') {
      const cids = yourRequest[3];
      if (arrayContains(cids, cid)) {
        return [createToggleThisCard(cid)];
      }
    }

    return [];
  }

  const cids = getYourHandCids(cs);

  return (
    <div className="hand">
      {cids.map((cid, index) => {
        const cardActions = getCardActions(cid);
        return (
          <CardCrop
            card={cid}
            key={cid}
            cardActions={cardActions}
            index={index}
          />
        );
      })}
    </div>
  );
}

function StrategyDiscardPile(props) {
  const { cs, pgpDispatch } = useContext(GameUiContext);
  const cids = getStrategyDiscardPileCids(cs);
  const inPlayCids = getInPlayCids(cs);
  const popupMenuCtx = useContext(PopupMenuContext);

  function createAction(pileType, labelPrefix) {
    const nCardsSuffix =
      pileType === 'invisible-cards' || pileType === 'aftermaths'
        ? ''
        : ` (${getNCardsInZone(cs, pileType)})`;
    return {
      key: pileType,
      onInvokeMobile() {
        pgpDispatch({
          type: 'showModal',
          modalType: 'expandedPile',
          pileType,
        });
      },
      onSelected() {
        pgpDispatch({
          type: 'showModal',
          modalType: 'expandedPile',
          pileType,
        });
      },
      label: `${labelPrefix}${nCardsSuffix}`,
    };
  }

  const cardActions = [
    createAction('strategy-discard', 'View strategy discard'),
    createAction('agenda-discard', 'View agenda discard'),
    createAction('invisible-cards', 'View invisible cards'),
  ];

  const ref = useRef(null);
  function handlePileClick() {
    if (cids.length > 0 || inPlayCids.length > 0) {
      // handled by one of those card crops.
      return;
    }
    popupMenuCtx.open(ref.current, {
      popupMenuKind: 'cardActions',
      choices: cardActions,
    });
  }

  return (
    <div className="pile" ref={ref} onClick={handlePileClick}>
      <div className="pileInner">
        <div className="discard">
          {cids.length > 0 ? (
            <CardCrop
              card={cids[cids.length - 1]}
              cardActions={cardActions}
              actionsAreNonRequest={true}
            />
          ) : null}
        </div>
        {cids.length === 0 ? (
          <div className="label">Strategy discard</div>
        ) : null}

        <div className="inPlay">
          {inPlayCids.length > 0 ? (
            <CardCrop
              card={inPlayCids[inPlayCids.length - 1]}
              cardActions={cardActions}
              actionsAreNonRequest={true}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

function SmallCardImg(props) {
  const { card } = props;
  const cid = card;
  const headlessCid = chopHead(cid);

  return <img alt={headlessCid} src={`/td/card-crop/${headlessCid}.jpg`} />;
}

function BigCardImg(props) {
  const { card, isShown } = props;

  const cid = card;
  if (!cid) {
    return null;
  }

  const headlessCid = chopHead(cid);
  const className = clsx('BigCardImg', { isShown, isLoaded: true });

  return (
    <>
      <img
        className={className}
        src={`/td/card-big/${headlessCid}.jpg`}
        alt={cidToTitle[headlessCid]}
      />
    </>
  );
}

const tdGd = {
  code: 'td',
  reactToClientStateChange(gameUiCtx, dispatch) {
    const { cs, pgpDispatch, shortName } = gameUiCtx;
    if (cs && cs['shouldRevealZone']) {
      pgpDispatch({
        type: 'showModal',
        modalType: 'expandedPile',
        pileType: 'aftermaths',
      });
      dispatch(zoneRevealed({ shortName }));
    }
  },
  getOrderedRoles(cs) {
    return cs['ordered-roles'];
  },
  getRoleToPlayerName(cs) {
    return cs['role->player-name'];
  },
  getRoleForHumans(role) {
    return roleForHumans(role);
  },
  getModeForHumans(mode) {
    return modeForHumans(mode);
  },
  getPlayerBadgeParts(gameUiCtx, playerName, connectionStatus) {
    const { cs } = gameUiCtx;
    const info = getPlayerBadgeInfo(cs, playerName, connectionStatus);
    const {
      role,
      nCards,
      nCubes,
      phase,
      hasInitiative,
      warDanger,
      hasLetter,
      effects,
    } = info;

    const cubeSrc = getCubeImgSrc(role);
    const cardBackSrc =
      phase === 'agenda' ? '/td/agenda-b.png' : '/td/strategy-b.png';
    const personalLetterSrc = '/td/perlet-big/pb-perlet.jpg';

    // TODO translations
    const roleLabel = role === 'u' ? 'United States' : 'Soviet Union';
    const roleIconSrc = `/td/icons/role-${role}.png`;
    const connectionStatusText = getConnectionStatusText(connectionStatus);

    const titleLines = [
      [connectionStatusText, playerName, roleLabel],
      [
        pluralize(nCards, 'card'),
        pluralize(nCubes, 'cube'),
        hasInitiative ? 'initiative' : null,
        warDanger ? 'war danger' : null,
      ],
      [
        hasLetter
          ? 'Personal Letter: use to gain +1 inf on a Command action'
          : null,
      ],
      ...effects.map((descriptor) => {
        const { title, explanation } = descriptor;
        return [`${title}: ${explanation}`];
      }),
    ];
    const title = getTitleFromLines(titleLines);

    return {
      title,
      info,
      iconComponent: <img alt="" className="roleIcon" src={roleIconSrc} />,
      infoLinesComponent: (
        <>
          <div className="infoLine">
            <span>
              <span>{nCards}</span>
              &nbsp;
              <img className="bordered" src={cardBackSrc} alt="cards in hand" />
            </span>
            <Separator />
            <span>
              <span>{nCubes}</span>
              &nbsp;
              <img src={cubeSrc} alt="cubes in reserve" />
            </span>
            {hasInitiative ? (
              <>
                <Separator />
                <span className="initiative">
                  <IoMdFlash />
                </span>
              </>
            ) : null}
            {warDanger ? (
              <>
                <Separator />
                <span className="warDanger">{defconSymbol}</span>
              </>
            ) : null}
          </div>
          <div className="infoLine">
            {hasLetter ? (
              <>
                <img
                  className="bordered"
                  src={personalLetterSrc}
                  alt="Personal Letter"
                />
              </>
            ) : null}
            {effects.map((descriptor) => {
              const { name, short } = descriptor;
              return <span key={name}>{short}</span>;
            })}
            <Separator /> {/* make the line always take space*/}
          </div>
        </>
      ),
    };
  },
  renderExpandedPlayerBadgeContent(info, pgpDispatch) {
    const {
      role,
      nCards,
      nCubes,
      hasInitiative,
      warDanger,
      hasLetter,
      effects,
    } = info;

    const secondLine = [
      pluralize(nCards, 'card'),
      pluralize(nCubes, 'cube'),
      hasInitiative ? 'initiative' : null,
      warDanger ? 'war danger' : null,
    ]
      .filter((x) => !!x)
      .join(' - ');

    return (
      <>
        <div>Plays as {roleForHumans(role)}.</div>
        <div>{secondLine}</div>
        {hasLetter && (
          <div>
            Has{' '}
            <InteractiveCardTitle
              card={'pl-perlet'}
              cardTitle={'Personal Letter'}
              pgpDispatch={pgpDispatch}
            />
            .
          </div>
        )}
        {effects.length > 0 && (
          <div>
            <span>Ongoing effects: </span>
            {effects.map((descriptor, index) => {
              const { name, title } = descriptor;
              return (
                <>
                  {index > 0 ? ', ' : null}
                  <InteractiveCardTitle
                    key={name}
                    card={name}
                    cardTitle={title}
                    pgpDispatch={pgpDispatch}
                  />
                </>
              );
            })}
            .
          </div>
        )}
      </>
    );
  },
  renderChoice(data, choice) {
    const { popupMenuKind } = data;
    switch (popupMenuKind) {
      case 'cubeChange':
        return <CubeChangeChoice whose={data.whose} choice={choice} />;
      case 'cardActions':
        return <CardActionChoice choice={choice} />;
      default: {
        console.warn('unrecognized popupMenuKind', popupMenuKind);
        return (
          <>
            {popupMenuKind} -- {choice.key}
          </>
        );
      }
    }
  },
  getOutcomeTranslation(outcome, _gameUiCtx) {
    return tdGetOutcomeTranslation(outcome);
  },
  getYourRequest(cs) {
    return getYourRequest(cs);
  },
  getActionLineParts(gameUiCtx, dispatch, sendAnswer) {
    const { shortName, cs, answer, gd, pgpDispatch, yourRole } = gameUiCtx;

    const yourRequest = gd.getYourRequest(cs);
    const roleToPlayerName = gd.getRoleToPlayerName(cs);

    let actionContent = null,
      controls = null,
      confirm = null;
    if (yourRequest) {
      const requestKind = yourRequest[0];
      const [textCode, ...textArgs] = parseTextDescr(yourRequest[1]);
      const templateStringOrFn = yourRequestTemplates[textCode];

      let template, args;
      if (!templateStringOrFn) {
        template = 'unrecognized request text code: %n';
        args = {
          n: textCode,
        };
      } else if (typeof templateStringOrFn === 'string') {
        template = templateStringOrFn;
        args = {};
      } else {
        [template, args] = templateStringOrFn(textArgs, yourRole);
      }

      actionContent = (
        <MessageFromTemplate
          code="td"
          template={template}
          args={args}
          roleToPlayerName={roleToPlayerName}
          pgpDispatch={pgpDispatch}
        />
      );

      function putAnswer(answer) {
        dispatch(setAnswer({ shortName, answer }));
      }

      function getModeControls(modes) {
        return (
          <>
            {modes.map((mode) => {
              return (
                <Button
                  key={mode}
                  isSecondary
                  isPressed={mode === answer}
                  onClick={() => putAnswer(mode)}
                >
                  {modeForHumans(mode, textCode)}
                </Button>
              );
            })}
          </>
        );
      }

      console.debug('yourRequest', yourRequest);

      if (requestKind === 'choose-mode') {
        const modes = yourRequest[2];
        controls = getModeControls(modes);
      } else if (requestKind === 'yn') {
        controls = getModeControls([true, false]);
      }

      const canUndo = cs['can-cancel?'];

      let canZero = false,
        setZero,
        isZeroed = false;

      let isValidAnswer = true;

      if (requestKind === 'choose-place-influence') {
        canZero = true;
        setZero = () => {
          putAnswer({});
        };
        isZeroed = !!answer && Object.keys(answer).length === 0;

        if (answer) {
          const max = yourRequest[2];
          const actualAmount = sumValues(answer);

          const reserve = getVar(cs, `c:${yourRole}`);

          // Not validating maxPer because we construct the bg choices
          // taking that into account.
          const isExactAmount = yourRequest[6];
          if (isExactAmount) {
            isValidAnswer = actualAmount === max;
          } else {
            isValidAnswer = actualAmount <= max;
          }

          isValidAnswer = isValidAnswer && actualAmount <= reserve;
        }
      } else if (requestKind === 'choose-remove-influence') {
        canZero = true;
        setZero = () => {
          putAnswer({});
        };
        isZeroed = !!answer && Object.keys(answer).length === 0;

        if (answer) {
          const max = yourRequest[3];
          const policy = yourRequest[4];

          const actualAmount = sumValues(answer);

          isValidAnswer =
            actualAmount <= max || (policy === 'half' && actualAmount === 1);
        }
      } else if (requestKind === 'choose-command') {
        canZero = true;
        setZero = () => {
          putAnswer(['a', 0]);
        };
        isZeroed = !!answer && answer[1] === 0;
      } else if (requestKind === 'choose-command-onto') {
        canZero = true;
        const bgs = yourRequest[3];
        setZero = () => {
          putAnswer([bgs[0], 0]);
        };
        isZeroed = !!answer && answer[1] === 0;
      } else if (requestKind === 'choose-change-defcon') {
        const maxTracks = yourRequest[3];
        const allowedTracks = yourRequest[4];
        const exactAmount = yourRequest[7];

        canZero = !exactAmount;
        setZero = () => {
          if (maxTracks === 1) {
            putAnswer({ [allowedTracks[0]]: 0 });
          } else {
            putAnswer({});
          }
        };
        isZeroed = !!answer && Object.values(answer).every((n) => n === 0);
      }

      confirm = (
        <>
          {canZero ? (
            <Button
              key="zero"
              isSecondary
              isPressed={isZeroed}
              onClick={() => setZero()}
            >
              Zero
            </Button>
          ) : null}
          {answer !== null ? (
            <Button
              key="confirm"
              isPrimary
              isDisabled={!isValidAnswer}
              onClick={() => sendAnswer(answer)}
            >
              Confirm
            </Button>
          ) : null}
          {canUndo ? (
            <Button key="undo" isDanger onClick={() => sendAnswer('*cancel*')}>
              Undo
            </Button>
          ) : null}
        </>
      );
    } else {
      const requestMap = cs['request-map'];
      const roles = Object.keys(requestMap);
      const n = roles.length;
      if (n === 2) {
        const otherRequest = requestMap[roles[0]];
        const [textCode, textArgs] = parseTextDescr(otherRequest[1]);

        let unused = textArgs; // eslint-disable-line

        // assuming it's the same for both, as is always the case in td.
        const template =
          bothPlayersRequestTemplates[textCode] ||
          'Both players do something unknown.';

        actionContent = (
          <MessageFromTemplate
            code="td"
            template={template}
            args={{}}
            roleToPlayerName={roleToPlayerName}
          />
        );
      } else if (n === 1) {
        const otherRole = roles[0];
        const otherRequest = requestMap[otherRole];
        const [textCode, textArgs] = parseTextDescr(otherRequest[1]);

        let unused = textArgs; // eslint-disable-line

        const template =
          otherRequestTemplates[textCode] || '%r does something unknown.';

        const args = {
          r: otherRole,
        };
        actionContent = (
          <MessageFromTemplate
            code="td"
            template={template}
            args={args}
            roleToPlayerName={roleToPlayerName}
          />
        );
      } else {
        console.warn('No current request and the game is not finished', cs);
      }
    }
    return {
      actionContent,
      controls,
      confirm,
    };
  },
  renderNonStandardMobilePreviewContents(gameUiCtx, mobilePreview) {
    const { pgpDispatch } = gameUiCtx;
    const { previewType } = mobilePreview;

    return (
      <DefconCloseup
        pgpDispatch={pgpDispatch}
        isShown={previewType === 'defconTracks'}
      />
    );
  },
  renderPrimaryPartition() {
    return (
      <>
        <Board />
        <div className="cards">
          <YourHand />
          <StrategyDiscardPile />
        </div>
      </>
    );
  },
  getExpandedPileModalContentParts(gameUiCtx, expandedPile) {
    const { cs } = gameUiCtx;
    const { pileType } = expandedPile;

    if (!pileType) {
      return null;
    }

    let cids = [],
      explanation = null,
      headerText = null;

    switch (pileType) {
      case 'strategy-discard': {
        headerText = 'Strategy dicard pile';
        cids = getZoneCids(cs, pileType);
        break;
      }
      case 'agenda-discard': {
        headerText = 'Agenda dicard pile';
        cids = getZoneCids(cs, pileType);
        break;
      }
      case 'invisible-cards': {
        headerText = 'Invisible cards';
        explanation = {
          template: `Strategy cards you haven't seen in play, the discard pile, or your hand.`,
        };
        cids = getInvisibleCardCids(cs);
        break;
      }
      case 'aftermaths': {
        const revealedCids = getZoneCids(cs, 'aftermath-reveal');
        headerText = 'Aftermath';
        if (revealedCids.length > 0) {
          cids = revealedCids;
        } else {
          const yourRole = cs['your-role'];
          if (yourRole === '*observer*') {
            const sCards = getNCardsInZone(cs, 's:aftermath');
            const uCards = getNCardsInZone(cs, 'u:aftermath');
            explanation = {
              template: `%r1 has saved %cards1.  %r2 has saved %cards2.`,
              args: {
                r1: 's',
                r2: 'u',
                cards1: sCards,
                cards2: uCards,
              },
            };
            cids = [];
          } else {
            const otherRole = yourRole === 'u' ? 's' : 'u';
            const otherNCards = getNCardsInZone(cs, `${otherRole}:aftermath`);
            cids = getZoneCids(cs, `${yourRole}:aftermath`);
            const yourNCards = cids.length;

            const suffix = yourNCards > 0 ? ':' : '.';
            explanation = {
              template: `%r has saved %cards1.  You have saved %cards2${suffix}`,
              args: {
                r: otherRole,
                cards1: otherNCards,
                cards2: yourNCards,
              },
            };
          }
        }
        break;
      }
      default: {
        headerText = '??';
        break;
      }
    }

    return {
      headerText,
      explanation,
      cards: cids,
    };
  },
  renderBigCardImg(card, isShown) {
    return <BigCardImg card={card} isShown={isShown} />;
  },
  getCardCropParts(gameUiCtx, card, cardActions, ref, popupMenuCtx) {
    const { cs, answer } = gameUiCtx;

    const cid = card;
    const request = getYourRequest(cs);
    const requestKind = request ? request[0] : null;

    const isChosen =
      requestKind === 'choose-card'
        ? answer === cid
        : requestKind === 'choose-card-and-mode'
        ? answer && answer[0] === cid
        : requestKind === 'choose-cards'
        ? arrayContains(answer || [], cid)
        : false;
    return {
      isChosen,
      renderSmallCardImg() {
        return <SmallCardImg card={card} />;
      },
      handleNonStandardClick() {
        if (
          (requestKind === 'choose-card' || requestKind === 'choose-cards') &&
          cardActions.length === 1
        ) {
          const cardAction = cardActions[0];
          cardAction.onInvokeDesktop();
          return true;
        } else if (requestKind === 'choose-card-and-mode') {
          popupMenuCtx.open(ref.current, {
            popupMenuKind: 'cardActions',
            choices: cardActions,
          });
          return true;
        }
        return false;
      },
    };
  },
  renderModalContent(modalState, gameUiCtx) {
    const { modalType } = modalState;
    console.error('Unrecognized modalType', modalType, modalState);
    return null;
  },
  getKeyOfCard(card) {
    const cid = card;
    return cid;
  },
  getTextRepresentationOfCard(cid) {
    const headlessCid = chopHead(cid);
    if (!headlessCid) {
      console.error(
        'getTextRepresentationOfCard: cannot find headlessCid',
        cid
      );
      return `?${cid}?`;
    }
    const result = {
      title: cidToTitle[headlessCid] || `?${cid}?`,
      isKnown: true,
    };
    return result;
  },
  getMftHoleReplacementComponent(holeType, holeValue) {
    switch (holeType) {
      case 'cubes': {
        const n = holeValue;
        return (
          <>
            <b>{pluralize(n, 'cube')}</b>
          </>
        );
      }
      default: {
        return null;
      }
    }
  },
};
registerGd('td', tdGd);
