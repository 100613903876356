import {
  State,
  Role,
  OrderSource,
  VOrderSource,
  Order,
  VOrder,
  NextLevelOrder,
  VNextLevelOrder,
  OrderDescriptorGroup,
  OrderDescriptorsMapObject,
  OrderDescriptorGroupsMapObject,
  OrderDescriptorGroupOptions,
  OrderWithImpliedName,
  AAResult,
  FilledOrder,
  VFilledOrder,
} from './types';
import { getYourKnowledge, getZoneName, buildZoneName } from './utils';
import { EError } from './errors';

const allGroups: {
  [code: string]: OrderDescriptorGroupsMapObject;
} = {};

export function createOrderGroup(
  code: string,
  name: string,
  options: OrderDescriptorGroupOptions,
  descriptors: OrderDescriptorsMapObject
): OrderDescriptorGroup {
  return {
    code,
    name,
    options,
    descriptors,
  };
}

export function registerOrderGroup(group: OrderDescriptorGroup) {
  const { code, name } = group;
  if (!allGroups[code]) {
    allGroups[code] = {};
  }
  allGroups[code][name] = group;
}

export const generalSource: OrderSource = 'general';

export function makeCardSource(card): OrderSource {
  return {
    sourceType: 'card',
    card,
  };
}

export function makeZoneSourceFromRoleAndSlug(role, slug): OrderSource {
  return {
    sourceType: 'zone',
    zoneName: buildZoneName(role, slug),
  };
}

export function makeZoneSource(zone): OrderSource {
  return {
    sourceType: 'zone',
    zoneName: getZoneName(zone),
  };
}

export function makePlayerSource(role: Role): OrderSource {
  return {
    sourceType: 'player',
    role,
  };
}

export function collectOrders(state: State): Order[] {
  const result = [];
  const orderGroupHash = allGroups[state.code] || {};

  // role to number: we don't want John to know how many orders Bill has.
  const disambiguationContext = {};

  Object.entries(orderGroupHash).forEach(([groupName, group]) => {
    const { options, descriptors } = group;
    if (options.precondition) {
      if (!options.precondition(state)) {
        return;
      }
    }
    Object.entries(descriptors).forEach(([orderName, orderDescriptor]) => {
      const { collect } = orderDescriptor;
      const collectionResult = collect(state);

      const ordersWithImpliedName =
        collectionResult === null
          ? []
          : Array.isArray(collectionResult)
          ? (collectionResult as (OrderWithImpliedName | null)[]).filter(
              (x) => !!x
            )
          : [collectionResult];
      const fullName = `${groupName}/${orderName}`;
      const namedOrders = ordersWithImpliedName.map((orderWithImpliedName) => {
        const { role, source } = orderWithImpliedName;

        if (!disambiguationContext[role]) {
          disambiguationContext[role] = 0;
        }
        const disambiguationNumber = disambiguationContext[role];
        disambiguationContext[role]++;

        return {
          ...orderWithImpliedName,
          orderName: fullName,
          disambiguationNumber,
        };
      });
      result.push(...namedOrders);
    });
  });
  return result;
}

export function makeCardVSource(cid: string): VOrderSource {
  return `card:${cid}`;
}

export function makeZoneVSource(owner: Role, slug: string): VOrderSource {
  return `zone:${buildZoneName(owner, slug)}`;
}

export function makePlayerVSource(role: Role): VOrderSource {
  return `player:${role}`;
}

export function orderSourceToVOrderSource(
  source: OrderSource,
  pov: Role
): VOrderSource | null {
  if (source === 'general') {
    return source;
  }

  switch (source.sourceType) {
    case 'player': {
      return `player:${source.role}`;
    }
    case 'card': {
      const yourKnowledge = getYourKnowledge(source.card, pov);
      if (yourKnowledge === null) {
        return null;
      }
      return makeCardVSource(yourKnowledge.cid);
    }
    case 'zone': {
      const { zoneName } = source;
      return `zone:${zoneName}`;
    }
    default: {
      console.error(
        'orderSourceToVOrderSource: Unrecognized sourceType',
        source
      );
      return null;
    }
  }
}

function cardsToCids(cards, pov) {
  return cards
    .map((card) => {
      const yourKnowledge = getYourKnowledge(card, pov);
      if (yourKnowledge === null) {
        console.error('cardsToCids: unknown card', card, pov);
        return null;
      } else {
        return yourKnowledge.cid;
      }
    })
    .filter((cid) => !!cid);
}

// Note: converts the relevant attributes, but keeps all others; it's at your
// own risk not to include sensitive data in them.
export function validateAndConvertChooseCardsOrderData(additionalData, pov) {
  const {
    cards,
    defaultChosenCards,
    minimum = null,
    maximum = null,
    ...moreAdditionalData
  } = additionalData;
  // XXX this check probably should occur after collecting such an order.
  if (!(cards && typeof minimum === 'number' && typeof maximum === 'number')) {
    console.error(
      'orderToVOrder: chooseCards must have cards, minimum, maximum',
      additionalData,
      pov
    );
  }

  const cids = cardsToCids(cards, pov);
  const result = {
    ...moreAdditionalData,
    cids,
    minimum,
    maximum,
  };
  if (defaultChosenCards) {
    result.defaultChosenCids = cardsToCids(defaultChosenCards, pov);
  }

  return result;
}

export function nextLevelOrderToVNextLevelOrder(
  order: NextLevelOrder,
  pov: Role
): VNextLevelOrder | null {
  const { orderType, orderName, source } = order;

  const vSource = orderSourceToVOrderSource(source, pov);
  if (vSource === null) {
    return null;
  }
  return {
    orderType,
    orderName,
    source: vSource,
  };
}

export function orderToVOrder(order: Order, pov: Role): VOrder {
  const {
    role,
    orderType,
    orderName,
    disambiguationNumber,
    source,
    additionalData,
  } = order;

  const vSource = orderSourceToVOrderSource(source, pov);
  if (vSource === null) {
    throw new EError(`cannot convert order to vOrder`, {
      source,
      order,
      pov,
    });
  }

  const result: VOrder = {
    orderType,
    orderName,
    source: vSource,
    disambiguationNumber,
  };
  if (order.isDisabled) {
    result.isDisabled = true;
  }
  if (order.isRepeatable) {
    result.isRepeatable = true;
  }
  if (!!order.orderRow) {
    result.orderRow = order.orderRow;
  }
  if (!!order.nextLevelOrders) {
    result.nextLevelOrders = order.nextLevelOrders.map((nextLevelOrder) => {
      const vNextLevelOrder = nextLevelOrderToVNextLevelOrder(
        nextLevelOrder,
        pov
      );
      if (vNextLevelOrder === null) {
        throw new EError(
          `cannot conert order to vOrder because of next level`,
          {
            source,
            order,
            nextLevelOrder,
          }
        );
      }
      return vNextLevelOrder;
    });
  }
  if (
    [
      'simple',
      'simpleOnCard',
      'simpleOnZone',
      'simpleOnPlayer',
      'cardWithAnotherCard',
      'chooseMode',
    ].includes(orderType)
  ) {
    result.additionalData = additionalData;
  } else if (['chooseCards', 'chooseZoneCards'].includes(orderType)) {
    result.additionalData = {
      ...validateAndConvertChooseCardsOrderData(order.additionalData, pov),
    };
  } else if (orderType === 'chooseZoneCardsAndMode') {
    const { modes } = additionalData;

    result.additionalData = {
      modes,
      ...validateAndConvertChooseCardsOrderData(order.additionalData, pov),
    };
  } else {
    console.warn('orderToVOrder: unrecognized orderType', order, pov);
  }

  if (order.conversions) {
    order.conversions.forEach((conversion) => {
      const { conversionType, key, out } = conversion;
      const value = result.additionalData[key];
      delete result.additionalData[key];
      let vValue;
      if (conversionType === 'cardsToCids') {
        vValue = cardsToCids(value, pov);
      } else {
        throw new EError('unrecognized conversionType', {
          order,
          pov,
          conversion,
        });
      }
      result.additionalData[out] = vValue;
    });
  }

  return result;
}

export function ordersToVOrders(orders: Order[], pov: Role): VOrder[] {
  const myOrders = orders.filter((order) => order.role === pov);

  return myOrders.map((order) => orderToVOrder(order, pov));
}

export function executeFilledOrder(
  state: State,
  order: Order,
  filledOrder: FilledOrder
): AAResult {
  const { orderName: fullName } = order;
  const [groupName, orderName] = fullName.split('/');

  const orderGroupHash = allGroups[state.code];
  const orderDefinition = orderGroupHash[groupName].descriptors[orderName];
  if (!orderDefinition) {
    throw new EError('Cannot find order definition for order', order);
  }
  if (order.isDisabled) {
    throw new EError('Cannot execute disabled order', order);
  }
  return orderDefinition.execute(state, order, filledOrder);
}

export function validateSource(filledOrder, order, pov) {
  const errors = [];
  if (filledOrder.source !== orderSourceToVOrderSource(order.source, pov)) {
    errors.push('sourceDoesNotMatch');
  }
  return errors;
}

export function validateChooseCards(filledOrder, order, pov): string[] {
  const vOrder = orderToVOrder(order, pov);

  const { cids } = filledOrder;

  const cidsMatch = cids.every((cid) =>
    vOrder.additionalData.cids.includes(cid)
  );
  const cidsAreDifferent = cids.length === new Set(cids).size;
  const { minimum, maximum } = order.additionalData;
  const cidsAreWithinRange = minimum <= cids.length && cids.length <= maximum;

  const errors = [
    cidsMatch ? null : 'cidsMatchFailed',
    cidsAreDifferent ? null : 'cidsAreDifferentFailed',
    cidsAreWithinRange ? null : 'cidsAreWithinRangeFailed',
  ].filter((x) => !!x);

  return errors;
}

export function validateMode(filledOrder, order, pov) {
  const errors = [];
  if (!order.additionalData.modes.includes(filledOrder.mode)) {
    errors.push('modeIsNotInAvailableOptions');
  }
  return errors;
}

export type OrderValidationResult = {
  isValid: boolean;
  errors?: string[];
};

export function validateFilledOrder(
  filledOrder: VFilledOrder,
  order: Order,
  pov: Role
): OrderValidationResult {
  let errors;

  if (order.disambiguationNumber !== filledOrder.disambiguationNumber) {
    throw new EError('validateFilledOrder: disambiguationNumber must match', {
      filledOrder,
      order,
      pov,
    });
  }

  switch (order.orderType) {
    case 'simple': {
      errors = [];
      break;
    }
    case 'simpleOnCard':
    case 'simpleOnZone':
    case 'simpleOnPlayer':
    case 'cardWithAnotherCard': {
      errors = validateSource(filledOrder, order, pov);
      break;
    }
    case 'chooseCards': {
      errors = [
        ...validateSource(filledOrder, order, pov),
        ...validateChooseCards(filledOrder, order, pov),
      ];
      break;
    }
    case 'chooseMode': {
      errors = [
        ...validateSource(filledOrder, order, pov),
        ...validateMode(filledOrder, order, pov),
      ];
      break;
    }
    case 'chooseZoneCards': {
      errors = [
        ...validateSource(filledOrder, order, pov),
        ...validateChooseCards(filledOrder, order, pov),
      ];
      break;
    }
    case 'chooseZoneCardsAndMode': {
      errors = [
        ...validateSource(filledOrder, order, pov),
        ...validateChooseCards(filledOrder, order, pov),
        ...validateMode(filledOrder, order, pov),
      ];

      break;
    }
    default: {
      throw new EError(`unrecognized orderType of order`, order);
    }
  }
  const isValid = errors.length === 0;
  return {
    isValid,
    errors,
  };
}
