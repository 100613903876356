import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import stable from 'stable';
import { selectCurrentRoomMessages } from 'slices/roomSlice';
import { createSelectGameLog } from 'slices/runtimeStatesSlice';
import { sendWsMessage } from 'connector';
import { useMediaQuery } from 'usehooks-ts';

export function useCurrentRoomChat(options = {}) {
  const roomMessages = useSelector(selectCurrentRoomMessages);

  const shortName = options.mergeWithGameLog;
  const gameLog = useSelector(createSelectGameLog(shortName));

  let allMessages;
  if (options.mergeWithGameLog && gameLog && gameLog.messages) {
    // Note: in thm log and seki historyEntries, timestamps in deltas are
    // numbers like 1589784254679, while in messages (by way of Postgres)
    // they're strings like "2020-05-18T06:44:12.066Z".  new Date() understands
    // both.
    allMessages = [...roomMessages, ...gameLog.messages];
    allMessages = stable(allMessages, (a, b) => {
      const aa = new Date(a.createdAt);
      const bb = new Date(b.createdAt);
      return aa > bb;
    });
  } else {
    allMessages = roomMessages;
  }

  const onChatLineSent = useCallback((text) => {
    sendWsMessage({
      tag: 'chat',
      text,
    });
  }, []);

  return {
    messages: allMessages,
    onChatLineSent,
    roleToPlayerName: gameLog ? gameLog.roleToPlayerName : null,
  };
}

export function usePageTitle(pageTitle) {
  useEffect(() => {
    const title = pageTitle ? `${pageTitle} - Chantry` : 'Chantry';
    document.title = title;
  }, [pageTitle]);
}

export function useIsUpToTablet() {
  // corresponds to up-to-tablet in index.scss
  const isUpToTablet = useMediaQuery('only screen and (max-width : 799px)');
  return isUpToTablet;
}
