import { GameCard } from 'components/GameCard';
import { Well } from 'components/Well';

export function ListOfGameCards(props) {
  const { games } = props;
  if (games === null) {
    return null;
  }

  if (games.length === 0) {
    return <Well modifier="info">No games here.</Well>;
  }

  return (
    <div className="ListOfGameCards">
      {games.map((game) => {
        return <GameCard key={game.shortName} game={game} />;
      })}
    </div>
  );
}
