import React from 'react';
import { Outlink } from 'components/Outlink';
import { Footer } from 'components/Footer';
import { usePageTitle } from 'hooks';

export function InfoPage(props) {
  usePageTitle('Credits, contact & support');
  return (
    <>
      <main className="InfoPage topLevel">
        <div className="container">
          <section>
            <h2>Credits</h2>
            <ul>
              <li>
                Game: <i>13 Days</i> by Daniel Skjold Pedersen & Asger Sams
                Granerud, published by{' '}
                <Outlink href="http://jollyrogergames.com/game/13-days/">
                  Jolly Roger Games
                </Outlink>
                {' and '}
                <Outlink href="https://www.ultrapro.com/product_info.php?products_id=4170">
                  Ultra Pro
                </Outlink>
              </li>
              <li>
                Icons: <Outlink href="https://ionicons.com/">Ionicons</Outlink>
              </li>
              <li>
                Font:{' '}
                <Outlink href="https://github.com/mozilla/zilla-slab/releases/tag/v1.0">
                  Zilla Slab by Mozilla
                </Outlink>
              </li>
              <li>Font: Lato by tyPoland - Lukasz Dziedzic</li>
              <li>
                Robots: lovingly delivered by{' '}
                <Outlink href="https://robohash.org">Robohash.org</Outlink>
              </li>
              <li>
                Code: half of <Outlink href="https://npmjs.com">NPM</Outlink>{' '}
                and a bit of{' '}
                <Outlink href="https://clojars.org">Clojars</Outlink>
              </li>
              <li>
                Sekigahara game icons made by Lorc, Skoll under{' '}
                <a href="https://creativecommons.org/licenses/by/3.0/">
                  CC BY 3.0
                </a>
                . Available on{' '}
                <a href="https://game-icons.net">https://game-icons.net</a>.
              </li>
            </ul>
          </section>
          <section>
            <h2>Contact</h2>
            My username at boardgamegeek is arry1. You can call me Alex.
          </section>
          <section></section>
          <section>
            <h2>Support</h2>
            <ul>
              <li>
                Contribute with your time at{' '}
                <Outlink href="https://bitbucket.org/arry/chantry-redesign">
                  Bitbucket
                </Outlink>
                .
              </li>
              <li>
                Or with your money at{' '}
                <Outlink href="https://www.patreon.com/arry_maker_of_chantry">
                  Patreon
                </Outlink>
                .
              </li>
            </ul>
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
}
