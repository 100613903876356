import React from 'react';

export function Avatar(props) {
  const { name, timestamp } = props;
  if (!name) {
    return null;
  }

  const baseSrc = `/avatar/${name}.png`;
  const src = timestamp ? `${baseSrc}?${timestamp.getTime()}` : baseSrc;

  return (
    <div className="avatar">
      <img src={src} alt={`Avatar of ${name}`} />
    </div>
  );
}
