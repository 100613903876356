import React, { useState } from 'react';
import { TopLevelPage } from 'components/TopLevelPage';
import { PeopleOnline } from 'components/PeopleOnline';
import { ChatComponent } from 'components/ChatComponent';
import { useCurrentRoomChat } from 'hooks';

export function ChatPage(props) {
  const { setHideNavbarOnSmallScreen } = props;

  const [hasFocus, setHasFocus] = useState(false);

  const chat = useCurrentRoomChat();

  function handleChatInputFocusChanged(hasFocus) {
    setHasFocus(hasFocus);
    setHideNavbarOnSmallScreen(hasFocus);
  }

  return (
    <TopLevelPage
      additionalClassName="ChatPage"
      footerHideSmall={true}
      title="Chat"
    >
      <PeopleOnline hideSmall={hasFocus} />
      <ChatComponent
        chat={chat}
        onChatInputFocusChanged={handleChatInputFocusChanged}
      />
    </TopLevelPage>
  );
}
