import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { sendWsMessage } from 'connector';
import { Loader } from 'components/Loader';
import { Button } from 'components/Button';
import {
  onBeforeGameActionSent,
  createSelectGameActionLoading,
} from 'slices/roomSlice';

function ActionButton(props) {
  const { shortName, action, onAction, isLoading = false, isOutline } = props;
  const { actionId, text, kind, isDisabled, navigateToPlay } = action;

  const className = clsx('button', {
    isPrimary: kind === 'primary' && !isOutline,
    isPrimaryOutline: kind === 'primary' && isOutline,
    isDanger: kind === 'danger' && !isOutline,
    isDangerOutline: kind === 'danger' && isOutline,
    isSecondary: kind === 'secondary',
    isDisabled: isDisabled || isLoading,
  });

  if (navigateToPlay) {
    const to = `/play/${shortName}`;
    return (
      <Link className={className} to={to}>
        {text}
      </Link>
    );
  } else {
    return (
      <Button
        onClick={() => onAction(action)}
        key={actionId}
        className={className}
      >
        {text}
      </Button>
    );
  }
}

export function GameActionButtons(props) {
  const { actions, shortName, isOutline, hideSmall } = props;

  const dispatch = useDispatch();
  const isLoading = useSelector(createSelectGameActionLoading(shortName));

  function handleAction(action) {
    const { actionId, sendToServer, isDisabled } = action;
    if (isDisabled) {
      return;
    }

    if (sendToServer) {
      dispatch(onBeforeGameActionSent(shortName));
      sendWsMessage({
        tag: 'applyGameAction',
        shortName,
        actionId,
      });
    } else {
      console.warn('Unrecognized next step of action', action);
    }
  }

  const className = clsx('GameActionButtons', { hideSmall });
  return (
    <div className={className}>
      {actions.map((action) => {
        const { actionId } = action;
        return (
          <ActionButton
            key={actionId}
            action={action}
            isOutline={isOutline}
            onAction={handleAction}
            shortName={shortName}
            isLoading={isLoading}
          />
        );
      })}
      <Loader isActive={isLoading} isMarginless />
    </div>
  );
}
