import {
  Role,
  State,
  ZoneName,
  Zone,
  ZoneAccess,
  Card,
  CardInRegion,
  VYourAccess,
  CardLocation,
  Region,
  Hash,
  CardKnowledge,
  VarValue,
  InitialAccess,
} from './types';
import { ActionError, EError } from './errors';

export function getZoneYourAccess(access: ZoneAccess, pov: Role): VYourAccess {
  const result =
    access === 'all' || access.includes(pov) ? 'accessible' : 'inaccessible';
  return result;
}

export function indent(level: number) {
  let result = '';
  for (let i = 0; i < level; ++i) {
    result += '  ';
  }
  return result;
}

export function stringComparator(a, b) {
  return a < b ? -1 : a === b ? 0 : 1;
}

// Zone of location is a zone that holds a card that is at location.
export function getZoneOfLocation(state: State, location: CardLocation): Zone {
  const { zoneName, index, attachmentIndex } = location;
  if (attachmentIndex === undefined) {
    return state.zones[zoneName];
  } else {
    return (state.zones[zoneName].cards[index] as CardInRegion).attachments;
  }
}

export function getZone(state: State, zoneName: ZoneName): Zone {
  const zone = state.zones[zoneName];
  return zone;
}

export function createAttachmentsRegion(sid: string, owner: Role): Region {
  return {
    zoneKind: 'region',
    owner,
    slug: `attachments:${sid}`,
    access: 'all',
    cards: [],
  };
}

export function findCardLocation(
  state: State,
  sid: string
): CardLocation | null {
  for (const [zoneName, zone] of Object.entries(state.zones)) {
    const { cards, zoneKind } = zone as Zone;

    for (let index = 0; index < cards.length; ++index) {
      const card = cards[index];
      if (card.sid === sid) {
        return {
          zoneName,
          index,
        };
      }
      if (zoneKind === 'region') {
        const { attachments } = card as CardInRegion;

        const attachmentIndex = attachments.cards.findIndex(
          (attachment) => attachment.sid === sid
        );
        if (attachmentIndex !== -1) {
          return {
            zoneName,
            index,
            attachmentIndex,
          };
        }
      }
    }
  }
  return null;
}

export function mapValues<K extends string | number, V, V1>(
  obj: Hash<K, V>,
  convertValue = (x: V, key?: K): V1 => x as unknown as V1
): Hash<K, V1> {
  const result = {} as Hash<K, V1>;
  Object.entries(obj).forEach(([k, v]) => {
    result[k] = convertValue(v as V, k as K);
  });
  return result;
}

export function getYourKnowledge(card: Card, pov: Role): CardKnowledge {
  return card.roleToKnowledge[pov] || null;
}

export function indentedList(
  header: string,
  items: string[],
  level: number
): string {
  const lines = [header, ...items.map((item) => `${indent(level)}- ${item}`)];

  const result = lines.join('\n');
  return result;
}

export function entriesSortedAlphabetically<V>(obj: {
  [k: string]: V;
}): [string, V][] {
  return Object.entries(obj).sort((a, b) => {
    return stringComparator(a[0], b[0]);
  });
}

export function buildZoneName(owner: Role | null, slug: string): string {
  return owner ? `${owner}:${slug}` : slug;
}

export function getZoneName(zone: Zone): string {
  const { owner, slug } = zone;
  return buildZoneName(owner, slug);
}

export function splitZoneName(zoneName: string): [Role, string] {
  const [owner, slug] = zoneName.split(':');
  return [owner as Role, slug];
}

export function getTranslatedCidFromKnowledge(
  knowledge: CardKnowledge
): string {
  let translatedCid;

  if (knowledge === undefined) {
    throw new Error('how can that be');
  }

  if (knowledge === null) {
    translatedCid = '?';
  } else {
    translatedCid = knowledge.cid;
  }

  return translatedCid;
}

/*
 * Translated cid is for purposes of card representation in delta reps.
 */
export function getCardTranslatedCid(card: Card, pov: Role): string {
  const { roleToKnowledge } = card;

  const knowledge = roleToKnowledge[pov] || null;

  return getTranslatedCidFromKnowledge(knowledge);
}

export function getCardByLocation(state: State, location: CardLocation): Card {
  const { zoneName, index, attachmentIndex } = location;
  const card = getZone(state, zoneName).cards[index];
  if (attachmentIndex === undefined) {
    return card;
  }
  if (card.tag !== 'cardInRegion') {
    throw new EError(`host card must be in region`, location);
  }
  return (card as CardInRegion).attachments.cards[attachmentIndex];
}

export function entryToValue<T>([_k, v]: [any, T]): T {
  return v;
}

export function qualifiedName(role, name) {
  return `${role}:${name}`;
}

// Returns the shuffled array, not modifying the original one.
export function shuffleArray(array) {
  const result = [...array];
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [result[currentIndex], result[randomIndex]] = [
      result[randomIndex],
      result[currentIndex],
    ];
  }

  return result;
}

export function getStateVar<T extends VarValue>(
  state: State,
  varName: string,
  defaultValue?: T
): T {
  const result = state.vars[varName];
  if (result === undefined) {
    if (defaultValue !== undefined) {
      return defaultValue;
    } else {
      throw new ActionError(`state var ${varName} doesn't exist`);
    }
  }
  return result as T;
}

export function getStateNumberVar(
  state: State,
  varName: string,
  defaultValue?: VarValue
): number {
  const result = getStateVar(state, varName, defaultValue);
  if (typeof result !== 'number') {
    throw new ActionError(`state var ${varName} is not a number: ${result}`);
  }
  return result as number;
}

export function getStateStashedValue<T>(
  state: State,
  varName: string,
  defaultValue?: T
): T {
  const result = state.stash[varName];
  // XXX conflating null and undefined can be confusing; on the other hand we
  // don't ever want to have stash's "null" values to mean something other than
  // "is unknown / was unset" so it's ok.
  if (result === undefined || result === null) {
    if (defaultValue !== undefined) {
      return defaultValue;
    } else {
      throw new ActionError(`state stashed value ${varName} doesn't exist`);
    }
  }
  return result as T;
}

export function getNumberStateVar(state: State, varName: string): number {
  const result = state.vars[varName];
  if (result === undefined) {
    throw new ActionError(`state var ${varName} doesn't exist`);
  }
  if (typeof result !== 'number') {
    throw new ActionError(`state var ${varName} must be a number: ${result}`);
  }
  return result as number;
}

export function getGameTagFromId(gameId) {
  return gameId.split('-')[0];
}

export function isEmpty(value) {
  return value === null || value === undefined;
}

export function getPlayerZone(state, role, slug) {
  const zoneName = buildZoneName(role, slug);
  return state.zones[zoneName];
}

export function getCardsInZone(state, zoneName): Card[] {
  return state.zones[zoneName].cards;
}

export function getNCardsInZone(state, zoneName): number {
  // handle both client and server representations
  const zone = state.zones[zoneName];
  if ('nCards' in zone) {
    return zone.nCards;
  } else {
    return zone.cards.length;
  }
}

export function getCardsInPlayerZone(state, role, slug): Card[] {
  const zoneName = buildZoneName(role, slug);
  return getCardsInZone(state, zoneName);
}

export function getNCardsInPlayerZone(state, role, slug): number {
  const zoneName = buildZoneName(role, slug);
  return getNCardsInZone(state, zoneName);
}

export function locationIsAttachment(location: CardLocation): boolean {
  return location.attachmentIndex !== undefined;
}

export type CardWalker<R> = (
  card: Card,
  location: CardLocation,
  role?: Role
) => R | null;

export type WalkCardConfig = {
  roles: Role | Role[];
  slugs: string | string[];
  skipAttachments?: boolean;
  onlyAttachments?: boolean;
};

export function walkCards<R>(
  state: State,
  config: WalkCardConfig,
  walker: CardWalker<R>
): R[] {
  const roleOrRoles = config.roles;
  const slugOrSlugs = config.slugs;
  const roles = typeof roleOrRoles === 'string' ? [roleOrRoles] : roleOrRoles;
  const slugs = typeof slugOrSlugs === 'string' ? [slugOrSlugs] : slugOrSlugs;

  return roles
    .flatMap((role) => {
      return slugs.flatMap((slug) => {
        const cards = getCardsInPlayerZone(state, role, slug);
        return cards.flatMap((card, index) => {
          const zoneName = buildZoneName(role, slug);

          const results = [];

          if (!config.onlyAttachments) {
            results.push(
              walker(
                card,
                {
                  zoneName,
                  index,
                },
                role
              )
            );
          }

          if (card.tag === 'cardInRegion' && !config.skipAttachments) {
            results.push(
              ...card.attachments.cards.map((attachment, attachmentIndex) => {
                return walker(
                  attachment,
                  {
                    zoneName,
                    index,
                    attachmentIndex,
                  },
                  role
                );
              })
            );
          }

          return results.filter((result) => result !== null);
        });
      });
    })
    .filter((x) => x !== null);
}

export function initAccessToAccess(
  initAccess: InitialAccess,
  owner: Role
): ZoneAccess {
  switch (initAccess) {
    case 'all':
      return 'all';
    case 'owner':
      return [owner];
    default:
      return [];
  }
}

export function createShortName(cardName) {
  function shorten(parts, len) {
    return parts.map((part) => part.substr(0, len)).join('');
  }

  function toSimpler(cardName) {
    return cardName
      .toLowerCase()
      .replace(/[-!()&'’:,]/g, '')
      .replaceAll('.', '')
      .replaceAll('ö', 'o')
      .replaceAll('á', 'a')
      .replaceAll('ü', 'u')
      .replaceAll('ç', 'c');
  }

  const parts = toSimpler(cardName)
    .split(/\s+/)
    .filter((s) => s.length > 0);

  const n = parts.length;
  switch (parts.length) {
    case 1:
      return shorten(parts, 6);
    case 2:
      return shorten(parts, 3);
    case 3:
      return shorten(parts, 2);
    default:
      return shorten(parts, 1);
  }
}

export function getBaseFromCid(cid) {
  const m = cid.match(/([^-#]+)([-#]\d+)?/);
  if (m === null) {
    console.error('cid is not proper', cid);
    return null;
  }
  const base = m[1];
  return base;
}

export function getBaseFromSid(sid) {
  const m = sid.match(/([^-:]+)([-:]\d+)?/);
  if (m === null) {
    console.error('sid is not proper', sid);
    return null;
  }
  const base = m[1];
  return base;
}

export function findCardsBySids(state, sids) {
  const cards = sids
    .map((sid) => {
      const cardLocation = findCardLocation(state, sid);
      if (!cardLocation) {
        console.error('findCardsBySids: sid not found', sid);
        return null;
      }
      return getCardByLocation(state, cardLocation);
    })
    .filter((card) => !!card);

  return cards;
}

// return the array with each element from `elements` removed.
export function removeAll<T>(array: T[], elements: T[]): T[] {
  return array.filter((element) => {
    return !elements.includes(element);
  });
}

export function separate<T>(array: T[], predicate: (T) => boolean): [T[], T[]] {
  const filtered = [];
  const removed = [];
  array.forEach((element) => {
    [filtered, removed][predicate(element) ? 0 : 1].push(element);
  });
  return [filtered, removed];
}

export function sum(values) {
  return values.reduce((result, value) => result + value, 0);
}

export function addToKeyedArray(obj, key, element) {
  if (!obj[key]) {
    obj[key] = [];
  }
  obj[key].push(element);
}

export function repeatString(str, amount) {
  let result = '';
  for (let i = 0; i < amount; ++i) {
    result += str;
  }
  return result;
}

export function getSidsOfCards(cards: Card[]): string[] {
  return cards.map((card) => {
    return card.sid;
  });
}
