import React from 'react';
import clsx from 'clsx';
import { Footer } from 'components/Footer';
import { usePageTitle } from 'hooks';

export function TopLevelPage(props) {
  const { additionalClassName, footerHideSmall, title } = props;
  usePageTitle(title);
  const className = clsx('topLevel', { [additionalClassName]: true });
  return (
    <>
      <main className={className}>
        <div className="container">{props.children}</div>
      </main>
      <Footer hideSmall={footerHideSmall} />
    </>
  );
}
