import { State, Role } from '../types';
import { EError } from '../errors';
import { createEmptyState } from '../creation';
import { fa, getStateAfterActions } from '../foundationActions';
import { shuffleArray, mapValues } from '../utils';
import {
  sekiLocationData,
  sekiUnitData,
  cardManifest,
  bothRoles,
  sekiGetUnitInfoByBase,
} from './sekiData';
import { sekia } from './sekiActions';
import './sekiOrders'; // imperatively register

// Return a random entry from input array, removing it from the array
// (modifying!).
function pick(bag) {
  const index = Math.floor(Math.random() * bag.length);
  const result = bag[index];
  bag.splice(index, 1);
  return result;
}

// From the location and unit data, return a mapping of the form
// `{ [zoneName]: base[] }` suitable for starting a game.
function allocateStartingUnits() {
  const locations = {};
  let allUnits = [];
  sekiUnitData.forEach((unitData) => {
    const { amount } = unitData;
    for (let index = 1; index <= amount; ++index) {
      allUnits.push(unitData);
    }
  });

  const ishiBag = allUnits.filter(
    (unit) => !unit.setup && unit.faction === 'i'
  );
  const tokuBag = allUnits.filter(
    (unit) => !unit.setup && unit.faction === 't'
  );

  const zoneNameToUnits = {};

  for (let locationDescriptor of sekiLocationData) {
    const { locationId, ishis, ishir, tokus, tokur } = locationDescriptor;

    const ishiUnits = [];
    const tokuUnits = [];

    if (ishis) {
      ishiUnits.push(
        ...allUnits.filter(
          (unit) => unit.setup === ishis && unit.faction === 'i'
        )
      );
    } else if (tokus) {
      tokuUnits.push(
        ...allUnits.filter(
          (unit) => unit.setup === tokus && unit.faction === 't'
        )
      );
    }

    if (ishir) {
      for (let i = 0; i < ishir; ++i) {
        ishiUnits.push(pick(ishiBag));
      }
    } else if (tokur) {
      for (let i = 0; i < tokur; ++i) {
        tokuUnits.push(pick(tokuBag));
      }
    }

    zoneNameToUnits[`i:${locationId}`] = ishiUnits;
    zoneNameToUnits[`t:${locationId}`] = tokuUnits;
  }

  zoneNameToUnits['i:bag'] = ishiBag;
  zoneNameToUnits['t:bag'] = tokuBag;

  const result = mapValues(zoneNameToUnits, (units: any[]) => {
    // Shuffle so that hidden identities cannot be traced.
    return shuffleArray(units.map((unit) => unit.base));
  });

  return result;
}

// XXX it's repetitive with seki/sekiData.ts.  Note: the weird key and value
// naming is a legacy of Clojure.
export type SekiStartGameDescriptor = {
  // more properly should be playerNames, but this naming matches the game
  // schema as in server/src/db.js.
  players: string[];
  gameSettings: {
    ['side-selection']?: 'random' | 'owner-i' | 'owner-t';
  };
};

export function getFourLetterRoleString(role) {
  return role === 't' ? 'toku' : 'ishi';
}

export function createSekiState(
  startGameDescriptor: SekiStartGameDescriptor
): State {
  let state = createEmptyState('seki');

  const { players, gameSettings } = startGameDescriptor;

  if (!(players.length === 2)) {
    throw new EError('must have two players', { players });
  }

  const sideSelection = gameSettings['side-selection'] || 'random';

  let roles = [...bothRoles];
  if (sideSelection === 'owner-i') {
    // do nothing
  } else if (sideSelection === 'owner-t') {
    roles.reverse();
  } else {
    roles = shuffleArray(roles);
  }

  const addPlayerActions = players.map((playerName, index) => {
    const role = roles[index];
    return fa.addPlayer(playerName, role);
  });

  const createCardZonesActions = bothRoles.map((role) => {
    return fa.seq(
      fa.createZone('bunch', role, 'deck', []),
      fa.createZone('bunch', role, 'discard', 'all'),
      fa.createZone('bunch', role, 'hand', [role]),
      fa.createZone('region', role, 'played', 'all'),
      fa.createZone('region', role, 'chosen', [role])
    );
  });

  const createPlayingCardsActions = bothRoles.map((role) => {
    const myCards = cardManifest.filter(
      (cardInfo) => cardInfo.cardBack[0] === role
    );
    let cardsWithAmount = [];
    myCards.forEach((cardInfo) => {
      const { amount } = cardInfo;
      for (let index = 0; index < amount; ++index) {
        cardsWithAmount.push(cardInfo);
      }
    });
    cardsWithAmount = shuffleArray(cardsWithAmount);

    return fa.seq(
      ...cardsWithAmount.map((cardInfo) => {
        const { base, cardBack } = cardInfo;
        const zoneName = `${role}:deck`;
        return fa.createCard(base, cardBack, zoneName);
      })
    );
  });

  const drawCardsActions = bothRoles.map((role) => {
    return sekia.drawCardsLowLevel(role, 5);
  });

  const createUnitZonesActions = bothRoles.map((role) => {
    return fa.seq(
      fa.createZone('bunch', role, 'bag', []),
      fa.createZone('bunch', role, 'dead', 'all'),
      ...sekiLocationData.map((location) => {
        const { locationId } = location;

        const slug = locationId;
        return fa.createZone('region', role, slug, [role]);
      })
    );
  });

  const allocated = allocateStartingUnits();
  const createUnitCardsActions = Object.entries(allocated).map(
    ([zoneName, bases]: [string, string[]]) => {
      return fa.seq(
        ...bases.map((base) => {
          const role = zoneName.split(':')[0];
          const cardBack = role === 'i' ? 'ib' : 'tb';

          const unitInfo = sekiGetUnitInfoByBase(base);

          return fa.createCard(base, cardBack, zoneName, {
            facing: unitInfo.kind === 'disc' ? 'faceup' : 'facedown',
          });
        })
      );
    }
  );

  const castleControlVars = {};
  sekiLocationData.forEach((location) => {
    const { castle, locationId } = location;
    if (location.castle) {
      castleControlVars[`cc:${locationId}`] = location.castle;
    }
  });

  const resourceLocationControlVars = {};
  sekiLocationData.forEach((location) => {
    const { resource, locationId, ishir, tokur } = location;
    if (location.resource) {
      // Not precisely correct, but good enough for the regular seki setup.
      resourceLocationControlVars[`rc:${locationId}`] = ishir
        ? 'i'
        : tokur
        ? 't'
        : null;
    }
  });

  state = getStateAfterActions(
    state,
    ...addPlayerActions,
    ...createCardZonesActions,
    ...createPlayingCardsActions,
    ...drawCardsActions,
    ...createUnitZonesActions,
    ...createUnitCardsActions,
    fa.setVars({
      'i:res': 1,
      't:res': 1,
      'i:cas': 4,
      't:cas': 5,
      ...castleControlVars,
      ...resourceLocationControlVars,
    }),
    sekia.startWeek(1, { skipReinforcementsStep: true })
  );

  return state;
}

const ADHOC_TESTING = false;
if (ADHOC_TESTING) {
  const ss = createSekiState({
    players: ['John', 'Bill'],
    gameSettings: {
      ['side-selection']: 'random',
    },
  });
  console.log(JSON.stringify(ss, null, 2));
}
