import { produce } from 'immer';
import { CardInRegion, Role, Card } from '../types';
import {
  CombatOutcome,
  RCO,
  RetreatConfiguration,
  UnpredictedCombatConfiguration,
} from './sekiTypes';
import {
  createActionGroup,
  registerActionGroup,
  applyAction,
} from '../actionsInfrastructure';
import { fa } from '../foundationActions';
import { ActionError } from '../errors';
import {
  getZone,
  qualifiedName,
  getZoneName,
  getCardsInZone,
  getCardsInPlayerZone,
  getNCardsInPlayerZone,
  buildZoneName,
  findCardsBySids,
  getStateVar,
  getStateNumberVar,
  getStateStashedValue,
  removeAll,
  walkCards,
  separate,
  getBaseFromSid,
  getSidsOfCards,
} from '../utils';
import {
  bothRoles,
  sekiGetOpponentRole,
  sekiGetConnectionName,
  sekiConnections,
  sekiLocationData,
  sekiFindLocationById,
} from './sekiData';
import {
  sekiGetCardInfoOfCard,
  sekiGetPhasingPlayer,
  sekiGetAttackerRole,
  sekiCollectContestedLocationIds,
  determineActivations,
  collectMovementTargets,
  DEFAULT_ALL_USED_BONUSES,
  getCanForceMarch,
  getMovableUnitsInLocation,
  collectActivatableLocationIds,
  sekiGetUnitInfoBySid,
  sekiCalculateAddedImpact,
  sekiGetCombatOutcome,
  sekiGetNLosses,
  sekiGetNAwardsForLosses,
  getCastleController,
  getLocationHasCastle,
  getConnections,
  getEnemyRelationshipOnMovingTo,
  collectUndeployedUnitCards,
  getIsPlayerWalled,
} from './sekiUtils';
import { saveToStashCtx } from '../actionHelpers';

function retrieveMovementParameters(
  state,
  role,
  options = {
    redetermineCanForceMarch: false,
  }
) {
  const movementParameters = getStateStashedValue(
    state,
    'movementParameters'
  ) as object;

  if (options.redetermineCanForceMarch) {
    const canForceMarch = getCanForceMarch(state, role);

    const newMovementParameters: any = {
      ...movementParameters,
      canForceMarch,
    };
    return newMovementParameters;
  }

  return movementParameters;
}
export function sekiDetermineRetreatConfiguration(
  state,
  role
): RetreatConfiguration {
  const attackerRole = sekiGetAttackerRole(state);
  const isAttacker = role === attackerRole;

  const locationId = getStateVar(state, 'combatLocationId');

  const unitCards = sekiGetRetreatingUnits(state, role);

  const isCastleRetreatPossible =
    getLocationHasCastle(locationId) &&
    getCastleController(state, locationId) === role;

  const isCastleCapacitySufficient = unitCards.length <= 2;

  const attackerUnitCards = getCardsInPlayerZone(
    state,
    attackerRole,
    locationId
  );

  const attackerLocationIds = [
    ...new Set(
      attackerUnitCards
        .map((unitCard: CardInRegion) => {
          return unitCard.vars.enteredFrom;
        })
        .filter((locationId) => !!locationId)
    ),
  ];

  const adjacentLocationIds = getConnections(locationId).map((connection) => {
    const { connectionType, targetLocationId } = connection;
    return targetLocationId;
  });

  let restricted;

  if (isAttacker) {
    restricted = attackerLocationIds;
  } else {
    restricted = adjacentLocationIds.filter((locationId) => {
      if (attackerLocationIds.includes(locationId)) {
        return false;
      }

      if (
        getNCardsInPlayerZone(state, sekiGetOpponentRole(role), locationId) > 0
      ) {
        return false;
      }

      return true;
    });
  }

  let possible;
  if (
    restricted.length === 0 &&
    !(isCastleRetreatPossible && isCastleCapacitySufficient)
  ) {
    possible = adjacentLocationIds;
  } else {
    possible = restricted;
  }

  return {
    unitCards,
    locationIds: possible,
    isCastleRetreatPossible,
  };
}

export function sekiGetRetreatingUnits(state, role) {
  const locationId = getStateVar(state, 'combatLocationId');
  return getCardsInPlayerZone(state, role, locationId) as CardInRegion[];
}

export const sekiGroup = createActionGroup('seki', {
  // ** General
  moveCards(state, cards, targetZoneName, options = {}) {
    const aaResult = applyAction(
      state,
      fa.seq(
        ...cards.map((card, index) => {
          return fa.moveCard(card.sid, targetZoneName, {
            facing: options.facing,
          });
        })
      )
    );

    const ctx = {
      state: aaResult.next,
    };
    if (options.saveAs) {
      const newCards = findCardsBySids(
        ctx.state,
        cards.map((card) => card.sid)
      );
      saveToStashCtx(ctx, options.saveAs, newCards);
    }

    return {
      next: ctx.state,
      delta: aaResult.delta,
    };
  },
  moveUnitsBySids(
    state,
    sids,
    targetZoneName,
    options = {
      saveAs: null,
      facing: null,
    }
  ) {
    const aaResult = applyAction(
      state,
      fa.seq(
        ...sids.map((sid, index) => {
          return fa.moveCard(sid, targetZoneName, {
            facing: options.facing || 'facedown',
          });
        })
      )
    );

    const ctx = {
      state: aaResult.next,
    };
    if (options.saveAs) {
      const newUnits = findCardsBySids(ctx.state, sids);
      saveToStashCtx(ctx, options.saveAs, newUnits);
    }

    return {
      next: ctx.state,
      delta: aaResult.delta,
    };
  },
  moveUnits(state, units, targetZoneName, options = {}) {
    return applyAction(
      state,
      sekia.moveUnitsBySids(
        units.map((unit) => unit.sid),
        targetZoneName,
        options
      )
    );
  },
  moveAllCards(state, sourceZoneName, targetZoneName, options = {}) {
    const cards = getCardsInZone(state, sourceZoneName);
    return applyAction(
      state,
      sekia.moveCards(cards, targetZoneName, {
        saveAs: options.saveAs,
      })
    );
  },
  drawCardsLowLevel(state, role, amount, options = {}) {
    const cards = getCardsInPlayerZone(state, role, 'deck');

    if (cards.length < amount) {
      throw new ActionError(
        `deck must have enough cards to draw ${amount}, has ${cards.length}`,
        {
          role,
        }
      );
    }
    return applyAction(
      state,
      sekia.moveCards(cards.slice(0, amount), buildZoneName(role, 'hand'), {
        saveAs: options.saveAs,
      })
    );
  },
  drawUnitsLowLevel(state, role, amount, options = {}) {
    const units = getCardsInPlayerZone(state, role, 'bag');

    if (units.length < amount) {
      throw new ActionError(
        `${role}:bag must have enough units to draw ${amount}, has ${units.length}`,
        {
          role,
        }
      );
    }
    return applyAction(
      state,
      sekia.moveUnits(units.slice(0, amount), `${role}:${role}box`, {
        saveAs: options.saveAs,
      })
    );
  },
  discardCardsLowLevel(state, role, cards, options = {}) {
    return applyAction(
      state,
      sekia.moveCards(cards, buildZoneName(role, 'discard'), {
        saveAs: options.saveAs,
      })
    );
  },
  reshuffleDiscard(state, role) {
    return applyAction(
      state,
      fa.seq(
        sekia.moveAllCards(
          buildZoneName(role, 'discard'),
          buildZoneName(role, 'deck')
        ),
        fa.shuffleZone(buildZoneName(role, 'deck'))
      )
    );
  },
  drawCardsReporting(state, role, amount) {
    const cards = getCardsInPlayerZone(state, role, 'deck');

    if (cards.length >= amount) {
      return applyAction(
        state,
        fa.seq(
          sekia.drawCardsLowLevel(role, amount, { saveAs: 'drawn' }),
          fa.text('cardsDrawn', {
            r: { value: role },
            cc: { fromStash: 'drawn' },
          })
        )
      );
    } else {
      return applyAction(
        state,
        fa.seq(
          sekia.drawCardsLowLevel(role, cards.length, { saveAs: 'drawn1' }),
          sekia.reshuffleDiscard(role),
          sekia.drawCardsLowLevel(role, amount - cards.length, {
            saveAs: 'drawn2',
          }),
          cards.length === 0
            ? fa.text('cardsDrawnWithOnlyReshuffling', {
                r: { value: role },
                cc2: { fromStash: 'drawn2' },
              })
            : fa.text('cardsDrawnWithReshuffling', {
                r: { value: role },
                cc1: { fromStash: 'drawn1' },
                cc2: { fromStash: 'drawn2' },
              })
        )
      );
    }
  },
  drawUnitsReporting(state, role, amount) {
    return applyAction(
      state,
      fa.seq(
        sekia.drawUnitsLowLevel(role, amount, { saveAs: 'drawn' }),
        fa.text('unitsDrawn', {
          r: { value: role },
          cc: { fromStash: 'drawn' },
        })
      )
    );
  },
  awardCardsForLosses(state, role, units, combatType) {
    const nAwards = sekiGetNAwardsForLosses(units.length, combatType);
    if (nAwards === 0) {
      return applyAction(state, fa.nop());
    } else {
      return applyAction(
        state,
        fa.seq(
          fa.text('cardsAwardedForLosses', {
            r: role,
            n1: nAwards,
            n2: units.length,
          }),
          sekia.drawCardsReporting(role, nAwards, { saveAs: 'drawn' })
        )
      );
    }
  },
  destroyUnits(state, owner, units, options = {}) {
    return applyAction(
      state,
      fa.seq(sekia.moveUnits(units, qualifiedName(owner, 'dead'), options))
    );
  },
  checkGameEndByMainUnitElimination(state) {
    const iUnitCards = getCardsInPlayerZone(state, 'i', 'dead');
    const tUnitCards = getCardsInPlayerZone(state, 't', 'dead');

    const iDead = iUnitCards.filter((unitCard) => {
      const unitInfo = sekiGetUnitInfoBySid(unitCard.sid);

      return unitInfo.main;
    });

    const tDead = tUnitCards.filter((unitCard) => {
      const unitInfo = sekiGetUnitInfoBySid(unitCard.sid);

      return unitInfo.main;
    });

    let hasDisc = false;
    const reportThem = fa.seq(
      ...[...iDead, ...tDead].map((unitCard) => {
        const unitInfo = sekiGetUnitInfoBySid(unitCard.sid);

        if (unitInfo.kind === 'disc') {
          hasDisc = true;
        }
        return fa.text(
          unitInfo.kind === 'disc'
            ? 'gameStructure/mainCharacterCaptured'
            : 'gameStructure/mainCharacterKilled',
          {
            c: { value: unitCard },
          }
        );
      })
    );

    let winnerRole;
    if (iDead.length === 0 && tDead.length === 0) {
      return applyAction(state, fa.nop());
    } else if (iDead.length > 0 && tDead.length === 0) {
      const submode = hasDisc
        ? 'toyotomiHideyoriCaptured'
        : 'ishidaMitsunariKilled';
      winnerRole = 't';
      return applyAction(
        state,
        fa.seq(
          reportThem,
          fa.text('gameStructure/winnerDeclared', {
            r: winnerRole,
          }),
          fa.endGame('win', submode, winnerRole)
        )
      );
    } else if (iDead.length === 0 && tDead.length > 0) {
      winnerRole = 'i';
      return applyAction(
        state,
        fa.seq(
          reportThem,
          fa.text('gameStructure/winnerDeclared', {
            r: winnerRole,
          }),
          fa.endGame('win', 'tokugawaIeyasuKilled', winnerRole)
        )
      );
    } else {
      winnerRole = 'i';
      return applyAction(
        state,
        fa.seq(
          reportThem,
          fa.endGame(
            'win',
            fa.text('gameStructure/winnerDeclared', {
              r: winnerRole,
            }),
            'bothSidesInstantVistory',
            winnerRole
          )
        )
      );
    }
  },
  // ** Game structure
  startWeek(state, n, options = { skipReinforcementsStep: false }) {
    return applyAction(
      state,
      fa.seq(
        fa.setVars({
          week: n,
        }),
        fa.text('gameStructure/startWeek', {
          n: { value: n },
        }),
        options.skipReinforcementsStep
          ? sekia.startTurnOrderStep()
          : sekia.startReinforcementsStep()
      )
    );
  },
  endWeek(state) {
    const week = getStateNumberVar(state, 'week');
    const iCastles = getStateNumberVar(state, 'i:cas');
    const iResources = getStateNumberVar(state, 'i:res');
    const tCastles = getStateNumberVar(state, 't:cas');
    const tResources = getStateNumberVar(state, 'i:res');
    return applyAction(
      state,
      fa.seq(
        fa.text('gameStructure/endWeek', {
          r1: 'i',
          n11: iCastles,
          n12: iResources,
          r2: 't',
          n21: tCastles,
          n22: tResources,
        }),
        week === 7
          ? sekia.endGameByTimeLimit()
          : sekia.startWeek(week + 1, { skipReinforcementsStep: false })
      )
    );
  },
  endGameByTimeLimit(state) {
    const iCastles = getStateNumberVar(state, 'i:cas');
    const iResources = getStateNumberVar(state, 'i:res');
    const tCastles = getStateNumberVar(state, 't:cas');
    const tResources = getStateNumberVar(state, 'i:res');

    const iScore = iCastles * 2 + iResources;
    const tScore = tCastles * 2 + tResources;

    let winnerRole;
    let submode;

    if (iScore > tScore) {
      winnerRole = 'i';
      submode = 'morePoints';
    } else if (iScore === tScore) {
      winnerRole = 'i';
      submode = 'tieInPoints';
    } else {
      winnerRole = 't';
      submode = 'morePoints';
    }
    return applyAction(
      state,
      fa.seq(
        fa.text('gameStructure/gameEndedByTimeLimit', {
          r1: 'i',
          n11: iCastles,
          n12: iResources,
          n13: iScore,
          r2: 't',
          n21: tCastles,
          n22: tResources,
          n23: tScore,
        }),
        fa.text('gameStructure/winnerDeclared', {
          r: winnerRole,
        }),
        fa.endGame('win', submode, winnerRole)
      )
    );
  },
  // ** Reinforcements step
  startReinforcementsStep(state) {
    const iCards = getCardsInPlayerZone(state, 'i', 'hand');
    const tCards = getCardsInPlayerZone(state, 't', 'hand');

    const iCastles = getStateNumberVar(state, 'i:cas');
    const tCastles = getStateNumberVar(state, 't:cas');
    const castleLeader = iCastles > tCastles ? 'i' : 't';

    const iResources = getStateNumberVar(state, 'i:res');
    const tResources = getStateNumberVar(state, 'i:res');

    const resourceLeader =
      iResources === tResources ? null : iResources > tResources ? 'i' : 't';

    const iWillDiscardCards = Math.floor(iCards.length / 2);
    const tWillDiscardCards = Math.floor(tCards.length / 2);

    const iWillDrawCards = castleLeader === 'i' ? 6 : 5;
    const tWillDrawCards = castleLeader === 't' ? 6 : 5;
    const baseUnits = getStateNumberVar(state, 'week') <= 4 ? 2 : 1;
    const iWillDrawUnits =
      resourceLeader === 'i' || resourceLeader === null
        ? baseUnits + 1
        : baseUnits;
    const tWillDrawUnits =
      resourceLeader === 't' || resourceLeader === null
        ? baseUnits + 1
        : baseUnits;

    return applyAction(
      state,
      fa.seq(
        fa.text('reinforcementsStep/start', {
          n1: iCastles,
          n2: iWillDrawCards,
          n3: tCastles,
          n4: tWillDrawCards,
          n5: iResources,
          n6: iWillDrawUnits,
          n7: tResources,
          n8: tWillDrawUnits,
          noResourceLeader: resourceLeader === null,
        }),
        fa.setVars({
          step: 'reinforcements',
          castleLeader,
          resourceLeader,
          ['i:willDrawCards']: iWillDrawCards,
          ['t:willDrawCards']: tWillDrawCards,
          ['i:willDrawUnits']: iWillDrawUnits,
          ['t:willDrawUnits']: tWillDrawUnits,
          ['i:discardedForReinforcements']: false,
          ['t:discardedForReinforcements']: false,
        }),
        ...(iWillDiscardCards === 0
          ? [sekia.onReinforcementsCardsChosen('i', [])]
          : []),
        ...(tWillDiscardCards === 0
          ? [sekia.onReinforcementsCardsChosen('t', [])]
          : [])
      )
    );
  },
  onReinforcementsCardsChosen(state, role, cards) {
    const willDrawCards = getStateNumberVar(
      state,
      qualifiedName(role, 'willDrawCards')
    );
    const amount = getCardsInPlayerZone(state, role, 'deck').length;
    const wouldReshuffle = amount < willDrawCards;

    // Once you have discarded cards, if you wouldn't reshuffle, then put the
    // cards in the temporary zones and reinforce at once so you can plan while
    // your opponent still thinks what to discard.  On the other hand, if you
    // would reshuffle, then wait for your opponent to choose cards so they
    // wouldn't know what you reshuffled (via chosen->discard!->deck).

    const discardedCardsStashName = qualifiedName(role, 'discarded');

    return applyAction(
      state,
      fa.seq(
        fa.setVars({
          [`${role}:discardedForReinforcements`]: true,
        }),
        sekia.moveCards(cards, buildZoneName(role, 'chosen'), {
          saveAs: discardedCardsStashName,
          facing: 'facedown',
        }),
        wouldReshuffle
          ? fa.text('reinforcementsStep/reinforcingDelayed', {
              role,
            })
          : sekia.reinforce(role, discardedCardsStashName),
        sekia.proceedToReinforcingIfBothDiscarded()
      )
    );
  },
  proceedToReinforcingIfBothDiscarded(state, role) {
    const iDiscarded = getStateVar(state, 'i:discardedForReinforcements');
    const tDiscarded = getStateVar(state, 't:discardedForReinforcements');

    if (!(iDiscarded && tDiscarded)) {
      return applyAction(state, fa.nop());
    } else {
      return applyAction(
        state,
        fa.seq(
          sekia.clearChosenCards(),
          sekia.reinforce('i', 'i:discarded'),
          sekia.reinforce('t', 't:discarded'),
          fa.setVars({
            ['i:reinforced']: null,
            ['t:reinforced']: null,
            ['i:discardedForReinforcements']: null,
            ['t:discardedForReinforcements']: null,
          }),
          sekia.startTurnOrderStep()
        )
      );
    }
  },
  clearChosenCards(state) {
    return applyAction(
      state,
      fa.seq(
        sekia.moveAllCards('i:chosen', 'i:discard', {
          saveAs: 'i:discarded',
        }),
        sekia.moveAllCards('t:chosen', 't:discard', {
          saveAs: 't:discarded',
        })
      )
    );
  },
  reinforce(state, role, discardedCardsStashName) {
    const alreadyReinforced = getStateVar(
      state,
      qualifiedName(role, 'reinforced'),
      false
    );
    const cards = getStateStashedValue<Card[]>(state, discardedCardsStashName);
    const nCards = cards.length;

    return applyAction(
      state,
      alreadyReinforced
        ? nCards === 0
          ? fa.nop()
          : fa.seq(
              fa.text('reinforcementsStep/cardsDiscardedForReference', {
                r: { value: role },
                cc: { fromStash: discardedCardsStashName },
              })
            )
        : fa.seq(
            nCards === 0
              ? fa.text('reinforcementsStep/noCardsDiscarded', {
                  r: { value: role },
                })
              : fa.text('reinforcementsStep/cardsDiscarded', {
                  r: { value: role },
                  cc: { fromStash: discardedCardsStashName },
                }),
            sekia.drawReinforcementsCards(role),
            sekia.drawReinforcementsUnits(role),
            fa.setVars({
              [qualifiedName(role, 'reinforced')]: true,
            })
          )
    );
  },
  drawReinforcementsCards(state, role) {
    const varName = qualifiedName(role, 'willDrawCards');
    const amount = getStateNumberVar(state, varName);

    // XXX in a reshufle situation, cards in the temporary zone won't get
    // reshuffled, which is incorrect.  Best will be to check for this
    // situation and delay drawing only for this case.  Otherwise we won't have
    // the nice thing of immediately drawing, without waiting for the other
    // player.
    return applyAction(
      state,
      fa.seq(
        sekia.drawCardsReporting(role, amount),
        fa.setVars({
          [varName]: null,
        })
      )
    );
  },
  drawReinforcementsUnits(state, role) {
    const varName = qualifiedName(role, 'willDrawUnits');
    const amount = getStateNumberVar(state, varName);

    return applyAction(
      state,
      fa.seq(
        sekia.drawUnitsReporting(role, amount),
        fa.setVars({
          [varName]: null,
        })
      )
    );
  },
  // ** Turn order step
  startTurnOrderStep(state) {
    return applyAction(
      state,
      fa.seq(
        fa.text('turnOrderStep/start'),
        fa.setVars({
          step: 'turnOrder',
        })
      )
    );
  },
  proceedToChoosingFirstPlayerIfTurnOrderCardsAreReady(state) {
    const iCards = getCardsInPlayerZone(state, 'i', 'chosen');
    const tCards = getCardsInPlayerZone(state, 't', 'chosen');

    if (!(iCards.length === 1 && tCards.length === 1)) {
      return applyAction(state, fa.nop());
    } else {
      const iCardInfo = sekiGetCardInfoOfCard(iCards[0]);
      const tCardInfo = sekiGetCardInfoOfCard(tCards[0]);

      const iInitiative = iCardInfo.i;
      const tInitiative = tCardInfo.i;

      const chooser = iInitiative > tInitiative ? 'i' : 't';

      return applyAction(
        state,
        fa.seq(
          fa.changeCardFacing(iCards[0].sid, 'faceup', {
            saveAs: 'iCard',
          }),
          fa.changeCardFacing(tCards[0].sid, 'faceup', {
            saveAs: 'tCard',
          }),
          fa.text('turnOrderStep/cardsRevealed', {
            r1: { value: 'i' },
            r2: { value: 't' },
            c1: { fromStash: 'iCard' },
            c2: { fromStash: 'tCard' },
            n1: { value: iInitiative },
            n2: { value: tInitiative },
            r3: { value: chooser },
          }),
          fa.setVars({
            whoChoosesFirstPlayer: chooser,
          })
        )
      );
    }
  },
  // ** Movement phase
  startMovementPhase(state) {
    const phasingPlayer = sekiGetPhasingPlayer(state);
    return applyAction(
      state,
      fa.seq(
        fa.text('movementPhase/startPhase', {
          r: { value: phasingPlayer },
          // XXX this is misappropriation of the word mode, but it fits for
          // "translate this partial text in a game-specific way"
          mode1: { value: getStateVar(state, 'letter') },
          mode2: { value: getStateVar(state, 'phasingPlayerLabel') },
        }),
        sekia.clearAllCoveredConnections(),
        sekia.restoreAllTiredUnits(),
        fa.setVars({
          phase: 'movement',
          whoBuysMovement: phasingPlayer,
        })
      )
    );
  },
  onMovementsBought(state, role, mode) {
    const { nActivations, locationIds } = determineActivations(
      state,
      role,
      mode
    );
    if (nActivations === 0) {
      // We always have the faction leader alive, so even in the extremely
      // unlikely case everybody else died, he should be activatable.
      throw new ActionError(
        'At movement start, has zero activations but should have at least one'
      );
    }
    // This is used only for humans, so counting from 1.
    const activationNumber = 1;
    return applyAction(
      state,
      fa.seq(
        fa.setVars({
          nActivations,
          activationNumber,
          whoActivatesLocation: role,
          activatableLocationIds: locationIds,
        })
      )
    );
  },
  resolveMovement(state) {
    const movementState = getStateStashedValue(state, 'movementState') as any;
    const {
      role,
      movementTarget,
      droppingOffSids,
      usedBonuses,
      allUsedBonuses,
      committedDropOff,
    } = movementState;

    const movementMode = getStateStashedValue(state, 'movementMode') as string;

    const movingUnitSids = getStateStashedValue(
      state,
      'movingUnitSids'
    ) as string[];

    const overrunLocationIds = [
      ...movementTarget.passingLocations,
      {
        hasOverrun: movementTarget.hasOverrun,
        locationId: movementTarget.targetLocationId,
      },
    ]
      .filter((possibleOverrun) => {
        return possibleOverrun.hasOverrun;
      })
      .map((possibleOverrun) => possibleOverrun.locationId);

    const { sourceLocationId, targetLocationId } = movementTarget;

    const passingLocationIds = movementTarget.passingLocations.map(
      (pl) => pl.locationId
    );

    const activationNumber = getStateNumberVar(state, 'activationNumber');
    const nActivations = getStateNumberVar(state, 'nActivations');

    return applyAction(
      state,
      fa.seq(
        sekia.moveUnitsBySids(
          movingUnitSids,
          qualifiedName(role, targetLocationId),
          {
            saveAs: 'moved',
          }
        ),
        sekia.setUnitsEnteredFrom(
          movingUnitSids,
          sourceLocationId,
          passingLocationIds
        ),
        sekia.coverConnections(sourceLocationId, movementTarget),
        movementMode === 'start'
          ? fa.seq(
              fa.text('movementPhase/activationStarted', {
                n1: activationNumber,
                n2: nActivations,
                l: sourceLocationId,
              }),
              fa.text('movementPhase/startMovement', {
                r: role,
                l1: sourceLocationId,
                ll: {
                  value: movementTarget.passingLocations.map(
                    (pl) => pl.locationId
                  ),
                },
                l2: targetLocationId,
                cc: { fromStash: 'moved' },
                usedBonuses: { value: usedBonuses },
                cc1: { fromStash: 'fmPayment' },
              }),
              (function () {
                const leftBehindSids = removeAll(
                  getMovableUnitsInLocation(state, role, sourceLocationId).map(
                    (card) => card.sid
                  ),
                  movingUnitSids
                );

                return leftBehindSids.length === 0
                  ? fa.nop()
                  : fa.setStashedValues({
                      followOnStacks: [
                        ...getStateStashedValue(state, 'followOnStacks', []),
                        {
                          movementState: null,
                          locationId: sourceLocationId,
                          sids: leftBehindSids,
                        },
                      ],
                    });
              })()
            )
          : movementMode === 'followOn'
          ? fa.seq(
              fa.text('movementPhase/followOnMovement', {
                r: role,
                l1: sourceLocationId,
                ll: {
                  value: movementTarget.passingLocations.map(
                    (pl) => pl.locationId
                  ),
                },
                l2: targetLocationId,
                cc: { fromStash: 'moved' },
                usedBonuses: { value: usedBonuses },
                cc1: { fromStash: 'fmPayment' },
              }),
              (function () {
                const followOnStacks = getStateStashedValue<any[]>(
                  state,
                  'followOnStacks'
                );
                const stack = followOnStacks.find((stack) => {
                  return stack.locationId === sourceLocationId;
                });

                if (!stack) {
                  throw new ActionError(
                    'must have stack to resolve follow-on movement',
                    { sourceLocationId, followOnStacks }
                  );
                }
                const remainingSids = removeAll(stack.sids, movingUnitSids);

                const newFollowOnStacks =
                  remainingSids.length === 0
                    ? followOnStacks.filter(
                        (fos) => fos.locationId !== sourceLocationId
                      )
                    : produce(followOnStacks, (draft) => {
                        draft.find(
                          (stack) => stack.locationId === sourceLocationId
                        ).sids = remainingSids;
                      });
                return fa.seq(
                  fa.setStashedValues({
                    followOnStacks: newFollowOnStacks,
                  })
                );
              })()
            )
          : fa.seq(
              committedDropOff && committedDropOff.sids.length > 0
                ? fa.seq(
                    fa.text('movementPhase/dropOff', {
                      r: role,
                      cc: {
                        value: findCardsBySids(state, committedDropOff.sids),
                      },
                    }),
                    fa.setStashedValues({
                      followOnStacks: [
                        ...getStateStashedValue(state, 'followOnStacks', []),
                        {
                          movementState: committedDropOff.movementState,
                          sids: committedDropOff.sids,
                          locationId:
                            committedDropOff.movementState.movementTarget
                              .targetLocationId,
                        },
                      ],
                    })
                  )
                : fa.nop(),

              fa.text('movementPhase/continueMovement', {
                r: role,
                ll: {
                  value: movementTarget.passingLocations.map(
                    (pl) => pl.locationId
                  ),
                },
                l2: targetLocationId,
                usedBonuses: { value: usedBonuses },
                cc1: { fromStash: 'fmPayment' },
              })
            ),
        ...overrunLocationIds.map((locationId) => {
          return sekia.resolveOverrun(locationId, role);
        }),
        sekia.recalculateControl([sourceLocationId], { movingRole: role }),
        sekia.recalculateControl(passingLocationIds, {
          movingRole: role,
          passingRole: role,
        }),
        sekia.recalculateControl([targetLocationId], { movingRole: role }),
        droppingOffSids.length === movingUnitSids.length
          ? sekia.endOneMovement()
          : sekia.continueMovement()
      )
    );
  },
  continueMovement(
    state,
    followOnLocationId = null,
    movementMode = 'continue'
  ) {
    const movementState = getStateStashedValue(state, 'movementState') as any;
    const {
      role,
      movementTarget,
      droppingOffSids,
      usedBonuses,
      allUsedBonuses,
    } = movementState;

    const movementParameters = retrieveMovementParameters(state, role, {
      redetermineCanForceMarch: true,
    });

    const movingUnitSids = getStateStashedValue(
      state,
      'movingUnitSids'
    ) as string[];

    const newMovingUnitSids = removeAll(movingUnitSids, droppingOffSids);

    const targets = collectMovementTargets(
      state,
      role,
      movementMode === 'followOn'
        ? followOnLocationId
        : movementTarget.targetLocationId,
      newMovingUnitSids,
      movementParameters,
      movementMode,
      movementTarget,
      allUsedBonuses
    );

    return applyAction(
      state,
      fa.seq(
        fa.setStashedValues({
          movementParameters,
          movingUnitSids: newMovingUnitSids,
          movementTargets: targets,
          movementMode,
        }),
        fa.setVars({
          whoChoosesMovementTarget: role,
        })
      )
    );
  },
  endOneMovement(state) {
    const movementState = getStateStashedValue<any>(state, 'movementState');
    const { role } = movementState;
    const movingUnitSids = getStateStashedValue<string[]>(
      state,
      'movingUnitSids'
    );

    const followOnStacks = getStateStashedValue(state, 'followOnStacks', []);
    const movementParameters = retrieveMovementParameters(state, role, {
      redetermineCanForceMarch: true,
    });

    const [movableFollowOnStacks, removedStacks] = separate(
      followOnStacks,
      (stack) => {
        const { movementState, sids, locationId } = stack;

        if (sids.length === 0) {
          throw new ActionError(
            'got a stack from followOnStacks which is empty',
            {
              stack,
              followOnStacks,
            }
          );
        }
        const potentialTargets = collectMovementTargets(
          state,
          role,
          locationId,
          sids.slice(0, 1),
          movementParameters,
          'followOn',
          movementState ? movementState.movementTarget : null,
          movementState
            ? movementState.allUsedBonuses
            : DEFAULT_ALL_USED_BONUSES
        );
        const canMove = potentialTargets.length > 0;
        return canMove;
      }
    );

    return applyAction(
      state,
      fa.seq(
        fa.setStashedValues({
          fmPayment: null,
          movementState: null,
          movingUnitSids: null,
          movementTargets: null,
          movementMode: null,
        }),
        fa.setVars({
          whoChoosesMovementTarget: null,
        }),
        sekia.setUnitsHaveMoved(movingUnitSids),
        sekia.setUnitsInStacksTired(removedStacks),

        movableFollowOnStacks.length === 0
          ? sekia.endActivation()
          : fa.seq(
              fa.setVars({
                whoChoosesFollowOnMovement: role,
              }),
              fa.setStashedValues({
                followOnStacks: movableFollowOnStacks,
              })
            )
      )
    );
  },
  startFollowOnMovement(state, role, locationId, sids) {
    const followOnStacks = getStateStashedValue<any[]>(state, 'followOnStacks');
    const stack = followOnStacks.find((stack) => {
      return stack.locationId === locationId;
    });
    console.log('startFollowOnMovement', locationId, sids, stack);
    let { movementState } = stack;

    if (!movementState) {
      const usedBonuses = DEFAULT_ALL_USED_BONUSES;
      movementState = {
        role,
        movementTarget: null,
        droppingOffSids: [],
        usedBonuses,
        allUsedBonuses: usedBonuses,
      };
    }

    return applyAction(
      state,
      fa.seq(
        fa.setStashedValues({
          movingUnitSids: sids,
          movementState: {
            ...movementState,
            droppingOffSids: [],
          },
        }),
        sekia.continueMovement(stack.locationId, 'followOn')
      )
    );
  },
  endActivation(state) {
    const role = sekiGetPhasingPlayer(state);
    const activationNumber = getStateNumberVar(state, 'activationNumber');
    const nActivations = getStateNumberVar(state, 'nActivations');

    return applyAction(
      state,
      fa.seq(
        fa.setVars({
          activeLocationId: null,
          whoActivatesLocation: null,
        }),
        activationNumber === nActivations
          ? sekia.endMovementPhase()
          : (function () {
              const locationIds = collectActivatableLocationIds(state, role);
              if (locationIds.length === 0) {
                return fa.seq(
                  fa.text('movementPhase/noMoreActivationsPossible', {
                    r: role,
                  }),
                  sekia.endMovementPhase()
                );
              } else {
                const nextActivationNumber = activationNumber + 1;
                return fa.seq(
                  fa.setVars({
                    activationNumber: nextActivationNumber,
                    whoActivatesLocation: role,
                    activatableLocationIds: locationIds,
                  })
                );
              }
            })()
      )
    );
  },
  // ** helpers for movement phase
  resolveOverrun(state, locationId, activeRole) {
    const opponentRole = sekiGetOpponentRole(activeRole);

    const unitCards = getCardsInPlayerZone(state, opponentRole, locationId);

    return applyAction(
      state,
      fa.seq(
        sekia.destroyUnits(opponentRole, unitCards, { saveAs: 'dead' }),
        fa.text('movementPhase/overrun', {
          r: activeRole,
          l: locationId,
          cc: { fromStash: 'dead' },
        }),
        sekia.checkGameEndByMainUnitElimination(),
        // There is no 'overrun' combatType; for the purposes of awards, it
        // functions as 'battle'.
        sekia.awardCardsForLosses(opponentRole, unitCards, 'battle')
      )
    );
  },
  setUnitsHaveMoved(state, sids) {
    return applyAction(
      state,
      fa.seq(
        ...sids.map((sid) => {
          return fa.setCardVars(sid, {
            // TODO save whole route (not only enteredFrom) so that it can be
            // shown on hover
            hasMoved: true,
          });
        })
      )
    );
  },
  setUnitsEnteredFrom(state, sids, sourceLocationId, passingLocationIds) {
    const enteredFrom =
      passingLocationIds.length === 0
        ? sourceLocationId
        : passingLocationIds[passingLocationIds.length - 1];
    return applyAction(
      state,
      fa.seq(
        ...sids.map((sid) => {
          return fa.setCardVars(sid, {
            enteredFrom,
          });
        })
      )
    );
  },
  setUnitsInStacksTired(state, followOnStacks) {
    const allSids = [];
    followOnStacks.forEach((followOnStack) => {
      const { sids } = followOnStack;
      allSids.push(...sids);
    });

    return applyAction(state, sekia.setUnitsHaveMoved(allSids));
  },
  restoreAllTiredUnits(state) {
    const actions = walkCards(
      state,
      {
        roles: bothRoles,
        slugs: sekiLocationData.map((location) => location.locationId),
        skipAttachments: true,
      },
      (unitCard: CardInRegion, frameworkLocation, role) => {
        if (!unitCard.vars.hasMoved) {
          return null;
        }
        return fa.setCardVars(unitCard.sid, {
          hasMoved: false,
          enteredFrom: null,
        });
      }
    );
    return applyAction(state, fa.seq(...actions));
  },
  coverConnections(state, sourceLocationId, movementTarget) {
    const locationIds = [
      sourceLocationId,
      ...movementTarget.passingLocations.map((pl) => pl.locationId),
      movementTarget.targetLocationId,
    ];
    const connections = [];
    for (let i = 0; i < locationIds.length - 1; ++i) {
      const connection = sekiGetConnectionName(
        locationIds[i],
        locationIds[i + 1]
      );
      connections.push(connection);
    }
    const setters = {};
    connections.forEach((connection) => {
      setters[connection] = 1;
    });
    return applyAction(state, fa.setVars(setters));
  },
  clearAllCoveredConnections(state) {
    const setters = {};

    sekiConnections.forEach((connection) => {
      const { connectionName } = connection;

      if (!!getStateVar(state, connectionName, null)) {
        setters[connectionName] = null;
      }
    });

    return applyAction(state, fa.setVars(setters));
  },
  recalculateControl(
    state,
    affectedLocationIds,
    options = { movingRole: null, passingRole: null, isAfterCombat: false }
  ) {
    const actions = [];

    const ccChange = { i: 0, t: 0 };
    const rcChange = { i: 0, t: 0 };

    affectedLocationIds.forEach((locationId) => {
      const iUnitCards = getCardsInPlayerZone(state, 'i', locationId);
      const tUnitCards = getCardsInPlayerZone(state, 't', locationId);
      let nIshida = iUnitCards.length;
      let nTokugawa = tUnitCards.length;

      const location = sekiFindLocationById(locationId);

      if (location.castle) {
        const whoWas = getStateVar(
          state,
          qualifiedName('cc', locationId)
        ) as Role | null;

        const whoIs =
          nIshida > 0 && nTokugawa > 0
            ? whoWas
            : nIshida > 0
            ? 'i'
            : nTokugawa > 0
            ? 't'
            : location.castle;

        if (whoWas !== whoIs) {
          const action = fa.seq(
            options.movingRole === whoWas
              ? fa.text('cc/playerCedes', {
                  r1: whoWas,
                  l: locationId,
                  r2: whoIs,
                })
              : fa.text('cc/playerReclaims', {
                  r1: whoIs,
                  l: locationId,
                  r2: whoWas,
                }),
            fa.setVars({
              [qualifiedName('cc', locationId)]: whoIs,
            })
          );
          actions.push(action);
          ccChange[whoIs] += 1;
          ccChange[whoWas] -= 1;
        }
      }

      if (location.resource) {
        nIshida += options.passingRole === 'i' ? 1 : 0;
        nTokugawa += options.passingRole === 't' ? 1 : 0;

        const whoWas = getStateVar(
          state,
          qualifiedName('rc', locationId),
          null
        ) as Role | null;
        const whoIs =
          nIshida > 0 && nTokugawa > 0
            ? null
            : nIshida > 0
            ? 'i'
            : nTokugawa > 0
            ? 't'
            : whoWas;

        if (whoWas !== whoIs) {
          let action = null;
          if (whoWas === null) {
            action = fa.seq(
              fa.text('rc/playerClaims', {
                r1: whoIs,
                l: locationId,
              }),
              fa.setVars({
                [qualifiedName('rc', locationId)]: whoIs,
              })
            );
            rcChange[whoIs] += 1;
          } else if (whoIs === null) {
            if (options.isAfterCombat) {
              throw new ActionError(
                'after combat at resource location, both players have units present',
                {
                  locationId,
                  iUnitCards,
                  tUnitCards,
                }
              );
            } else {
              // don't do anything: it will become clear after combat
            }
          } else {
            action = fa.seq(
              fa.text('rc/playerClaimsFrom', {
                r1: whoIs,
                r2: whoWas,
                l: locationId,
              }),
              fa.setVars({
                [qualifiedName('rc', locationId)]: whoIs,
              })
            );
            rcChange[whoWas] -= 1;
            rcChange[whoIs] += 1;
          }

          if (action) {
            actions.push(action);
          }
        }
      }
    });

    bothRoles.forEach((role) => {
      if (ccChange[role] !== 0) {
        actions.push(
          fa.setVars({
            [qualifiedName(role, 'cas')]:
              (getStateVar(state, qualifiedName(role, 'cas')) as number) +
              ccChange[role],
          })
        );
      }

      if (rcChange[role] !== 0) {
        actions.push(
          fa.setVars({
            [qualifiedName(role, 'res')]:
              (getStateVar(state, qualifiedName(role, 'res')) as number) +
              rcChange[role],
          })
        );
      }
    });

    return applyAction(state, fa.seq(...actions));
  },
  endMovementPhase(state) {
    return applyAction(
      state,
      fa.seq(
        fa.setVars({
          nActivations: null,
          activatableLocationIds: null,
          activationNumber: null,
        }),
        sekia.startCombatPhase()
      )
    );
  },
  // ** Combat phase
  startCombatPhase(state) {
    const contestedLocationIds = sekiCollectContestedLocationIds(state);

    const phasingPlayer = sekiGetPhasingPlayer(state);

    return applyAction(
      state,
      fa.seq(
        fa.text('combatPhase/startPhase', {
          r: { value: phasingPlayer },
          mode1: { value: getStateVar(state, 'letter') },
          mode2: { value: getStateVar(state, 'phasingPlayerLabel') },
        }),
        fa.setVars({
          phase: 'combat',
        }),
        contestedLocationIds.length === 0
          ? fa.seq(
              fa.text('combatPhase/noContestedLocations'),
              sekia.proceedAfterCombatPhase()
            )
          : fa.seq(
              fa.text('combatPhase/someContestedLocations', {
                ll: { value: contestedLocationIds },
              }),
              fa.setVars({
                whoDeclaresCombat: phasingPlayer,
                contestedLocationIds,
                nCombats: contestedLocationIds.length,
                combatNumber: 1,
              })
            )
      )
    );
  },
  endOneCombat(state) {
    const combatLocationId = getStateVar(state, 'combatLocationId');
    const combatNumber = getStateNumberVar(state, 'combatNumber');

    return applyAction(
      state,
      fa.seq(
        fa.setVars({
          [qualifiedName('fought', combatLocationId)]: true,
          'combatLocationId': null,
          'attackerRole': null,
          'combatType': null,
          'impact:i': null,
          'impact:t': null,
          'deployedWithCard:i': null,
          'deployedWithCard:t': null,
          'doneDeploying:i': null,
          'doneDeploying:t': null,
        }),
        fa.setStashedValues({
          rcoState: null,
          remainingRolesToResolveLosses: null,
          combatOutcome: null,
        }),
        sekia.concealAllUnitsAtLocation(combatLocationId),
        sekia.checkCombatPhaseEnd(combatNumber + 1)
      )
    );
  },
  checkCombatPhaseEnd(state, newCombatNumber) {
    const nCombats = getStateNumberVar(state, 'nCombats');
    const phasingPlayer = sekiGetPhasingPlayer(state);

    const unpredictedCombat =
      getStateStashedValue<UnpredictedCombatConfiguration | null>(
        state,
        'unpredictedCombat',
        null
      );

    if (unpredictedCombat) {
      const { role, locationId } = unpredictedCombat;
      const opponentRole = sekiGetOpponentRole(role);

      return applyAction(
        state,
        fa.seq(
          fa.setVars({
            combatLocationId: locationId,
          }),
          fa.text('combatPhase/unpredictedCombatStarted', {
            r: role,
            l: locationId,
          }),
          fa.setStashedValues({
            unpredictedCombat: null,
          }),
          getIsPlayerWalled(state, opponentRole, locationId)
            ? fa.seq(
                fa.setVars({
                  whoChoosesCastleDefense: opponentRole,
                })
              )
            : fa.seq(sekia.startOneCombat('battle', role))
        )
      );
    } else if (newCombatNumber > nCombats) {
      return applyAction(state, sekia.endCombatPhase());
    } else {
      return applyAction(
        state,
        fa.seq(
          fa.setVars({
            whoDeclaresCombat: phasingPlayer,
            combatNumber: newCombatNumber,
          })
        )
      );
    }
  },
  endCombatPhase(state) {
    const clearFoughtVars = {};
    sekiLocationData
      .map((location) => location.locationId)
      .filter((locationId) => {
        return getStateVar(state, qualifiedName('fought', locationId), false);
      })
      .forEach((locationId) => {
        clearFoughtVars[qualifiedName('fought', locationId)] = null;
      });

    return applyAction(
      state,
      fa.seq(
        fa.setVars({
          contestedLocationIds: null,
          nCombats: null,
          combatNumber: null,
          ...clearFoughtVars,
        }),
        sekia.proceedAfterCombatPhase()
      )
    );
  },
  startOneCombat(state, combatType, attackerRole = null) {
    let role = attackerRole || sekiGetPhasingPlayer(state);
    const opponentRole = sekiGetOpponentRole(role);

    const locationId = getStateVar(state, 'combatLocationId');
    // TODO when osaka, bring all mori

    return applyAction(
      state,
      fa.seq(
        sekia.concealAllUnitsAtLocation(locationId),
        fa.setVars({
          'attackerRole': role,
          'impact:i': 0,
          'impact:t': 0,
          'deployedWithCard:i': false,
          'deployedWithCard:t': false,
          [qualifiedName('doneDeploying', role)]: false,
          [qualifiedName('doneDeploying', opponentRole)]:
            combatType === 'siege' ? true : false,
          'whoDeploysUnit': role,
          combatType,
        })
      )
    );
  },
  // deployingCards is an array of 0..1 elements.
  deployUnits(state, role, unitSids, deployingCards) {
    const unitInfos = unitSids.map((sid) => {
      return sekiGetUnitInfoBySid(sid);
    });
    const locationId = getStateVar(state, 'combatLocationId');
    const alreadyDeployedUnitCards = getCardsInPlayerZone(
      state,
      role,
      locationId
    ).filter((card) => (card as CardInRegion).facing === 'faceup');
    const alreadyDeployedUnitInfos = alreadyDeployedUnitCards.map(
      (unitCard) => {
        return sekiGetUnitInfoBySid(unitCard.sid);
      }
    );
    const deploymentMode =
      deployingCards.length > 0 ? 'withCard' : 'withoutCard';

    const impactCalculation = sekiCalculateAddedImpact(
      getStateVar(state, qualifiedName('impact', role)),
      alreadyDeployedUnitInfos,
      unitInfos,
      deploymentMode === 'withCard'
        ? getBaseFromSid(deployingCards[0].sid)
        : null,
      getStateVar(state, 'combatType')
    );

    return applyAction(
      state,
      fa.seq(
        ...unitSids.map((sid, index) => {
          return fa.seq(
            fa.setCardVars(sid, {
              deploymentNumber: alreadyDeployedUnitCards.length + index,
              addedImpact:
                index === unitSids.length - 1
                  ? impactCalculation.addedImpact
                  : 0,
            }),
            fa.changeCardFacing(sid, 'faceup')
          );
        }),
        fa.setVars({
          [qualifiedName('impact', role)]: impactCalculation.newImpact,
          whoDeploysUnit: null,
        }),
        deploymentMode === 'withCard'
          ? fa.setVars({
              [qualifiedName('deployedWithCard', role)]: true,
            })
          : fa.nop(),
        fa.updateStash('deployed', (state) => {
          return findCardsBySids(state, unitSids);
        }),
        fa.moveCards(deployingCards, qualifiedName(role, 'chosen'), {
          saveAs: 'deployingCards',
        }),
        fa.text('combatPhase/deployedUnits', {
          r: role,
          cc: { fromStash: 'deployed' },
          n1: impactCalculation.addedImpact,
          n2: impactCalculation.newImpact,
          n3: impactCalculation.baseBonus,
          n4: impactCalculation.clanBonus,
          n5: impactCalculation.specialAttackBonus,
          mode: impactCalculation.attackType,
          cc20: { fromStash: 'deployingCards' },
          mode20: deploymentMode,
        }),
        // TODO lc option if withCard and not siege
        sekia.proceedAfterDeployment()
      )
    );
  },
  concealAllUnitsAtLocation(state, locationId) {
    return applyAction(
      state,
      fa.seq(
        ...bothRoles.map((role) => {
          return sekia.concealPlayerUnitsAtLocation(role, locationId);
        })
      )
    );
  },
  concealPlayerUnitsAtLocation(state, role, locationId) {
    const unitCards = getCardsInPlayerZone(state, role, locationId).filter(
      (unitCard: CardInRegion) => {
        return unitCard.facing === 'faceup';
      }
    );

    return applyAction(
      state,
      fa.seq(
        ...unitCards.map((unitCard) => {
          return fa.seq(
            fa.changeCardFacing(unitCard.sid, 'facedown'),
            fa.setCardVars(unitCard.sid, {
              deploymentNumber: null,
              addedImpact: null,
              hasMoved: null,
              enteredFrom: null,
            })
          );
        }),
        unitCards.length > 0
          ? fa.shuffleZone(qualifiedName(role, locationId))
          : fa.nop()
      )
    );
  },
  proceedAfterDeployment(state) {
    const iDoneDeploying = getStateVar(state, 'doneDeploying:i');
    const tDoneDeploying = getStateVar(state, 'doneDeploying:t');

    let role;

    if (iDoneDeploying && tDoneDeploying) {
      return applyAction(state, fa.seq(sekia.startResolvingCombatOutcome()));
    } else if (!iDoneDeploying && tDoneDeploying) {
      role = 'i';
    } else if (iDoneDeploying && !tDoneDeploying) {
      role = 't';
    } else {
      const preliminaryOutcome = sekiGetCombatOutcome(state);
      const { whoIsLosing } = preliminaryOutcome;
      role = whoIsLosing;
    }

    const locationId = getStateVar(state, 'combatLocationId');
    const unitCards = collectUndeployedUnitCards(state, role, locationId);
    return applyAction(
      state,
      fa.seq(
        unitCards.length === 0
          ? sekia.resolveDoneDeployingUnits(role)
          : fa.setVars({
              whoDeploysUnit: role,
            })
      )
    );
  },
  resolveDoneDeployingUnits(state, role) {
    return applyAction(
      state,
      fa.seq(
        fa.setVars({
          [qualifiedName('doneDeploying', role)]: true,
        }),
        fa.text('combatPhase/playerIsDoneDeployingUnits', {
          r: role,
        }),
        sekia.proceedAfterDeployment(role)
      )
    );
  },
  startResolvingCombatOutcome(state) {
    const combatOutcome = sekiGetCombatOutcome(state);

    const { iImpact, tImpact, whoIsWinning, whoIsLosing } = combatOutcome;

    const attackerRole = sekiGetAttackerRole(state);

    const lossesOrder = [attackerRole, sekiGetOpponentRole(attackerRole)];
    const locationId = getStateVar(state, 'combatLocationId');

    const combatType = getStateVar(state, 'combatType');
    const rcoState: RCO[] = lossesOrder.map((role) => {
      const nLosses = sekiGetNLosses(
        role === 'i' ? tImpact : iImpact,
        whoIsLosing === role,
        combatType
      );
      const units = getCardsInPlayerZone(state, role, locationId);
      const actualNLosses = Math.min(units.length, nLosses);

      const nReplenishedCards =
        getNCardsInPlayerZone(state, role, 'chosen') +
        sekiGetNAwardsForLosses(nLosses, combatType);

      return {
        role,
        impact: role === 'i' ? iImpact : tImpact,
        actualNLosses,
        nReplenishedCards,
      };
    });

    return applyAction(
      state,
      fa.seq(
        combatType === 'battle'
          ? fa.text('combatPhase/battleOutcome', {
              ...(whoIsWinning === rcoState[0].role
                ? {
                    r1: rcoState[0].role,
                    n11: rcoState[0].impact,
                    n12: rcoState[0].actualNLosses,
                    r2: rcoState[1].role,
                    n21: rcoState[1].impact,
                    n22: rcoState[1].actualNLosses,
                  }
                : {
                    r1: rcoState[1].role,
                    n11: rcoState[1].impact,
                    n12: rcoState[1].actualNLosses,
                    r2: rcoState[0].role,
                    n21: rcoState[0].impact,
                    n22: rcoState[0].actualNLosses,
                  }),
            })
          : fa.text('combatPhase/siegeOutcome', {
              r1: rcoState[0].role,
              n11: rcoState[0].impact,
              r2: rcoState[1].role,
              n22: rcoState[1].actualNLosses,
            }),
        fa.setStashedValues({
          combatOutcome,
          rcoState,
          remainingRolesToResolveLosses: rcoState
            .filter((descriptor) => descriptor.actualNLosses > 0)
            .map((descriptor) => descriptor.role),
        }),
        sekia.resolveOnePlayerLosses()
      )
    );
  },
  resolveOnePlayerLosses(state) {
    const remainingRolesToResolveLosses = getStateStashedValue<string[]>(
      state,
      'remainingRolesToResolveLosses'
    );
    if (remainingRolesToResolveLosses.length === 0) {
      return applyAction(
        state,
        fa.seq(
          sekia.clearChosenCards(),
          sekia.checkGameEndByMainUnitElimination(),
          sekia.startResolvingRetreat()
        )
      );
    } else {
      const role = remainingRolesToResolveLosses[0];
      const rcoState = getStateStashedValue<RCO[]>(state, 'rcoState');
      const descriptor = rcoState.find((d) => d.role === role);
      const locationId = getStateVar(state, 'combatLocationId');

      const { actualNLosses } = descriptor;

      const groups = [
        {
          tag: 'defected',
          unitCards: getCardsInPlayerZone(
            state,
            sekiGetOpponentRole(role),
            locationId
          ).filter((unitCard) => unitCard.cardBack === `${role}b`),
        },
        {
          tag: 'deployed',
          unitCards: getCardsInPlayerZone(state, role, locationId).filter(
            (unitCard) => (unitCard as CardInRegion).facing === 'faceup'
          ),
        },
        (function () {
          const combatType = getStateVar(state, 'combatType');
          let moreUnits = [];
          if (
            combatType === 'siege' &&
            role === 'i' &&
            (locationId === 'osaka' || locationId === 'ueda')
          ) {
            moreUnits = getCardsInPlayerZone(state, role, `d_${locationId}`);
          }

          return {
            tag: 'waiting',
            unitCards: [
              ...getCardsInPlayerZone(state, role, locationId).filter(
                (unitCard) => (unitCard as CardInRegion).facing !== 'faceup'
              ),
              ...moreUnits,
            ],
          };
        })(),
      ];

      const mandatoryLossUnitCards = [];
      let remaining = actualNLosses;
      let selectLossesChoice = null;
      for (let i = 0; i < groups.length; ++i) {
        const group = groups[i];
        const { unitCards } = group;

        if (unitCards.length < remaining) {
          remaining -= unitCards.length;
          mandatoryLossUnitCards.push(...unitCards);
        } else if (unitCards.length === remaining) {
          remaining -= unitCards.length;
          mandatoryLossUnitCards.push(...unitCards);
          break;
        } else {
          selectLossesChoice = {
            mandatoryLossUnitCards,
            group,
            nLosses: remaining,
          };
          break;
        }
      }

      if (!selectLossesChoice) {
        return applyAction(
          state,
          fa.seq(
            sekia.returnDefectedUnits(role),
            sekia.resolveLosses(role, mandatoryLossUnitCards)
          )
        );
      } else {
        return applyAction(
          state,
          fa.seq(
            sekia.returnDefectedUnits(role),
            fa.setStashedValues({
              selectLossesChoice,
            }),
            fa.setVars({
              whoSelectsLosses: role,
            })
          )
        );
      }
    }
  },
  returnDefectedUnits(state, role) {
    const locationId = getStateVar(state, 'combatLocationId');
    const unitCards = getCardsInPlayerZone(
      state,
      sekiGetOpponentRole(role),
      locationId
    ).filter((unitCard) => unitCard.owner === role);
    return applyAction(
      state,
      fa.moveCards(unitCards, qualifiedName(role, locationId))
    );
  },
  startResolvingRetreat(state) {
    const combatType = getStateVar(state, 'combatType');
    if (combatType === 'siege') {
      return applyAction(state, fa.seq(sekia.proceedAfterRetreat()));
    }
    const combatOutcome = getStateStashedValue<CombatOutcome>(
      state,
      'combatOutcome'
    );
    const { whoIsLosing } = combatOutcome;

    const role = whoIsLosing;

    const unitCards = sekiGetRetreatingUnits(state, role);

    if (unitCards.length === 0) {
      return applyAction(state, sekia.proceedAfterRetreat());
    }

    const retreatConfiguration = sekiDetermineRetreatConfiguration(state, role);

    const { locationIds, isCastleRetreatPossible } = retreatConfiguration;

    return applyAction(
      state,
      fa.seq(
        fa.setStashedValues({
          retreatConfiguration,
        }),
        locationIds.length === 0 && isCastleRetreatPossible
          ? sekia.resolveRetreatToCastle(role)
          : locationIds.length === 1 && !isCastleRetreatPossible
          ? sekia.resolveRetreatToAdjacentLocation(
              role,
              unitCards,
              locationIds[0]
            )
          : fa.setVars({
              whoRetreatsUnits: role,
            })
      )
    );
  },
  // unitCards are those moved.  In the castle case, those staying are not
  // included here.  This means it's possible unitCards is actually empty.
  resolveRetreatToCastle(state, role) {
    const retreatConfiguration = getStateStashedValue<RetreatConfiguration>(
      state,
      'retreatConfiguration'
    );

    const { unitCards } = retreatConfiguration;

    const combatLocationId = getStateVar(state, 'combatLocationId');

    return applyAction(
      state,
      fa.seq(
        sekia.concealPlayerUnitsAtLocation(role, combatLocationId),
        fa.text('combatPhase/retreatedToCastle', {
          r: role,
          cc: { value: unitCards },
          l: combatLocationId,
        }),
        sekia.proceedAfterRetreat()
      )
    );
  },
  resolveRetreatToAdjacentLocation(
    state,
    role,
    retreatingUnitCards,
    locationId
  ) {
    const combatLocationId = getStateVar(state, 'combatLocationId');

    const retreatConfiguration = getStateStashedValue<RetreatConfiguration>(
      state,
      'retreatConfiguration'
    );

    const { unitCards: allUnitCards } = retreatConfiguration;

    const castleUnitCards = allUnitCards.filter((unitCard) => {
      return !retreatingUnitCards.find(
        (thatCard) => thatCard.sid === unitCard.sid
      );
    });

    const opponentRole = sekiGetOpponentRole(role);
    const supportingUnitCards = getCardsInPlayerZone(state, role, locationId);
    const opponentUnitCards = getCardsInPlayerZone(
      state,
      opponentRole,
      locationId
    );

    const relationship = getEnemyRelationshipOnMovingTo(
      state,
      role,
      locationId,
      retreatingUnitCards.length
    );
    const contestedLocationIds = getStateVar<string[]>(
      state,
      'contestedLocationIds'
    );
    const isScheduled = contestedLocationIds.includes(locationId);

    const shouldAddUnpredictedCombat =
      opponentUnitCards.length > 0 &&
      supportingUnitCards.length === 0 &&
      !getStateVar(state, qualifiedName('fought', locationId), false);

    return applyAction(
      state,
      fa.seq(
        sekia.concealPlayerUnitsAtLocation(role, combatLocationId),
        sekia.moveUnits(retreatingUnitCards, qualifiedName(role, locationId), {
          saveAs: 'retreated',
        }),
        sekia.setUnitsEnteredFrom(
          getSidsOfCards(retreatingUnitCards),
          combatLocationId,
          []
        ),
        castleUnitCards.length > 0
          ? fa.text('combatPhase/retreatedToCastle', {
              r: role,
              cc: { value: castleUnitCards },
              l: combatLocationId,
            })
          : fa.nop(),
        fa.text('combatPhase/retreatedToAdjacentLocation', {
          r: role,
          cc: { fromStash: 'retreated' },
          l: locationId,
        }),
        relationship === 'overrun'
          ? fa.seq(
              sekia.resolveOverrun(locationId, role),
              isScheduled
                ? fa.seq(
                    sekia.increaseCombatNumber(),
                    fa.setVars({
                      [qualifiedName('fought', locationId)]: true,
                    })
                  )
                : fa.nop()
            )
          : shouldAddUnpredictedCombat
          ? fa.seq(
              fa.setStashedValues({
                unpredictedCombat: {
                  locationId,
                  role,
                },
              })
            )
          : fa.nop(),
        sekia.proceedAfterRetreat()
      )
    );
  },
  increaseCombatNumber(state) {
    const combatNumber = getStateNumberVar(state, 'combatNumber');
    return applyAction(
      state,
      fa.setVars({
        combatNumber: combatNumber + 1,
      })
    );
  },
  proceedAfterRetreat(state) {
    const locationId = getStateVar(state, 'combatLocationId');

    return applyAction(
      state,
      fa.seq(
        fa.setStashedValues({
          retreatConfiguration: null,
        }),
        sekia.concealAllUnitsAtLocation(locationId),
        sekia.recalculateControl([locationId], { isAfterCombat: true }),
        sekia.replenishCombatCards(),
        sekia.endOneCombat()
      )
    );
  },
  replenishCombatCards(state) {
    const rcoState = getStateStashedValue<RCO[]>(state, 'rcoState');
    return applyAction(
      state,
      fa.seq(
        ...rcoState.map((descriptor) => {
          const { role, nReplenishedCards } = descriptor;
          if (nReplenishedCards === 0) {
            return fa.nop();
          }
          return fa.seq(sekia.drawCardsReporting(role, nReplenishedCards));
        })
      )
    );
  },
  resolveLosses(state, role, unitCards) {
    return applyAction(
      state,
      fa.seq(
        sekia.destroyUnits(role, unitCards, {
          saveAs: 'dead',
        }),
        fa.text('combatPhase/lossesApplied', {
          r: role,
          cc: { fromStash: 'dead' },
        }),
        // Technically toyhid too, but it doesn't matter how many cards you
        // replenish after you lose the game.  And even more technically,
        // toyhid should be auto-selected as the last unit lost.
        unitCards.find((unitCard) => getBaseFromSid(unitCard.sid) === 'sanmas')
          ? fa.setStashedValues({
              rcoState: produce(
                getStateStashedValue(state, 'rcoState'),
                (draft: RCO[]) => {
                  draft.find(
                    (descriptor) => descriptor.role === role
                  ).nReplenishedCards -= 1;
                }
              ),
            })
          : fa.nop(),
        fa.setStashedValues({
          remainingRolesToResolveLosses: getStateStashedValue<string[]>(
            state,
            'remainingRolesToResolveLosses'
          ).slice(1),
        }),
        sekia.resolveOnePlayerLosses()
      )
    );
  },
  proceedAfterCombatPhase(state) {
    const letter = getStateVar(state, 'letter');
    const phasingPlayerLabel = getStateVar(state, 'phasingPlayerLabel');

    let nextLetter, nextPlayer;
    if (phasingPlayerLabel === 'first') {
      return applyAction(
        state,
        fa.seq(
          fa.setVars({
            phasingPlayerLabel: 'second',
          }),
          sekia.startMovementPhase()
        )
      );
    } else if (letter === 'a') {
      return applyAction(
        state,
        fa.seq(
          fa.setVars({
            phasingPlayerLabel: 'first',
            letter: 'b',
          }),
          sekia.startMovementPhase()
        )
      );
    } else {
      return applyAction(
        state,
        fa.seq(
          fa.setVars({
            phasingPlayerLabel: null,
            letter: null,
          }),
          sekia.endWeek()
        )
      );
    }
  },
});

export const sekia = sekiGroup.actionCreators;

registerActionGroup(sekiGroup);
