import { createSlice, createSelector } from '@reduxjs/toolkit';

export const connectionStatusSlice = createSlice({
  name: 'connectionStatus',
  initialState: {
    connected: false,
  },
  reducers: {
    setConnected(state, action) {
      const value = action.payload;
      state.connected = value;
    },
  },
});

export const { setConnected } = connectionStatusSlice.actions;

const selectConnectionStatus = (state) => state.connectionStatus;

export const selectConnected = createSelector(
  selectConnectionStatus,
  (state) => state.connected
);
