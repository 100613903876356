import React, { useState } from 'react';
import { TopLevelPage } from 'components/TopLevelPage';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'slices/userSlice';
import { Redirect } from 'react-router-dom';
import { FormField } from 'components/FormField';
import { Well } from 'components/Well';
import { Loader } from 'components/Loader';

function FormStatus(props) {
  const { state } = props;

  if (state === 'start') {
    return null;
  }

  if (state === 'loading') {
    return <Loader />;
  }

  if (state === 'success') {
    return (
      <Well modifier="success">
        We've sent an email with instructions to reset your password to your
        address. Please check your email.
      </Well>
    );
  } else if (state === 'userNotFound') {
    return (
      <Well modifier="danger">
        User with this username or email is not found.
      </Well>
    );
  } else if (state === 'passwordRecoveryInProgress') {
    return (
      <Well modifier="danger">
        Password recovery is already in progress for this user. We didn't send
        an email another time in case it's not your email address. If you're
        sure it's your account, please contact the site author.
      </Well>
    );
  } else {
    return (
      <Well modifier="danger">
        There was an error connecting to the server. Please try again later.
      </Well>
    );
  }
}

export function RequestPasswordResetPage(props) {
  const [state, setState] = useState('start');
  const [usernameOrEmail, setUsernameOrEmail] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();

    if (state === 'loading') {
      return;
    }
    setState('loading');
    try {
      const response = await fetch('/request-password-reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          usernameOrEmail,
        }),
      });
      if (response.status === 200) {
        setState('success');
      } else if (response.status === 403) {
        const body = await response.json();
        setState(body.tag);
      } else {
        setState('serverError');
      }
    } catch (e) {
      setState('serverError');
    }
  }

  const isLoggedIn = useSelector(selectIsLoggedIn);
  if (isLoggedIn) {
    return <Redirect to="/lobby" />;
  }

  return (
    <TopLevelPage
      additionalClassName="RequestPasswordResetPage"
      title="Request password reset"
    >
      <h1>Request password reset</h1>
      <form onSubmit={handleSubmit}>
        <FormField id="usernameOrEmail" labelText="Username or email:">
          <input
            id="usernameOrEmail"
            value={usernameOrEmail}
            onChange={(e) => setUsernameOrEmail(e.target.value)}
            type="text"
            name="usernameOrEmail"
          />
        </FormField>
        <div className="buttons">
          <input
            type="submit"
            className="button isPrimary"
            disabled={state === 'loading'}
            value="Reset password"
          />
        </div>
        <FormStatus state={state} />
      </form>
    </TopLevelPage>
  );
}
