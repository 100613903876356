export const fakeNames = [
  'Aaron',
  'Amie',
  'Arnie',
  'Bill',
  'Brand',
  'Brooklyn',
  'Callahan',
  'Collyn',
  'Cuthbert',
  'Daphne',
  'Faith',
  'Fran',
  'Glenna',
  'Goddard',
  'Herbert',
  'Isabel',
  'Jace',
  'Janie',
  'John',
  'Kate',
  'Kim',
  'Leighton',
  'Mirabelle',
  'Noble',
  'Paige',
  'Rosy',
  'Rudie',
  'Shirley',
  'Simon',
  'Stella',
  'Steve',
  'Ulyssa',
  'Vivyan',
  'Zoey',
];
