import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import produce from 'immer';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { TopLevelPage } from 'components/TopLevelPage';
import { Loader } from 'components/Loader';
import { Well } from 'components/Well';
import { ListOfGameCards } from 'components/ListOfGameCards';
import { selectUserInitialized, selectIsLoggedIn } from 'slices/userSlice';
import { sendWsMessage } from 'connector';
import { GameCommentInput } from 'components/GameCommentInput';
import { GameSideSelectionInput } from 'components/GameSideSelectionInput';
import { clientServerCommonData } from '@cr/engine';
import { FormField } from 'components/FormField';
import { fakeNames } from 'data/fakeNames';
import { pickTwo } from 'utils';
import {
  selectCreateSandboxGameErrorText,
  clearCreateSandboxGameError,
  selectSandboxGames,
  setSandboxGames,
} from 'slices/sandboxSlice';

function Status(props) {
  const { state } = props;
  if (state === 'loading') {
    return <Loader />;
  } else if (state === 'error') {
    return (
      <Well modifier="danger">
        Unable to fetch sandbox games. Please try again later.
      </Well>
    );
  } else {
    return null;
  }
}

const { usernameMinLength, usernameMaxLength } =
  clientServerCommonData.validationConstants;

function PlayerNameInput(props) {
  const { labelText, name, playerName, setPlayerName, fieldsValidation } =
    props;

  return (
    <FormField
      id={name}
      labelText={labelText}
      fieldsValidation={fieldsValidation}
      disableShowingErrorList
    >
      <input
        autoComplete="off"
        type="text"
        name={name}
        minLength={usernameMinLength}
        maxLength={usernameMaxLength}
        value={playerName}
        onChange={(e) => {
          setPlayerName(e.target.value);
        }}
      />
    </FormField>
  );
}

function validatePlayerNames(playerNames) {
  let hasError = false;
  const fieldsValidation = {};

  playerNames.forEach((playerName, index) => {
    if (!(playerName.length >= usernameMinLength)) {
      fieldsValidation[`playerName_${index}`] = [
        {
          tag: 'playerNameTooShort',
          message: `Player name must have at least ${usernameMinLength} characters.`,
        },
      ];
      hasError = true;
    }
  });

  return {
    fieldsValidation,
    hasError,
  };
}

function CreateSandboxGameSection(props) {
  const isDisabled = false;
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [side, setSide] = useState('random');
  const code = 'seki';
  const [fieldsValidation, setFieldsValidation] = useState({});
  const [playerNames, setPlayerNames] = useState(pickTwo(fakeNames));
  const [hasError, setHasError] = useState(false);
  const dispatch = useDispatch();
  const errorText = useSelector(selectCreateSandboxGameErrorText);

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(clearCreateSandboxGameError());

    if (hasError) {
      return;
    }

    sendWsMessage({
      tag: 'createSandboxGame',
      code,
      comment,
      side,
      playerNames,
    });
    setIsLoading(true);
  }

  useEffect(() => {
    const validation = validatePlayerNames(playerNames);
    setFieldsValidation(validation.fieldsValidation);
    setHasError(validation.hasError);
  }, [playerNames]);

  useEffect(() => {
    if (errorText) {
      setIsLoading(false);
    }
  }, [errorText, setIsLoading]);

  return (
    <section>
      <form onSubmit={handleSubmit}>
        {playerNames.map((playerName, index) => {
          return (
            <PlayerNameInput
              name={`playerName_${index}`}
              key={index}
              labelText={`Player ${index + 1}`}
              playerName={playerName}
              setPlayerName={(value) => {
                setPlayerNames(
                  produce(playerNames, (draft) => {
                    draft[index] = value;
                  })
                );
              }}
              fieldsValidation={fieldsValidation}
            />
          );
        })}
        <GameCommentInput comment={comment} setComment={setComment} />
        <GameSideSelectionInput
          label="First player's side:"
          code={code}
          setSide={setSide}
          side={side}
        />

        <div className="buttons">
          <input
            type="submit"
            className={clsx('button isPrimary')}
            value="Create"
            disabled={isDisabled}
          />
          <Loader isActive={isLoading} isMarginless isCentered />
        </div>
        <div className="errors">
          {errorText && <Well modifier="danger">{errorText}</Well>}
        </div>
      </form>
    </section>
  );
}

export function SandboxPage() {
  const games = useSelector(selectSandboxGames);
  const dispatch = useDispatch();
  const [state, setState] = useState('success');

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userInitialized = useSelector(selectUserInitialized);

  async function fetchSandboxGames() {
    setState('loading');
    try {
      const response = await fetch('/sandbox-games');

      if (response.status === 200) {
        const responseGames = await response.json();
        dispatch(setSandboxGames({ games: responseGames }));
        setState('success');
      } else {
        setState('error');
      }
    } catch (e) {
      setState('error');
    }
  }

  useEffect(() => {
    if (isLoggedIn && !games) {
      fetchSandboxGames();
    }
  }, [isLoggedIn, games]);

  return (
    <TopLevelPage additionalClassName="SandboxPage" title="Sandbox">
      <h1>Sandbox</h1>

      <CreateSandboxGameSection />

      {userInitialized && !isLoggedIn ? (
        <>
          <Well modifier="info">
            Please <Link to="/login?after=/past-games">log in</Link> to view
            your sandbox games.
          </Well>
        </>
      ) : (
        <>
          <Status state={state} />
          <ListOfGameCards games={games} />
        </>
      )}
    </TopLevelPage>
  );
}
