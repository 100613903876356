import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { TopLevelPage } from 'components/TopLevelPage';
import { LinkSeparator } from 'components/LinkSeparator';
import { Loader } from 'components/Loader';
import { Well } from 'components/Well';
import { selectIsLoggedIn, logIn } from 'slices/userSlice';
import { FormField } from 'components/FormField';
import { getClientSocketId } from 'connector';

export function LoginPage(props) {
  const [state, setState] = useState('start');
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();

  if (isLoggedIn) {
    const after = new URLSearchParams(window.location.search).get('after');
    const target = after || '/lobby';
    return <Redirect to={target} />;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (state === 'loading') {
      return;
    }

    setState('loading');

    try {
      const response = await fetch('/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          usernameOrEmail,
          password,
          clientSocketId: getClientSocketId(),
        }),
      });
      if (response.status === 200) {
        setState('start');
        const user = await response.json();
        dispatch(logIn(user));
      } else {
        setState('error');
      }
    } catch (e) {
      setState('error');
    }
  }

  return (
    <TopLevelPage additionalClassName="LoginPage" title="Log in">
      <h1>Log in</h1>
      <form onSubmit={handleSubmit}>
        <FormField id="usernameOrEmail" labelText="Username or email:">
          <input
            id="usernameOrEmail"
            value={usernameOrEmail}
            onChange={(e) => setUsernameOrEmail(e.target.value)}
            type="text"
            name="usernameOrEmail"
          />
        </FormField>
        <FormField id="password" labelText="Password:">
          <input
            value={password}
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            name="password"
          />
        </FormField>
        <div className="buttons">
          <input
            type="submit"
            className="button isPrimary"
            disabled={state === 'loading'}
            value="Log in"
          />
        </div>
        {state === 'loading' ? (
          <Loader />
        ) : state === 'error' ? (
          <Well modifier="danger">Invalid username or password.</Well>
        ) : (
          <></>
        )}
        <p>
          <Link to="/request-password-reset">Request password reset</Link>
          <LinkSeparator />
          <Link to="/signup">Sign up</Link>
        </p>
      </form>
    </TopLevelPage>
  );
}
