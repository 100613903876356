import React, { useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { IoMdArrowDropdown, IoMdArrowDropright } from 'react-icons/io';
import { pluralize } from 'utils';
import { Avatar } from 'components/Avatar';
import { selectCurrentRoomUsernames } from 'slices/roomSlice';

export function PeopleOnline(props) {
  const { hideSmall } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const usernames = useSelector(selectCurrentRoomUsernames);

  function toggleIsExpanded() {
    setIsExpanded(!isExpanded);
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter' || e.key === ' ') {
      toggleIsExpanded();
    }
  }

  const className = clsx('PeopleOnline', {
    isExpanded,
    hideSmall,
  });
  return (
    <div className={className}>
      <div
        className="toggle"
        onClick={toggleIsExpanded}
        tabIndex={0}
        onKeyPress={handleKeyPress}
      >
        {isExpanded ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
        {pluralize(usernames.length, 'user', 'users')} here
        {1 <= usernames.length && usernames.length <= 5 && !isExpanded ? (
          <>: {usernames.join(', ')}</>
        ) : null}
      </div>
      <ul>
        {usernames.map((username) => {
          return (
            <li key={username}>
              <Avatar name={username} />
              {username}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
