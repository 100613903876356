import React, { useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { clientServerCommonData } from '@cr/engine';
import { TopLevelPage } from 'components/TopLevelPage';
import { FormField } from 'components/FormField';
import { Loader } from 'components/Loader';
import { Well } from 'components/Well';
import { ValidationErrorList } from 'components/ValidationErrorList';
import { logIn, selectUser } from 'slices/userSlice';
import { text } from 'texts';
import { history } from 'utils/history';
import { AllowGameEmailsField } from 'components/AllowGameEmailsField';
import { getClientSocketId } from 'connector';

function CompleteSignupStatus(props) {
  const { state, validationErrors } = props;
  if (state === 'start') {
    return null;
  } else if (state === 'loading') {
    return <Loader />;
  } else if (state === 'serverError') {
    return <Well modifier="danger">{text('serverError')}</Well>;
  } else {
    return (
      <Well modifier="danger">
        There were some errors with creating an account.
        <ValidationErrorList validationErrors={validationErrors} />
      </Well>
    );
  }
}

const NONE = 'NONE';

function getFieldsValidationData(validationErrors) {
  const result = { [NONE]: [] };
  if (!validationErrors) {
    return result;
  }
  for (const validationError of validationErrors) {
    const { field = NONE } = validationError;
    if (!result[field]) {
      result[field] = [];
    }
    result[field].push(validationError);
  }
  return result;
}

const { usernameMaxLength } = clientServerCommonData.validationConstants;

function CompleteSignupForm(props) {
  const [state, setState] = useState('start');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [validationErrors, setValidationErrors] = useState(null);
  const [allowGameEmails, setAllowGameEmails] = useState(false);
  const loggedInUser = useSelector(selectUser);

  const fieldsValidation = getFieldsValidationData(validationErrors);

  const { code } = useParams();

  const dispatch = useDispatch();
  if (loggedInUser) {
    return <Redirect to="/lobby" />;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (state === 'loading') {
      return;
    }

    setState('loading');

    try {
      const response = await fetch('/complete-signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code,
          email,
          username,
          password,
          repeatPassword,
          allowGameEmails,
          clientSocketId: getClientSocketId(),
        }),
      });
      if (response.status === 200) {
        const user = await response.json();
        batch(() => {
          dispatch(logIn(user));
          setState('success');
          setValidationErrors(null);
        });
        history.pushState('/lobby');
      } else if (response.status === 403) {
        const errors = await response.json();
        setValidationErrors(errors);
        setState('error');
      } else {
        setState('serverError');
        setValidationErrors(null);
      }
    } catch (e) {
      setState('serverError');
      setValidationErrors(null);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormField
        id="email"
        labelText="Email once again (for security):"
        fieldsValidation={fieldsValidation}
      >
        <input
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          name="email"
        />
      </FormField>
      <FormField
        id="username"
        labelText="Desired username:"
        fieldsValidation={fieldsValidation}
      >
        <input
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          type="text"
          name="username"
          maxLength={usernameMaxLength}
        />
      </FormField>
      <FormField
        id="password"
        labelText="Create password:"
        fieldsValidation={fieldsValidation}
      >
        <input
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          name="password"
        />
      </FormField>
      <FormField
        id="repeatPassword"
        labelText="Repeat password:"
        fieldsValidation={fieldsValidation}
      >
        <input
          id="repeatPassword"
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
          type="password"
          name="repeatPassword"
        />
      </FormField>
      <AllowGameEmailsField
        allowGameEmails={allowGameEmails}
        setAllowGameEmails={setAllowGameEmails}
      />
      <div className="buttons">
        <input
          type="submit"
          className="button isPrimary"
          disabled={state === 'loading'}
          value="Complete sign-up"
        />
      </div>
      <CompleteSignupStatus
        state={state}
        validationErrors={fieldsValidation[NONE]}
      />
    </form>
  );
}

export function CompleteSignupPage(props) {
  return (
    <TopLevelPage title="Complete signup">
      <h1>Complete signup</h1>
      <CompleteSignupForm />
    </TopLevelPage>
  );
}
