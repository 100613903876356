import React from 'react';
import { gameConfigByCode } from 'data/gameConfig';

export function GameSideSelectionInput(props) {
  const { label, code, side, setSide } = props;
  const gameConfig = gameConfigByCode[code];
  const { sideSelectionOptionChoices } = gameConfig;

  function handleChangeSide(e) {
    setSide(e.target.value);
  }

  return (
    <div className="FormField">
      <label className="FormLabel">{label}</label>
      <div className="FormValue">
        <select onChange={handleChangeSide} value={side}>
          <option value="random">Random</option>
          {sideSelectionOptionChoices.map((choice) => {
            const { value, selectionLabel } = choice;
            return (
              <option key={value} value={value}>
                {selectionLabel}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
