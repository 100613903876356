import React from 'react';
import { roles, getDefconVarName } from 'data/tdData';
import { Tracker } from 'components/PlayTdPage/Tracker';

export function DefconDiscs(props) {
  const { where = 'board' } = props;
  return (
    <>
      {roles.map((role) => {
        const offset = {
          x: role === 's' ? 0.05 : -0.05,
          y: role === 's' ? 0.2 : -0.2,
        };

        const imgSrc = `/td/markers/${role}-defcon.png`;
        return (
          <React.Fragment key={role}>
            {['m', 'p', 'w'].map((track) => {
              return (
                <Tracker
                  key={track}
                  imgSrc={imgSrc}
                  trackerKind="disc"
                  varName={getDefconVarName(role, track)}
                  markerPrefix={track}
                  offset={offset}
                  bigSizeProp={where}
                  rectProp={where === 'defconCloseup' ? 'closeupRect' : 'rect'}
                />
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
}
