// This is not strictly engine's field of responsibility, but we already
// include this module in both server and client, so to share some site-global
// data it makes sense to use it too.

export const clientServerCommonData = {
  validationConstants: {
    usernameMinLength: 3,
    usernameMaxLength: 20,
    maxCommentLength: 50,
  },
};
