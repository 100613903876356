/* eslint jsx-a11y/alt-text: 0 */

import React, {
  useLayoutEffect,
  useEffect,
  useState,
  useRef,
  useContext,
  useReducer,
  useCallback,
} from 'react';
import clsx from 'clsx';
import FocusTrap from 'focus-trap-react';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar } from 'components/Avatar';
import {
  IoMdMenu,
  IoMdExpand,
  IoMdContract,
  IoMdRadioButtonOff,
  IoMdRadioButtonOn,
  IoMdCheckmark,
  IoMdAlert,
  IoMdEyeOff,
  IoMdEye,
} from 'react-icons/io';
import { ChatComponent } from 'components/ChatComponent';
import { DismissButton } from 'components/DismissButton';
import { useCurrentRoomChat, usePageTitle } from 'hooks';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { Well } from 'components/Well';
import { Loader } from 'components/Loader';
import { MessageFromTemplate } from 'components/MessageFromTemplate';
import { GameUiContext } from 'components/PlayGamePage/GameUiContext';
import { PopupMenuContext } from 'components/PlayGamePage/PopupMenuContext';
import { CardCrop } from 'components/PlayGamePage/CardCrop';
import { GameMenuChoice } from 'components/GameMenuChoice';
import { createSelectGameByShortName } from 'slices/gamesSlice';
import { selectYourUsername, selectIsLoggedIn } from 'slices/userSlice';
import {
  createSelectGameUiCtxByShortName,
  takePov,
  createSelectNCheckpointsByShortName,
} from 'slices/runtimeStatesSlice';
import {
  selectCurrentRoomUsernames,
  selectCurrentRoomPersonalNotesContent,
  setCurrentRoomPersonalNotesContent,
} from 'slices/roomSlice';
import { getConnectionStatusText, getCodeFromShortName, numbers } from 'utils';
import { usePutAnswer, useSendAnswer } from 'utils/playGamePageUtils';
import { useTap } from 'utils/useTap';
import { history } from 'utils/history';

import produce from 'immer';
import { createPopper } from '@popperjs/core';
import { DefaultPage } from 'pages/DefaultPage';
import { getGdForCode } from 'data/gdRegistry';
import { sendWsMessage } from 'connector';
import { useIsUpToTablet } from 'hooks';

// -- utils

function getConnectionStatus(
  gameUiCtx,
  role,
  playerName,
  currentRoomUsernames
) {
  if (!gameUiCtx) {
    return null;
  }
  const { game, currentPov } = gameUiCtx;

  if (!game) {
    return null;
  }
  if (game.kind === 'sandbox') {
    return role === currentPov ? 'sgameCurrentPov' : 'sgameNonCurrentPov';
  } else {
    return currentRoomUsernames.find((username) => username === playerName)
      ? 'hgameOnline'
      : 'hgameOffline';
  }
}

// -- components

function ConnectionStatus(props) {
  const { connectionStatus } = props;
  const className = clsx('connectionStatus', { [connectionStatus]: true });
  return (
    <div className={className}>
      {connectionStatus === 'hgameOnline' ? (
        <IoMdRadioButtonOn />
      ) : connectionStatus === 'hgameOffline' ? (
        <IoMdRadioButtonOff />
      ) : connectionStatus === 'sgameCurrentPov' ? (
        <IoMdEye />
      ) : (
        <IoMdEyeOff />
      )}
    </div>
  );
}

function PlayerBadge(props) {
  const { role, playerName, connectionStatus } = props;

  const gameUiCtx = useContext(GameUiContext);
  const { gd, pgpDispatch, game } = gameUiCtx;

  const { shortName } = game;

  const { title, iconComponent, infoLinesComponent } = gd.getPlayerBadgeParts(
    gameUiCtx,
    playerName,
    connectionStatus
  );

  const className = clsx('PlayerBadge', { [role]: true });

  const dispatch = useDispatch();

  const isUpToTablet = useIsUpToTablet();

  const handleTap = useCallback(() => {
    if (isUpToTablet) {
      pgpDispatch({
        type: 'showModal',
        modalType: 'expandedPlayerBadge',
        playerName,
        role,
      });
    } else {
      if (game.kind !== 'sandbox') {
        return;
      }
      dispatch(takePov({ shortName, role }));
    }
  }, [isUpToTablet]);

  const tapHandlers = useTap(handleTap);

  return (
    <div className={className} title={title} {...tapHandlers}>
      <Avatar name={playerName} />
      {iconComponent}
      <div className="lines">
        <div className="playerName">
          <ConnectionStatus connectionStatus={connectionStatus} />
          {playerName}
        </div>
        {infoLinesComponent}
      </div>
    </div>
  );
}

function History(props) {
  const { chat, isHidden, isExpanded } = props;

  const { pgpDispatch } = useContext(GameUiContext);

  const isLoggedIn = useSelector(selectIsLoggedIn);

  function handleToggleClick(e) {
    e.preventDefault();
    pgpDispatch({ type: 'setHistoryExpanded', value: !isExpanded });
  }

  const icon = isExpanded ? <IoMdContract /> : <IoMdExpand />;

  const className = clsx('History', { isHidden, isExpanded });
  return (
    <div className={className}>
      <a
        href="#toggle"
        className={clsx('ToggleButton', { isLoggedIn })}
        onClick={handleToggleClick}
      >
        {icon}
      </a>
      <div className="ChatContainer">
        <ChatComponent chat={chat} isCondensed pgpDispatch={pgpDispatch} />
      </div>
    </div>
  );
}

function PopupMenu(props) {
  const { choices, renderItem, onChoiceSelected } = props;

  const popupMenuCtx = useContext(PopupMenuContext);

  const focusTrapOptions = {
    onDeactivate: function () {
      popupMenuCtx.close();
    },
    clickOutsideDeactivates: false,
    allowOutsideClick: function (event) {
      // Done this way instead of allowing the click so that the click
      // event only closes the menu, and doesn't go through and trigger
      // e.g. additional opening of the menu.

      if (event.type === 'touchstart') {
        event.preventDefault();
        popupMenuCtx.close();
      } else if (event.type === 'click') {
        popupMenuCtx.close();
      }
      return false;
    },
  };

  function handleKeyDown(event) {
    if (event.keyCode === 27) {
      event.preventDefault();
      popupMenuCtx.close();
    } else if (event.keyCode === 13) {
      event.preventDefault();
      popupMenuCtx.close();
      const index = event.currentTarget.dataset.index;
      onChoiceSelected(choices[index]);
    }
  }

  function handleClick(event) {
    const index = event.currentTarget.dataset.index;
    onChoiceSelected(choices[index]);
    popupMenuCtx.close();
  }

  return (
    <FocusTrap focusTrapOptions={focusTrapOptions}>
      <div className="PopupMenu">
        <ul>
          {choices.map((choice, index) => {
            return (
              <li key={choice.key} data-index={index} onClick={handleClick}>
                <div
                  className="focusable"
                  tabIndex={0}
                  onKeyDown={handleKeyDown}
                  data-index={index}
                >
                  {renderItem(choice)}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </FocusTrap>
  );
}

// Renders PopupMenu but knows how to render choices and handle their actions.
function SmartPopupMenu(props) {
  const popupMenuCtx = useContext(PopupMenuContext);
  const { data } = popupMenuCtx;

  const { gd, shortName, answer } = useContext(GameUiContext);
  const { popupMenuKind } = data;

  const putAnswer = usePutAnswer(shortName);

  function handleChoiceSelected(choice) {
    if (choice.answer) {
      putAnswer(choice.answer);
    } else if (choice.onSelected) {
      choice.onSelected(putAnswer, answer);
    } else {
      console.warn('Choice must have either answer or onSelected', choice);
    }
  }

  return (
    <PopupMenu
      choices={data.choices}
      renderItem={(choice) => {
        if (popupMenuKind === 'gameMenu') {
          return <GameMenuChoice choice={choice} />;
        } else {
          return gd.renderChoice(data, choice);
        }
      }}
      onChoiceSelected={handleChoiceSelected}
    />
  );
}

function DesktopCardPreview(props) {
  const { cardPreview } = props;

  const { gd } = useContext(GameUiContext);

  const { cursorOnCardCrop, card, showOnLeft } = cardPreview;

  const [hovered, setHovered] = useState(false);

  function handleHoverChanged(value) {
    setHovered(value);
  }

  const isActive = !!card && (hovered || cursorOnCardCrop);
  const className = clsx('DesktopCardPreview', { isActive, showOnLeft });

  //console.log('DesktopCardPreview', cardPreview, className);

  return (
    <div
      className={className}
      onMouseEnter={() => handleHoverChanged(true)}
      onMouseLeave={() => handleHoverChanged(false)}
    >
      {gd.renderBigCardImg(card, isActive)}
    </div>
  );
}

export function MobilePreview(props) {
  const { mobilePreview } = props;
  const gameUiCtx = useContext(GameUiContext);
  const { pgpDispatch, gd } = gameUiCtx;

  const { isOpen, previewType, card, actions = [] } = mobilePreview;

  function handleDismiss() {
    pgpDispatch({ type: 'closeMobilePreview' });
  }

  const tapHandlers = useTap(handleDismiss);

  return (
    <div
      className={clsx('MobilePreview', {
        isOpen,
      })}
    >
      <DismissButton onDismiss={handleDismiss} />
      <div className="MainContainer" {...tapHandlers}>
        <div className="More">
          {gd.renderBigCardImg(card, previewType === 'card')}
          {gd.renderNonStandardMobilePreviewContents(gameUiCtx, mobilePreview)}
        </div>
      </div>
      <div className="Buttons">
        {actions.map((action) => {
          const { key, isPrimary, label } = action;
          return (
            <Button
              isPrimary={isPrimary}
              key={key}
              onClick={() => {
                pgpDispatch({ type: 'closeMobilePreview' });
                action.onInvokeMobile();
              }}
            >
              {label}
            </Button>
          );
        })}
      </div>
    </div>
  );
}

function PlayerBadges(props) {
  const { currentRoomUsernames } = props;
  const gameUiCtx = useContext(GameUiContext);
  if (!gameUiCtx) {
    return null;
  }
  const { cs, gd } = gameUiCtx;
  const orderedRoles = gd.getOrderedRoles(cs);
  const roleToPlayerName = gd.getRoleToPlayerName(cs);
  return (
    <div className="badges">
      {orderedRoles.map((role) => {
        const playerName = roleToPlayerName[role];
        const connectionStatus = getConnectionStatus(
          gameUiCtx,
          role,
          playerName,
          currentRoomUsernames
        );
        return (
          <PlayerBadge
            key={role}
            role={role}
            playerName={playerName}
            connectionStatus={connectionStatus}
          />
        );
      })}
    </div>
  );
}

function GameMenuButton(props) {
  const { to } = props;
  const popupMenuCtx = useContext(PopupMenuContext);
  const ref = useRef(null);
  const { yourRole, pgpDispatch, game } = useContext(GameUiContext);

  const yourUsername = useSelector(selectYourUsername);

  const actions = [];

  const { shortName, kind, ownerUsername } = game;

  const nCheckpoints = useSelector(
    createSelectNCheckpointsByShortName(shortName)
  );

  if (kind === 'sandbox' && ownerUsername === yourUsername) {
    actions.push(
      {
        key: 'saveCheckpoint',
        label: 'Save checkpoint',
        onSelected() {
          sendWsMessage({
            tag: 'sandboxSaveCheckpoint',
            shortName,
          });
        },
      },
      ...numbers(nCheckpoints).map((checkpointIndex) => {
        return {
          key: `restoreCheckpoint_${checkpointIndex}`,
          label: `Restore checkpoint ${checkpointIndex + 1}`,
          onSelected() {
            sendWsMessage({
              tag: 'sandboxRestoreCheckpoint',
              shortName,
              checkpointIndex,
            });
          },
        };
      })
    );
  }

  if (yourRole !== '*observer*') {
    actions.push({
      key: 'showPersonalNotesModal',
      onSelected() {
        pgpDispatch({
          type: 'showModal',
          modalType: 'personalNotesModal',
        });
      },
      label: 'Personal notes',
    });
  }

  actions.push({
    key: 'navigateToSite',
    onSelected() {
      history.push(to);
    },
    label: 'Return to site',
  });

  function handleClick(e) {
    popupMenuCtx.open(ref.current, {
      popupMenuKind: 'gameMenu',
      choices: actions,
    });
  }

  return (
    <Button
      ref={ref}
      className="GameMenuButton"
      isSecondary
      onClick={handleClick}
    >
      <IoMdMenu />
    </Button>
  );
}

function ActionLine(props) {
  const gameUiCtx = useContext(GameUiContext);
  const { shortName, cs, gd, pgpDispatch, yourRole, status } = gameUiCtx;

  const to = `/game/${shortName}`;

  const roleToPlayerName = gd.getRoleToPlayerName(cs);

  let parts = {
    actionContent: null,
    controls: null,
    confirm: null,
  };

  const sendAnswer = useSendAnswer(yourRole, shortName);

  const dispatch = useDispatch();

  if (status === 'voided') {
    parts.actionContent = 'The game is voided due to a period of inactivity.';
  } else if (!!cs['outcome']) {
    const outcome = cs['outcome'];
    const [template, args] = gd.getOutcomeTranslation(outcome, gameUiCtx);
    parts.actionContent = (
      <MessageFromTemplate
        code={gd.code}
        template={template}
        args={args}
        roleToPlayerName={roleToPlayerName}
        pgpDispatch={pgpDispatch}
      />
    );
  } else {
    parts = gd.getActionLineParts(gameUiCtx, dispatch, sendAnswer);
  }

  const { actionContent, controls, confirm, isActionExpected } = parts;
  return (
    <div className={clsx('actionLine', { isActionExpected })}>
      <div className="rest">
        <div className="action">{actionContent}</div>
        <div className="controls">
          {controls}
          {confirm}
        </div>
      </div>
      <GameMenuButton to={to} />
    </div>
  );
}

function ExpandedPileModalContent(props) {
  const { modalState } = props;

  const gameUiCtx = useContext(GameUiContext);
  const { cs, gd, pgpDispatch } = gameUiCtx;
  const roleToPlayerName = gd.getRoleToPlayerName(cs);

  const parts = gd.getExpandedPileModalContentParts(gameUiCtx, modalState);

  if (!parts) {
    return null;
  }
  const { headerText, explanation, cards } = parts;
  return (
    <div className="ExpandedPileModalContent">
      <h2>{headerText}</h2>
      {explanation && (
        <div className="explanationText">
          <MessageFromTemplate
            code={gd.code}
            template={explanation.template}
            args={explanation.args}
            roleToPlayerName={roleToPlayerName}
            pgpDispatch={pgpDispatch}
          />
        </div>
      )}
      <div className="cards">
        {cards.map((card, index) => {
          const cardActions = [];
          return (
            <CardCrop
              card={card}
              key={gd.getKeyOfCard(card)}
              cardActions={cardActions}
              index={index}
            />
          );
        })}
      </div>
    </div>
  );
}

function PlayGameModal(props) {
  const { pgpState } = props;

  const { modals } = pgpState;

  const isShown = modals.length > 0;

  const modalState = isShown ? modals[modals.length - 1] : null;

  const { pgpDispatch } = useContext(GameUiContext);

  function handleDismiss() {
    pgpDispatch({ type: 'hideModal' });
  }

  return (
    <Modal isActive={isShown} onDismiss={handleDismiss} look="pgp" isFullHeight>
      <ModalContent modalState={modalState} />
    </Modal>
  );
}

function ModalContent(props) {
  const { modalState } = props;

  const gameUiCtx = useContext(GameUiContext);
  const { gd } = gameUiCtx;
  if (modalState === null) {
    return null;
  }

  const { modalType } = modalState;
  if (modalType === 'expandedPile') {
    return <ExpandedPileModalContent modalState={modalState} />;
  } else if (modalType === 'expandedPlayerBadge') {
    return <ExpandedPlayerBadgeModalContent modalState={modalState} />;
  } else if (modalType === 'personalNotesModal') {
    return <PersonalNotesModalContent modalState={modalState} />;
  } else {
    return gd.renderModalContent(modalState, gameUiCtx);
  }
}

function ExpandedPlayerBadgeModalContent(props) {
  const { modalState } = props;

  const { playerName, role } = modalState;

  const gameUiCtx = useContext(GameUiContext);
  const { gd, pgpDispatch, game } = gameUiCtx;

  const currentRoomUsernames = useSelector(selectCurrentRoomUsernames);

  const connectionStatus = getConnectionStatus(
    gameUiCtx,
    role,
    playerName,
    currentRoomUsernames
  );

  const { info } = gd.getPlayerBadgeParts(
    gameUiCtx,
    playerName,
    connectionStatus
  );

  const dispatch = useDispatch();

  return (
    <div className="ExpandedPlayerBadgeModalContent">
      <div className="header">
        <Avatar name={playerName} />
        <h2>{playerName}</h2>
      </div>
      <div>
        <ConnectionStatus connectionStatus={connectionStatus} />
        {getConnectionStatusText(connectionStatus)}
        {game.kind === 'sandbox' ? (
          <Button
            isSecondary
            onClick={() => {
              const { shortName } = game;
              dispatch(takePov({ shortName, role }));
            }}
            isDisabled={connectionStatus === 'sgameCurrentPov'}
          >
            Take pov
          </Button>
        ) : null}
      </div>
      {gd.renderExpandedPlayerBadgeContent(info, pgpDispatch)}
    </div>
  );
}

function PersonalNotesModalContent(props) {
  const { shortName } = useContext(GameUiContext);

  const personalNotesContent = useSelector(
    selectCurrentRoomPersonalNotesContent
  );

  const initialContent = personalNotesContent;
  const [content, setContent] = useState(initialContent);
  const dispatch = useDispatch();

  const [state, setState] = useState('start');

  async function handleSubmit(e) {
    e.preventDefault();

    if (state === 'loading') {
      return;
    }

    // Even if an error happens, don't lose it for the current session.
    dispatch(setCurrentRoomPersonalNotesContent({ content }));
    setState('loading');

    try {
      const response = await fetch('/set-personal-notes-content', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content,
          shortName,
        }),
      });
      if (response.status === 200) {
        setState('success');
      } else {
        setState('error');
      }
    } catch (e) {
      setState('error');
    }
  }
  return (
    <div className="PersonalNotesModalContent">
      <form onSubmit={handleSubmit}>
        <h2>Personal notes</h2>
        <textarea
          maxLength={2048}
          value={content || ''}
          onChange={(e) => setContent(e.target.value)}
        />
        <div className="bottom">
          <Button isPrimary onClick={handleSubmit}>
            Save
          </Button>
          {state === 'loading' ? (
            <Loader isMarginless isCentered />
          ) : state === 'success' ? (
            <div className="success">
              <IoMdCheckmark /> Saved
            </div>
          ) : state === 'error' ? (
            <div className="failure">
              <IoMdAlert /> Failed to save notes, please try again later.
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
}

const pgpReducer = produce((ps, action) => {
  console.debug('pgp reducer', action);
  switch (action.type) {
    case 'setHistoryExpanded': {
      const { value } = action;
      ps.historyExpanded = value;
      break;
    }
    case 'setCursorOnCardCrop': {
      const { card, value, isLeftSide } = action;
      ps.cardPreview = {
        ...ps.cardPreview,
        card: value ? card : null,
        cursorOnCardCrop: value,
        showOnLeft: !isLeftSide,
      };
      break;
    }
    case 'toggleCursorOnCardCrop': {
      const { card } = action;
      // TODO getKeyOfCard?
      const now =
        ps.cardPreview.cursorOnCardCrop && card === ps.cardPreview.card;

      const value = !now;
      ps.cardPreview.card = value ? card : null;
      ps.cardPreview.cursorOnCardCrop = value;
      break;
    }
    case 'openMobilePreview': {
      const { previewType, card, actions = [] } = action;
      // TODO for Android back button, might want to import { history } from
      // 'utils/history'; history.push(`#${cid}`) and pop history on
      // dismissing mobile preview; and dismiss mobile preview on popping
      // history.  And show this (but not push to history) when page refreshed.
      ps.mobilePreview = {
        ...ps.mobilePreview,
        isOpen: true,
        previewType,
        card,
        actions,
      };
      break;
    }
    case 'closeMobilePreview': {
      ps.mobilePreview.isOpen = false;
      ps.mobilePreview.card = null;
      break;
    }
    case 'showModal': {
      const { modalType, ...restOfModalState } = action;
      const modalState = {
        // Usually I dislike spreading, let's see whether it presents a problem
        // after a while.  The alternative is nesting modalState which is a
        // different inelegant can of worms.
        modalType,
        ...restOfModalState,
      };
      let skip = false;
      let replace = false;
      if (ps.modals.length > 0) {
        const topModal = ps.modals[ps.modals.length - 1];
        const { modalType: topModalType, ...topModalRest } = topModal;
        if (topModalType === modalType) {
          const restMatches = Object.entries(topModalRest).every((entry) => {
            const [k, v] = entry;
            return restOfModalState[k] === v;
          });
          if (restMatches) {
            skip = true;
          } else {
            replace = true;
          }
        }
      }
      if (skip) {
        // do nothing
      } else if (replace) {
        ps.modals[ps.modals.length - 1] = modalState;
      } else {
        ps.modals.push(modalState);
      }
      break;
    }
    case 'hideModal': {
      ps.modals.pop();
      break;
    }
    default: {
      return ps;
    }
  }
});

function PopperContent(props) {
  const { target } = props;
  const ref = useRef(null);

  const popperRef = useRef(null);

  useLayoutEffect(() => {
    popperRef.current = createPopper(target, ref.current);
    return () => {
      if (!popperRef.current) {
        console.warn('Trying to destroy already-destroyed popper instance');
        return;
      }
      popperRef.current.destroy();
      popperRef.current = null;
    };
  }, [target]);

  return (
    <div className="PopperContent" ref={ref}>
      <SmartPopupMenu />
    </div>
  );
}

function PopperWrapper(props) {
  const popupMenuCtx = useContext(PopupMenuContext);
  if (!popupMenuCtx.isOpen) {
    return null;
  }
  return (
    <PopperContent target={popupMenuCtx.target} close={popupMenuCtx.close} />
  );
}

const initialPgpState = {
  historyExpanded: false,
  cardPreview: {
    card: null,
    cursorOnCardCrop: false,
  },
  modals: [],
  mobilePreview: {
    isOpen: false,
    previewType: null,
    card: null,
    actions: [],
  },
};

export function CoveringPanel(props) {
  const { isActive, children } = props;
  return <div className={clsx('CoveringPanel', { isActive })}>{children}</div>;
}

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function LoadedPgp(props) {
  const { shortName, setHideNavbarOnSmallScreen, game, gameUiCtx, pgpState } =
    props;

  const { code } = game;

  const { gd, yourRole } = gameUiCtx;

  const verb = yourRole !== '*observer*' ? 'Play' : 'Watch';
  usePageTitle(`${verb} ${shortName}`);

  //console.debug('cs', cs);
  const chat = useCurrentRoomChat({
    mergeWithGameLog: shortName,
  });

  const dispatch = useDispatch();
  const previousGuc = usePrevious(gameUiCtx);
  useEffect(() => {
    if (!previousGuc || gameUiCtx.cs !== previousGuc.cs) {
      gd.reactToClientStateChange(gameUiCtx, dispatch);
    }
  }, [gd, gameUiCtx, dispatch, previousGuc]);

  const currentRoomUsernames = useSelector(selectCurrentRoomUsernames);

  useLayoutEffect(() => {
    setHideNavbarOnSmallScreen(true);
    return () => {
      setHideNavbarOnSmallScreen(false);
    };
  }, [setHideNavbarOnSmallScreen]);

  const [popupMenuCtx, setPopupMenuCtx] = useState({
    isOpen: false,
    target: null,
    open: function (target, data) {
      setPopupMenuCtx({
        ...popupMenuCtx,
        isOpen: true,
        target,
        data,
      });
    },
    close: function () {
      setPopupMenuCtx({
        ...popupMenuCtx,
        isOpen: false,
      });
    },
  });

  const mainRef = useRef(null);

  useEffect(() => {
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;

    if (
      isFirefox &&
      isAndroid &&
      mainRef.current &&
      mainRef.current.requestFullscreen &&
      code === 'seki'
    ) {
      mainRef.current.requestFullscreen();
      return () => {
        document.exitFullscreen && document.exitFullscreen();
      };
    }
  }, [game, code]);

  // Note: for mobile, using two History components, one expanded and one
  // not, to maintain a separate scroll position in each one (the expand
  // button is hidden on non-mobiles, so this affects only mobile; we do keep
  // two components even on larger devices).

  // Note: not reusing the TopLevelPage because of special handling of padding
  // (actionLine should take full screen width on mobile).
  return (
    <div className={clsx('PlayGamePage', game.code)} ref={mainRef}>
      <GameUiContext.Provider value={gameUiCtx}>
        <PopupMenuContext.Provider value={popupMenuCtx}>
          <div className="container">
            {/* Cannot use Modal here because due to position: fixed, on
              Firefox on Android there is a white stripe at the bottom that
              covers the input's text.*/}
            <CoveringPanel isActive={pgpState.historyExpanded}>
              <History
                isExpanded
                isHidden={!pgpState.historyExpanded}
                chat={chat}
              />
            </CoveringPanel>
            <PlayGameModal pgpState={pgpState} />
            <div className="partition1">
              <ActionLine />
              {gd.renderPrimaryPartition()}
            </div>
            <div className="partition2">
              <PlayerBadges currentRoomUsernames={currentRoomUsernames} />
              {/* hiding needed only for firefox devtools because it shows
                    scroller above the form input which is ridiculous */}
              <History isHidden={pgpState.historyExpanded} chat={chat} />
            </div>
          </div>

          <DesktopCardPreview cardPreview={pgpState.cardPreview} />
          <MobilePreview mobilePreview={pgpState.mobilePreview} />
          <PopperWrapper />
        </PopupMenuContext.Provider>
      </GameUiContext.Provider>
    </div>
  );
}

export function PlayGamePage(props) {
  const { shortName, setHideNavbarOnSmallScreen } = props;
  const game = useSelector(createSelectGameByShortName(shortName));

  const [pgpState, pgpDispatch] = useReducer(pgpReducer, initialPgpState);

  const code = getCodeFromShortName(shortName);
  const gd = getGdForCode(code);

  const gameUiCtx = useSelector(
    createSelectGameUiCtxByShortName(shortName, gd, pgpState, pgpDispatch)
  );

  if (game === undefined) {
    return (
      <div className="PlayGamePage">
        <Loader />
        <Well modifier="info">Loading game data...</Well>
      </div>
    );
  } else if (game === null) {
    return <DefaultPage />;
  } else if (game.status === 'deleted') {
    return (
      <div className="PlayGamePage">
        <Well modifier="danger">
          This game was deleted from another connection.
        </Well>
      </div>
    );
  } else if (!gameUiCtx) {
    return (
      <div className="PlayGamePage">
        <Loader />
        <Well modifier="info">Loading runtime state...</Well>
      </div>
    );
  }

  return (
    <LoadedPgp
      shortName={shortName}
      setHideNavbarOnSmallScreen={setHideNavbarOnSmallScreen}
      game={game}
      gameUiCtx={gameUiCtx}
      pgpState={pgpState}
      pgpDispatch={pgpDispatch}
    />
  );
}
