import React, { useState } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { Navbar } from 'components/Navbar';
import { ChatPage } from 'pages/ChatPage';
import { GameDetailsPage } from 'pages/GameDetailsPage';
import { IntroPage } from 'pages/IntroPage';
import { InfoPage } from 'pages/InfoPage';
import { LobbyPage } from 'pages/LobbyPage';
import { LoginPage } from 'pages/LoginPage';
import { StartSignupPage } from 'pages/StartSignupPage';
import { CompleteSignupPage } from 'pages/CompleteSignupPage';
import { PlayGamePage } from 'pages/PlayGamePage';
import { DefaultPage } from 'pages/DefaultPage';
import { AccountPage } from 'pages/AccountPage';
import { PastGamesPage } from 'pages/PastGamesPage';
import { SandboxPage } from 'pages/SandboxPage';
import { StatsPage } from 'pages/StatsPage';
import { useCanUseSeki } from 'utils';
import { RequestPasswordResetPage } from 'pages/RequestPasswordResetPage';
import { CompletePasswordResetPage } from 'pages/CompletePasswordResetPage';
import { history } from 'utils/history';

// Need to import for imperative `registerGd`.
import 'pages/PlayTdPage';
import 'pages/PlaySekiPage';

// We allow `/play/123` and `/game/123` URLs for backwards compatibility with
// times when td was the only game.  But now the URL pattern is `/play/td-123`,
// because each game has its own counter.
export function withGameFinder(actionSlug, fn) {
  return (routeProps) => {
    const { match } = routeProps;
    const { gameParam } = match.params;
    const regexp = new RegExp('^(.+?)-(\\d+)$');
    const matches = gameParam.match(regexp);
    if (gameParam.match(new RegExp('^(\\d+)$'))) {
      // It's ok to hard-code td here because it was the only one for the time
      // when gid URL patterns were allowed.
      return <Redirect to={`/${actionSlug}/td-${gameParam}`} />;
    } else if (matches) {
      const shortName = gameParam;
      return fn(shortName);
    } else {
      return <DefaultPage />;
    }
  };
}

function App(props) {
  const [hideNavbarOnSmallScreen, setHideNavbarOnSmallScreen] = useState(false);
  const canUseSeki = useCanUseSeki();

  return (
    <>
      <Navbar hideSmall={hideNavbarOnSmallScreen} />
      <Switch>
        <Route path="/" exact>
          <IntroPage />
        </Route>
        <Route path="/info" exact>
          <InfoPage />
        </Route>
        <Route path="/chat">
          <ChatPage setHideNavbarOnSmallScreen={setHideNavbarOnSmallScreen} />
        </Route>
        <Route path="/lobby">
          <LobbyPage />
        </Route>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/signup">
          <StartSignupPage />
        </Route>
        <Route path="/complete-signup/:code">
          <CompleteSignupPage />
        </Route>
        <Route
          path="/game/:gameParam"
          render={withGameFinder('game', (shortName) => {
            return (
              <GameDetailsPage
                shortName={shortName}
                setHideNavbarOnSmallScreen={setHideNavbarOnSmallScreen}
              />
            );
          })}
        />
        <Route
          path="/play/:gameParam"
          render={withGameFinder('play', (shortName) => {
            const [code, _number] = shortName.split('-');

            if (code === 'td') {
              return (
                <PlayGamePage
                  shortName={shortName}
                  setHideNavbarOnSmallScreen={setHideNavbarOnSmallScreen}
                />
              );
            } else if (code === 'seki') {
              if (!canUseSeki) {
                return <DefaultPage />;
              }
              return (
                <PlayGamePage
                  shortName={shortName}
                  setHideNavbarOnSmallScreen={setHideNavbarOnSmallScreen}
                />
              );
            } else {
              return <DefaultPage />;
            }
          })}
        />
        <Route path="/account">
          <AccountPage />
        </Route>
        <Route path="/request-password-reset">
          <RequestPasswordResetPage />
        </Route>
        <Route path="/complete-password-reset/:code">
          <CompletePasswordResetPage />
        </Route>
        <Route path="/past-games">
          <PastGamesPage />
        </Route>
        <Route path="/sandbox">
          <SandboxPage />
        </Route>
        <Route path="/stats">
          <StatsPage />
        </Route>
        <Route>
          <DefaultPage />
        </Route>
      </Switch>
    </>
  );
}

const WrappedInRouter = (props) => {
  return (
    <Router history={history}>
      <App />
    </Router>
  );
};

export default WrappedInRouter;
