import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useDebouncedCallback } from 'use-debounce';
import { clientServerCommonData } from '@cr/engine';
import { Modal } from 'components/Modal';
import { sendWsMessage } from 'connector';
import { Loader } from 'components/Loader';
import { FormField } from 'components/FormField';
import { GameCommentInput } from 'components/GameCommentInput';
import { GameSideSelectionInput } from 'components/GameSideSelectionInput';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectCreateGameErrorText,
  clearCreateGameErrorText,
} from 'slices/lobbySlice';
import { Well } from 'components/Well';
import { gameConfigByCode } from 'data/gameConfig';

function Radio(props) {
  const { autoFocus, currentValue, name, value, onChange, children } = props;

  return (
    <label className="radio">
      <input
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        checked={value === currentValue}
      />
      {children}
    </label>
  );
}

const { usernameMaxLength, usernameMinLength, maxCommentLength } =
  clientServerCommonData.validationConstants;

const INVITEE_VALIDATION_ERRORS = [
  {
    tag: 'inviteeDoesNotExist',
    message: 'There is no user by this username.',
  },
];

function Contents(props) {
  const { code, handleCancel, isLoading, setIsLoading } = props;

  const [kind, setKind] = useState('async');
  const [comment, setComment] = useState('');
  const [invitee, setInvitee] = useState('');

  const [side, setSide] = useState('random');

  const [checkInviteeState, setCheckInviteeState] = useState(null);

  const errorText = useSelector(selectCreateGameErrorText);

  const gameConfig = gameConfigByCode[code];

  useEffect(() => {
    if (errorText) {
      setIsLoading(false);
    }
  }, [errorText, setIsLoading]);

  function setInviteeWithConsequences(value) {
    setInvitee(value);
    setIsLoading(true);
    checkInviteeExists();
  }

  const [checkInviteeExists] = useDebouncedCallback(async () => {
    if (invitee.length === 0) {
      setCheckInviteeState(null);
      setIsLoading(false);
    } else if (invitee.length === 1) {
      // There are too many results; don't even try.
    } else {
      try {
        const response = await fetch('/check-invitee', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            invitee,
          }),
        });
        const bodyJson = await response.json();

        if (response.status === 200) {
          setCheckInviteeState({
            outcome: bodyJson.found ? 'success' : 'error',
            usernames: bodyJson.usernames,
            nUsernames: bodyJson.nUsernames,
            errors: bodyJson.found ? null : INVITEE_VALIDATION_ERRORS,
          });
        } else {
          setCheckInviteeState({
            outcome: 'error',
            errors: INVITEE_VALIDATION_ERRORS,
          });
        }
        setIsLoading(false);
      } catch (e) {
        // Note: strictly speaking, the internal error is not validation
        // errors, and this could be handled better.
        setCheckInviteeState({
          outcome: 'error',
          errors: INVITEE_VALIDATION_ERRORS,
        });
        setIsLoading(false);
      }
    }
  }, 200);

  const { displayName, sideSelectionOptionChoices } = gameConfig;

  function handleSubmit(e) {
    e.preventDefault();
    sendWsMessage({ tag: 'createGame', code, kind, comment, side, invitee });
    setIsLoading(true);
  }

  function handleChangeKind(e) {
    setKind(e.target.value);
  }

  const isDisabled =
    isLoading || (checkInviteeState && checkInviteeState.outcome === 'error');

  return (
    <>
      <h2>New {displayName} game</h2>
      <form onSubmit={handleSubmit}>
        <div className="FormField">
          <label className="FormLabel">Kind: </label>
          <div className="FormValue">
            <Radio
              autoFocus
              currentValue={kind}
              name="kind"
              value="async"
              onChange={handleChangeKind}
            >
              Async
            </Radio>
            <Radio
              name="kind"
              value="live"
              currentValue={kind}
              onChange={handleChangeKind}
            >
              Live
            </Radio>
          </div>
        </div>
        <div className="inviteeContainer">
          <FormField
            id="invitee"
            labelText="Reserve place for:"
            isSuccess={
              checkInviteeState && checkInviteeState.outcome === 'success'
            }
            fieldsValidation={
              checkInviteeState && checkInviteeState.outcome === 'error'
                ? { invitee: checkInviteeState.errors }
                : null
            }
            disableShowingErrorList
          >
            <input
              autoComplete="off"
              type="text"
              name="invitee"
              maxLength={usernameMaxLength}
              value={invitee}
              onChange={(e) => {
                setInviteeWithConsequences(e.target.value);
              }}
            />
          </FormField>
          <ul className="selectionList">
            {!isLoading &&
            checkInviteeState &&
            checkInviteeState.usernames &&
            !(
              checkInviteeState.usernames.length === 1 &&
              checkInviteeState.usernames[0] === invitee
            ) ? (
              <>
                {checkInviteeState.usernames.map((username) => {
                  const isCurrent = invitee === username;
                  return (
                    <li
                      key={username}
                      className={clsx('choice', {
                        isCurrent,
                      })}
                      onClick={() => {
                        if (isCurrent) {
                          return;
                        }
                        setInviteeWithConsequences(username);
                      }}
                    >
                      {username}
                    </li>
                  );
                })}
                {checkInviteeState.usernames.length <
                checkInviteeState.nUsernames ? (
                  <li>
                    (and{' '}
                    {checkInviteeState.nUsernames -
                      checkInviteeState.usernames.length}{' '}
                    more)
                  </li>
                ) : null}
              </>
            ) : null}
          </ul>
        </div>
        <GameCommentInput comment={comment} setComment={setComment} />
        <GameSideSelectionInput
          label="Your side:"
          code={code}
          setSide={setSide}
          side={side}
        />
        <div className="errors">
          {errorText && <Well modifier="danger">{errorText}</Well>}
        </div>
        <div className="buttons">
          <input
            type="submit"
            className={clsx('button isPrimary')}
            value="Create"
            disabled={isDisabled}
          />
          <button className="button isSecondary" onClick={handleCancel}>
            Cancel
          </button>
          <Loader isActive={isLoading} />
        </div>
      </form>
    </>
  );
}

export function CreateGameModal(props) {
  const { isActive, onDismiss, code } = props;
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  function handleCancel() {
    if (isLoading) {
      setIsLoading(false);
    }
    dispatch(clearCreateGameErrorText());
    onDismiss();
  }

  return (
    <Modal
      className="CreateGameModal"
      isActive={isActive}
      onDismiss={handleCancel}
      look="window"
    >
      <Contents
        code={code}
        handleCancel={handleCancel}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </Modal>
  );
}
