import { AAResult, ActionApplicatorsMapObject } from './types';
import { mapValues } from './utils';
import { ActionError, EError } from './errors';

export function createActionGroup(
  code: string,
  actionApplicators: ActionApplicatorsMapObject
) {
  const actionCreators = mapValues(
    actionApplicators,
    (_applicator, actionType) => {
      return (...args: any[]) => ({
        code,
        actionType,
        args,
      });
    }
  );
  return {
    code,
    actionCreators,
    actionApplicators,
  };
}

const groupByCode = {};

export function registerActionGroup(group) {
  groupByCode[group.code] = group;
}

const ACTIONS_DEBUG = false;

export function applyAction(state, action, ...otherArgs): AAResult {
  if (otherArgs.length > 0) {
    throw new EError(
      'calling applyAction with extra args, must have only 2',
      otherArgs
    );
  }
  if (!!state.outcome) {
    throw new EError('cannot applyAction on a finished state', {
      action,
      outcome: state.outcome,
    });
  }
  if (ACTIONS_DEBUG) {
    console.debug('applyAction', action);
  }
  const { actionType } = action;

  const group = groupByCode[action.code];

  if (!group) {
    console.warn('Unrecognized actionType of action', action);
    return { next: state, delta: null };
  }

  const applicator = group.actionApplicators[actionType];
  try {
    const aaResult = applicator(state, ...action.args);
    if (!(aaResult.next && aaResult.delta)) {
      console.error(
        'Error while applying action: missing next or delta',
        action
      );
    }
    if (ACTIONS_DEBUG) {
      console.debug(
        'applied action:',
        action,
        'delta:',
        aaResult.delta,
        'stash:',
        aaResult.next.stash
      );
    }
    return aaResult;
  } catch (e) {
    if (e instanceof ActionError) {
      throw new EError(e.message, action);
    } else {
      throw e;
    }
  }
}
