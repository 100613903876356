import React from 'react';
import clsx from 'clsx';

export const Button = React.forwardRef((props, ref) => {
  let {
    onClick,
    children,
    isDisabled,
    isPrimary,
    isSecondary,
    isDanger,
    isPrimaryOutline,
    isDangerOutline,
    className,
    isPressed,
    style,
    hasPositionAbsolute,
    title,
  } = props;

  if (
    !(
      className ||
      isPrimary ||
      isSecondary ||
      isDanger ||
      isPrimaryOutline ||
      isDangerOutline
    )
  ) {
    isSecondary = true;
  }

  const mergedClassName = clsx(
    'button',
    {
      isDisabled,
      isPrimary,
      isSecondary,
      isDanger,
      isPrimaryOutline,
      isDangerOutline,
      isPressed,
      hasPositionAbsolute,
    },
    className
  );
  return (
    <button
      ref={ref}
      className={mergedClassName}
      onClick={(e) => {
        if (onClick && !isDisabled) {
          onClick(e);
        }
      }}
      style={style}
      title={title}
    >
      {children}
    </button>
  );
});
