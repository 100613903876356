const registry = {};

export function registerGd(code, gd) {
  registry[code] = gd;
}

export function getGdForCode(code) {
  const gd = registry[code];
  if (!gd) {
    console.error('Unrecognized code', code);
  }

  return gd;
}
