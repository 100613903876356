import produce, { current, isDraft } from 'immer';
import {
  Card,
  CardLocation,
  CardFacing,
  CardRotation,
  VarValues,
  VarChangers,
  ZoneName,
  ZoneAccess,
  Role,
  VState,
  VCard,
  VZone,
  Zone,
  Delta,
  VDelta,
  DeltaCreatorsMapObject,
  Outcome,
} from './types';
import {
  indentedList,
  buildZoneName,
  getZoneName,
  getZoneYourAccess,
  getTranslatedCidFromKnowledge,
} from './utils';
import {
  getZoneAccessRep,
  getZoneRepModifiers,
  getCardLocationRep,
  getCardRepModifiers,
  getSettersRep,
  getChangersRep,
} from './rep';
import { cardToVCard, historyEntryToVHistoryEntry } from './view';

export const dc: DeltaCreatorsMapObject<Delta> = {
  empty() {
    return {
      deltaType: 'empty',
      toView(_pov) {
        return {};
      },
    };
  },
  gameEnded(outcome: Outcome) {
    return {
      deltaType: 'gameEnded',
      outcome,
      toView(_pov) {
        return {
          outcome,
        };
      },
    };
  },
  playerAdded(playerName, role) {
    return {
      deltaType: 'playerAdded',
      playerName,
      role,
      toView(_pov) {
        return {
          playerName,
          role,
        };
      },
    };
  },
  zoneCreated(zoneKind, owner, slug, access) {
    return {
      deltaType: 'zoneCreated',
      zoneKind,
      owner,
      slug,
      access,
      toView(pov) {
        const yourAccess = getZoneYourAccess(access, pov);
        return {
          zoneKind,
          owner,
          slug,
          access,
          yourAccess,
        };
      },
    };
  },
  // Interface of delta creator is not like action creator: we require array
  // instead of the rest arg; because we're the user of delta factory, but
  // action seq is outside code, and we make it nicer for them.
  seq(childDeltas) {
    return {
      deltaType: 'seq',
      childDeltas,
      toView(pov) {
        const { childDeltas } = this;

        if (childDeltas.length === 0) {
          return {
            _replace: {
              deltaType: 'empty',
            },
          };
        } else if (childDeltas.length === 1) {
          const vChildDelta = deltaToVDelta(childDeltas[0], pov);
          return {
            _replace: vChildDelta,
          };
        } else {
          let vChildDeltas = [];

          childDeltas.forEach((childDelta) => {
            const vChildDelta = deltaToVDelta(childDelta, pov);
            if (vChildDelta.deltaType === 'seq') {
              vChildDeltas.push(...vChildDelta.childDeltas);
            } else {
              vChildDeltas.push(vChildDelta);
            }
          });
          return {
            childDeltas: vChildDeltas,
          };
        }
      },
    };
  },
  cardCreated(card: Card, location: CardLocation) {
    return {
      deltaType: 'cardCreated',
      card,
      location,
      toView(pov) {
        return {
          location,
          card: cardToVCard(card, pov),
        };
      },
    };
  },
  cardChangedFacing(card: Card, facing: CardFacing, location: CardLocation) {
    return {
      deltaType: 'cardChangedFacing',
      card,
      facing,
      location,
      toView(pov) {
        return {
          card: cardToVCard(card, pov),
          facing,
          location,
        };
      },
    };
  },
  cardMoved(
    card: Card,
    sourceLocation: CardLocation,
    targetLocation: CardLocation
  ) {
    return {
      deltaType: 'cardMoved',
      card,
      sourceLocation,
      targetLocation,
      toView(pov) {
        return {
          card: cardToVCard(card, pov),
          sourceLocation,
          targetLocation,
        };
      },
    };
  },
  cardChangedRotation(
    card: Card,
    rotation: CardRotation,
    location: CardLocation
  ) {
    return {
      deltaType: 'cardChangedRotation',
      card,
      rotation,
      location,
      toView(pov) {
        return {
          card: cardToVCard(card, pov),
          rotation,
          location,
        };
      },
    };
  },
  cardVarsSet(card: Card, location: CardLocation, setters: VarValues) {
    return {
      deltaType: 'cardVarsSet',
      card,
      location,
      setters,
      toView(pov) {
        return {
          card: cardToVCard(card, pov),
          location,
          setters,
        };
      },
    };
  },
  cardVarsChanged(card: Card, location: CardLocation, changers: VarChangers) {
    return {
      deltaType: 'cardVarsChanged',
      card,
      location,
      changers,
      toView(pov) {
        return {
          card: cardToVCard(card, pov),
          location,
          changers,
        };
      },
    };
  },
  stateVarsSet(setters: VarValues) {
    return {
      deltaType: 'stateVarsSet',
      setters,
      toView(_pov) {
        return {
          setters,
        };
      },
    };
  },
  stateVarsChanged(changers: VarChangers, newValues: VarValues) {
    return {
      deltaType: 'stateVarsChanged',
      changers,
      newValues,
      toView(pov) {
        return {
          changers,
          newValues,
        };
      },
    };
  },
  zoneAccessSet(
    zoneName: ZoneName,
    zoneKind,
    access: ZoneAccess,
    cards: Card[],
    oldAccess: ZoneAccess
  ) {
    return {
      deltaType: 'zoneAccessSet',
      zoneName,
      zoneKind,
      access,
      cards,
      oldAccess,
      toView(pov) {
        const yourAccess = getZoneYourAccess(access, pov);
        const oldYourAccess = getZoneYourAccess(oldAccess, pov);

        const includeCids =
          oldYourAccess !== 'accessible' && yourAccess === 'accessible';

        const shouldTranslateCards =
          zoneKind === 'region' || yourAccess === 'accessible';

        return {
          zoneName,
          access,
          cards: shouldTranslateCards
            ? cards.map((card) => cardToVCard(card, pov))
            : null,
          oldAccess,
          yourAccess,
          includeCids,
        };
      },
    };
  },
  zoneShuffled(zone: Zone) {
    return {
      deltaType: 'zoneShuffled',
      zone,
      toView(pov) {
        const { zoneKind, access, cards } = zone;
        const zoneName = getZoneName(zone);
        const yourAccess = getZoneYourAccess(access, pov);
        const shouldTranslateCards =
          zoneKind === 'region' || yourAccess === 'accessible';

        return {
          zoneName,
          cards: shouldTranslateCards
            ? cards.map((card) => cardToVCard(card, pov))
            : null,
        };
      },
    };
  },
  historyEntryAdded(historyEntry) {
    return {
      deltaType: 'historyEntryAdded',
      historyEntry,
      toView(pov) {
        return {
          historyEntry: historyEntryToVHistoryEntry(historyEntry, pov),
        };
      },
    };
  },
};

export function deltaToVDelta(delta: Delta, pov: Role): VDelta | null {
  const view = delta.toView(pov);

  if (view._replace) {
    return view._replace;
  } else {
    return {
      deltaType: delta.deltaType,
      ...view,
    };
  }
}

export function deltaToRep(delta: Delta, pov: Role): string {
  const vDelta = deltaToVDelta(delta, pov);
  return vDeltaToRep(vDelta);
}

export function addVCardToLocation(
  vs: VState,
  card: VCard,
  location: CardLocation
): VState {
  const { zoneName, index, attachmentIndex } = location;

  const zone = vs.zones[zoneName];
  const zoneHasCards = zone.cards !== null;

  const newZone = produce(zone, (draftZone) => {
    if (attachmentIndex !== undefined) {
      draftZone.cards[index].attachedCards.splice(attachmentIndex, 0, card);
    } else {
      draftZone.nCards += 1;
      if (zoneHasCards) {
        draftZone.cards.splice(index, 0, card);
        draftZone.topCardBack = draftZone.cards[0].cardBack;
      }
    }
  });
  return produce(vs, (draft) => {
    draft.zones[zoneName] = newZone;
  });
}

export function removeVCardAtLocation(
  vs: VState,
  location: CardLocation
): VState {
  const { zoneName, index, attachmentIndex } = location;

  const zone = vs.zones[zoneName];
  const zoneHasCards = zone.cards !== null;

  const newZone = produce(zone, (draftZone) => {
    if (attachmentIndex !== undefined) {
      draftZone.cards[index].attachedCards.splice(attachmentIndex, 1);
    } else {
      if (zoneHasCards) {
        draftZone.cards.splice(index, 1);
      }
      draftZone.nCards -= 1;

      draftZone.topCardBack =
        draftZone.nCards === 0
          ? null
          : zoneHasCards
          ? draftZone.cards[0].cardBack
          : draftZone.topCardBack;
    }
  });
  return produce(vs, (draft) => {
    draft.zones[zoneName] = newZone;
  });
}

export function replaceVCardAtLocation(
  vs: VState,
  card: VCard,
  location: CardLocation
): VState {
  const { zoneName, index, attachmentIndex } = location;
  const zone = vs.zones[zoneName];
  const zoneHasCards = zone.cards !== null;

  return produce(vs, (draft) => {
    if (zoneHasCards) {
      if (attachmentIndex !== undefined) {
        draft.zones[zoneName].cards[index].attachedCards[attachmentIndex] =
          card;
      } else {
        draft.zones[zoneName].cards[index] = card;
      }
    }
  });
}

export function applyVDelta(vs: VState, delta: VDelta): VState {
  const { deltaType } = delta;
  switch (deltaType) {
    case 'empty': {
      return vs;
    }
    case 'gameEnded': {
      const { outcome } = delta;

      return produce(vs, (draft) => {
        draft.outcome = outcome;
      });
    }
    case 'playerAdded': {
      const { playerName, role } = delta;
      return produce(vs, (draft) => {
        draft.roleToPlayerName[role] = playerName;
        draft.roles.push(role);
      });
    }
    case 'zoneCreated': {
      const { zoneKind, owner, slug, access, yourAccess } = delta;
      const zone: VZone = {
        zoneKind,
        owner,
        slug,
        access,
        yourAccess,
        cards: [],
        nCards: 0,
        topCardBack: null,
      };
      const zoneName = buildZoneName(owner, slug);
      return produce(vs, (draft) => {
        draft.zones[zoneName] = zone;
      });
    }
    case 'seq': {
      const { childDeltas } = delta;
      // XXX I have no idea why this works, or whether it indeed does.  This is
      // introduced to work around the issue whereby when a setCardVars was
      // used standalone, it was ok, but when inside a seq, it crashed because
      // after replaceVCardAtLocation, the zone was [proxy, card, proxy]
      // instead of [card, card, card].
      let curVs = isDraft(vs) ? current(vs) : vs;
      childDeltas.forEach((delta) => {
        curVs = applyVDelta(curVs, delta);
      });
      return curVs;
    }
    case 'cardCreated': {
      const { card, location } = delta;
      return addVCardToLocation(vs, card, location);
    }
    case 'cardChangedFacing': {
      const { card, facing, location } = delta;
      const result = replaceVCardAtLocation(vs, card, location);
      return result;
    }
    case 'cardMoved': {
      const { card, sourceLocation, targetLocation } = delta;

      let current = removeVCardAtLocation(vs, sourceLocation);
      current = addVCardToLocation(current, card, targetLocation);
      return current;
    }
    case 'cardChangedRotation': {
      const { card, location } = delta;
      return replaceVCardAtLocation(vs, card, location);
    }
    case 'cardVarsSet': {
      const { card, location } = delta;
      return replaceVCardAtLocation(vs, card, location);
    }
    case 'cardVarsChanged': {
      const { card, location } = delta;
      return replaceVCardAtLocation(vs, card, location);
    }
    case 'stateVarsSet': {
      const { setters } = delta;
      return produce(vs, (draft) => {
        Object.entries(setters as VarValues).forEach(([k, v]) => {
          draft.vars[k] = v;
        });
      });
    }
    case 'stateVarsChanged': {
      const { newValues } = delta;
      const newVars = produce(vs.vars, (draftVars) => {
        Object.entries(newValues as VarValues).forEach(([k, v]) => {
          draftVars[k] = v;
        });
      });
      return produce(vs, (draft) => {
        draft.vars = newVars;
      });
    }
    case 'zoneAccessSet': {
      const { zoneName, oldAccess, access, cards, includeCids, yourAccess } =
        delta;
      return produce(vs, (draft) => {
        draft.zones[zoneName].access = access;
        draft.zones[zoneName].yourAccess = yourAccess;
        draft.zones[zoneName].cards = cards;
      });
    }
    case 'zoneShuffled': {
      const { zoneName, cards } = delta;
      return produce(vs, (draft) => {
        draft.zones[zoneName].cards = cards;
      });
    }
    case 'historyEntryAdded': {
      const { historyEntry } = delta;
      return produce(vs, (draft) => {
        draft.historyEntries.push(historyEntry);
      });
    }
    default: {
      console.warn(`unrecognized deltaType for applyVDelta:`, delta);
      return vs;
    }
  }
}

export function getVCardTranslatedCid(card: VCard): string {
  return getTranslatedCidFromKnowledge(card.yourKnowledge);
}

export function vDeltaToRep(delta: VDelta | null, level = 0): string {
  if (!delta) {
    return ':none';
  }

  const { deltaType } = delta;

  function fromParts(...parts: string[]): string {
    const head = `:${deltaType}`;
    return [head, ...parts].join(' ');
  }

  switch (deltaType) {
    case 'empty': {
      return fromParts();
    }
    case 'playerAdded': {
      const { playerName, role } = delta;
      return fromParts(playerName, role);
    }
    case 'zoneCreated': {
      const { zoneKind, owner, slug, access, yourAccess } = delta;
      const zoneName = buildZoneName(owner, slug);
      const accessRep = getZoneAccessRep(access);
      const modifiers = getZoneRepModifiers(zoneKind, yourAccess);
      return fromParts(zoneName, modifiers, accessRep);
    }
    case 'seq': {
      const { childDeltas } = delta;
      const childReps = childDeltas.map((childDelta) =>
        vDeltaToRep(childDelta, level + 1)
      );
      const beginning = `:${deltaType}`;
      return indentedList(beginning, childReps, level);
    }
    case 'cardCreated': {
      const { card, location } = delta;
      const translatedCid = getVCardTranslatedCid(card);
      const locationRep = getCardLocationRep(location);

      const parts = [locationRep, translatedCid];
      if (card.tag === 'cardInRegion') {
        const { facing, rotation } = card;
        const modifiers = getCardRepModifiers(facing, rotation);
        parts.push(modifiers);
      }
      return fromParts(...parts);
    }
    case 'cardChangedFacing': {
      const { card, facing, location } = delta;
      const translatedCid = getVCardTranslatedCid(card);
      const locationRep = getCardLocationRep(location);
      return fromParts(locationRep, translatedCid, facing);
    }
    case 'cardMoved': {
      const { card, sourceLocation, targetLocation } = delta;
      const parts = [
        getCardLocationRep(sourceLocation),
        getCardLocationRep(targetLocation),
        getVCardTranslatedCid(card),
      ];
      if (card.tag === 'cardInRegion') {
        const { facing, rotation } = card;
        parts.push(getCardRepModifiers(facing, rotation));
      }
      return fromParts(...parts);
    }
    case 'cardChangedRotation': {
      const { card, rotation, location } = delta;
      const translatedCid = getVCardTranslatedCid(card);
      const locationRep = getCardLocationRep(location);
      return fromParts(locationRep, translatedCid, rotation);
    }
    case 'cardVarsSet': {
      const { card, location, setters } = delta;
      const translatedCid = getVCardTranslatedCid(card);
      const locationRep = getCardLocationRep(location);
      const settersRep = getSettersRep(setters);

      return fromParts(locationRep, translatedCid, settersRep);
    }
    case 'cardVarsChanged': {
      const { card, location, changers } = delta;
      const translatedCid = getVCardTranslatedCid(card);
      const locationRep = getCardLocationRep(location);
      const changersRep = getChangersRep(changers, card.vars);
      return fromParts(locationRep, translatedCid, changersRep);
    }
    case 'stateVarsSet': {
      const { setters } = delta;
      const settersRep = getSettersRep(setters);
      return fromParts(settersRep);
    }
    case 'stateVarsChanged': {
      const { changers, newValues } = delta;
      const changersRep = getChangersRep(changers, newValues);

      return fromParts(changersRep);
    }
    case 'zoneAccessSet': {
      const { zoneName, oldAccess, access, cards, includeCids, yourAccess } =
        delta;
      const beginning = `:${deltaType}`;
      const accessRep = getZoneAccessRep(access);
      const parts = [zoneName, accessRep, yourAccess];

      const header = [beginning, ...parts].join(' ');
      if (includeCids) {
        const cids = cards.map((card) => getVCardTranslatedCid(card));
        return indentedList(header, cids, level);
      } else {
        return header;
      }
    }
    case 'zoneShuffled': {
      const { zoneName, cards } = delta;
      const beginning = `:${deltaType}`;
      const header = [beginning, zoneName].join(' ');

      if (cards !== null) {
        const cids = cards.map((card) => getVCardTranslatedCid(card));
        return indentedList(header, cids, level);
      } else {
        return header;
      }
    }
    default: {
      console.warn('unrecognized deltaType for vDeltaToRep', delta);
      return `:${deltaType} TODO`;
    }
  }
}
