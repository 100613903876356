import { createSlice, createSelector } from '@reduxjs/toolkit';
import { setInitialRoomData } from 'slices/roomSlice';

function addGame(state, game) {
  if (!state) {
    console.warn('add game: state is null');
    return;
  }
  if (!game.shortName) {
    console.warn('add game: game must have shortName', game);
    return;
  }
  state[game.shortName] = game;
}

function addGamesIfPresent(state, games) {
  if (!games) {
    return;
  }
  for (const game of games) {
    if (!game) {
      continue;
    }
    addGame(state, game);
  }
}

export const gamesSlice = createSlice({
  name: 'games',
  initialState: {},
  reducers: {
    gameCreated: function (state, action) {
      const game = action.payload;
      const { shortName } = game;
      state[shortName] = game;
    },
    createGameSuccess: function (state, action) {
      const game = action.payload;
      const { shortName } = game;
      state[shortName] = game;
    },
    gameByShortNameCanceled: function (state, action) {
      const shortName = action.payload;
      state[shortName].status = 'canceled';
    },
    gameByShortNameDeleted: function (state, action) {
      // Actually it's deleted on the backend, but this passes the message.
      const shortName = action.payload;
      state[shortName].status = 'deleted';
    },
    gameByShortNameChangedPlayers: function (state, action) {
      const { shortName, newPlayers } = action.payload;
      state[shortName].players = newPlayers;
    },
    gameByShortNameStarted: function (state, action) {
      const shortName = action.payload;
      state[shortName].status = 'running';
    },
    gameByShortNameFinished: function (state, action) {
      const { shortName, outcome } = action.payload;
      state[shortName].status = 'finished';
      state[shortName].outcome = outcome;
    },
    gameByShortNameUpdated: function (state, action) {
      const { shortName, newGame } = action.payload;
      state[shortName] = newGame;
    },
  },
  extraReducers: {
    [setInitialRoomData]: function (state, action) {
      const { roomData } = action.payload;

      addGamesIfPresent(state, [roomData.game]);
      addGamesIfPresent(state, roomData.games);
      addGamesIfPresent(state, roomData.recentFinishedGames);

      if (roomData.shortName) {
        state[roomData.shortName] = roomData.game;
      }
    },
  },
});

export const {
  gameCreated,
  createGameSuccess,
  gameByShortNameChangedPlayers,
  gameByShortNameCanceled,
  gameByShortNameDeleted,
  gameByShortNameStarted,
  gameByShortNameFinished,
  gameByShortNameUpdated,
} = gamesSlice.actions;

export const selectGamesSlice = (state) => state.games;

export const createSelectGameByShortName = (shortName) =>
  createSelector(selectGamesSlice, (state) => {
    return state[shortName];
  });
