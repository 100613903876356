import React from 'react';
import { gameConfigByCode } from 'data/gameConfig';

export function SideSelection(props) {
  const { game } = props;
  if (!game) {
    return null;
  }

  let { gameSettings, code } = game;
  if (!gameSettings) {
    gameSettings = {};
  }
  const { 'side-selection': sideSelection } = gameSettings;

  if (!sideSelection) {
    return null;
  }

  const sideSelectionOptionChoices =
    gameConfigByCode[code].sideSelectionOptionChoices;

  const choice = sideSelectionOptionChoices.find((choice) => {
    return choice.value === sideSelection;
  });

  const label = choice ? choice.displayLabel : 'Random sides';

  return <div>{label}</div>;
}
