import React from 'react';
import { tdFormatTiming } from 'data/tdData';
import { sekiFormatTiming } from 'data/sekiData';

export function Timing(props) {
  const { game } = props;
  const { runtimeInfo } = game;
  if (!runtimeInfo) {
    return null;
  }

  const { code } = game;
  let formatted = null;

  const { timing } = runtimeInfo;

  switch (code) {
    case 'td': {
      formatted = tdFormatTiming(timing);
      break;
    }
    case 'seki': {
      formatted = sekiFormatTiming(timing);
      break;
    }
    default: {
      console.error('Timing: unrecognized game code', game);
      break;
    }
  }

  if (!formatted) {
    return null;
  }

  return <div>{formatted}</div>;
}
