import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { TopLevelPage } from 'components/TopLevelPage';
import { Loader } from 'components/Loader';
import { Well } from 'components/Well';
import { ListOfGameCards } from 'components/ListOfGameCards';
import { selectUserInitialized, selectIsLoggedIn } from 'slices/userSlice';

function Status(props) {
  const { state } = props;
  if (state === 'loading') {
    return <Loader />;
  } else if (state === 'error') {
    return (
      <Well modifier="danger">
        Unable to fetch past games. Please try again later.
      </Well>
    );
  } else {
    return null;
  }
}

export function PastGamesPage() {
  const [games, setGames] = useState(null);
  const [state, setState] = useState('loading');

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userInitialized = useSelector(selectUserInitialized);

  async function fetchPastGames() {
    try {
      const response = await fetch('/past-games');

      if (response.status === 200) {
        const games = await response.json();
        setGames(games);
        setState('success');
      } else {
        setState('error');
      }
    } catch (e) {
      setState('error');
    }
  }

  useEffect(() => {
    if (isLoggedIn && !games) {
      fetchPastGames();
    }
  }, [isLoggedIn, games]);

  return (
    <TopLevelPage additionalClassName="PastGamesPage" title="Past games">
      <h1>Past games</h1>

      {userInitialized && !isLoggedIn ? (
        <>
          <Well modifier="info">
            Please <Link to="/login?after=/past-games">log in</Link> to view
            your past games.
          </Well>
        </>
      ) : (
        <>
          <Status state={state} />
          <ListOfGameCards games={games} />
        </>
      )}
    </TopLevelPage>
  );
}
