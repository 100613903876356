import React, { useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { TopLevelPage } from 'components/TopLevelPage';
import { FormField } from 'components/FormField';
import { Loader } from 'components/Loader';
import { Well } from 'components/Well';
import { ValidationErrorList } from 'components/ValidationErrorList';
import { logIn, selectUser } from 'slices/userSlice';
import { text } from 'texts';
import { history } from 'utils/history';
import { getClientSocketId } from 'connector';

function CompletePasswordResetStatus(props) {
  const { state, validationErrors } = props;
  if (state === 'start') {
    return null;
  } else if (state === 'loading') {
    return <Loader />;
  } else if (state === 'serverError') {
    return <Well modifier="danger">{text('serverError')}</Well>;
  } else {
    return (
      <Well modifier="danger">
        There were some errors.
        <ValidationErrorList validationErrors={validationErrors} />
      </Well>
    );
  }
}

const NONE = 'NONE';

function getFieldsValidationData(validationErrors) {
  const result = { [NONE]: [] };
  if (!validationErrors) {
    return result;
  }
  for (const validationError of validationErrors) {
    const { field = NONE } = validationError;
    if (!result[field]) {
      result[field] = [];
    }
    result[field].push(validationError);
  }
  return result;
}

function CompletePasswordResetForm(props) {
  const [state, setState] = useState('start');
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [validationErrors, setValidationErrors] = useState(null);

  const loggedInUser = useSelector(selectUser);

  const fieldsValidation = getFieldsValidationData(validationErrors);

  const { code } = useParams();

  const dispatch = useDispatch();
  if (loggedInUser) {
    return <Redirect to="/lobby" />;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (state === 'loading') {
      return;
    }

    setState('loading');

    try {
      const response = await fetch('/complete-password-reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          usernameOrEmail,
          password,
          repeatPassword,
          code,
          clientSocketId: getClientSocketId(),
        }),
      });
      if (response.status === 200) {
        const user = await response.json();
        batch(() => {
          setState('success');
          setValidationErrors(null);
          dispatch(logIn(user));
        });
        history.push('/lobby');
      } else if (response.status === 403) {
        const errors = await response.json();
        setValidationErrors(errors);
        setState('error');
      } else {
        setState('serverError');
        setValidationErrors(null);
      }
    } catch (e) {
      setState('serverError');
      setValidationErrors(null);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormField
        id="usernameOrEmail"
        labelText="Username or email once again (for security):"
        fieldsValidation={fieldsValidation}
      >
        <input
          id="usernameOrEmail"
          value={usernameOrEmail}
          onChange={(e) => setUsernameOrEmail(e.target.value)}
          type="text"
          name="usernameOrEmail"
        />
      </FormField>
      <FormField
        id="password"
        labelText="New password:"
        fieldsValidation={fieldsValidation}
      >
        <input
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          name="password"
        />
      </FormField>
      <FormField
        id="repeatPassword"
        labelText="Repeat password:"
        fieldsValidation={fieldsValidation}
      >
        <input
          id="repeatPassword"
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
          type="password"
          name="repeatPassword"
        />
      </FormField>
      <div className="buttons">
        <input
          type="submit"
          className="button isPrimary"
          disabled={state === 'loading'}
          value="Complete password reset"
        />
      </div>
      <CompletePasswordResetStatus
        state={state}
        validationErrors={fieldsValidation[NONE]}
      />
    </form>
  );
}

export function CompletePasswordResetPage(props) {
  return (
    <TopLevelPage title="Reset password">
      <h1>Reset password</h1>
      <CompletePasswordResetForm />
    </TopLevelPage>
  );
}
