import React from 'react';
import { useSelector } from 'react-redux';
import { getGameActions, getGameSlotsFromPlayers } from 'utils';
import { Link } from 'react-router-dom';
import { Avatar } from 'components/Avatar';
import { YourTurnIndicator } from 'components/YourTurnIndicator';
import { formatTimestamp } from 'utils';
import { selectYourUsername } from 'slices/userSlice';
import { GameActionButtons } from 'components/GameActionButtons';
import { SideSelection } from 'components/SideSelection';
import { Timing } from 'components/Timing';

export function GameCard(props) {
  const { game } = props;

  const { createdAt, kind, players, comment, status, shortName } = game;

  const yourUsername = useSelector(selectYourUsername);
  const actions = getGameActions(game, yourUsername);
  const slots = getGameSlotsFromPlayers(players);

  return (
    <div className="GameCard">
      <div className="one">
        <div className="info">
          <div className="infoList">
            <h3>
              <Link to={`game/${shortName}`}>{shortName}</Link>
            </h3>
            <div>{kind}</div>
            <div>{status}</div>
          </div>
          <aside className="timestamp">{formatTimestamp(createdAt)}</aside>
          <SideSelection game={game} />
          <div className="comment">{comment}</div>
          <Timing game={game} />
          <YourTurnIndicator game={game} yourUsername={yourUsername} />
        </div>
        <GameActionButtons
          isOutline={true}
          actions={actions}
          shortName={shortName}
        />
      </div>
      <ul className="playerSlots isSmall">
        {slots.map((slot) => {
          const { key, hasPlayer, display, className } = slot;
          const title = hasPlayer ? display : null;
          return (
            <li key={key} className={className}>
              {hasPlayer && <Avatar name={display} />}
              <span className="slotDisplay" title={title}>
                {display}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
