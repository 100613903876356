export type { State, Role, Order, StringHash, VOrder, Delta } from './types';

export const testEntry = () => 'this is indeed a test entry point';

export {
  getGameTagFromId,
  qualifiedName,
  getStateVar,
  getStateNumberVar,
  isEmpty,
  buildZoneName,
  getBaseFromCid,
  separate,
  sum,
  addToKeyedArray,
  getNCardsInZone,
  getNCardsInPlayerZone,
  repeatString,
  mapValues,
} from './utils';

export { EError } from './errors';

export { applyAction } from './actionsInfrastructure';

export { fa, getStateAfterActions } from './foundationActions';

export {
  collectOrders,
  ordersToVOrders,
  executeFilledOrder,
  makeCardVSource,
  makeZoneVSource,
  orderSourceToVOrderSource,
  makePlayerVSource,
  validateFilledOrder,
} from './ordersInfrastructure';

export { stateToVState } from './view';

export { deltaToVDelta, applyVDelta } from './deltas';

export { createSekiState } from './seki/sekiState';

export {
  sekiLocationData,
  sekiConnections,
  sekiFindLocationById,
  sekiUnitData,
  sekiSideSelectionOptionChoices,
  sekiGetUnitInfoByBase,
  sekiGetCardInfoByBase,
  sekiGetMovementPenaltyForUnitAmount,
  sekiGetOpponentRole,
  sekiGetConnectionName,
  sekiClanToMusteringLocationId,
} from './seki/sekiData';
export {
  sekiCalculateAddedImpact,
  sekiGetPhasingPlayer,
  sekiGetAttackerRole,
  sekiGetCombatOutcome,
} from './seki/sekiUtils';

export { clientServerCommonData } from './site/clientServerCommonData';
