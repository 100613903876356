export class EError extends Error {
  constructor(message: string, context: any) {
    const extendedMessage = `${message} - ${JSON.stringify(context)}`;
    super(extendedMessage);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, EError);
    }
    Object.setPrototypeOf(this, EError.prototype);
  }
}

export class ActionError extends EError {
  constructor(message: string, context: any = {}) {
    super(message, context);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ActionError);
    }
    Object.setPrototypeOf(this, ActionError.prototype);
  }
}
