import React, { useContext } from 'react';
import { getVar, getTrackerStyle } from 'data/tdData';
import { GameUiContext } from 'components/PlayGamePage/GameUiContext';

// An image that is positioned to a marker whose name depends on a var's value.
export function Tracker(props) {
  const {
    varName,
    markerPrefix,
    imgSrc,
    trackerKind,
    offset,
    bigSizeProp,
    rectProp,
  } = props;

  const { cs } = useContext(GameUiContext);

  const value = getVar(cs, varName);
  const style = getTrackerStyle(`${markerPrefix}${value}`, trackerKind, {
    offset,
    bigSizeProp,
    rectProp,
  });
  return (
    <div className="tracker" style={style}>
      <img alt="" src={imgSrc} />
    </div>
  );
}
