import React, { useEffect, useState } from 'react';
import { TopLevelPage } from 'components/TopLevelPage';
import { Loader } from 'components/Loader';
import { Well } from 'components/Well';

function Status(props) {
  const { state } = props;
  if (state === 'loading') {
    return <Loader />;
  } else if (state === 'error') {
    return (
      <Well modifier="danger">
        Unable to fetch stats. Please try again later.
      </Well>
    );
  } else {
    return null;
  }
}

function WtlRow(props) {
  const { label, wtl } = props;
  return (
    <tr>
      <th>{label}</th>
      <td>{wtl.total}</td>
      <td>{wtl.wins}</td>
      <td>{wtl.ties}</td>
      <td>{wtl.losses}</td>
    </tr>
  );
}

function UserStatsSection(props) {
  const { userStats } = props;
  if (!userStats) {
    return null;
  }

  const { wtl, wtlByRole } = userStats;
  return (
    <>
      <h2>Your stats</h2>
      <table>
        <thead>
          <tr>
            <th>{/* blank intentionally */}</th>
            <th>total</th>
            <th>wins</th>
            <th>ties</th>
            <th>losses</th>
          </tr>
        </thead>
        <tbody>
          <WtlRow label="Your games" wtl={wtl} />
          <WtlRow label="– as USSR" wtl={wtlByRole.s} />
          <WtlRow label="– as USA" wtl={wtlByRole.u} />
        </tbody>
      </table>
    </>
  );
}

function SiteStatsSection(props) {
  const { siteStats } = props;
  if (!siteStats) {
    return null;
  }
  const { highLevel } = siteStats;

  return (
    <>
      <h2>Site stats</h2>
      <table>
        <tbody>
          <tr>
            <th>All games</th>
            <td>{highLevel.total}</td>
          </tr>
          <tr>
            <th>– USSR wins</th>
            <td>{highLevel.sWins}</td>
          </tr>
          <tr>
            <th>– USA wins</th>
            <td>{highLevel.uWins}</td>
          </tr>
          <tr>
            <th>– ties</th>
            <td>{highLevel.ties}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

function Stats(props) {
  const { stats } = props;
  if (!stats) {
    return null;
  }
  return (
    <>
      <UserStatsSection userStats={stats.userStats} />
      <SiteStatsSection siteStats={stats.siteStats} />
    </>
  );
}

export function StatsPage() {
  const [stats, setStats] = useState(null);
  const [state, setState] = useState('loading');

  async function fetchStats() {
    try {
      const response = await fetch('/stats');

      if (response.status === 200) {
        const stats = await response.json();
        setStats(stats);
        setState('success');
      } else {
        setState('error');
      }
    } catch (e) {
      setState('error');
    }
  }

  useEffect(() => {
    if (!stats) {
      fetchStats();
    }
  }, [stats]);

  return (
    <TopLevelPage additionalClassName="StatsPage" title="Stats">
      <Status state={state} />
      <Stats stats={stats} />
    </TopLevelPage>
  );
}
