import React from 'react';
import clsx from 'clsx';

export function Loader(props) {
  const { isCentered, isMarginless, isInNavbar, isActive = true } = props;
  const className = clsx('Loader', { isCentered, isMarginless, isInNavbar });

  if (!isActive) {
    return null;
  }

  return <div className={className} />;
}
