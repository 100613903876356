import React from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

export function Footer(props) {
  const { hideSmall } = props;
  const className = clsx('topLevel', { hideSmall });
  return (
    <footer className={className}>
      <div className="container">
        Made by arry with lots of love.{' '}
        <NavLink to="/info">Credits, contact & support</NavLink>
      </div>
    </footer>
  );
}
