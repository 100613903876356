import React from 'react';
import { Checkbox } from 'components/Checkbox';

export function AllowGameEmailsField(props) {
  const { allowGameEmails, setAllowGameEmails } = props;
  return (
    <div className="FormField AllowGameEmailsField">
      <Checkbox
        name="allowGameEmails"
        value="allowGameEmails"
        isChecked={allowGameEmails}
        onChange={(e) => {
          setAllowGameEmails(e.target.checked);
        }}
      >
        Allow emails from async games
      </Checkbox>
    </div>
  );
}
