import React, { useState } from 'react';
import clsx from 'clsx';
import {
  getGameActions,
  getGameSlotsFromPlayers,
  formatTimestamp,
} from 'utils';
import { useCurrentRoomChat } from 'hooks';
import { Avatar } from 'components/Avatar';
import { ChatComponent } from 'components/ChatComponent';
import { TopLevelPage } from 'components/TopLevelPage';
import { YourTurnIndicator } from 'components/YourTurnIndicator';
import { createSelectGameByShortName } from 'slices/gamesSlice';
import { useSelector } from 'react-redux';
import { Loader } from 'components/Loader';
import { DefaultPage } from 'pages/DefaultPage';
import { PeopleOnline } from 'components/PeopleOnline';
import { selectYourUsername } from 'slices/userSlice';
import { GameActionButtons } from 'components/GameActionButtons';
import { SideSelection } from 'components/SideSelection';
import { Timing } from 'components/Timing';

export function GameDetailsPage(props) {
  const { setHideNavbarOnSmallScreen, shortName } = props;
  const game = useSelector(createSelectGameByShortName(shortName));
  const chat = useCurrentRoomChat({
    mergeWithGameLog: shortName,
  });
  const yourUsername = useSelector(selectYourUsername);
  const [hasFocus, setHasFocus] = useState(false);

  if (game === undefined) {
    return (
      <TopLevelPage
        additionalClassName="GameDetailsPage"
        footerHideSmall={true}
      >
        <Loader />
      </TopLevelPage>
    );
  } else if (game === null) {
    return <DefaultPage />;
  }

  const { createdAt, kind, status, players, comment } = game;

  const actions = getGameActions(game, yourUsername, { onDetailsPage: true });
  const slots = getGameSlotsFromPlayers(players);

  function handleChatInputFocusChanged(hasFocus) {
    setHasFocus(hasFocus);
    setHideNavbarOnSmallScreen(hasFocus);
  }

  return (
    <TopLevelPage
      additionalClassName="GameDetailsPage"
      footerHideSmall={true}
      title={shortName}
    >
      <h2>{shortName}</h2>
      <div className="infoList">
        <div>{kind}</div>
        <div className="status">{status}</div>
        <aside className="timestamp">{formatTimestamp(createdAt)}</aside>
        <SideSelection game={game} />
        {comment && <div className="comment">{comment}</div>}
        <Timing game={game} />
        <YourTurnIndicator game={game} yourUsername={yourUsername} />
      </div>
      <ul className={clsx('playerSlots', { hideSmall: hasFocus })}>
        {slots.map((slot) => {
          return (
            <li key={slot.key} className={slot.className}>
              {slot.hasPlayer && <Avatar name={slot.display} />}
              {slot.display}
            </li>
          );
        })}
      </ul>
      <GameActionButtons
        hideSmall={hasFocus}
        actions={actions}
        shortName={shortName}
      />
      <PeopleOnline hideSmall={hasFocus} />
      <ChatComponent
        chat={chat}
        onChatInputFocusChanged={handleChatInputFocusChanged}
      />
    </TopLevelPage>
  );
}
