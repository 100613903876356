import {
  Role,
  CardKnowledge,
  Card,
  Zone,
  CardRegistry,
  CardFacing,
  VYourAccess,
} from './types';
import { getZoneYourAccess, getYourKnowledge } from './utils';
import produce from 'immer';

export function createCardRegistry(role: Role): CardRegistry {
  return {
    role,
    baseToCounter: {},
    cidToSid: {},
  };
}

export function cloneCardRegistryForRole(
  cardRegistry: CardRegistry,
  role: Role
): CardRegistry {
  return {
    ...cardRegistry,
    role,
  };
}

type Stance = 'none' | 'lim/past' | 'full';

function getStance(facing: CardFacing | null, yourAccess: VYourAccess): Stance {
  if (yourAccess === 'inaccessible') {
    if (facing === null) {
      return 'none';
    } else if (facing === 'facedown') {
      return 'lim/past';
    } else {
      return 'full';
    }
  } else {
    return 'full';
  }
}

type Change =
  | 'preserve'
  | 'create-lim'
  | 'create-full'
  | 'forget'
  | 'change-to-full'
  | 'replace-lim';

function getChange(
  previousKnowledge: CardKnowledge,
  stance: Stance,
  options = {
    replaceLimitedKnowledge: false,
  }
): Change {
  const prev = previousKnowledge ? previousKnowledge.knowledgeType : 'none';
  if (prev === 'none') {
    if (stance === 'none') {
      return 'preserve';
    } else if (stance === 'lim/past') {
      return 'create-lim';
    } else {
      return 'create-full';
    }
  } else if (prev === 'limited') {
    if (stance === 'none') {
      return 'forget';
    } else if (stance === 'lim/past') {
      if (options.replaceLimitedKnowledge) {
        return 'replace-lim';
      } else {
        return 'preserve';
      }
    } else {
      return 'change-to-full';
    }
  } else {
    // full
    if (stance === 'none') {
      return 'forget';
    } else if (stance === 'lim/past') {
      if (options.replaceLimitedKnowledge) {
        return 'replace-lim';
      } else {
        return 'preserve';
      }
    } else {
      return 'preserve';
    }
  }
}

function createCidFromBase(
  registry: CardRegistry,
  base: string,
  sid: string
): { next: CardRegistry; cid: string } {
  const counter = registry.baseToCounter[base] || 0;
  const nextCounter = counter + 1;
  const cid = `${base}#${nextCounter}`;
  const next = produce(registry, (draft) => {
    draft.baseToCounter[base] = nextCounter;
    draft.cidToSid[cid] = sid;
  });

  return {
    next,
    cid,
  };
}

function unlinkCid(
  registry: CardRegistry,
  knowledge: CardKnowledge,
  sid: string
): { next: CardRegistry } {
  if (knowledge === null) {
    throw new Error(`Cannot unlink cid: ${sid}`);
  }
  const next = produce(registry, (draft) => {
    delete draft.cidToSid[knowledge.cid];
  });
  return { next };
}

function createFullKnowledgeCid(
  registry: CardRegistry,
  sid: string
): { next: CardRegistry; cid: string } {
  const base = sid.split(':')[0];

  return createCidFromBase(registry, base, sid);
}

function createLimitedKnowledgeCid(
  registry: CardRegistry,
  sid: string,
  owner: Role
): { next: CardRegistry; cid: string } {
  const base = `H${owner}`;

  return createCidFromBase(registry, base, sid);
}

function applyChange(
  registry: CardRegistry,
  previousKnowledge: CardKnowledge,
  sid: string,
  owner: Role,
  change: Change
): [CardRegistry, CardKnowledge] {
  switch (change) {
    case 'preserve': {
      return [registry, previousKnowledge];
    }
    case 'create-lim': {
      const { next, cid } = createLimitedKnowledgeCid(registry, sid, owner);
      return [next, { knowledgeType: 'limited', cid }];
    }
    case 'create-full': {
      const { next, cid } = createFullKnowledgeCid(registry, sid);
      return [next, { knowledgeType: 'full', cid }];
    }
    case 'forget': {
      const { next } = unlinkCid(registry, previousKnowledge, sid);
      return [next, null];
    }
    case 'change-to-full': {
      const { next } = unlinkCid(registry, previousKnowledge, sid);
      const { next: next1, cid } = createFullKnowledgeCid(next, sid);
      return [next1, { knowledgeType: 'full', cid }];
    }
    case 'replace-lim': {
      // When an accessible zone full of limited- (or past-) knowledge cards is
      // shuffled, then we regenerate the sid<->cid link because we don't know
      // which cards are which after shuffling (even though the user might
      // distinguish them by (public) vars).
      const { next } = unlinkCid(registry, previousKnowledge, sid);
      const { next: next1, cid } = createLimitedKnowledgeCid(
        registry,
        sid,
        owner
      );
      return [next1, { knowledgeType: 'limited', cid }];
    }
  }
}

export function registerCardChange(
  registry: CardRegistry,
  card: Card,
  facing: CardFacing | null,
  zone: Zone,
  options = {
    replaceLimitedKnowledge: false,
  }
): [CardRegistry, CardKnowledge] {
  const { access } = zone;
  const { owner } = card;

  const yourAccess = getZoneYourAccess(access, registry.role);

  const stance = getStance(facing, yourAccess);

  const { sid } = card;
  const previousKnowledge = getYourKnowledge(card, registry.role);

  const change = getChange(previousKnowledge, stance, options);

  return applyChange(registry, previousKnowledge, sid, owner, change);
}
