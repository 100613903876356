import { createSlice, createSelector } from '@reduxjs/toolkit';
import { createGameSuccess } from 'slices/gamesSlice';

export const sandboxSlice = createSlice({
  name: 'sandbox',
  initialState: {
    games: null,
    createSandboxGameErrorText: null,
  },
  reducers: {
    createSandboxGameError(state, action) {
      const text = action.payload;
      state.createSandboxGameErrorText = text;
    },
    clearCreateSandboxGameError(state) {
      state.createSandboxGameErrorText = null;
    },
    setSandboxGames(state, action) {
      state.games = action.payload.games;
    },
    onGameByShortNameDeleted(state, action) {
      const { shortName } = action.payload;
      state.games = state.games.filter((game) => {
        return game.shortName !== shortName;
      });
    },
  },
  extraReducers: {
    [createGameSuccess](state, action) {
      const game = action.payload;
      const { kind } = game;
      if (kind !== 'sandbox') {
        return;
      }
      state.games.unshift(game);
    },
  },
});

export const {
  createSandboxGameError,
  clearCreateSandboxGameError,
  setSandboxGames,
  onGameByShortNameDeleted,
} = sandboxSlice.actions;

export const selectSandboxSlice = (state) => {
  return state.sandbox;
};

export const selectSandboxGames = createSelector(
  selectSandboxSlice,
  (state) => {
    return state.games;
  }
);

export const selectCreateSandboxGameErrorText = createSelector(
  selectSandboxSlice,
  (state) => state.createSandboxGameErrorText
);
