import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { useTap } from 'utils/useTap';

export function DismissButton(props) {
  const { onDismiss } = props;

  const handlers = useTap(onDismiss);

  return (
    <button {...handlers} className="DismissButton">
      <IoMdClose />
    </button>
  );
}
