import { createShortName } from '../utils';

export const sekiSideSelectionOptionChoices = [
  {
    value: 'owner-i',
    displayLabel: 'Owner is Ishida',
    selectionLabel: 'Ishida',
  },
  {
    value: 'owner-t',
    displayLabel: 'Owner is Tokugawa',
    selectionLabel: 'Tokugawa',
  },
];

export const clanToFaction = {
  date: 't',
  fuku: 't',
  ii: 't',
  ishi: 'i',
  koba: 'i',
  maed: 't',
  mori: 'i',
  toku: 't',
  uesu: 'i',
  ukit: 'i',
};

export const sekiClanToMusteringLocationId = {
  date: 'sendai',
  fuku: 'kiyosu',
  koba: 'osaka',
  maed: 'kanaza',
  mori: 'himeji',
  toku: 'edo',
  uesu: 'aizu',
  ukit: 'himeji',
};

const unitManifest = [
  { amount: 2, face: 'date-arq', title: 'Date Arquebusiers' },
  { amount: 2, face: 'date-cav', title: 'Date Cavalry' },
  { amount: 2, face: 'date-elite', title: 'Date Elite' },
  { amount: 1, face: 'date-elite-circle', title: 'Date Elite (s)' },
  { amount: 1, face: 'date-leader-circle', title: 'Date Masamune' },
  { amount: 2, face: 'date-regular', title: 'Date Regulars' },
  { amount: 1, face: 'date-regular-circle', title: 'Date Regulars (s)' },
  { amount: 2, face: 'fuku-arq', title: 'Fukushima Arquebusiers' },
  { amount: 1, face: 'fuku-cav', title: 'Fukushima Cavalry' },
  { amount: 1, face: 'fuku-cav-star', title: 'Fukushima Cavalry (s)' },
  { amount: 2, face: 'fuku-elite', title: 'Fukushima Elite' },
  { amount: 1, face: 'fuku-leader-star', title: 'Fukushima Masanori' },
  { amount: 3, face: 'fuku-regular', title: 'Fukushima Regulars' },
  { amount: 1, face: 'ii-devils-star', title: 'Ii Red Devils' },
  {
    amount: 1,
    face: 'ishi-leader-square',
    title: 'Ishida Mitsunari',
    main: true,
  },
  { amount: 2, face: 'koba-arq', title: 'Kobayakawa Arquebusiers' },
  { amount: 2, face: 'koba-cav', title: 'Kobayakawa Cavalry' },
  { amount: 3, face: 'koba-elite', title: 'Kobayakawa Elite' },
  { amount: 1, face: 'koba-elite-square', title: 'Kobayakawa Elite (s)' },
  { amount: 1, face: 'koba-leader-square', title: 'Kobayakawa Hideaki' },
  { amount: 4, face: 'koba-regular', title: 'Kobayakawa Regulars' },
  { amount: 1, face: 'maed-arq', title: 'Maeda Arquebusiers' },
  { amount: 1, face: 'maed-arq-triangle', title: 'Maeda Arquebusiers (s)' },
  { amount: 2, face: 'maed-cav', title: 'Maeda Cavalry' },
  { amount: 3, face: 'maed-elite', title: 'Maeda Elite' },
  { amount: 1, face: 'maed-leader-triangle', title: 'Maeda Toshinaga' },
  { amount: 1, face: 'maed-regular', title: 'Maeda Regulars' },
  { amount: 2, face: 'maed-regular-triangle', title: 'Maeda Regulars (s)' },
  { amount: 2, face: 'mori-arq', title: 'Mōri Arquebusiers' },
  { amount: 1, face: 'mori-cav', title: 'Mōri Cavalry' },
  { amount: 1, face: 'mori-cav-square', title: 'Mōri Cavalry (s)' },
  { amount: 2, face: 'mori-elite', title: 'Mōri Elite' },
  { amount: 4, face: 'mori-elite-triangle', title: 'Mōri Elite (s)' },
  { amount: 1, face: 'mori-leader-triangle', title: 'Mōri Terumoto' },
  { amount: 4, face: 'mori-regular', title: 'Mōri Regulars' },
  { amount: 1, face: 'toku-arq', title: 'Tokugawa Arquebusiers' },
  { amount: 1, face: 'toku-arq-square', title: 'Tokugawa Arquebusiers (s)' },
  { amount: 2, face: 'toku-cav', title: 'Tokugawa Cavalry' },
  { amount: 4, face: 'toku-elite', title: 'Tokugawa Elite' },
  { amount: 2, face: 'toku-elite-square', title: 'Tokugawa Elite (s)' },
  {
    amount: 1,
    face: 'toku-leader-square',
    title: 'Tokugawa Ieyasu',
    main: true,
  },
  { amount: 4, face: 'toku-regular', title: 'Tokugawa Regulars' },
  // XXX are there Uesugi Arquebusiers without the setup symbol?
  { amount: 2, face: 'uesu-arq-circle', title: 'Uesugi Arquebusiers (s)' },
  { amount: 1, face: 'uesu-cav', title: 'Uesugi Cavalry' },
  { amount: 2, face: 'uesu-elite-circle', title: 'Uesugi Elite (s)' },
  { amount: 1, face: 'uesu-leader-circle', title: 'Uesugi Kagekatsu' },
  { amount: 2, face: 'ukit-arq', title: 'Ukita Arquebusiers' },
  { amount: 2, face: 'ukit-cav', title: 'Ukita Cavalry' },
  { amount: 4, face: 'ukit-elite', title: 'Ukita Elite' },
  { amount: 1, face: 'ukit-leader-square', title: 'Ukita Hideie' },
  { amount: 3, face: 'ukit-regular', title: 'Ukita Regulars' },
  { amount: 1, face: 'ukit-regular-square', title: 'Ukita Regulars (s)' },
  {
    amount: 1,
    face: 'sanmas-disc-ueda',
    title: 'Sanada Masayuki',
  },
  {
    amount: 1,
    face: 'toyhid-disc-osaka',
    title: 'Toyotomi Hideyori',
    main: true,
  },
];

export const cardManifest = [
  {
    base: 'tokloy',
    cardBack: 'tc',
    pic: 'toku-loycha',
    amount: 4,
    i: 8,
    title: 'Tokugawa Loyalty Challenge',
    cardKind: 'lc',
    clan: null,
  },
  {
    base: 'datnor',
    cardBack: 'tc',
    pic: 'date-normal',
    amount: 6,
    i: 2,
    title: 'Date Normal',
    cardKind: 'normal',
    clan: 'date',
  },
  {
    base: 'datspe',
    cardBack: 'tc',
    pic: 'date-special',
    amount: 4,
    i: 4,
    title: 'Date Special',
    cardKind: 'special',
    clan: 'date',
  },
  {
    base: 'datdou',
    cardBack: 'tc',
    pic: 'date-double',
    amount: 1,
    d: 1,
    i: 6,
    title: 'Date Double',
    cardKind: 'double',
    clan: 'date',
  },
  {
    base: 'fuknor',
    cardBack: 'tc',
    pic: 'fuku-normal',
    amount: 7,
    i: 2,
    title: 'Fukushima Normal',
    cardKind: 'normal',
    clan: 'fuku',
  },
  {
    base: 'fukspe',
    cardBack: 'tc',
    pic: 'fuku-special',
    amount: 4,
    i: 4,
    title: 'Fukushima Special',
    cardKind: 'special',
    clan: 'fuku',
  },
  {
    base: 'fukdou',
    cardBack: 'tc',
    pic: 'fuku-double',
    amount: 1,
    d: 1,
    i: 6,
    title: 'Fukushima Double',
    cardKind: 'double',
    clan: 'fuku',
  },
  {
    base: 'maenor',
    cardBack: 'tc',
    pic: 'maed-normal',
    amount: 7,
    i: 2,
    title: 'Maeda Normal',
    cardKind: 'normal',
    clan: 'maed',
  },
  {
    base: 'maespe',
    cardBack: 'tc',
    pic: 'maed-special',
    amount: 4,
    i: 4,
    title: 'Maeda Special',
    cardKind: 'special',
    clan: 'maed',
  },
  {
    base: 'maedou',
    cardBack: 'tc',
    pic: 'maed-double',
    amount: 1,
    d: 1,
    i: 6,
    title: 'Maeda Double',
    cardKind: 'double',
    clan: 'maed',
  },
  {
    base: 'toknor',
    cardBack: 'tc',
    pic: 'toku-normal',
    amount: 10,
    i: 2,
    title: 'Tokugawa Normal',
    cardKind: 'normal',
    clan: 'toku',
  },
  {
    base: 'tokspe',
    cardBack: 'tc',
    pic: 'toku-special',
    amount: 5,
    i: 4,
    title: 'Tokugawa Special',
    cardKind: 'special',
    clan: 'toku',
  },
  {
    base: 'tokdou',
    cardBack: 'tc',
    pic: 'toku-double',
    amount: 1,
    i: 6,
    title: 'Tokugawa Double',
    cardKind: 'double',
    clan: 'toku',
  },
  {
    base: 'ishloy',
    cardBack: 'ic',
    pic: 'ishi-loycha',
    amount: 3,
    i: 7,
    title: 'Ishida Loyalty Challenge',
    cardKind: 'lc',
    clan: null,
  },
  {
    base: 'kobnor',
    cardBack: 'ic',
    pic: 'koba-normal',
    amount: 8,
    i: 1,
    title: 'Kobayakawa Normal',
    cardKind: 'normal',
    clan: 'koba',
  },
  {
    base: 'kobspe',
    cardBack: 'ic',
    pic: 'koba-special',
    amount: 5,
    i: 3,
    title: 'Kobayakawa Special',
    cardKind: 'special',
    clan: 'koba',
  },
  {
    base: 'kobdou',
    cardBack: 'ic',
    pic: 'koba-double',
    amount: 1,
    d: 1,
    i: 5,
    title: 'Kobayakawa Double',
    cardKind: 'double',
    clan: 'koba',
  },
  {
    base: 'mornor',
    cardBack: 'ic',
    pic: 'mori-normal',
    amount: 10,
    i: 1,
    title: 'Mōri Normal',
    cardKind: 'normal',
    clan: 'mori',
  },
  {
    base: 'morspe',
    cardBack: 'ic',
    pic: 'mori-special',
    amount: 5,
    i: 3,
    title: 'Mōri Special',
    cardKind: 'special',
    clan: 'mori',
  },
  {
    base: 'mordou',
    cardBack: 'ic',
    pic: 'mori-double',
    amount: 1,
    i: 5,
    title: 'Mōri Double',
    cardKind: 'double',
    clan: 'mori',
  },
  {
    base: 'uesnor',
    cardBack: 'ic',
    pic: 'uesu-normal',
    amount: 4,
    i: 1,
    title: 'Uesugi Normal',
    cardKind: 'normal',
    clan: 'uesu',
  },
  {
    base: 'uesspe',
    cardBack: 'ic',
    pic: 'uesu-special',
    amount: 3,
    i: 3,
    title: 'Uesugi Special',
    cardKind: 'special',
    clan: 'uesu',
  },
  {
    base: 'uesdou',
    cardBack: 'ic',
    pic: 'uesu-double',
    amount: 1,
    i: 5,
    title: 'Uesugi Double',
    cardKind: 'double',
    clan: 'uesu',
  },
  {
    base: 'ukinor',
    cardBack: 'ic',
    pic: 'ukit-normal',
    amount: 8,
    i: 1,
    title: 'Ukita Normal',
    cardKind: 'normal',
    clan: 'ukit',
  },
  {
    base: 'ukispe',
    cardBack: 'ic',
    pic: 'ukit-special',
    amount: 5,
    i: 3,
    title: 'Ukita Special',
    cardKind: 'special',
    clan: 'ukit',
  },
  {
    base: 'ukidou',
    cardBack: 'ic',
    pic: 'ukit-double',
    amount: 1,
    i: 5,
    title: 'Ukita Double',
    cardKind: 'double',
    clan: 'ukit',
  },
];

export function sekiGetCardInfoByBase(base) {
  return cardManifest.find((cardInfo) => cardInfo.base === base);
}

function cleanEmptyValues(obj) {
  const result = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      return;
    }
    result[key] = value;
  });
  return result;
}

function createUnitData(manifest) {
  const result = [];
  for (const group of manifest) {
    const { amount, face, title, main } = group;

    // Units are represented with engine's notion of cards.  The card base is a
    // contraction of a three-part descriptor: clan, type, symbol; each part
    // getting the two-letter code in the six-letter base.  Symbol is "none"
    // for the purposes of making the base; but the part as a whole is absent
    // in picture file names (referenced by `face` values).
    const start = face.split('-').length === 2 ? `${face}-none` : face;

    let [clan, kind, setup] = face.split('-');

    const base =
      kind === 'disc' ? clan : createShortName(start.replaceAll('-', ' '));

    if (kind === 'disc') {
      clan = null;
    }

    const strength =
      kind === 'disc'
        ? 0
        : kind === 'devils'
        ? 4
        : kind === 'elite'
        ? 3
        : kind === 'regular'
        ? 2
        : 1;
    const faction = kind === 'disc' ? 'i' : clanToFaction[clan];
    const common = {
      base,
      amount,
      face,
      clan,
      kind,
      strength,
      faction,
      title,
      main,
    };
    const joker = clan === 'ii' || clan === 'ishi' ? 1 : null;
    const special = kind === 'arq' ? 'arq' : kind === 'cav' ? 'cav' : null;

    result.push(
      cleanEmptyValues({
        ...common,
        setup,
        joker,
        special,
      })
    );
  }

  return result;
}

export const sekiUnitData = createUnitData(unitManifest);

export const sekiGetUnitInfoByBase = (base) => {
  const unitInfo = sekiUnitData.find((unit) => unit.base === base);
  if (!unitInfo) {
    throw new Error(`Cannot find unit info for base ${base}`);
  }
  return unitInfo;
};

export const sekiLocationData = [
  {
    name: 'Aizu',
    roads: ['shirak', 'tsugaw'],
    highways: [],
    x: 3015,
    y: 935,
    policy: 'm',
    castle: 'i',
    ishis: 'circle',
    ishir: 1,
    locationId: 'aizu',
    musters: ['uesu'],
  },
  {
    name: 'Anotsu',
    roads: ['kumano', 'kuwana'],
    highways: [],
    x: 853,
    y: 1147,
    policy: 'd',
    castle: 't',
    tokur: 1,
    locationId: 'anotsu',
  },
  {
    name: 'Arato',
    roads: ['takeda', 'matsum', 'ueda'],
    highways: [],
    x: 2314,
    y: 850,
    policy: 'm',
    locationId: 'arato',
  },
  {
    name: 'Ayabe',
    roads: ['himeji', 'miyazu', 'kyoto'],
    highways: [],
    x: 595,
    y: 571,
    policy: 'u',
    locationId: 'ayabe',
    limit: 8,
  },
  {
    name: 'Edo',
    roads: ['mito', 'oyama'],
    highways: ['hakone', 'oshi'],
    x: 2389,
    y: 1740,
    policy: 'd',
    resource: 1,
    cap: 't',
    tokus: 'square',
    tokur: 2,
    locationId: 'edo',
    musters: ['toku'],
  },
  {
    name: 'Gifu',
    roads: ['kiyosu'],
    highways: ['iwamur', 'sawaya'],
    x: 1256,
    y: 1012,
    policy: 'u',
    castle: 'i',
    ishir: 2,
    locationId: 'gifu',
  },
  {
    name: 'Hakone',
    roads: [],
    highways: ['numazu', 'edo'],
    x: 2079,
    y: 1725,
    policy: 'd',
    castle: 't',
    tokur: 1,
    locationId: 'hakone',
  },
  {
    name: 'Hamamatsu',
    roads: [],
    highways: ['okazak', 'sunpu'],
    x: 1293,
    y: 1473,
    policy: 'd',
    locationId: 'hamama',
  },
  {
    name: 'Himeji',
    roads: ['ayabe', 'osaka'],
    highways: [],
    x: 380,
    y: 571,
    policy: 'u',
    locationId: 'himeji',
    musters: ['mori', 'ukit'],
    limit: 8,
  },
  {
    name: 'Iwaki',
    roads: ['mito', 'soma'],
    highways: [],
    x: 2981,
    y: 1484,
    policy: 'd',
    locationId: 'iwaki',
  },
  {
    name: 'Iwamura',
    roads: [],
    highways: ['gifu', 'kiso'],
    x: 1453,
    y: 1103,
    policy: 'u',
    locationId: 'iwamur',
  },
  {
    name: 'Kanazawa',
    roads: ['kitano', 'toyama'],
    highways: [],
    x: 1640,
    y: 372,
    policy: 'm',
    castle: 't',
    tokus: 'triangle',
    tokur: 1,
    locationId: 'kanaza',
    musters: ['maed'],
  },
  {
    name: 'Kiso',
    roads: ['matsum'],
    highways: ['iwamur', 'suwa'],
    x: 1723,
    y: 1107,
    policy: 'u',
    resource: 1,
    locationId: 'kiso',
  },
  {
    name: 'Kitanosho',
    roads: ['kanaza', 'tsurug'],
    highways: [],
    x: 1301,
    y: 420,
    policy: 'm',
    locationId: 'kitano',
  },
  {
    name: 'Kiyosu',
    roads: ['gifu'],
    highways: ['kuwana', 'okazak'],
    x: 1171,
    y: 1140,
    policy: 'u',
    tokus: 'star',
    tokur: 1,
    locationId: 'kiyosu',
    musters: ['fuku'],
  },
  {
    name: 'Kofu',
    roads: ['numazu', 'suwa'],
    highways: [],
    x: 1839,
    y: 1389,
    policy: 'm',
    locationId: 'kofu',
  },
  {
    name: 'Kumano',
    roads: ['anotsu', 'tanabe'],
    highways: [],
    x: 563,
    y: 1278,
    policy: 'd',
    locationId: 'kumano',
  },
  {
    name: 'Kuwana',
    roads: ['anotsu'],
    highways: ['kiyosu', 'minaku'],
    x: 999,
    y: 1064,
    policy: 'd',
    resource: 1,
    locationId: 'kuwana',
    squeezeX: 0.5,
  },
  {
    name: 'Kyoto',
    roads: ['ayabe'],
    highways: ['minaku', 'osaka', 'sawaya'],
    x: 745,
    y: 830,
    policy: 'm',
    resource: 1,
    cap: 'i',
    ishir: 2,
    locationId: 'kyoto',
  },
  {
    name: 'Matsumoto',
    roads: ['arato', 'kiso'],
    highways: [],
    x: 1989,
    y: 974,
    policy: 'u',
    locationId: 'matsum',
  },
  {
    name: 'Minakuchi',
    roads: [],
    highways: ['kuwana', 'kyoto'],
    x: 896,
    y: 961,
    policy: 'm',
    limit: 4,
    locationId: 'minaku',
    squeezeY: 0.8,
  },
  {
    name: 'Mito',
    roads: ['edo', 'iwaki'],
    highways: [],
    x: 2749,
    y: 1663,
    policy: 'd',
    locationId: 'mito',
  },
  {
    name: 'Miyazu',
    roads: ['ayabe', 'tsurug'],
    highways: [],
    tokur: 1,
    x: 791,
    y: 468,
    policy: 'u',
    castle: 't',
    locationId: 'miyazu',
  },
  {
    name: 'Murakami',
    roads: ['niigat', 'yamaga'],
    highways: [],
    x: 3095,
    y: 568,
    policy: 'u',
    locationId: 'muraka',
  },
  {
    name: 'Nagaoka',
    roads: ['niigat', 'takeda', 'uonuma'],
    highways: [],
    x: 2633,
    y: 605,
    policy: 'u',
    locationId: 'nagaok',
  },
  {
    name: 'Nihonmatsu',
    roads: ['sendai', 'shirak'],
    highways: [],
    x: 3159,
    y: 1117,
    policy: 'm',
    locationId: 'nihonm',
    limit: 8,
  },
  {
    name: 'Niigata',
    roads: ['muraka', 'nagaok'],
    highways: [],
    x: 2855,
    y: 545,
    policy: 'u',
    resource: 1,
    locationId: 'niigat',
  },
  {
    name: 'Numazu',
    roads: ['kofu'],
    highways: ['hakone', 'sunpu'],
    x: 1834,
    y: 1631,
    policy: 'd',
    locationId: 'numazu',
  },
  {
    name: 'Okazaki',
    roads: [],
    highways: ['hamama', 'kiyosu'],
    x: 1151,
    y: 1262,
    policy: 'd',
    castle: 't',
    locationId: 'okazak',
    xPolicy: 'r',
  },
  {
    name: 'Osaka',
    roads: ['himeji', 'wakaya'],
    highways: ['kyoto'],
    x: 555,
    y: 834,
    policy: 'm',
    castle: 'i',
    ishis: 'square',
    ishir: 2,
    locationId: 'osaka',
    musters: ['koba'],
    limit: 8,
  },
  {
    name: 'Osaka disc',
    locationId: 'd_osaka',
    x: 534,
    y: 924,
    policy: 'm',
    ishis: 'osaka',
    locationType: 'discs',
    roads: [],
    highways: [],
  },
  {
    name: 'Oshi',
    roads: [],
    highways: ['edo', 'takasa'],
    x: 2407,
    y: 1469,
    policy: 'm',
    locationId: 'oshi',
  },
  {
    name: 'Oyama',
    roads: ['edo', 'utsuno'],
    highways: [],
    x: 2588,
    y: 1556,
    policy: 'm',
    locationId: 'oyama',
  },
  {
    name: 'Saku',
    roads: ['ueda'],
    highways: ['suwa', 'takasa'],
    x: 2178,
    y: 1153,
    policy: 'd',
    locationId: 'saku',
  },
  {
    name: 'Sawayama',
    roads: ['tsurug'],
    highways: ['gifu', 'kyoto'],
    x: 1037,
    y: 831,
    policy: 'm',
    locationId: 'sawaya',
  },
  {
    name: 'Sendai',
    roads: ['nihonm', 'soma', 'yamaga'],
    highways: [],
    x: 3308,
    y: 1093,
    policy: 'm',
    tokus: 'circle',
    tokur: 1,
    locationId: 'sendai',
    musters: ['date'],
    squeezeX: 0.5,
  },
  {
    name: 'Shibukawa',
    roads: ['takasa', 'uonuma'],
    highways: [],
    x: 2522,
    y: 1099,
    policy: 'm',
    locationId: 'shibuk',
  },
  {
    name: 'Shirakawa',
    roads: ['aizu', 'nihonm', 'utsuno'],
    highways: [],
    x: 2955,
    y: 1167,
    policy: 'm',
    resource: 1,
    locationId: 'shirak',
  },
  {
    name: 'Soma',
    roads: ['iwaki', 'sendai'],
    highways: [],
    x: 3244,
    y: 1328,
    policy: 'd',
    locationId: 'soma',
  },
  {
    roads: [],
    highways: ['hamama', 'numazu'],
    name: 'Sunpu',
    x: 1549,
    y: 1587,
    policy: 'd',
    locationId: 'sunpu',
  },
  {
    name: 'Suwa',
    roads: ['kofu'],
    highways: ['kiso', 'saku'],
    x: 1940,
    y: 1203,
    policy: 'm',
    locationId: 'suwa',
  },
  {
    name: 'Takasaki',
    roads: ['shibuk'],
    highways: ['oshi', 'saku'],
    x: 2364,
    y: 1251,
    policy: 'm',
    resource: 1,
    locationId: 'takasa',
  },
  {
    name: 'Takeda',
    roads: ['arato', 'nagaok', 'toyama'],
    highways: [],
    x: 2334,
    y: 631,
    policy: 'u',
    resource: 1,
    locationId: 'takeda',
  },
  {
    name: 'Tanabe',
    roads: ['kumano', 'wakaya'],
    highways: [],
    x: 238,
    y: 1233,
    policy: 'd',
    locationId: 'tanabe',
  },
  {
    name: 'Toyama',
    roads: ['kanaza', 'takeda'],
    highways: [],
    x: 1986,
    y: 513,
    policy: 'm',
    locationId: 'toyama',
  },
  {
    name: 'Tsugawa',
    roads: ['aizu', 'niigat'],
    highways: [],
    x: 2919,
    y: 744,
    policy: 'm',
    locationId: 'tsugaw',
  },
  {
    name: 'Tsuruga',
    roads: ['kitano', 'miyazu', 'sawaya'],
    highways: [],
    x: 1148,
    y: 604,
    policy: 'u',
    resource: 1,
    locationId: 'tsurug',
  },
  {
    name: 'Ueda',
    roads: ['arato', 'saku'],
    highways: [],
    x: 2189,
    y: 1002,
    policy: 'u',
    castle: 'i',
    ishir: 3,
    locationId: 'ueda',
  },
  {
    name: 'Ueda disc',
    locationId: 'd_ueda',
    x: 2314,
    y: 1041,
    policy: 'm',
    ishis: 'ueda',
    locationType: 'discs',
    roads: [],
    highways: [],
  },
  {
    name: 'Uonuma',
    roads: ['nagaok', 'shibuk'],
    highways: [],
    x: 2644,
    y: 843,
    policy: 'd',
    locationId: 'uonuma',
  },
  {
    name: 'Utsunomiya',
    roads: ['oyama', 'shirak'],
    highways: [],
    x: 2789,
    y: 1342,
    policy: 'm',
    locationId: 'utsuno',
  },
  {
    name: 'Wakayama',
    roads: ['osaka', 'tanabe'],
    highways: [],
    x: 283,
    y: 993,
    policy: 'u',
    locationId: 'wakaya',
  },
  {
    name: 'Yamagata',
    roads: ['muraka', 'sendai'],
    highways: [],
    x: 3308,
    y: 732,
    policy: 'u',
    locationId: 'yamaga',
  },
  {
    roads: [],
    highways: [],
    name: 'Mōri box',
    x: 2412,
    y: 400,
    policy: 'box',
    ishis: 'triangle',
    locationId: 'moribox',
  },
  {
    roads: [],
    highways: [],
    name: 'Ishida reinforcements',
    x: 2987,
    y: 400,
    policy: 'box',
    ishir: 4,
    locationId: 'ibox',
    squeezeX: 1,
    limit: 4,
  },
  {
    roads: [],
    highways: [],
    name: 'Tokugawa reinforcements',
    x: 3011,
    y: 1930,
    policy: 'box',
    tokur: 4,
    locationId: 'tbox',
    squeezeX: 1,
    limit: 4,
  },
];

export function sekiFindLocationById(locationId) {
  return sekiLocationData.find((x) => x.locationId === locationId);
}

export const bothRoles = ['i', 't'];

export function sekiGetMovementPenaltyForUnitAmount(amount): number | null {
  if (amount <= 4) {
    return 0;
  } else if (amount <= 8) {
    return -1;
  } else if (amount <= 12) {
    return -2;
  } else if (amount <= 16) {
    return -3;
  } else {
    return null;
  }
}

export function sekiGetOpponentRole(role) {
  return role === 'i' ? 't' : 'i';
}

export function sekiGetConnectionName(locationId1, locationId2) {
  return 'conn/' + [locationId1, locationId2].sort().join('-');
}

function initializeConnections() {
  const seen = {};
  const connections = [];

  sekiLocationData.forEach((location) => {
    const { locationId, x: x1, y: y1 } = location;

    ['roads', 'highways'].forEach((key) => {
      (location[key] || []).forEach((targetLocationId) => {
        const targetLocation = sekiFindLocationById(targetLocationId);

        const { x: x2, y: y2 } = targetLocation;

        const connectionName = sekiGetConnectionName(
          locationId,
          targetLocationId
        );

        if (seen[connectionName]) {
          return;
        }
        seen[connectionName] = true;

        const x = (x1 + x2) / 2;
        const y = (y1 + y2) / 2;
        connections.push({
          connectionName,
          x,
          y,
        });
      });
    });
  });
  return connections;
}

export const sekiConnections = initializeConnections();
