import { useDispatch } from 'react-redux';
import { answerSent, setAnswer } from 'slices/runtimeStatesSlice';
import { sendWsMessage } from 'connector';

export function usePutAnswer(shortName) {
  const dispatch = useDispatch();

  return function (answer) {
    dispatch(setAnswer({ shortName, answer }));
  };
}

export function useSendAnswer(yourRole, shortName) {
  const dispatch = useDispatch();

  return function (answer) {
    sendWsMessage({
      tag: 'sendAnswer',
      shortName,
      answer,
      role: yourRole,
    });
    dispatch(answerSent({ shortName }));
  };
}
