/* eslint-disable jsx-a11y/alt-text */
import React, {
  useEffect,
  Fragment,
  useState,
  useRef,
  useLayoutEffect,
  useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useDebouncedCallback } from 'use-debounce';
import { useSendAnswer } from 'utils/playGamePageUtils';
import { Separator } from 'components/Separator';
import { Button } from 'components/Button';
import {
  IoMdAdd,
  IoMdRemove,
  IoMdArrowDropdown,
  IoMdArrowDropup,
} from 'react-icons/io';
import { GameUiContext } from 'components/PlayGamePage/GameUiContext';
import {
  sekiLocationData,
  sekiConnections,
  sekiFindLocationById,
  sekiGetUnitInfoByBase,
  sekiGetMovementPenaltyForUnitAmount,
  getBaseFromCid,
  sekiGetCardInfoByBase,
  makeCardVSource,
  makeZoneVSource,
  getStateVar,
  getStateNumberVar,
  buildZoneName,
  qualifiedName,
  sekiGetOpponentRole,
  sekiGetAttackerRole,
  sekiClanToMusteringLocationId,
  separate,
  addToKeyedArray,
  sekiCalculateAddedImpact,
  getNCardsInZone,
  sekiGetCombatOutcome,
  repeatString,
} from '@cr/engine';
import { CardCrop } from 'components/PlayGamePage/CardCrop';
import { setAnswer, setGlobalValue } from 'slices/runtimeStatesSlice';
import {
  sekiGetActionLineContentTemplate,
  sekiGetRoleForHumans,
  sekiGetModeForHumans,
  sekiGetLocationForHumans,
  sekiGetOutcomeTranslation,
} from 'data/sekiData';
import { MessageFromTemplate } from 'components/MessageFromTemplate';
import {
  getConnectionStatusText,
  getTitleFromLines,
  pluralize,
  boldedList,
  interpose,
} from 'utils';
import {
  TransformWrapper,
  TransformComponent,
} from '@pronestor/react-zoom-pan-pinch';
import { registerGd } from 'data/gdRegistry';

const dashSeparator = ' – ';

export function useDebouncedResizeRecalculation(callback, deps) {
  const [debounced] = useDebouncedCallback(callback, 200, deps);

  /* eslint-disable react-hooks/exhaustive-deps */
  useLayoutEffect(() => {
    callback();

    window.addEventListener('resize', debounced);
    return () => {
      window.removeEventListener('resize', debounced);
    };
  }, deps);
  /* eslint-enable react-hooks/exhaustive-deps */
}

export const bothRoles = ['i', 't'];

const clanWeight = {
  ishi: 1,
  mori: 2,
  koba: 3,
  uesu: 4,
  ukit: 5,
  toku: 11,
  ii: 12,
  date: 13,
  fuku: 14,
  maed: 15,
};

const kindWeight = {
  leader: 1,
  arq: 2,
  cav: 3,
  devils: 4,
  elite: 5,
  regular: 6,
};

const capabilityWeightByKind = {
  leader: 1,
  arq: 2,
  cav: 3,
  devils: 4,
  elite: 4,
  regular: 4,
};

export function getZoneCards(cs, zoneName) {
  const zone = cs['zones'][zoneName];
  return zone['cards'];
}

export function compareClans(aClan, bClan) {
  const aw = clanWeight[aClan],
    bw = clanWeight[bClan];
  const result = aw - bw;
  return result;
}

export function compareKinds(aKind, bKind) {
  const aw = kindWeight[aKind],
    bw = kindWeight[bKind];
  const result = aw - bw;
  return result;
}

export function compareCapabilities(aKind, bKind) {
  const aw = capabilityWeightByKind[aKind],
    bw = capabilityWeightByKind[bKind];
  const result = aw - bw;
  return result;
}

export function createUnitComparator(sortPriority, isAscending = true) {
  if (sortPriority === 'clanFirstDesc') {
    return createUnitComparator('clanFirst', false);
  } else if (sortPriority === 'kindFirstDesc') {
    return createUnitComparator('kindFirst', false);
  } else {
    return function compareUnits(a, b) {
      let result;
      if (sortPriority === 'clanFirst') {
        // Clan first: all Tokugawa, then all Ii, etc.; within the clan, leaders
        // then arquebusiers etc.
        const clanRank = compareClans(a.clan, b.clan);
        if (clanRank !== 0) {
          result = clanRank;
        } else {
          const kindRank = compareKinds(a.kind, b.kind);
          result = kindRank;
        }
      } else {
        // Kind first: all leaders, all arquebusiers, all cavalry, all
        // non-symboled; within the group, all Tokugawa then all Ii; and within
        // the clan group, Elite are before Regular.

        const capabilityRank = compareCapabilities(a.kind, b.kind);

        if (capabilityRank !== 0) {
          result = capabilityRank;
        } else {
          const clanRank = compareClans(a.clan, b.clan);
          if (clanRank !== 0) {
            result = clanRank;
          } else {
            const kindRank = compareKinds(a.kind, b.kind);
            result = kindRank;
          }
        }
      }

      if (!isAscending) {
        result = -result;
      }

      return result;
    };
  }
}

export function sortUnits(units, sortPriority) {
  const result = [...units].sort(createUnitComparator(sortPriority));
  return result;
}

export function separateUnits(units) {
  const separatedUnits = {
    t: sortUnits(units.filter((unit) => unit.cardBack === 'tb')),
    i: sortUnits(units.filter((unit) => unit.cardBack === 'ib')),
  };
  return separatedUnits;
}

export function getPlayerUnitsAtLocation(cs, role, locationId, sortPriority) {
  const zoneName = `${role}:${locationId}`;
  const unitCards = cs.zones[zoneName].cards;

  const units = unitCards.map((unitCard) => {
    const { cardBack, yourKnowledge, facing, vars } = unitCard;
    const { cid, knowledgeType } = yourKnowledge;

    if (knowledgeType === 'limited') {
      return {
        cid,
        isKnown: false,
        cardBack,
        faction: cardBack === 'ib' ? 'i' : 't',
        facing,
      };
    } else {
      const base = getBaseFromCid(cid);
      const unitInfo = sekiGetUnitInfoByBase(base);

      return {
        ...unitInfo,
        cid,
        isKnown: true,
        facing,
        vars,
      };
    }
  });

  return sortUnits(units, sortPriority);
}

export function getDiscsAtLocation(cs, locationId) {
  const role = 'i';

  const zoneName = `${role}:d_${locationId}`;
  if (!(zoneName in cs.zones)) {
    return [];
  }
  const unitCards = cs.zones[zoneName].cards;
  return unitCards.map((unitCard) => {
    const { yourKnowledge } = unitCard;
    const { cid } = yourKnowledge;
    const base = getBaseFromCid(cid);
    const unitInfo = sekiGetUnitInfoByBase(base);

    return {
      ...unitInfo,
      cid,
      isKnown: true,
    };
  });
}

export function unitDeploymentNumberComparator(a, b) {
  const adn = a.vars.deploymentNumber;
  const bdn = b.vars.deploymentNumber;
  // reversed because UnitStack will show them in reverse.
  return bdn - adn;
}

export function getSeparatedUnitsAtLocation(cs, locationId, sortPriority) {
  return {
    t: getPlayerUnitsAtLocation(cs, 't', locationId, sortPriority),
    i: getPlayerUnitsAtLocation(cs, 'i', locationId, sortPriority),
  };
}

export function getFourLetterRoleString(role) {
  return role === 't' ? 'toku' : 'ishi';
}

export function getRoleFromPlayerSource(source) {
  return source.split(':')[1];
}

export function getCidFromCardVSource(vSource) {
  return vSource.split(':')[1];
}

export function getUnitImageHref(unit) {
  let { face, isKnown, faction } = unit;

  const displayFace = isKnown
    ? face
    : `back-${getFourLetterRoleString(faction)}`;

  return `/seki/unit/${displayFace}.png`;
}

const mapSize = {
  w: 3380,
  h: 2160,
};

const unitSize = {
  w: 105,
  h: 50,
};

const markers = [
  {
    start: {
      x: 89,
      y: 1826,
    },
    finish: {
      x: 143,
      y: 1883,
    },
    name: 'imp0',
  },
  {
    start: {
      x: 147,
      y: 1826,
    },
    finish: {
      x: 201,
      y: 1884,
    },
    name: 'imp21',
  },
  {
    start: {
      x: 90,
      y: 1763,
    },
    finish: {
      x: 144,
      y: 1824,
    },
    name: 'imp1',
  },
  {
    start: {
      x: 61,
      y: 110,
    },
    finish: {
      x: 126,
      y: 174,
    },
    name: 'turn1',
  },
  {
    start: {
      x: 137,
      y: 110,
    },
    finish: {
      x: 202,
      y: 174,
    },
    name: 'turn2',
  },
  {
    start: {
      x: 148,
      y: 47,
    },
    finish: {
      x: 190,
      y: 83,
    },
    name: 'reinremin2',
  },
  {
    start: {
      x: 149,
      y: 199,
    },
    finish: {
      x: 190,
      y: 237,
    },
    name: 'turnhalf-2a',
  },
  {
    start: {
      x: 149,
      y: 274,
    },
    finish: {
      x: 190,
      y: 312,
    },
    name: 'turnhalf-2b',
  },
  {
    start: {
      x: 277,
      y: 439,
    },
    finish: {
      x: 357,
      y: 521,
    },
    name: 'recruit-ukita',
  },
  {
    start: {
      x: 277,
      y: 543,
    },
    finish: {
      x: 357,
      y: 624,
    },
    name: 'recruit-mori',
  },
  {
    start: {
      x: 413,
      y: 807,
    },
    finish: {
      x: 494,
      y: 889,
    },
    name: 'recruit-kobayakawa',
  },
  {
    start: {
      x: 505,
      y: 922,
    },
    finish: {
      x: 583,
      y: 1002,
    },
    name: 'disc-osaka',
  },
  {
    start: {
      x: 1036,
      y: 1167,
    },
    finish: {
      x: 1117,
      y: 1248,
    },
    name: 'recruit-fukushima',
  },
  {
    start: {
      x: 2323,
      y: 1042,
    },
    finish: {
      x: 2401,
      y: 1122,
    },
    name: 'disc-ueda',
  },
  {
    start: {
      x: 2440,
      y: 1858,
    },
    finish: {
      x: 2521,
      y: 1940,
    },
    name: 'recruit-tokugawa',
  },
  {
    start: {
      x: 3278,
      y: 1016,
    },
    finish: {
      x: 3358,
      y: 1098,
    },
    name: 'recruit-date',
  },
  {
    start: {
      x: 2959,
      y: 934,
    },
    finish: {
      x: 3038,
      y: 1015,
    },
    name: 'recruit-uesugi',
  },
  {
    start: {
      x: 1578,
      y: 416,
    },
    finish: {
      x: 1659,
      y: 498,
    },
    name: 'recruit-maeda',
  },
];

function ZoomControls(props) {
  const { zoomIn, zoomOut } = props;

  return (
    <div className="ZoomControls">
      <Button
        isSecondary
        onClick={() => {
          zoomIn();
        }}
      >
        <IoMdAdd />
      </Button>

      <Button
        isSecondary
        onClick={() => {
          zoomOut();
        }}
      >
        <IoMdRemove />
      </Button>
    </div>
  );
}

const boardSrc = '/seki/seki-board.webp';

function CardZone(props) {
  const { zoneName, areCardsHoverable, isHidden, isInLocationModal } = props;
  const { cs } = useContext(GameUiContext);

  const cards = getZoneCards(cs, zoneName);
  return (
    <div
      className={clsx('CardZone', {
        isHidden,
      })}
    >
      <CardsRow
        cards={cards}
        areCardsHoverable={areCardsHoverable}
        isInLocationModal={isInLocationModal}
      />
    </div>
  );
}

function CombinedCardZone(props) {
  const { zoneNames, areCardsHoverable } = props;
  const { cs } = useContext(GameUiContext);

  const cards = [].concat(
    ...zoneNames.map((zoneName) => {
      return getZoneCards(cs, zoneName);
    })
  );

  return (
    <div className={clsx('CardZone')}>
      <CardsRow cards={cards} areCardsHoverable={areCardsHoverable} />
    </div>
  );
}

function getOrderedRoles(cs) {
  if (cs.pov === 't') {
    return ['t', 'i'];
  } else {
    return bothRoles;
  }
}

function stringComparator(a, b) {
  if (a < b) {
    return -1;
  } else if (a === b) {
    return 0;
  } else {
    return 1;
  }
}

function SekiLocationZones(props) {
  const { hideUnits } = props;

  const { cs, globals } = useContext(GameUiContext);

  const currentSortPriority =
    globals && globals.sortPriority ? globals.sortPriority : 'clanFirst';

  const result = [];

  sekiLocationData.forEach((location) => {
    let { locationId, policy, limit, squeezeX, squeezeY } = location;
    if (!limit) {
      limit = 6;
    }
    if (!squeezeX) {
      squeezeX = 0.8;
    }
    if (!squeezeY) {
      squeezeY = 1;
    }

    if (hideUnits) {
      return null;
    }

    const separatedUnits = getSeparatedUnitsAtLocation(
      cs,
      locationId,
      currentSortPriority
    );

    const roles = getOrderedRoles(cs);
    const { pov } = cs;
    const cidToPosition = {}; // column, row

    let column = 0;
    let row = 0;
    let actualLimit = null;

    roles.forEach((role) => {
      const roleUnits = separatedUnits[role].reverse();

      const nRoleUnits = roleUnits.length;

      roleUnits.forEach((unit, unitIndex) => {
        cidToPosition[unit.cid] = {
          column,
          row,
        };
        row += 1;

        if (row === limit) {
          column += 1;
          row = 0;
        }
      });

      if (nRoleUnits > 0) {
        if (nRoleUnits % limit !== 0) {
          column += 1;
        }
        row = 0;
      }

      if (
        0 < nRoleUnits &&
        nRoleUnits < limit &&
        (actualLimit === null || nRoleUnits > actualLimit)
      ) {
        actualLimit = nRoleUnits;
      }
    });

    const nColumns = column;
    const nRows = actualLimit === null ? limit : actualLimit;

    roles.forEach((role) => {
      const roleUnits = separatedUnits[role].reverse();
      roleUnits.forEach((unit, unitIndex) => {
        let { cid, faction, kind } = unit;

        const href = getUnitImageHref(unit);
        const { row, column } = cidToPosition[unit.cid];

        const dx = placeInRow(
          column,
          nColumns,
          unitSize.w,
          unitSize.w * nColumns * squeezeX
        );
        const dy = placeInRow(
          row,
          nRows,
          unitSize.h,
          unitSize.h * nRows * squeezeY
        );

        const baseY = location.y - unitSize.h * 0.5;
        let startY;
        if (policy === 'd') {
          startY = baseY - unitSize.h + nRows * unitSize.h * 0.5;
        } else if (policy === 'm') {
          startY = baseY - unitSize.w * 0.25;
        } else if (policy === 'u' || policy === 'box') {
          startY = baseY - nRows * unitSize.h * 0.5;
        } else {
          throw new Error(`Invalid policy ${policy} of ${locationId}`);
        }

        const x = location.x + dx;
        const y = startY - dy;
        const unitImage = (
          <image
            key={
              kind === 'disc'
                ? // sort earlier than units so that units cover discs.
                  `0_${cid}`
                : // your priority blocks should cover other ones
                  `1_${
                    pov === 'i'
                      ? faction === 'i'
                        ? 1
                        : 0
                      : faction === 't'
                      ? 1
                      : 0
                  }_${cid}`
            }
            onDragStart={(e) => {
              e.preventDefault();
            }}
            className={clsx('unit', { [faction]: true })}
            style={{ transform: `translate(${x}px,${y}px)` }}
            x={0}
            y={0}
            width={unitSize.w}
            height={unitSize.h}
            href={href}
          />
        );
        result.push(unitImage);
      });
    });
  });
  // XXX maybe it's not very proper, but when we are not sorting the array,
  // React seems to remove/add elements when order changes.
  result.sort((a, b) => {
    return stringComparator(a.key, b.key);
  });
  return result;
}

function placeInRow(index, nItems, itemWidth, totalWidth) {
  const fittingWidth = totalWidth / nItems;
  const start = 0 - (nItems / 2) * fittingWidth;
  const result = start + index * fittingWidth + (fittingWidth - itemWidth) / 2;
  return result;
}

function MovementTargetIconRow(props) {
  const { pov, vOrder, startX, startY, baseSize } = props;

  const { movementTarget } = vOrder.additionalData;

  let iconDescriptors = [
    movementTarget.h ? 'mth' : null,
    movementTarget.al ? `mtal-${pov}` : null,
    movementTarget.dl ? `mtdl-${pov}` : null,
    movementTarget.fm ? `icon-card-${pov}` : null,
    movementTarget.dlfmChoice ? `mtdlfmChoice-${pov}` : null,
    movementTarget.ms ? 'icon-conflict' : null,
    // TODO overrun icon
  ].filter((x) => !!x);

  if (iconDescriptors.length === 0) {
    iconDescriptors = [`icon-walk`];
  }

  const width = baseSize * 2;
  const iconSize = (baseSize * 2) / 3 - 5;

  const row = (
    <>
      <rect
        className="movementTargetIcons"
        x={startX}
        y={startY - baseSize * 2}
        width={width}
        height={baseSize}
        rx="10"
      />
      {iconDescriptors.map((iconName, index) => {
        const url = `/seki/${iconName}.webp`;
        const iconX = placeInRow(
          index,
          iconDescriptors.length,
          iconSize,
          width
        );
        return (
          <image
            key={iconName}
            x={startX + baseSize + iconX}
            y={startY - baseSize * 2 + (baseSize - iconSize) / 2}
            width={iconSize}
            height={iconSize}
            href={url}
          />
        );
      })}
    </>
  );

  return row;
}

function MovementTargetIcons(props) {
  const { pov, movementTargetOrders, startX, startY, baseSize } = props;
  const n = movementTargetOrders.length;

  if (n === 0) {
    return null;
  }

  return movementTargetOrders.map((vOrder, index) => {
    return (
      <MovementTargetIconRow
        key={index}
        pov={pov}
        startX={startX}
        startY={startY - (n - 1) * baseSize + index * baseSize}
        vOrder={vOrder}
        rowIndex={0}
        baseSize={baseSize}
      />
    );
  });
}

function SekiLocationMarkers(props) {
  const { cs } = useContext(GameUiContext);

  const { pov } = cs;

  const { pointOfAttentionLocation, setPointOfAttentionLocation } = props;

  const baseSize = 60;

  return (
    <>
      {sekiLocationData.map((location, index) => {
        const { x, y, locationId, locationType } = location;

        if (locationType === 'discs') {
          return null;
        }
        const vSource = `zone:${pov}:${locationId}`;

        const myOrders = cs.vSourceToVOrders[vSource] || [];

        let isActive;

        if (myOrders.length > 0) {
          isActive = true;
        }

        const isPointOfAttention = pointOfAttentionLocation === locationId;

        return (
          <circle
            key={locationId}
            onMouseEnter={() => {
              setPointOfAttentionLocation(locationId);
            }}
            onMouseLeave={() => {
              setPointOfAttentionLocation(null);
            }}
            className={clsx(
              'svgMarker',
              'location',
              `staggerStraight${index}`,
              {
                isActive,
                isPointOfAttention,
              }
            )}
            style={{ transformOrigin: `${x}px ${y}px` }}
            data-location-id={locationId}
            cx={x}
            cy={y}
            r={baseSize}
          />
        );
      })}
      {sekiLocationData.map((location, index) => {
        const { x, y, locationId } = location;

        const vSource = `zone:${pov}:${locationId}`;

        const myOrders = cs.vSourceToVOrders[vSource] || [];

        let more = null;

        if (myOrders.length > 0) {
          const movementTargetOrders = myOrders.filter(
            (vOrder) =>
              vOrder.orderName === 'movementPhase/chooseMovementTarget' ||
              vOrder.orderName === 'combatPhase/retreatToAdjacentLocation'
          );

          more = (
            <MovementTargetIcons
              key={locationId}
              pov={pov}
              movementTargetOrders={movementTargetOrders}
              startX={x - baseSize}
              startY={y}
              baseSize={baseSize}
            />
          );
        }

        return more;
      })}
    </>
  );
}

function SekiCoveredConnectionIcons() {
  const { cs } = useContext(GameUiContext);

  const iconSize = 64;
  return sekiConnections.map((connection) => {
    const { connectionName, x, y } = connection;
    const isCovered = getStateVar(cs, connectionName, null) !== null;

    return (
      <image
        key={connectionName}
        className={clsx('connectionIcon', { isCovered })}
        href="/seki/icon-walk.webp"
        x={x - iconSize / 2}
        y={y - iconSize / 2}
        width={iconSize}
        height={iconSize}
      />
    );
  });
}

const TAP_DISTANCE_THRESHOLD_PIXELS_SQUARED = 50;

function getEventCoordinates(event) {
  if (event.touches) {
    return [event.touches[0].clientX, event.touches[0].clientY];
  } else {
    return [event.clientX, event.clientY];
  }
}

function SekiBoard(props) {
  const viewportRef = useRef(null);
  const gameUiCtx = useContext(GameUiContext);
  const { pgpDispatch } = gameUiCtx;

  const [hideUnits, setHideUnits] = useState(false);

  const [pointOfAttentionLocation, setPointOfAttentionLocation] =
    useState(null);

  const [width, setWidth] = useState(0);

  // Sizer width should be something sensible, and I decided to use the
  // viewport width, so that at zoomLevel=1 the whole board fits, regardless of
  // the screen size.  However this means we have to use JS for sizes, because
  // react-zoom-pan-pinch adds its own components in-between, and uses
  // fit-content, therefore we cannot set the width to 100% via CSS.
  // spaceLimiter is here so that react-zoom-pan-pinch plays nicely within the
  // bounds; and sizer defines the svg size to match img size.

  useDebouncedResizeRecalculation(() => {
    const bb = viewportRef.current.getBoundingClientRect();
    setWidth(bb.width);
  }, []);

  const dragRef = useRef({
    x: null,
    y: null,
    isDragging: false,
    tooMuch: false,
  });

  return (
    <div className="boardOuter" ref={viewportRef}>
      <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
        maxScale={4}
        centerOnInit
        doubleClick={{ disabled: true }}
        onPanningStart={(ref, event) => {
          const coords = getEventCoordinates(event);
          dragRef.current.coords = coords;
          dragRef.current.isDragging = true;
          dragRef.current.tooMuch = false;
        }}
        onPanning={(ref, event) => {
          const [sx, sy] = dragRef.current.coords;
          const [x, y] = getEventCoordinates(event);
          const dx = x - sx;
          const dy = y - sy;
          const distanceSquared = dx * dx + dy * dy;
          const tooMuch =
            distanceSquared > TAP_DISTANCE_THRESHOLD_PIXELS_SQUARED;
          dragRef.current.tooMuch = tooMuch;
        }}
        onPanningStop={(ref, event) => {
          const { locationId } = event.target.dataset;
          dragRef.current.isDragging = false;

          if (!locationId) {
            return null;
          }
          if (dragRef.current.tooMuch) {
            return null;
          }
          pgpDispatch({
            type: 'showModal',
            modalType: 'sekiLocation',
            locationId,
          });
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <div className="spaceLimiter">
            <TransformComponent
              wrapperStyle={{
                width: '100%',
                height: '100%',
              }}
            >
              <div
                className="sizer"
                style={{
                  width: `${width}px`,
                }}
              >
                <img
                  width="100%"
                  className="boardImage"
                  src={boardSrc}
                  alt="game board"
                />
                <svg
                  className="widgets"
                  viewBox={`0 0 ${mapSize.w} ${mapSize.h}`}
                >
                  <SekiCoveredConnectionIcons />
                  <SekiLocationZones hideUnits={hideUnits} />
                  {markers.map((marker) => {
                    const { name, start, finish } = marker;

                    return (
                      <rect
                        className={clsx('svgMarker', { [name]: true })}
                        key={name}
                        x={start.x}
                        y={start.y}
                        width={finish.x - start.x}
                        height={finish.y - start.y}
                        r={10}
                      />
                    );
                  })}
                  <SekiLocationMarkers
                    pointOfAttentionLocation={pointOfAttentionLocation}
                    setPointOfAttentionLocation={setPointOfAttentionLocation}
                  />
                </svg>
              </div>
            </TransformComponent>
            <ZoomControls zoomIn={zoomIn} zoomOut={zoomOut} />
          </div>
        )}
      </TransformWrapper>
      <Button
        hasPositionAbsolute
        style={{
          right: '3rem',
          top: '5.5rem',
          zIndex: 1,
        }}
        onClick={() => {
          setHideUnits(!hideUnits);
        }}
      >
        {`!`}
      </Button>
    </div>
  );
}

export function UnitStack(props) {
  const { units, activeCids, onUnitClick } = props;

  const ref = useRef(null);

  useLayoutEffect(() => {
    // Firefox updates the parent's width too late, resulting in an impression
    // of "one click too late", so it's a hack to synchronize the width (by
    // forcing re-laout) as soon as units update.
    ref.current.parentElement.style.width = `${ref.current.clientWidth}px`;
  }, [units]);

  // TODO useTap like in CardCrop

  return (
    <div className="UnitStack" ref={ref}>
      {units.reverse().map((unit, index) => {
        const href = getUnitImageHref(unit);
        const { cid, title, vars } = unit;
        const isActive = activeCids && activeCids.includes(cid);

        const { addedImpact } = vars || {};
        return (
          <div
            key={cid}
            className={clsx('unit', `stagger${index}`, {
              isActive,
            })}
            title={title}
          >
            <div
              className="overlay"
              onClick={() => {
                if (!onUnitClick) {
                  return;
                }
                if (!isActive) {
                  return;
                }
                onUnitClick(cid, unit);
              }}
            />
            <img src={href} alt={title} />
            {!!addedImpact ? (
              <div className="addedImpactContainer">
                <div className="addedImpactLabel">+{addedImpact}</div>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

function recalculateCardsRow(rowRef, cardRef, cards, setOffset) {
  const el = rowRef.current;
  if (!el) {
    return null;
  }
  const cel = cardRef.current;
  if (!cel) {
    return null;
  }

  const cw = cel.getBoundingClientRect().width;
  const required = cards.length * cw;
  const actual = el.getBoundingClientRect().width;
  const rOffset = required < actual ? cw : (actual - cw) / (cards.length - 1);
  setOffset(rOffset);
}

function makeFilledOrderForVOrder(vOrder, more = {}) {
  const { orderName, source, disambiguationNumber } = vOrder;
  const result = {
    orderName,
    source,
    disambiguationNumber,
    ...more,
  };
  return result;
}

function getUsesForcedMarch(vOrder, answer) {
  if (!answer) {
    return false;
  }
  if (answer.disambiguationNumber !== vOrder.disambiguationNumber) {
    return false;
  }
  const { movementTarget } = vOrder.additionalData;

  return (
    (movementTarget.dlfmChoice &&
      answer.additionalData.dlfmAnswer === 'chooseFm') ||
    movementTarget.fm
  );
}

export function getCardActions(
  card,
  cs,
  answer,
  dispatch,
  shortName,
  options = {
    isInLocationModal: false,
  }
) {
  const { isInLocationModal } = options;

  if (!card.yourKnowledge) {
    console.warn('getCardActions: card is unknown');
    return null;
  }

  function putAnswer(answer) {
    dispatch(setAnswer({ shortName, answer }));
  }

  const cid = card.yourKnowledge.cid;
  const vSource = makeCardVSource(cid);

  const cardVOrders = cs.vSourceToVOrders[vSource] || [];

  const cardActions = cardVOrders
    .map((vOrder) => {
      const { orderName } = vOrder;

      if (orderName === 'turnOrder/chooseTurnOrderCard') {
        return {
          key: orderName,
          isPrimary: true,
          label: 'Choose this card',
          onInvoke() {
            const filledOrder = makeFilledOrderForVOrder(vOrder);
            putAnswer(filledOrder);
          },
          onInvokeMobile() {
            this.onInvoke();
          },
          onInvokeDesktop() {
            this.onInvoke();
          },
        };
      } else if (
        orderName === 'combatPhase/deploySingleUnitWithCard' ||
        orderName === 'combatPhase/deployUnitsWithDoubleCard'
      ) {
        if (!isInLocationModal) {
          return null;
        }
        return {
          key: orderName,
          isPrimary: true,
          onInvoke() {
            let filledOrder;
            if (!answer) {
              filledOrder = makeFilledOrderForVOrder(vOrder, {
                cids: [],
              });
            } else if (
              answer.disambiguationNumber === vOrder.disambiguationNumber
            ) {
              // Unselect the card: if there is a chosen unit that can be
              // deployed cardlessly, then switch back to that.
              const cardlesslyVOrder = vOrders.find(
                (thatVOrder) =>
                  thatVOrder.orderName === 'combatPhase/deployCardlessly'
              );
              if (
                cardlesslyVOrder &&
                answer.cids.some((chosenCid) => {
                  return cardlesslyVOrder.additionalData.cids.includes(
                    chosenCid
                  );
                })
              ) {
                filledOrder = makeFilledOrderForVOrder(cardlesslyVOrder, {
                  cids: answer.cids.filter((chosenCid) => {
                    return cardlesslyVOrder.additionalData.cids.includes(
                      chosenCid
                    );
                  }),
                });
              } else {
                filledOrder = null;
              }
            } else {
              // Preserve chosen units, now meaning "deploy this unit with
              // the card".
              filledOrder = makeFilledOrderForVOrder(vOrder, {
                cids: answer.cids.filter((thatCid) => {
                  return vOrder.additionalData.cids.includes(thatCid);
                }),
              });
            }
            putAnswer(filledOrder);
          },
          onInvokeMobile() {
            this.onInvoke();
          },
          onInvokeDesktop() {
            this.onInvoke();
          },
        };
      } else {
        console.warn('Unrecognized vOrder with card source', card, vOrder);
        return null;
      }
    })
    .filter((action) => !!action);

  const { vOrders } = cs;

  const chooseCardsActions = [];
  const chooseCardsOrders = vOrders.filter((vOrder) => {
    if (
      !(
        vOrder.orderType === 'chooseZoneCards' ||
        vOrder.orderType === 'chooseZoneCardsAndMode'
      )
    ) {
      return false;
    }

    if (vOrder.orderName === 'movementPhase/bringMori') {
      return isInLocationModal;
    }

    return true;
  });

  chooseCardsOrders.forEach((vOrder) => {
    const { orderName } = vOrder;
    const { cids } = vOrder.additionalData;
    if (!cids.includes(cid)) {
      return;
    }

    const isWithMode = vOrder.orderType === 'chooseZoneCardsAndMode';

    const action = {
      key: orderName,
      isPrimary: true,
      label:
        answer && answer.cids.includes(cid)
          ? 'Mark to keep'
          : 'Mark to discard',
      // Note: this mixes the order-specific logic.  I think it's fine for the
      // moment because this is in seki-only file.
      onInvokeMobile() {
        this.onInvoke();
      },
      onInvokeDesktop() {
        this.onInvoke();
      },
      onInvoke() {
        let newAnswer;
        if (!answer) {
          newAnswer = makeFilledOrderForVOrder(vOrder, {
            cids: [cid],
            ...(isWithMode
              ? {
                  mode: 'limited',
                }
              : {}),
          });
        } else if (answer.cids.includes(cid)) {
          const newCids = answer.cids.filter((thatCid) => thatCid !== cid);
          if (newCids.length === 0) {
            newAnswer = {
              ...answer,
              cids: [],
            };
          } else {
            newAnswer = {
              ...answer,
              cids: newCids,
              ...(isWithMode
                ? {
                    mode: 'limited', // can only go from 2 to 1
                  }
                : {}),
            };
          }
        } else {
          const { cids } = answer;
          const { maximum } = vOrder.additionalData;

          const newCids = pushWithMaximum(cids, maximum, cid);

          newAnswer = {
            ...answer,
            cids: newCids,
            ...(isWithMode
              ? {
                  mode: newCids.length === 2 ? 'total' : 'limited',
                }
              : {}),
          };
        }

        putAnswer(newAnswer);
      },
    };

    chooseCardsActions.push(action);
  });

  const forcedMarchOrders = vOrders.filter((vOrder) => {
    if (!(vOrder.orderName === 'movementPhase/chooseMovementTarget')) {
      return false;
    }

    if (getUsesForcedMarch(vOrder, answer)) {
      return true;
    }

    return false;
  });

  const forcedMarchActions = [];
  if (forcedMarchOrders.length === 1) {
    const vOrder = forcedMarchOrders[0];

    function onInvoke() {
      const newAnswer = {
        ...answer,
        fmCids:
          answer.fmCids[0] === cid
            ? []
            : pushWithMaximum(answer.fmCids, 1, cid),
      };
      putAnswer(newAnswer);
    }

    const action = {
      key: vOrder.orderName,
      isPrimary: true,
      label: answer.fmCids.includes(cid)
        ? 'Mark to keep'
        : 'Pay for forced march',
      onInvokeMobile: onInvoke,
      onInvokeDesktop: onInvoke,
    };

    forcedMarchActions.push(action);
  }

  const result = [...cardActions, ...chooseCardsActions, ...forcedMarchActions];

  return result;
}

export function CardsRow(props) {
  const { cards, areCardsHoverable, isInLocationModal } = props;

  const rowRef = useRef(null);

  const { cs, shortName, answer } = useContext(GameUiContext);

  const cardRef = useRef(null);
  const [offset, setOffset] = useState(0);

  useDebouncedResizeRecalculation(() => {
    recalculateCardsRow(rowRef, cardRef, cards, setOffset);
  }, [cards]);

  const dispatch = useDispatch();

  return (
    <div className="CardsRow" ref={rowRef}>
      {cards.map((card, index) => {
        const cardActions = getCardActions(
          card,
          cs,
          answer,
          dispatch,
          shortName,
          { isInLocationModal }
        );
        const cid = card.yourKnowledge.cid;

        return (
          <div
            className="cardHolder"
            key={cid}
            style={{
              transform: `translateX(${offset * index}px)`,
            }}
          >
            <CardCrop
              card={card}
              index={index}
              cardRef={index === 0 ? cardRef : null}
              cardActions={cardActions}
              isHoverable={areCardsHoverable}
            />
          </div>
        );
      })}
    </div>
  );
}

function getSortPriorityArrow(sortPriority, currentSortPriority) {
  let descriptor = null;
  if (
    sortPriority === 'kindFirst' &&
    (currentSortPriority === 'kindFirst' ||
      currentSortPriority === 'kindFirstDesc')
  ) {
    descriptor = currentSortPriority === 'kindFirst' ? 'asc' : 'desc';
  } else if (
    sortPriority === 'clanFirst' &&
    (currentSortPriority === 'clanFirst' ||
      currentSortPriority === 'clanFirstDesc')
  ) {
    descriptor = currentSortPriority === 'clanFirst' ? 'asc' : 'desc';
  }
  if (!descriptor) {
    // In order to have regular space so buttons don't jump, render an arrow
    // anyway, just hidden.
    return <IoMdArrowDropup style={{ visibility: 'hidden' }} />;
  } else if (descriptor === 'asc') {
    return <IoMdArrowDropup />;
  } else {
    return <IoMdArrowDropdown />;
  }
}

export function UnitSortPriorityButton(props) {
  const { sortPriority, currentSortPriority, setCurrentSortPriority } = props;

  const text = sortPriority === 'clanFirst' ? 'Clan' : 'Kind';
  const arrow = getSortPriorityArrow(sortPriority, currentSortPriority);

  return (
    <Button
      isSecondary
      onClick={() => {
        let newSortPriority;
        if (currentSortPriority === sortPriority) {
          newSortPriority = `${sortPriority}Desc`;
        } else if (currentSortPriority === `${sortPriority}Desc`) {
          newSortPriority = sortPriority;
        } else {
          newSortPriority = sortPriority;
        }

        setCurrentSortPriority(newSortPriority);
      }}
      isPressed={
        currentSortPriority === sortPriority ||
        currentSortPriority === `${sortPriority}Desc`
      }
    >
      {arrow}
      {text}
    </Button>
  );
}

export function pushWithMaximum(array, maximum, ...items) {
  const result = [...array, ...items];

  if (maximum !== null && result.length > maximum) {
    return result.slice(result.length - maximum);
  } else {
    return result;
  }
}

function initializeAnswerForChooseMovementTarget(vOrder) {
  const { movementTarget } = vOrder.additionalData;
  const cids = movementTarget.isFinal ? vOrder.additionalData.cids : [];
  const additionalData = movementTarget.dlfmChoice
    ? { dlfmAnswer: 'chooseDl' }
    : {};

  const filledOrder = makeFilledOrderForVOrder(vOrder, {
    cids,
    additionalData,
    fmCids: [],
  });
  return filledOrder;
}

export function HandInLocationModal(props) {
  const { cs } = useContext(GameUiContext);
  const { pov } = cs;

  return (
    <div className="HandInLocationModal">
      <CardZone
        zoneName={buildZoneName(pov, 'hand')}
        areCardsHoverable
        isInLocationModal
      />
    </div>
  );
}

function getLocationIdFromZoneVOrderSource(vSource) {
  return vSource.split(':')[2];
}

function LocationPreview(props) {
  const { locationId, displayHand } = props;
  const { cs } = useContext(GameUiContext);
  const location = sekiFindLocationById(locationId);
  const { x, y } = location;

  return (
    <div className={clsx('locationPreview', { displayHand })}>
      <div className="holder">
        <img
          src={boardSrc}
          alt="location preview"
          style={{
            transform: `translate(-${x}px, -${y}px) scale(50%)`,
            transformOrigin: `${x}px ${y}px`,
          }}
        />
        <div className="unitCountsLabel">
          {getOrderedRoles(cs).map((role) => {
            const zoneName = `${role}:${locationId}`;
            const unitCards = cs.zones[zoneName].cards;

            return (
              <div key={role} className={role}>
                {unitCards.length}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function getLocationName(locationId) {
  return sekiFindLocationById(locationId).name;
}

export function SekiLocationModalContent(props) {
  const { modalState, gameUiCtx } = props;
  const { locationId } = modalState;
  const { shortName, cs, globals, answer, pgpDispatch } = gameUiCtx;

  const location = sekiFindLocationById(locationId);
  const { name } = location;

  const { pov, vSourceToVOrders } = cs;

  const dispatch = useDispatch();

  const sendAnswer = useSendAnswer(pov, shortName);

  const currentSortPriority =
    globals && globals.sortPriority ? globals.sortPriority : 'clanFirst';

  const separatedUnits = getSeparatedUnitsAtLocation(
    cs,
    locationId,
    currentSortPriority
  );

  const isBattlingHere =
    getStateVar(cs, 'step') === 'turns' &&
    getStateVar(cs, 'phase', null) === 'combat' &&
    getStateVar(cs, 'combatLocationId', null) === locationId &&
    getStateVar(cs, 'combatType', null) !== null;

  function putAnswer(answer) {
    dispatch(setAnswer({ shortName, answer }));
  }

  function updateAnswer(updater) {
    const newAnswer = updater(answer);
    putAnswer(newAnswer);
  }

  function setCurrentSortPriority(sortPriority) {
    dispatch(
      setGlobalValue({ shortName, key: 'sortPriority', value: sortPriority })
    );
  }

  function getMainColumns() {
    const columns = [].concat(
      ...getOrderedRoles(cs).map((role) => {
        return [
          {
            key: `units_${role}`,
            label: sekiGetRoleForHumans(role),
            className: role,
            units: separatedUnits[role],
            alwaysShow: false,
          },
          role === 'i'
            ? {
                key: `discs`,
                label: 'disc',
                className: 'i',
                units: getDiscsAtLocation(cs, locationId),
                alwaysShow: false,
              }
            : null,
        ];
      })
    );

    return columns;
  }

  function getConfirm(options = {}) {
    const text = options.text || 'Confirm';
    const isDisabled = !!options.isDisabled;

    return (
      <Button
        key="confirm"
        isPrimary
        onClick={() => {
          if (isDisabled) {
            return;
          }
          let shouldHideModal = true;

          if ('disableHideModal' in options && options.disableHideModal) {
            shouldHideModal = false;
          } else if (options.disableHideModalFn) {
            if (options.disableHideModalFn()) {
              shouldHideModal = false;
            }
          }

          if (shouldHideModal) {
            pgpDispatch({ type: 'hideModal' });
          }
          sendAnswer(answer);
        }}
        isDisabled={isDisabled}
      >
        {text}
      </Button>
    );
  }

  function getMovingControls(
    allUnits,
    leftUnits,
    rightUnits,
    transferLeftToRight,
    transferRightToLeft,
    options = {
      onlyClan: false,
    }
  ) {
    const configs = [
      {
        id: 'all',
        matcher: (unit, _value) => true,
        values: ['all'],
        getIconSource: (_value) =>
          `/seki/unit/back-${getFourLetterRoleString(pov)}.png`,
      },
      {
        id: 'clan',
        matcher: (unit, value) => unit.clan === value,
        values:
          pov === 'i'
            ? ['mori', 'koba', 'uesu', 'ukit']
            : ['toku', 'date', 'fuku', 'maed'],
        getIconSource: (value) => `/seki/ci-${value}.png`,
      },
      {
        id: 'kind',
        matcher: (unit, value) => {
          if (value === 'arq' || value === 'cav') {
            return unit.special === value;
          } else if (value === 'none') {
            return unit.strength > 1;
          } else if (value === 'leader') {
            return unit.kind === 'leader';
          } else {
            throw new Error(`Unrecognized value: ${value}`);
          }
        },
        values: ['leader', 'arq', 'cav', 'none'],
        getIconSource: (value) => `/seki/ki-${pov}-${value}.png`,
      },
    ];

    const result = (
      <>
        {configs.map((config) => {
          const { id, matcher, values, getIconSource } = config;

          if (options.onlyClan && id !== 'clan') {
            return null;
          }

          return values.map((value) => {
            if (!allUnits.some((unit) => matcher(unit, value))) {
              return null;
            }
            const isPressed = !leftUnits.some((unit) => matcher(unit, value));

            return (
              <Button
                className={pov}
                title={sekiGetModeForHumans(value)}
                key={value}
                isPressed={isPressed}
                onClick={() => {
                  if (isPressed) {
                    const cids = rightUnits
                      .filter((unit) => matcher(unit, value))
                      .map((unit) => unit.cid);
                    transferRightToLeft(cids);
                  } else {
                    const cids = leftUnits
                      .filter((unit) => matcher(unit, value))
                      .map((unit) => unit.cid);
                    transferLeftToRight(cids);
                  }
                }}
              >
                <img src={getIconSource(value)} />
              </Button>
            );
          });
        })}
      </>
    );
    return result;
  }

  const vSource = makeZoneVSource(pov, locationId);
  const locationVOrders = vSourceToVOrders[vSource] || [];

  // Here we compare by the source because for moving via alternative paths,
  // there are several orders with the same location source; and we treat an
  // answer to any of them as relevant, because we want to switch between them
  // preserving information.
  const hasRelevantAnswer = answer && answer.source === vSource;

  let confirm = null;
  let confirmPlacement = null;
  let view;

  let columns;

  const yourRole = pov;
  const opponentRole = sekiGetOpponentRole(yourRole);

  let secondRow = null;
  let choosePathControls = null;

  let usesForcedMarch = false;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (locationVOrders.length > 0 && !hasRelevantAnswer) {
      const firstVOrder = locationVOrders.filter(
        (vOrder) =>
          vOrder.orderType === 'chooseZoneCards' ||
          vOrder.orderType === 'chooseZoneCardsAndMode' ||
          vOrder.orderType === 'chooseMode' ||
          vOrder.orderType === 'chooseCards' ||
          vOrder.orderType === 'simple'
      )[0];

      if (!firstVOrder) {
        return;
      }
      const { orderType, orderName, additionalData } = firstVOrder;

      let filledOrder;
      if (orderType === 'chooseZoneCards') {
        if (orderName === 'movementPhase/activateLocation') {
          filledOrder = makeFilledOrderForVOrder(firstVOrder, {
            cids: additionalData.cids.length === 1 ? additionalData.cids : [],
            additionalData: {},
          });
        } else if (orderName === 'movementPhase/chooseMovementTarget') {
          filledOrder = initializeAnswerForChooseMovementTarget(firstVOrder);
        } else if (
          orderName === 'movementPhase/changeMovingUnitsOnActivation' ||
          orderName === 'movementPhase/changeMovingUnitsOnContinuing' ||
          orderName === 'movementPhase/changeMovingUnitsOnFollowOn'
        ) {
          filledOrder = makeFilledOrderForVOrder(firstVOrder, {
            cids: additionalData.defaultChosenCids,
          });
        } else if (orderName === 'movementPhase/bringMori') {
          filledOrder = makeFilledOrderForVOrder(firstVOrder, {
            cids: [],
            // not needed by server, but makes location preview code a bit simpler
            locationId: 'osaka',
          });
        } else {
          filledOrder = makeFilledOrderForVOrder(firstVOrder, {
            cids: [],
            additionalData: {},
          });
        }
      } else if (orderType === 'chooseZoneCardsAndMode') {
        if (orderName === 'movementPhase/musterUnits') {
          filledOrder = makeFilledOrderForVOrder(firstVOrder, {
            cids: [],
            mode: null,
            locationId: null,
          });
        }
      } else if (orderType === 'chooseMode') {
        if (orderName === 'combatPhase/declareCombat') {
          filledOrder = makeFilledOrderForVOrder(firstVOrder, {
            mode: firstVOrder.additionalData.modes[0],
          });
        } else if (orderName === 'combatPhase/chooseCastleDefense') {
          filledOrder = makeFilledOrderForVOrder(firstVOrder, {
            mode: firstVOrder.additionalData.modes[0],
          });
        } else {
          // TODO
          console.warn('chooseMode on location has unknown name', orderName);
        }
      } else if (orderType === 'chooseCards') {
        if (orderName === 'combatPhase/retreatToAdjacentLocation') {
          const { minimum, maximum, cids } = firstVOrder.additionalData;
          if (minimum === maximum) {
            filledOrder = makeFilledOrderForVOrder(firstVOrder, {
              cids,
            });
          } else {
            filledOrder = makeFilledOrderForVOrder(firstVOrder, {
              cids: [],
            });
          }
        } else {
          console.warn('chooseCards on location has unknown name', orderName);
        }
      } else if (orderType === 'simple') {
        if (orderName === 'combatPhase/retreatToCastle') {
          filledOrder = makeFilledOrderForVOrder(firstVOrder);
        } else {
          console.warn(
            'simple order on location has unrecognized name',
            orderName,
            firstVOrder
          );
        }
      }
      putAnswer(filledOrder);
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  let title = name;

  const sortControls = (
    <>
      <div className="label">Order:</div>
      <UnitSortPriorityButton
        sortPriority="clanFirst"
        currentSortPriority={currentSortPriority}
        setCurrentSortPriority={setCurrentSortPriority}
      />
      <UnitSortPriorityButton
        sortPriority="kindFirst"
        currentSortPriority={currentSortPriority}
        setCurrentSortPriority={setCurrentSortPriority}
      />
    </>
  );

  let movingControls = null;

  if (!hasRelevantAnswer && !isBattlingHere) {
    view = 'main';

    columns = getMainColumns();
  } else if (
    locationVOrders.length === 1 &&
    (locationVOrders[0].orderName === 'movementPhase/activateLocation' ||
      locationVOrders[0].orderName ===
        'movementPhase/changeMovingUnitsOnActivation' ||
      locationVOrders[0].orderName === 'movementPhase/chooseFollowOnMovement' ||
      locationVOrders[0].orderName ===
        'movementPhase/changeMovingUnitsOnFollowOn')
  ) {
    view = 'moving';
    const vOrder = locationVOrders[0];
    title = `Moving from ${name}`;
    const activeCids = vOrder.additionalData.cids;

    const yourUnits = separatedUnits[yourRole].filter((unit) => {
      return activeCids.includes(unit.cid);
    });

    const leftUnits = yourUnits.filter((unit) => {
      const { cid } = unit;
      if (!answer) {
        return true;
      }
      return !answer.cids.includes(cid);
    });

    const rightUnits = answer.cids.map((cid) => {
      return yourUnits.find((unit) => unit.cid === cid);
    });

    const movementPenalty = sekiGetMovementPenaltyForUnitAmount(
      rightUnits.length
    );

    const movementPenaltyLabel =
      movementPenalty !== null && movementPenalty !== 0
        ? ` (${movementPenalty})`
        : '';

    function transferLeftToRight(cids) {
      const filledOrder = {
        ...answer,
        cids: pushWithMaximum(
          answer.cids,
          vOrder.additionalData.maximum,
          ...cids
        ),
      };
      putAnswer(filledOrder);
    }

    function transferRightToLeft(cids) {
      const filledOrder = {
        ...answer,
        cids: answer.cids.filter((thatCid) => !cids.includes(thatCid)),
      };
      putAnswer(filledOrder);
    }

    columns = [
      {
        key: `youStaying`,
        label: 'staying',
        className: yourRole,
        units: leftUnits,
        activeCids,
        onUnitClick(cid) {
          if (!hasRelevantAnswer) {
            throw new Error('must have initialized the relevant answer');
          }
          transferLeftToRight([cid]);
        },
        alwaysShow: true,
      },
      {
        key: `youMoving`,
        label: `moving${movementPenaltyLabel}`,
        className: yourRole,
        units: rightUnits,
        activeCids,
        onUnitClick(cid) {
          if (!answer) {
            throw new Error('Must have answer to click on moving units');
          }
          transferRightToLeft([cid]);
        },
        alwaysShow: true,
      },
      {
        key: 'youTired',
        label:
          vOrder.orderName === 'movementPhase/chooseFollowOnMovement'
            ? 'not moving'
            : 'tired',
        className: yourRole,
        units: separatedUnits[yourRole].filter((unit) => {
          return !activeCids.includes(unit.cid);
        }),
        alwaysShow: false,
      },
      {
        key: `opponent`,
        label: sekiGetRoleForHumans(opponentRole),
        className: opponentRole,
        units: separatedUnits[opponentRole],
        alwaysShow: false,
      },
    ];

    const isDisabled = !(
      vOrder.additionalData.minimum <= answer.cids.length &&
      answer.cids.length <= vOrder.additionalData.maximum
    );
    const text = answer.cids.length === 0 ? "Don't move" : 'Confirm';
    confirm = getConfirm({ isDisabled, text });
    confirmPlacement = 'inFirstRow';

    movingControls = getMovingControls(
      yourUnits,
      leftUnits,
      rightUnits,
      transferLeftToRight,
      transferRightToLeft
    );
  } else if (
    locationVOrders.length >= 1 &&
    (locationVOrders[0].orderName === 'movementPhase/chooseMovementTarget' ||
      locationVOrders[0].orderName ===
        'movementPhase/changeMovingUnitsOnContinuing')
  ) {
    const vOrder = locationVOrders.find(
      (thatVOrder) =>
        thatVOrder.disambiguationNumber === answer.disambiguationNumber
    );

    if (locationVOrders.length > 1) {
      choosePathControls = locationVOrders.map((vOrder, index) => {
        const { disambiguationNumber } = vOrder;
        return (
          <Button
            key={disambiguationNumber}
            isSecondary
            isPressed={disambiguationNumber === answer.disambiguationNumber}
            onClick={() => {
              const filledOrder =
                initializeAnswerForChooseMovementTarget(vOrder);
              putAnswer(filledOrder);
            }}
          >
            Path {index + 1}
          </Button>
        );
      });
    }

    const isChanging =
      vOrder.orderName === 'movementPhase/changeMovingUnitsOnContinuing';

    const { movementTarget } = vOrder.additionalData;
    const activeCids = vOrder.additionalData.cids;

    if (!isChanging) {
      let bonuses = [];
      if (movementTarget.h) {
        bonuses.push(sekiGetModeForHumans('h'));
      }
      if (movementTarget.al) {
        bonuses.push(sekiGetModeForHumans('al'));
      }
      if (movementTarget.dl) {
        bonuses.push(sekiGetModeForHumans('dl'));
      }
      if (movementTarget.fm) {
        bonuses.push(sekiGetModeForHumans('fm'));
      }

      let moreSecondRow;
      if (movementTarget.dlfmChoice) {
        const dlfmOptions = [
          { value: 'chooseDl', label: 'Declare leader' },
          { value: 'chooseFm', label: 'Forced march' },
        ];
        moreSecondRow = (
          <>
            {dlfmOptions.map((dlfmOption) => {
              const { value, label } = dlfmOption;
              return (
                <Button
                  key={value}
                  isSecondary
                  isPressed={answer.additionalData.dlfmAnswer === value}
                  onClick={() => {
                    const filledOrder = {
                      ...answer,
                      additionalData: {
                        ...answer.additionalData,
                        dlfmAnswer: value,
                      },
                    };
                    putAnswer(filledOrder);
                  }}
                >
                  {label}
                </Button>
              );
            })}
          </>
        );
      }

      usesForcedMarch = getUsesForcedMarch(vOrder, answer);

      const labelParts = [
        movementTarget.passingLocations.length > 0 ? (
          <Fragment key="passing">
            via{' '}
            {boldedList(
              movementTarget.passingLocations.map((pl) => {
                const { locationId, hasOverrun } = pl;
                const name = sekiGetLocationForHumans(locationId);

                return `${name}${hasOverrun ? ' (overrun)' : ''}`;
              })
            )}
          </Fragment>
        ) : null,
        bonuses.length > 0 ? (
          <Fragment key="uses">uses {boldedList(bonuses)}</Fragment>
        ) : null,
        movementTarget.ms ? <Fragment key="ms">must stop</Fragment> : null,
      ].filter((x) => !!x);

      secondRow = (
        <>
          {labelParts.length > 0 ? (
            <span className="label">
              {interpose(labelParts, dashSeparator)}
            </span>
          ) : null}
          {moreSecondRow}
        </>
      );
    }

    const movingUnits = getPlayerUnitsAtLocation(
      cs,
      yourRole,
      isChanging
        ? getLocationIdFromZoneVOrderSource(vOrder.source)
        : movementTarget.sourceLocationId,
      currentSortPriority
    ).filter((unit) => {
      const { cid } = unit;
      return activeCids.includes(cid);
    });

    const meetsForcedMarchRequirement = usesForcedMarch
      ? answer.fmCids.length === 1
      : true;

    if (!isChanging && movementTarget.isFinal) {
      view = 'stopping';
      title = `Moving to ${name}`;

      columns = [
        {
          key: `youStopping`,
          label: 'stopping',
          className: yourRole,
          units: movingUnits,
          alwaysShow: true,
        },
        {
          key: 'youWelcoming',
          label: 'welcoming',
          className: yourRole,
          units: separatedUnits[yourRole],
          alwaysShow: false,
        },
        {
          key: 'opponent',
          label: movementTarget.hasOverrun
            ? 'overrun'
            : sekiGetRoleForHumans(opponentRole),
          className: opponentRole,
          units: separatedUnits[opponentRole],
          alwaysShow: false,
        },
      ];

      const isDisabled = !(
        vOrder.additionalData.minimum <= answer.cids.length &&
        answer.cids.length <= vOrder.additionalData.maximum &&
        meetsForcedMarchRequirement
      );

      confirm = getConfirm({ text: 'Move & stop', isDisabled });
      confirmPlacement = 'inSecondRow';
    } else {
      view = 'droppingOff';
      title = `Moving through ${name}`;

      const leftUnits = movingUnits.filter((unit) => {
        const { cid } = unit;
        return !answer.cids.includes(cid);
      });

      function transferLeftToRight(cids) {
        const filledOrder = {
          ...answer,
          cids: pushWithMaximum(
            answer.cids,
            vOrder.additionalData.maximum,
            ...cids
          ),
        };
        putAnswer(filledOrder);
      }

      const rightUnits = answer.cids.map((cid) => {
        return movingUnits.find((unit) => unit.cid === cid);
      });

      function transferRightToLeft(cids) {
        if (!answer) {
          throw new Error(
            'Must have answer to transfer dropping-off units back'
          );
        }
        const filledOrder = {
          ...answer,
          cids: answer.cids.filter((thatCid) => !cids.includes(thatCid)),
        };
        putAnswer(filledOrder);
      }

      columns = [
        {
          key: `youContinuing`,
          label: 'continuing',
          className: yourRole,
          units: leftUnits,
          activeCids,
          onUnitClick(cid) {
            transferLeftToRight([cid]);
          },
          alwaysShow: true,
        },
        {
          key: `youDroppingOff`,
          label: 'dropping off',
          className: yourRole,
          units: rightUnits,
          activeCids,
          onUnitClick(cid) {
            transferRightToLeft([cid]);
          },
          alwaysShow: true,
        },
        {
          key: 'youWelcoming',
          label: 'welcoming',
          className: yourRole,
          units: separatedUnits[yourRole].filter((unit) => {
            return !movingUnits.some((mb) => mb.cid === unit.cid);
          }),
          alwaysShow: false,
        },
        {
          key: 'opponent',
          label:
            !isChanging && movementTarget.hasOverrun
              ? 'overrun'
              : sekiGetRoleForHumans(opponentRole),
          className: opponentRole,
          units: separatedUnits[opponentRole],
          alwaysShow: false,
        },
      ];
      const isDisabled = !(
        vOrder.additionalData.minimum <= answer.cids.length &&
        answer.cids.length <= vOrder.additionalData.maximum &&
        meetsForcedMarchRequirement
      );
      const text =
        answer.cids.length === 0
          ? 'Move & continue'
          : answer.cids.length === activeCids.length
          ? 'Move & stop'
          : 'Drop off & continue';
      confirm = getConfirm({ text, isDisabled });
      confirmPlacement = 'inSecondRow';

      movingControls = getMovingControls(
        movingUnits,
        leftUnits,
        rightUnits,
        transferLeftToRight,
        transferRightToLeft
      );
    }
  } else if (
    locationVOrders.length >= 1 &&
    locationVOrders[0].orderName === 'movementPhase/musterUnits'
  ) {
    const vOrder = locationVOrders[0];

    view = 'mustering';

    const movingUnits = separatedUnits[yourRole];

    const leftUnits = movingUnits.filter((unit) => {
      const { cid } = unit;
      return !answer.cids.includes(cid);
    });
    const rightUnits = answer.cids.map((cid) => {
      return movingUnits.find((unit) => unit.cid === cid);
    });

    function getMode(newCids) {
      if (newCids.length === 0) {
        return null;
      } else if (newCids.length === 1) {
        return 'hiddenMustering';
      } else {
        return 'openMustering';
      }
    }

    function transferLeftToRight(cids) {
      const cid = cids[0];
      const unit = leftUnits.find((unit) => unit.cid === cid);

      let newCids;
      if (rightUnits.length > 0 && rightUnits[0].clan !== unit.clan) {
        newCids = cids;
      } else {
        newCids = [...answer.cids, ...cids];
      }
      let locationId;
      if (answer.locationId && newCids.length === 1) {
        locationId = answer.locationId;
      } else {
        locationId = sekiClanToMusteringLocationId[unit.clan];
      }

      const filledOrder = {
        ...answer,
        cids: newCids,
        locationId,
        mode: getMode(newCids),
      };
      putAnswer(filledOrder);
    }

    function transferRightToLeft(cids) {
      if (!answer) {
        throw new Error('Must have answer to transfer dropping-off units back');
      }
      const newCids = answer.cids.filter((thatCid) => !cids.includes(thatCid));
      const filledOrder = {
        ...answer,
        cids: newCids,
        mode: getMode(newCids),
      };
      putAnswer(filledOrder);
    }
    const activeCids = vOrder.additionalData.cids;

    columns = [
      {
        key: 'staying',
        label: 'staying',
        className: yourRole,
        units: leftUnits,
        activeCids,
        alwaysShow: true,
        onUnitClick(cid) {
          transferLeftToRight([cid]);
        },
      },
      {
        key: 'mustered',
        label: 'mustered',
        className: yourRole,
        units: rightUnits,
        activeCids,
        onUnitClick(cid) {
          transferRightToLeft([cid]);
        },
        alwaysShow: true,
      },
      ...(answer.locationId
        ? [
            {
              key: 'there',

              label: `at ${getLocationName(answer.locationId)}`,

              className: yourRole,
              units: getPlayerUnitsAtLocation(
                cs,
                yourRole,
                answer.locationId,
                currentSortPriority
              ),
              alwaysShow: false,
            },
            {
              key: 'thereOpponent',
              label: sekiGetRoleForHumans(opponentRole),
              className: opponentRole,
              units: getPlayerUnitsAtLocation(
                cs,
                opponentRole,
                answer.locationId,
                currentSortPriority
              ),
              alwaysShow: false,
            },
          ]
        : []),
    ];

    const locationButtons = vOrder.additionalData.musteringLocationIds.map(
      (locationId) => {
        return (
          <Button
            key={locationId}
            isSecondary
            isPressed={answer.locationId === locationId}
            isDisabled={answer.cids.length > 1}
            onClick={() => {
              const filledOrder = {
                ...answer,
                locationId,
              };
              putAnswer(filledOrder);
            }}
          >
            {getLocationName(locationId)}
          </Button>
        );
      }
    );

    movingControls = getMovingControls(
      movingUnits,
      leftUnits,
      rightUnits,
      transferLeftToRight,
      transferRightToLeft,
      { onlyClan: true }
    );
    secondRow = locationButtons;

    const isDisabled = answer.mode === null;

    const text = answer.mode
      ? sekiGetModeForHumans(answer.mode)
      : sekiGetModeForHumans('cannotMuster');

    confirm = getConfirm({ isDisabled, text });
    confirmPlacement = 'inMovingControls';
  } else if (
    locationVOrders.length >= 1 &&
    locationVOrders[0].orderName === 'movementPhase/bringMori'
  ) {
    view = 'bringingMori';

    const locationId = 'osaka';
    const movingUnits = getPlayerUnitsAtLocation(
      cs,
      yourRole,
      'moribox',
      'kindFirstDesc'
    );

    const rightUnits = movingUnits.slice(0, answer.cids.length);

    const leftUnits = movingUnits.slice(answer.cids.length);

    columns = [
      {
        key: 'staying',
        label: 'staying',
        className: yourRole,
        units: leftUnits,
        alwaysShow: true,
      },
      {
        key: 'brought',
        label: 'to play',
        className: yourRole,
        units: rightUnits,
        alwaysShow: true,
      },
      {
        key: 'there',
        label: `at ${sekiFindLocationById(locationId).name}`,

        className: yourRole,
        units: getPlayerUnitsAtLocation(
          cs,
          yourRole,
          locationId,
          currentSortPriority
        ),
        alwaysShow: false,
      },
      {
        key: 'thereOpponent',
        label: sekiGetRoleForHumans(opponentRole),
        className: opponentRole,
        units: getPlayerUnitsAtLocation(
          cs,
          opponentRole,
          locationId,
          currentSortPriority
        ),
        alwaysShow: false,
      },
    ];

    const isDisabled = answer.cids.length === 0;

    const text = sekiGetModeForHumans('bringMori');

    confirm = getConfirm({ isDisabled, text });
    confirmPlacement = 'inFirstRow';
  } else if (
    locationVOrders.length >= 1 &&
    (locationVOrders[0].orderName === 'combatPhase/declareCombat' ||
      locationVOrders[0].orderName === 'combatPhase/chooseCastleDefense')
  ) {
    view = 'declaringCombat';
    if (!(locationVOrders.length === 1)) {
      console.error(
        'expected single relevant order',
        locationId,
        locationVOrders
      );
    }

    const vOrder = locationVOrders[0];
    const { modes } = vOrder.additionalData;

    secondRow = (
      <>
        {modes.map((mode) => {
          return (
            <Button
              key={mode}
              onClick={() => {
                updateAnswer((answer) => {
                  return {
                    ...answer,
                    mode,
                  };
                });
              }}
              isPressed={answer.mode === mode}
            >
              {sekiGetModeForHumans(mode)}
            </Button>
          );
        })}
      </>
    );
    columns = getMainColumns();
    confirm = getConfirm({ disableHideModal: true });
    confirmPlacement = 'inSecondRow';
  } else if (isBattlingHere && pov === 'spec') {
    view = 'battlingForSpec';
    // TODO sorted by attacker-first, columns for deployed units too
    columns = getMainColumns();

    const combatType = getStateVar(cs, 'combatType');
    title = combatType === 'battle' ? `Battle at ${name}` : `Siege at ${name}`;
  } else if (
    // The check must be before isBattlingHere because it is more specific.
    locationVOrders.length === 1 &&
    locationVOrders[0].orderName === 'combatPhase/selectLosses'
  ) {
    view = 'selectingLosses';

    const vOrder = locationVOrders[0];
    const { additionalData } = vOrder;
    const { cids, mandatoryLossCids, minimum, maximum } = additionalData;
    title = `Select ${pluralize(minimum, 'loss', 'losses')} at ${name}`;

    const activeCids = cids;

    const baseUnits = separatedUnits[yourRole];
    if (
      yourRole === 'i' &&
      cids
        .map((cid) => getBaseFromCid(cid))
        .some((cid) => {
          return cid === 'sanmas' || cid === 'toyhid';
        })
    ) {
      baseUnits.push(...getDiscsAtLocation(cs, locationId));
    }

    const movableUnits = baseUnits.filter((unit) => {
      return !mandatoryLossCids.includes(unit.cid);
    });

    const [leftUnits, rightUnits] = separate(baseUnits, (unit) => {
      if (mandatoryLossCids.includes(unit.cid)) {
        return false;
      }
      if (answer && answer.cids.includes(unit.cid)) {
        return false;
      }
      return true;
    });
    function transferLeftToRight(cids) {
      let filledOrder;
      if (!answer) {
        filledOrder = makeFilledOrderForVOrder(vOrder, {
          cids: pushWithMaximum([], maximum, ...cids),
        });
      } else {
        filledOrder = {
          ...answer,
          cids: pushWithMaximum(answer.cids, maximum, ...cids),
        };
      }
      putAnswer(filledOrder);
    }
    function transferRightToLeft(cids) {
      const filledOrder = {
        ...answer,
        cids: answer.cids.filter((thatCid) => !cids.includes(thatCid)),
      };
      putAnswer(filledOrder);
    }

    columns = [
      {
        key: 'living',
        label: 'living',
        className: yourRole,
        units: leftUnits,
        activeCids,
        onUnitClick(cid) {
          transferLeftToRight([cid]);
        },
        alwaysShow: true,
      },
      {
        key: 'losses',
        label: 'losses',
        className: yourRole,
        units: rightUnits,
        activeCids,
        onUnitClick(cid) {
          transferRightToLeft([cid]);
        },
        alwaysShow: true,
      },
    ];
    movingControls = getMovingControls(
      movableUnits,
      leftUnits,
      rightUnits,
      transferLeftToRight,
      transferRightToLeft
    );

    const hasValidAnswer =
      answer &&
      answer.cids &&
      minimum <= answer.cids.length &&
      answer.cids.length <= maximum;
    confirm = getConfirm({
      isDisabled: !hasValidAnswer,
    });
    confirmPlacement = 'inMovingControls';
  } else if (
    locationVOrders.length === 1 &&
    locationVOrders[0].orderName === 'combatPhase/retreatToCastle'
  ) {
    view = 'retreatingToCastle';
    title = `Retreat to castle at ${name}`;
    columns = getMainColumns();
    confirm = getConfirm();
    confirmPlacement = 'inFirstRow';
  } else if (
    locationVOrders.length === 1 &&
    locationVOrders[0].orderName === 'combatPhase/retreatToAdjacentLocation'
  ) {
    view = 'retreatingToAdjacentLocation';
    title = `Retreat to ${name}`;
    const vOrder = locationVOrders[0];
    const { additionalData } = vOrder;
    const { cids, minimum, maximum } = additionalData;

    const combatLocationId = getStateVar(cs, 'combatLocationId');
    const activeCids = cids;

    if (minimum < maximum) {
      // can choose someone to stay in castle
      const movingUnits = getPlayerUnitsAtLocation(
        cs,
        yourRole,
        combatLocationId,
        currentSortPriority
      );
      const leftUnits = movingUnits.filter((unit) => {
        const { cid } = unit;
        return !answer.cids.includes(cid);
      });
      const rightUnits = [
        ...movingUnits.filter((unit) => {
          const { cid } = unit;
          return answer.cids.includes(cid);
        }),
        ...separatedUnits[yourRole],
      ];

      function transferLeftToRight(cids) {
        let filledOrder;
        if (!answer) {
          filledOrder = makeFilledOrderForVOrder(vOrder, {
            cids: pushWithMaximum([], maximum, ...cids),
          });
        } else {
          filledOrder = {
            ...answer,
            cids: pushWithMaximum(answer.cids, maximum, ...cids),
          };
        }
        putAnswer(filledOrder);
      }
      function transferRightToLeft(cids) {
        const filledOrder = {
          ...answer,
          cids: answer.cids.filter((thatCid) => !cids.includes(thatCid)),
        };
        putAnswer(filledOrder);
      }

      columns = [
        {
          key: 'hereOpponent',
          label: getLocationName(combatLocationId),
          className: opponentRole,
          units: getPlayerUnitsAtLocation(cs, opponentRole, combatLocationId),
        },
        {
          key: 'staying',
          label: `castle`,
          className: yourRole,
          units: leftUnits,
          activeCids,
          alwaysShow: true,
          onUnitClick(cid) {
            transferLeftToRight([cid]);
          },
        },
        {
          key: 'retreating',
          label: name,
          className: yourRole,
          units: rightUnits,
          activeCids,
          alwaysShow: true,
          onUnitClick(cid) {
            transferRightToLeft([cid]);
          },
        },
        {
          key: 'thereOpponent',
          label: sekiGetRoleForHumans(opponentRole),
          className: opponentRole,
          units: separatedUnits[opponentRole],
        },
      ];

      movingControls = getMovingControls(
        movingUnits,
        leftUnits,
        rightUnits,
        transferLeftToRight,
        transferRightToLeft
      );

      const hasValidAnswer =
        answer &&
        answer.cids &&
        minimum <= answer.cids.length &&
        answer.cids.length <= maximum;

      confirm = getConfirm({ isDisabled: !hasValidAnswer });
      confirmPlacement = 'inMovingControls';
    } else {
      const yourUnits = [
        ...getPlayerUnitsAtLocation(cs, yourRole, locationId),
        ...getPlayerUnitsAtLocation(cs, yourRole, combatLocationId),
      ];

      columns = [
        {
          key: 'hereOpponent',
          label: `${getLocationName(
            combatLocationId
          )}${dashSeparator}${sekiGetRoleForHumans(opponentRole)}`,
          className: opponentRole,
          units: getPlayerUnitsAtLocation(cs, opponentRole, combatLocationId),
        },
        {
          key: 'thereYou',
          label: name,
          className: yourRole,
          units: yourUnits,
          activeCids,
          alwaysShow: true,
          onUnitClick(cid) {
            // do nothing: activeCids only to distinguish from already-present.
          },
        },
        {
          key: 'thereOpponent',
          label: `${getLocationName(
            locationId
          )}${dashSeparator}${sekiGetRoleForHumans(opponentRole)}`,
          className: opponentRole,
          units: separatedUnits[opponentRole],
          alwaysShow: false,
        },
      ];
      confirm = getConfirm();
      confirmPlacement = 'inFirstRow';
    }
  } else if (isBattlingHere) {
    view = 'battling';

    const combatType = getStateVar(cs, 'combatType');
    const isSiege = combatType === 'siege';
    title = combatType === 'battle' ? `Battle at ${name}` : `Siege at ${name}`;

    let [yourWaitingUnits, yourDeployedUnits] = separate(
      separatedUnits[yourRole],
      (unit) => {
        return unit.facing === 'facedown';
      }
    );
    yourDeployedUnits.sort(unitDeploymentNumberComparator);

    let [opponentWaitingUnits, opponentDeployedUnits] = separate(
      separatedUnits[opponentRole],
      (unit) => {
        return unit.facing === 'facedown';
      }
    );
    opponentDeployedUnits.sort(unitDeploymentNumberComparator);

    let rightColumnUnits = yourDeployedUnits;
    // Cannot use hasRelevantAnswer here because it checks the location source,
    // and deployment sources are sometimes cards.
    let leftColumnUnits = yourWaitingUnits;
    let newlyDeployingUnits = [];
    if (answer && answer.cids && answer.cids.length > 0) {
      const [matchingUnits, remainingUnits] = separate(
        yourWaitingUnits,
        (unit) => {
          return answer.cids.includes(unit.cid);
        }
      );
      newlyDeployingUnits = matchingUnits;
      leftColumnUnits = remainingUnits;
      rightColumnUnits = [...newlyDeployingUnits, ...yourDeployedUnits];
    }

    // TODO it will get more complex than that.

    /*

There is at most a single deployCardlessly order, which has deployable leader
cids, min=1,max=1.  Clicking such a unit creates a new answer for that order;
TODO or if a card is already selected that matches the unit, updates the
order.

There will be also single card; double card single unit, and double card two
units options.

Single-mon cards will be a source of one chooseCards
(min=1,max=1,cids=matching) order. Therefore those cards will be highlighted;
clickable; and when clicked, create a new answer for that order with empty cids
(confirm will ensures min&max): with such answer activeCids will become cids
from that order.

Double-mon cards will have two orders.  One will be for jokers, as above:
single card, single unit.  Another will be for (min=1, max=2 - but only if
cids.length >= 2).  Clicking such a card will merge activeCids from two orders;
clicking those cids will determine which order they come from, and
switch/update the answer.

     */

    const { vOrders } = cs;
    const deployCardlesslyVOrder = vOrders.find((vOrder) => {
      return vOrder.orderName === 'combatPhase/deployCardlessly';
    });
    const singleVOrders = vOrders.filter((vOrder) => {
      return vOrder.orderName === 'combatPhase/deploySingleUnitWithCard';
    });

    const cidToVariants = {};

    if (deployCardlesslyVOrder) {
      deployCardlesslyVOrder.additionalData.cids.forEach((cid) => {
        const variant = {
          kind: 'cardlessly',
          vOrder: deployCardlesslyVOrder,
        };
        addToKeyedArray(cidToVariants, cid, variant);
      });
    }
    singleVOrders.forEach((vOrder) => {
      vOrder.additionalData.cids.forEach((cid) => {
        const variant = {
          kind: 'singleMon',
          vOrder,
        };
        addToKeyedArray(cidToVariants, cid, variant);
      });
    });

    const activeCids = Object.keys(cidToVariants);

    function getDamagePart(whose, addedImpactRole = null, addedImpact = null) {
      const preliminaryOutcome = sekiGetCombatOutcome(cs, {
        addedImpactRole,
        addedImpact,
      });

      const nLosses = preliminaryOutcome[`${sekiGetOpponentRole(whose)}Losses`];
      if (nLosses > 0) {
        return ` ${repeatString('\u25CE', nLosses)}`;
      } else {
        return '';
      }
    }

    let addedImpactPart = '';
    let yourDamagePart;
    let opponentDamagePart;
    if (answer && answer.cids && answer.cids.length > 0) {
      const alreadyDeployedUnitInfos = yourDeployedUnits;
      const newlyDeployedUnitInfos = newlyDeployingUnits;
      const impactCalculation = sekiCalculateAddedImpact(
        getStateVar(cs, qualifiedName('impact', yourRole)),
        alreadyDeployedUnitInfos,
        newlyDeployedUnitInfos,
        answer.source.match(/^card:/)
          ? getBaseFromCid(getCidFromCardVSource(answer.source))
          : null,
        getStateVar(cs, 'combatType')
      );
      const { addedImpact } = impactCalculation;
      if (addedImpact > 0) {
        addedImpactPart = `+${addedImpact}`;
      }
      yourDamagePart = getDamagePart(yourRole, yourRole, addedImpact);
      opponentDamagePart = getDamagePart(opponentRole, yourRole, addedImpact);
    } else {
      yourDamagePart = getDamagePart(yourRole);
      opponentDamagePart = getDamagePart(opponentRole);
    }

    function onUnitClick(cid) {
      function applyFirstVariant() {
        const variant = cidToVariants[cid][0];
        const filledOrder = makeFilledOrderForVOrder(variant.vOrder, {
          cids: [cid],
        });
        putAnswer(filledOrder);
      }

      let updated = false;

      if (answer && answer.cids && answer.cids.includes(cid)) {
        putAnswer({
          ...answer,
          cids: answer.cids.filter((thatCid) => thatCid !== cid),
        });
        updated = true;
      } else if (answer && answer.cids && !deployCardlesslyVOrder) {
        const vOrder = vOrders.find(
          (vOrder) =>
            vOrder.disambiguationNumber === answer.disambiguationNumber
        );
        if (vOrder) {
          const { cids, maximum } = vOrder.additionalData;

          if (cids.includes(cid)) {
            const newCids = pushWithMaximum(answer.cids, maximum, cid);
            const filledOrder = {
              ...answer,
              cids: newCids,
            };
            putAnswer(filledOrder);
            updated = true;
          }
        }
      }

      if (!updated) {
        applyFirstVariant();
      }
    }

    columns = [
      {
        key: 'yourWaitingUnits',
        label: sekiGetRoleForHumans(yourRole),
        className: yourRole,
        units: leftColumnUnits,
        activeCids,
        alwaysShow: true,
        onUnitClick,
      },
      {
        key: 'yourDeployedUnits',
        label: `${getStateVar(
          cs,
          qualifiedName('impact', yourRole)
        )}${addedImpactPart} impact${yourDamagePart}`,
        className: yourRole,
        units: rightColumnUnits,
        alwaysShow:
          isSiege && yourRole !== sekiGetAttackerRole(cs) ? false : true,
        activeCids,
        onUnitClick,
      },
      {
        key: 'opponentDeployedUnits',
        label: `${getStateVar(
          cs,
          qualifiedName('impact', opponentRole)
        )} impact${opponentDamagePart}`,
        className: opponentRole,
        units: opponentDeployedUnits,
        alwaysShow:
          isSiege && yourRole === sekiGetAttackerRole(cs) ? false : true,
      },
      {
        key: 'opponentWaitingUnits',
        label: sekiGetRoleForHumans(opponentRole),
        className: opponentRole,
        units: opponentWaitingUnits,
        alwaysShow: true,
      },
      yourRole === 't' && isSiege
        ? {
            key: `discs`,
            label: 'disc',
            className: 'i',
            units: getDiscsAtLocation(cs, locationId),
            alwaysShow: false,
          }
        : null,
    ];

    if (vOrders.length > 0) {
      const scr = getSkipControls(
        cs,
        'combatPhase/doneDeployingUnits',
        answer,
        putAnswer
      );
      secondRow = scr.controls;

      const hasValidAnswer = answer && answer.cids && answer.cids.length > 0;
      confirm = getConfirm({
        isDisabled: scr.isConfirmVisible
          ? scr.isConfirmDisabled
          : !hasValidAnswer,
        disableHideModalFn: () => {
          return answer.orderName !== 'combatPhase/doneDeployingUnits';
        },
      });
    }
    confirmPlacement = 'inSecondRow';
  } else {
    view = 'main';

    columns = getMainColumns();
  }

  const displayHand =
    usesForcedMarch || view === 'bringingMori' || isBattlingHere;
  console.debug('SekiLocationModalContent', { view });

  return (
    <div className="sekiLocationModalContent">
      <h2>{title}</h2>
      <div className="controls">
        {choosePathControls}
        {sortControls}
        {confirm && confirmPlacement === 'inFirstRow' ? (
          <>
            <Separator />
            {confirm}
          </>
        ) : null}
      </div>
      {movingControls || confirmPlacement === 'inMovingControls' ? (
        <div className="controls">
          {movingControls}
          {confirm && confirmPlacement === 'inMovingControls' ? confirm : null}
        </div>
      ) : null}
      {secondRow || confirmPlacement === 'inSecondRow' ? (
        <div className="controls wrap">
          {secondRow}
          {confirm && confirmPlacement === 'inSecondRow' ? confirm : null}
        </div>
      ) : null}
      <div className="stacks">
        {columns
          .filter((column) => !!column)
          .map((column) => {
            const {
              key,
              label,
              className,
              units,
              activeCids,
              onUnitClick,
              alwaysShow,
            } = column;
            if (units.length === 0 && !alwaysShow) {
              return null;
            }
            return (
              <div key={key} className={clsx('stackContainer', className)}>
                <UnitStack
                  units={units}
                  activeCids={activeCids}
                  onUnitClick={onUnitClick}
                />
                <div className="label">{label}</div>
              </div>
            );
          })}
      </div>
      <div className="bottomPart">
        <LocationPreview
          locationId={
            answer && answer.locationId ? answer.locationId : locationId
          }
          displayHand={displayHand}
        />
        {displayHand ? <HandInLocationModal /> : null}
      </div>
    </div>
  );
}

function getCastleIconSrc(role) {
  return `/seki/icon-castle-${role}.webp`;
}

function getCubeIconSrc(role) {
  return `/seki/icon-cube-${role}.webp`;
}

function getCardIconSrc(role) {
  return `/seki/icon-card-${role}.webp`;
}

function getFirstPlayerIconSrc(role) {
  return `/seki/icon-turn-${getFourLetterRoleString(role)}.png`;
}

export function PrimaryPartition(props) {
  const { cs, pgpState } = useContext(GameUiContext);

  console.debug(cs, pgpState);
  const { pov } = cs;
  return (
    <>
      <SekiBoard />
      <div className="CardsOverlay">
        {pov === 'spec' ? null : (
          <CardZone
            zoneName={buildZoneName(pov, 'hand')}
            areCardsHoverable
            isHidden={
              pgpState.mobilePreview.isOpen || pgpState.modals.length > 0
            }
          />
        )}
        <div className="chosenCardZones">
          <CombinedCardZone zoneNames={['i:chosen', 't:chosen']} />
        </div>
      </div>
    </>
  );
}

function getSkipControls(cs, skipOrderName, answer, putAnswer) {
  const { vOrders } = cs;

  const relevantVOrders = vOrders.filter((vOrder) => {
    return vOrder.orderName === skipOrderName;
  });

  if (relevantVOrders.length === 0) {
    return {
      controls: null,
      isConfirmVisible: false,
      isConfirmDisabled: false,
    };
  }

  if (relevantVOrders.length > 1) {
    console.warn(
      'getSkipControls: expected one relevant vOrder',
      skipOrderName,
      relevantVOrders
    );
  }

  let isConfirmVisible = false;
  let isConfirmDisabled = true;

  const controls = relevantVOrders.map((vOrder) => {
    const { orderName, disambiguationNumber } = vOrder;

    const hasRelevantAnswer =
      answer && answer.disambiguationNumber === disambiguationNumber;
    if (hasRelevantAnswer) {
      isConfirmVisible = true;
      isConfirmDisabled = false;
    }
    return (
      <Button
        key={disambiguationNumber}
        isSecondary
        onClick={() => {
          const answer = makeFilledOrderForVOrder(vOrder);
          putAnswer(answer);
        }}
        isPressed={hasRelevantAnswer}
      >
        {sekiGetModeForHumans(orderName)}
      </Button>
    );
  });

  return {
    controls,
    isConfirmVisible,
    isConfirmDisabled,
  };
}

export function InteractiveLocationTitle(props) {
  const { locationId } = props;
  const title = sekiGetLocationForHumans(locationId);
  const { pgpDispatch } = useContext(GameUiContext);

  return (
    <b
      className="InteractiveLocationTitle"
      onClick={() => {
        // Happens on the /game page.
        if (!pgpDispatch) {
          return;
        }

        pgpDispatch({
          type: 'showModal',
          modalType: 'sekiLocation',
          locationId,
        });
      }}
    >
      {title}
    </b>
  );
}

const sekiGd = {
  code: 'seki',
  reactToClientStateChange(gameUiCtx, dispatch) {
    const { cs, answer, answerSent, shortName, globals, pgpDispatch } =
      gameUiCtx;
    const { vOrders, pov } = cs;
    if (
      !answer &&
      !answerSent &&
      vOrders.length === 1 &&
      vOrders[0].orderName === 'combatPhase/chooseCastleDefense'
    ) {
      const vOrder = vOrders[0];
      const newAnswer = makeFilledOrderForVOrder(vOrder, {
        mode: vOrder.additionalData.modes[0],
      });
      dispatch(setAnswer({ shortName, answer: newAnswer }));
    } else if (
      !globals.forcedOpeningModal &&
      (getStateVar(cs, 'whoDeploysUnit', null) !== null ||
        getStateVar(cs, 'whoSelectsLosses', null) === pov)
    ) {
      pgpDispatch({
        type: 'showModal',
        modalType: 'sekiLocation',
        locationId: getStateVar(cs, 'combatLocationId'),
      });
      dispatch(
        setGlobalValue({
          shortName,
          key: 'forcedOpeningModal',
          value: true,
        })
      );
    }
  },
  getRoleToPlayerName(cs) {
    return cs.roleToPlayerName;
  },
  getOrderedRoles(cs) {
    return getOrderedRoles(cs);
  },
  getRoleForHumans(role) {
    return sekiGetRoleForHumans(role);
  },
  getModeForHumans(mode) {
    return sekiGetModeForHumans(mode);
  },
  getPlayerBadgeParts(gameUiCtx, playerName, connectionStatus) {
    const { cs } = gameUiCtx;
    const { roleToPlayerName } = cs;
    const role = (Object.entries(roleToPlayerName).find(
      ([role, thatPlayerName]) => {
        return thatPlayerName === playerName;
      }
    ) || [])[0];

    const roleIconSrc = `/seki/icon-${getFourLetterRoleString(role)}.png`;

    const nCastles = getStateNumberVar(cs, qualifiedName(role, 'cas'));
    const nResourceLocations = getStateNumberVar(
      cs,
      qualifiedName(role, 'res')
    );

    const nCards = getNCardsInZone(cs, buildZoneName(role, 'hand'));

    const isFirstPlayer = getStateVar(cs, 'firstPlayerRole', null) === role;

    const connectionStatusText = getConnectionStatusText(connectionStatus);
    const roleLabel = sekiGetRoleForHumans(role);
    const titleLines = [
      [connectionStatusText, playerName, roleLabel],
      [
        pluralize(nCastles, 'castle'),
        pluralize(nResourceLocations, 'resource location'),
        pluralize(nCards, 'card'),
      ],
      [isFirstPlayer ? 'first player' : null],
    ];
    const title = getTitleFromLines(titleLines);

    return {
      title,
      info: {
        playerName,
        connectionStatus,
        role,
        nCastles,
        nResourceLocations,
        nCards,
        isFirstPlayer,
      },
      iconComponent: <img alt="" className="roleIcon" src={roleIconSrc} />,
      infoLinesComponent: (
        <>
          <div className="infoLine">
            <span>
              {nCastles}
              <img src={getCastleIconSrc(role)} />
            </span>
            <Separator />
            <span>
              {nResourceLocations}
              <img src={getCubeIconSrc(role)} />
            </span>
            <Separator />
            <span>
              {nCards}
              <img src={getCardIconSrc(role)} />
            </span>
          </div>
          <div className="infoLine">
            {isFirstPlayer ? (
              <span>
                <img src={getFirstPlayerIconSrc(role)} />
              </span>
            ) : (
              <Separator />
            )}
          </div>
        </>
      ),
    };
  },
  renderExpandedPlayerBadgeContent(info, pgpDispatch) {
    const { role, nCastles, nResourceLocations, nCards, isFirstPlayer } = info;

    const secondLine = [
      pluralize(nCastles, 'castle'),
      pluralize(nResourceLocations, 'resource location'),
      pluralize(nCards, 'card'),
      isFirstPlayer ? 'first player' : null,
    ]
      .filter((x) => !!x)
      .join(dashSeparator);
    return (
      <>
        <div>Plays as {sekiGetRoleForHumans(role)}</div>
        <div>{secondLine}</div>
      </>
    );
  },
  renderChoice(data, choice) {
    const { popupMenuKind } = data;
    return (
      <>
        {popupMenuKind} -- {choice.key}
      </>
    );
  },
  getOutcomeTranslation(outcome, gameUiCtx) {
    return sekiGetOutcomeTranslation(outcome, gameUiCtx);
  },
  getYourRequest(cs) {
    return null;
  },
  getActionLineParts(gameUiCtx, dispatch, sendAnswer) {
    const { shortName, cs, answer, yourRole, pgpDispatch } = gameUiCtx;

    const { roleToPlayerName } = cs;
    const step = getStateVar(cs, 'step');
    const phase = getStateVar(cs, 'phase', null);

    function getConfirm(options = {}) {
      const text = options.text || 'Confirm';
      const isDisabled = !!options.isDisabled;

      return (
        <>
          {answer !== null ? (
            <Button
              key="confirm"
              isPrimary
              onClick={() => sendAnswer(answer)}
              isDisabled={isDisabled}
            >
              {text}
            </Button>
          ) : null}
        </>
      );
    }

    function putAnswer(answer) {
      dispatch(setAnswer({ shortName, answer }));
    }

    let messageDescriptor,
      confirm,
      controls,
      isActionExpected = false;

    const { vOrders } = cs;

    if (step === 'reinforcements') {
      const iDiscarded = getStateVar(cs, 'i:discardedForReinforcements', false);
      const tDiscarded = getStateVar(cs, 't:discardedForReinforcements', false);

      const youDiscarded =
        yourRole === 'i' ? iDiscarded : yourRole === 't' ? tDiscarded : false;
      if (!youDiscarded && yourRole !== 'spec') {
        const vOrder = vOrders[0];
        if (!vOrder) {
          console.error(
            'when you have not discarded yet, should have a single order to choose cards',
            vOrders
          );
        }
        isActionExpected = true;

        messageDescriptor = {
          templateCode: 'reinforcementsStep/youChooseCards',
          args: {
            cards: vOrder.additionalData.minimum,
          },
        };
        confirm = getConfirm(
          answer
            ? { isDisabled: answer.cids.length < vOrder.additionalData.minimum }
            : {}
        );
      } else if (!iDiscarded && !tDiscarded) {
        messageDescriptor = {
          templateCode: 'reinforcementsStep/bothPlayersChooseCards',
        };
      } else if (!iDiscarded) {
        messageDescriptor = {
          templateCode: 'reinforcementsStep/anotherPlayerChoosesCards',
          args: { r: 'i' },
        };
      } else if (!tDiscarded) {
        messageDescriptor = {
          templateCode: 'reinforcementsStep/anotherPlayerChoosesCards',
          args: { r: 't' },
        };
      } else {
        console.error('should not be here', iDiscarded, tDiscarded, yourRole);
      }
    } else if (step === 'turnOrder') {
      const whoChoosesFirstPlayer = getStateVar(
        cs,
        'whoChoosesFirstPlayer',
        null
      );
      if (!whoChoosesFirstPlayer) {
        const playersReady = {
          i: getZoneCards(cs, `i:chosen`).length === 1,
          t: getZoneCards(cs, `t:chosen`).length === 1,
        };
        if (yourRole === 'spec') {
          if (!playersReady.i && !playersReady.t) {
            messageDescriptor = {
              templateCode: 'turnOrder/bothPlayersChooseCard',
            };
          } else if (!playersReady.i) {
            messageDescriptor = {
              templateCode: 'turnOrder/anotherPlayerChoosesCard',
              args: { r: 'i' },
            };
          } else if (!playersReady.t) {
            messageDescriptor = {
              templateCode: 'turnOrder/anotherPlayerChoosesCard',
              args: { r: 't' },
            };
          } else {
            console.error(
              'getActionLineParts: turnOrder flow is invalid state',
              cs
            );
            messageDescriptor = null;
          }
        } else {
          const youReady = playersReady[yourRole];
          const opponentRole = sekiGetOpponentRole(yourRole);
          const opponentReady = playersReady[opponentRole];

          if (!youReady) {
            messageDescriptor = {
              templateCode: 'turnOrder/youChooseCard',
            };
            isActionExpected = true;
            confirm = getConfirm();
          } else if (!opponentReady) {
            messageDescriptor = {
              templateCode: 'turnOrder/anotherPlayerChoosesCard',
              args: { r: opponentRole },
            };
            confirm = null;
          } else {
            console.error(
              'getActionLineParts: turnOrder flow is invalid state',
              cs
            );
            messageDescriptor = null;
            confirm = null;
          }
        }
      } else {
        if (yourRole === whoChoosesFirstPlayer) {
          isActionExpected = true;
          messageDescriptor = {
            templateCode: 'turnOrder/youChooseFirstPlayer',
          };
          controls = (
            <>
              {[...vOrders]
                .sort((a, b) => {
                  const aRole = getRoleFromPlayerSource(a.source);
                  const bRole = getRoleFromPlayerSource(b.source);
                  if (aRole === yourRole) {
                    return -1;
                  } else if (bRole === yourRole) {
                    return 1;
                  }
                  return 0;
                })
                .map((vOrder) => {
                  const { source } = vOrder;
                  const role = getRoleFromPlayerSource(source);

                  return (
                    <Button
                      key={role}
                      isSecondary
                      isPressed={answer && answer.source === source}
                      onClick={() => {
                        const filledOrder = makeFilledOrderForVOrder(vOrder);
                        putAnswer(filledOrder);
                      }}
                    >
                      {sekiGetRoleForHumans(role)}
                    </Button>
                  );
                })}
            </>
          );
          confirm = getConfirm();
        } else {
          messageDescriptor = {
            templateCode: 'turnOrder/anotherPlayerChoosesFirstPlayer',
            args: {
              r: whoChoosesFirstPlayer,
            },
          };
          confirm = null;
        }
      }
    } else if (step === 'turns' && phase === 'movement') {
      const whoBuysMovement = getStateVar(cs, 'whoBuysMovement', null);
      const whoReplenishesCards = getStateVar(cs, 'whoReplenishesCards', null);
      const whoActivatesLocation = getStateVar(
        cs,
        'whoActivatesLocation',
        null
      );
      const whoChoosesMovementTarget = getStateVar(
        cs,
        'whoChoosesMovementTarget',
        null
      );
      const whoChoosesFollowOnMovement = getStateVar(
        cs,
        'whoChoosesFollowOnMovement',
        null
      );

      if (whoBuysMovement === yourRole) {
        const vOrder = vOrders[0];
        if (!vOrder.orderName === 'movementPhase/buyMovement') {
          console.error('Expected to have a buyMovement order', vOrders);
        }

        isActionExpected = true;
        messageDescriptor = {
          templateCode: 'movementPhase/youBuyMovement',
          args: {},
        };

        controls = (
          <>
            <Button
              isSecondary
              isPressed={answer ? answer.mode === 'no' : false}
              onClick={() => {
                const answer = makeFilledOrderForVOrder(vOrder, {
                  cids: [],
                  mode: 'no',
                });
                putAnswer(answer);
              }}
            >
              {sekiGetModeForHumans('no')}
            </Button>
            <Button
              isSecondary
              isPressed={answer ? answer.mode === 'minimal' : false}
              onClick={() => {
                const answer = makeFilledOrderForVOrder(vOrder, {
                  cids: [],
                  mode: 'minimal',
                });
                putAnswer(answer);
              }}
            >
              {sekiGetModeForHumans('minimal')}
            </Button>
          </>
        );
        let confirmText;
        if (answer) {
          if (answer.cids.length === 1) {
            confirmText = sekiGetModeForHumans('limited');
          } else if (answer.cids.length === 2) {
            confirmText = sekiGetModeForHumans('total');
          }
        }
        confirm = getConfirm({ text: confirmText });
      } else if (whoBuysMovement !== null) {
        messageDescriptor = {
          templateCode: 'movementPhase/anotherPlayerBuysMovement',
          args: {
            r: whoBuysMovement,
          },
        };
      } else if (whoReplenishesCards === yourRole) {
        isActionExpected = true;

        const vOrder = vOrders[0];
        if (!vOrder.orderName === 'movementPhase/replenishCards') {
          console.error('Expected to have a replenishCards order', vOrders);
        }
        messageDescriptor = {
          templateCode: 'movementPhase/youReplenishCards',
          args: {},
        };
        controls = (
          <>
            <Button
              isSecondary
              isPressed={answer ? answer.cids.length === 0 : false}
              onClick={() => {
                const answer = makeFilledOrderForVOrder(vOrder, { cids: [] });
                putAnswer(answer);
              }}
            >
              {sekiGetModeForHumans('zero')}
            </Button>
          </>
        );
        confirm = getConfirm(
          answer && answer.cids.length > 0
            ? { text: `Confirm ${answer.cids.length}` }
            : {}
        );
      } else if (whoReplenishesCards !== null) {
        messageDescriptor = {
          templateCode: 'movementPhase/anotherPlayerReplenishesCards',
          args: {
            r: whoReplenishesCards,
          },
        };
      }
      // The movement phase subsequence checks must be before
      // whoActivatesLocation because we want to show the more specific prompt
      // to the active player, while not bothering the non-active ones.
      else if (whoChoosesFollowOnMovement === yourRole) {
        isActionExpected = true;

        messageDescriptor = {
          templateCode: 'movementPhase/youChooseFollowOnMovement',
        };

        const scr = getSkipControls(
          cs,
          'movementPhase/skipFollowOnMovements',
          answer,
          putAnswer
        );
        controls = scr.controls;

        confirm = scr.isConfirmVisible
          ? getConfirm({ isDisabled: scr.isConfirmDisabled })
          : null;
      } else if (whoChoosesMovementTarget === yourRole) {
        isActionExpected = true;

        const relevantVOrders = vOrders.filter((vOrder) => {
          return vOrder.additionalData && vOrder.additionalData.movementTarget;
        });
        const hasDropOffPoints = relevantVOrders.some((vOrder) => {
          return (
            vOrder.orderName === 'movementPhase/chooseMovementTarget' &&
            !vOrder.additionalData.movementTarget.isFinal
          );
        });
        messageDescriptor = {
          templateCode: hasDropOffPoints
            ? 'movementPhase/youChooseDestinationOrDropOffPoint'
            : 'movementPhase/youChooseDestination',
        };
      } else if (whoActivatesLocation === yourRole) {
        isActionExpected = true;

        const activationNumber = getStateNumberVar(cs, 'activationNumber');
        const nActivations = getStateNumberVar(cs, 'nActivations');
        messageDescriptor = {
          templateCode: 'movementPhase/youActivateLocation',
          args: {
            n1: activationNumber,
            n2: nActivations,
          },
        };

        const scr = getSkipControls(
          cs,
          'movementPhase/skipRemainingActivations',
          answer,
          putAnswer
        );
        controls = scr.controls;

        confirm = scr.isConfirmVisible
          ? getConfirm({ isDisabled: scr.isConfirmDisabled })
          : null;
      } else if (whoActivatesLocation !== null) {
        const activationNumber = getStateNumberVar(cs, 'activationNumber');
        const nActivations = getStateNumberVar(cs, 'nActivations');

        messageDescriptor = {
          templateCode: 'movementPhase/anotherPlayerActivatesLocation',
          args: {
            r: whoActivatesLocation,
            n1: activationNumber,
            n2: nActivations,
          },
        };
      } else {
        console.error(
          'in movement phase, but nobody buys movement, replenishes cards or activates location',
          cs
        );
      }
    } else if (step === 'turns' && phase === 'combat') {
      const whoDeclaresCombat = getStateVar(cs, 'whoDeclaresCombat', null);
      const whoChoosesCastleDefense = getStateVar(
        cs,
        'whoChoosesCastleDefense',
        null
      );
      const whoDeploysUnit = getStateVar(cs, 'whoDeploysUnit', null);
      const whoSelectsLosses = getStateVar(cs, 'whoSelectsLosses', null);
      const whoRetreatsUnits = getStateVar(cs, 'whoRetreatsUnits', null);

      if (whoDeclaresCombat !== null) {
        const combatNumber = getStateNumberVar(cs, 'combatNumber');
        const nCombats = getStateNumberVar(cs, 'nCombats');

        if (whoDeclaresCombat === yourRole) {
          isActionExpected = true;
          messageDescriptor = {
            templateCode: 'combatPhase/youDeclareCombat',
            args: {
              n1: combatNumber,
              n2: nCombats,
            },
          };
          const scr = getSkipControls(
            cs,
            'combatPhase/skipMyCastles',
            answer,
            putAnswer
          );
          controls = scr.controls;
          confirm = scr.isConfirmVisible
            ? getConfirm({ isDisabled: scr.isConfirmDisabled })
            : null;
        } else {
          messageDescriptor = {
            templateCode: 'combatPhase/anotherPlayerDeclaresCombat',
            args: {
              r: whoDeclaresCombat,
              n1: combatNumber,
              n2: nCombats,
            },
          };
        }
      } else if (whoChoosesCastleDefense !== null) {
        if (whoChoosesCastleDefense === yourRole) {
          isActionExpected = true;
          messageDescriptor = {
            templateCode: 'combatPhase/youChooseCastleDefense',
            args: {
              l: getStateVar(cs, 'combatLocationId'),
            },
          };

          const relevantVOrders = vOrders.filter((vOrder) => {
            return vOrder.orderName === 'combatPhase/chooseCastleDefense';
          });

          if (relevantVOrders.length !== 1) {
            console.error(
              'expected a single chooseCastleDefense order when youChooseCastleDefense',
              relevantVOrders
            );
          } else {
            const vOrder = relevantVOrders[0];
            controls = vOrder.additionalData.modes.map((mode) => {
              return (
                <Button
                  key={mode}
                  isPressed={answer && answer.mode === mode}
                  onClick={() => {
                    putAnswer({ ...answer, mode });
                  }}
                >
                  {sekiGetModeForHumans(mode)}
                </Button>
              );
            });
            confirm = getConfirm();
          }
        } else {
          messageDescriptor = {
            templateCode: 'combatPhase/anotherPlayerChoosesCastleDefense',
            args: {
              l: getStateVar(cs, 'combatLocationId'),
              r: whoChoosesCastleDefense,
            },
          };
        }
      } else if (whoDeploysUnit !== null) {
        if (whoDeploysUnit === yourRole) {
          isActionExpected = true;
          messageDescriptor = {
            templateCode: 'combatPhase/youDeployUnit',
            args: {
              l: getStateVar(cs, 'combatLocationId'),
            },
          };
          const scr = getSkipControls(
            cs,
            'combatPhase/doneDeployingUnits',
            answer,
            putAnswer
          );
          controls = scr.controls;
          confirm = scr.isConfirmVisible
            ? getConfirm({ isDisabled: scr.isConfirmDisabled })
            : null;
        } else {
          messageDescriptor = {
            templateCode: 'combatPhase/anotherPlayerDeploysUnit',
            args: {
              l: getStateVar(cs, 'combatLocationId'),
              r: whoDeploysUnit,
            },
          };
        }
      } else if (whoSelectsLosses !== null) {
        if (whoSelectsLosses === yourRole) {
          isActionExpected = true;
          messageDescriptor = {
            templateCode: 'combatPhase/youSelectLosses',
            args: {
              l: getStateVar(cs, 'combatLocationId'),
            },
          };
        } else {
          messageDescriptor = {
            templateCode: 'combatPhase/anotherPlayerSelectsLosses',
            args: {
              l: getStateVar(cs, 'combatLocationId'),
              r: whoSelectsLosses,
            },
          };
        }
      } else if (whoRetreatsUnits !== null) {
        if (whoRetreatsUnits === yourRole) {
          isActionExpected = true;
          messageDescriptor = {
            templateCode: 'combatPhase/youRetreatUnits',
            args: {
              l: getStateVar(cs, 'combatLocationId'),
            },
          };
        } else {
          messageDescriptor = {
            templateCode: 'combatPhase/anotherPlayerRetreatsUnits',
            args: {
              l: getStateVar(cs, 'combatLocationId'),
              r: whoRetreatsUnits,
            },
          };
        }
      } else {
        // TODO other checks...
      }
    } else {
      // TODO
    }

    const templateCode = messageDescriptor
      ? messageDescriptor.templateCode
      : 'darkness';

    const template = sekiGetActionLineContentTemplate(templateCode);
    const actionContent = (
      <MessageFromTemplate
        code="seki"
        template={template}
        args={messageDescriptor ? messageDescriptor.args : null}
        roleToPlayerName={roleToPlayerName}
        pgpDispatch={pgpDispatch}
      />
    );

    return {
      actionContent,
      confirm,
      controls,
      isActionExpected,
    };
  },
  renderNonStandardMobilePreviewContents(gameUiCtx, mobilePreview) {
    return null;
  },
  renderPrimaryPartition() {
    return <PrimaryPartition />;
  },
  renderBigCardImg(card, isShown) {
    if (!card) {
      return null;
    }
    const { yourKnowledge } = card;
    if (!yourKnowledge) {
      console.warn('renderBigCardImg: yourKnowledge is absent', card);
      return null;
    }

    const cid = yourKnowledge.cid;
    let src;
    if (yourKnowledge.knowledgeType === 'full') {
      const base = getBaseFromCid(cid);
      const cardInfo = sekiGetCardInfoByBase(base);
      if (cardInfo) {
        const { pic } = cardInfo;
        src = `/seki/card/${pic}.jpg`;
      } else {
        // TODO something better
        const unitInfo = sekiGetUnitInfoByBase(base);

        src = `/seki/unit/${unitInfo.face}.png`;
      }
    } else {
      const { cardBack } = card;
      src = `/seki/card/cb-${cardBack === 'ic' ? 'ishi' : 'toku'}.jpg`;
    }

    return (
      <img
        className={clsx('BigCardImg', { isShown, isLoaded: true })}
        alt={cid}
        src={src}
      />
    );
  },
  getKeyOfCard(card) {
    const { yourKnowledge } = card;
    const cid = yourKnowledge.cid;
    return cid;
  },
  getCardCropParts(gameUiCtx, card, cardActions, ref, popupMenuCtx) {
    const { answer } = gameUiCtx;

    const { yourKnowledge, facing } = card;

    const cid = yourKnowledge.cid;
    const vSource = makeCardVSource(cid);
    let isChosen;
    if (!!answer) {
      if (vSource === answer.source) {
        isChosen = true;
      }

      // XXX more properly should check orderType, but we don't have it at hand.
      if (
        answer.orderName === 'movementPhase/buyMovement' ||
        answer.orderName === 'movementPhase/replenishCards' ||
        answer.orderName === 'reinforcementsStep/discardCards' ||
        answer.orderName === 'movementPhase/payForForcedMarch' ||
        answer.orderName === 'movementPhase/bringMori'
      ) {
        isChosen = answer.cids.includes(cid);
      } else if (answer.orderName === 'movementPhase/chooseMovementTarget') {
        isChosen = answer.fmCids.includes(cid);
      }
    }

    return {
      isChosen,
      renderSmallCardImg() {
        if (yourKnowledge.knowledgeType === 'full') {
          const base = getBaseFromCid(cid);
          const cardInfo = sekiGetCardInfoByBase(base);
          const { pic } = cardInfo;
          return (
            <img
              className={`facing_${facing}`}
              alt={cid}
              src={`/seki/card/${pic}.jpg`}
            />
          );
        } else {
          const { cardBack } = card;
          return (
            <img
              alt={cid}
              className={`facing_${facing}`}
              src={`/seki/card/cb-${cardBack}.jpg`}
            />
          );
        }
      },
      handleNonStandardClick() {
        if (cardActions.length === 1) {
          cardActions[0].onInvokeDesktop();
          return true;
        } else {
          console.error(
            'handleNonStandardClick: more than one card action',
            cid,
            cardActions
          );
        }
        return false;
      },
    };
  },
  renderModalContent(modalState, gameUiCtx) {
    const { modalType } = modalState;
    if (modalType === 'sekiLocation') {
      return (
        <SekiLocationModalContent
          modalState={modalState}
          gameUiCtx={gameUiCtx}
        />
      );
    } else {
      console.error('Unrecognized modalType', modalType);
      return null;
    }
  },
  getTextRepresentationOfCard(card) {
    const { yourKnowledge, cardBack } = card;

    const unknownWord = cardBack[1] === 'c' ? 'card' : 'unit';

    if (!yourKnowledge) {
      return {
        title: null,
        isKnown: false,
        unknownWord,
        unknownWordSg: `an unknown ${unknownWord}`,
      };
    } else if (yourKnowledge.knowledgeType === 'limited') {
      return {
        title: null,
        isKnown: false,
        unknownWord,
        unknownWordSg: `a ${unknownWord}`,
      };
    } else {
      const { cid } = yourKnowledge;
      const base = getBaseFromCid(cid);
      const cardInfo = sekiGetCardInfoByBase(base);

      if (cardInfo) {
        return {
          title: `${cardInfo.title}`,
          isKnown: true,
        };
      } else {
        const unitInfo = sekiGetUnitInfoByBase(base);
        return {
          title: `${unitInfo.title}`,
          isKnown: true,
        };
      }
    }
  },
  getMftHoleReplacementComponent(holeType, holeValue) {
    switch (holeType) {
      case 'cards': {
        const n = holeValue;
        return <b>{pluralize(n, 'card')}</b>;
      }
      case 'units': {
        const n = holeValue;
        return <b>{pluralize(n, 'block')}</b>;
      }
      case 'l': {
        return <InteractiveLocationTitle locationId={holeValue} />;
      }
      case 'll': {
        return interpose(
          holeValue.map((locationId) => {
            return (
              <InteractiveLocationTitle
                key={locationId}
                locationId={locationId}
              />
            );
          }),
          ', ',
          ' and '
        );
      }
      default: {
        return null;
      }
    }
  },
};
registerGd('seki', sekiGd);
