import React from 'react';
import { Footer } from 'components/Footer';
import { usePageTitle } from 'hooks';

export function DefaultPage(props) {
  usePageTitle('');
  return (
    <>
      <main className="topLevel">Not found</main>
      <Footer />
    </>
  );
}
