import { pluralize, rangeInclusive, memoize, chopHead } from 'utils';

export const tdSideSelectionOptionChoices = [
  {
    value: 'owner-u',
    displayLabel: 'Owner is USA',
    selectionLabel: 'United States',
  },
  {
    value: 'owner-s',
    displayLabel: 'Owner is USSR',
    selectionLabel: 'Soviet Union',
  },
];

export const cidToTitle = {
  afasa: 'A Face-Saver',
  airstr: 'Air Strike',
  atlant: 'Atlantic',
  baofpi: 'Bay of Pigs',
  berblo: 'Berlin Blockade',
  berlin: 'Berlin',
  cloall: 'Close Allies',
  contai: 'Containment',
  cubmil: 'Cuba (Military)',
  cubpol: 'Cuba (Political)',
  defmis: 'Defensive Missiles',
  excomm: 'EXCOMM',
  eytoey: 'Eyeball to Eyeball',
  fidcas: 'Fidel Castro',
  fiffif: 'Fifty-Fifty',
  inofcu: 'Invasion of Cuba',
  intrep: 'Intelligence Reports',
  italy: 'Italy',
  leofmu: 'Lessons of Munich',
  maskir: 'Maskirovka',
  matpre: 'Mathematical Precision',
  milita: 'Military',
  miob: 'Moscow is our Brain',
  mistra: 'Missile Trade',
  mrbirb: 'MRBMs & IRBMs',
  natlib: 'National Liberation',
  noinpl: 'Non-Invasion Pledge',
  nucsub: 'Nuclear Submarines',
  offmis: 'Offensive Missiles',
  opemon: 'Operation Mongoose',
  perlet: 'Personal Letter',
  politi: 'Political',
  pubpro: 'Public Protests',
  quaran: 'Quarantine',
  scramb: 'Scramble',
  sops: 'SOPs',
  strbal: 'Strategic Balance',
  sttn: 'Speech to the Nation',
  suehun: 'Suez-Hungary',
  summee: 'Summit Meeting',
  tbts: 'Turn Back the Ships',
  tgoa: 'The Guns of August',
  tothbr: 'To the Brink',
  turkey: 'Turkey',
  u2do: 'U-2 Downed',
  u2ph: 'U-2 Photographs',
  utha: 'U Thant',
  waansm: 'Wave and Smile',
  woropi: 'World Opinion',
};

// Same-color sections, alphabetically within a section.
export const allStrategyCids = [
  'afasa',
  'cloall',
  'contai',
  'fiffif',
  'intrep',
  'nucsub',
  'scramb',
  'sops',
  'sttn',
  'summee',
  'tgoa',
  'tothbr',
  'utha',
  'airstr',
  'excomm',
  'inofcu',
  'leofmu',
  'matpre',
  'noinpl',
  'offmis',
  'opemon',
  'pubpro',
  'quaran',
  'u2ph',
  'waansm',
  'baofpi',
  'berblo',
  'defmis',
  'eytoey',
  'fidcas',
  'maskir',
  'mistra',
  'miob',
  'mrbirb',
  'natlib',
  'strbal',
  'suehun',
  'tbts',
  'u2do',
];

const tdSizes = {
  board: { w: 1658, h: 1158 },
  defconCloseup: { w: 300, h: 719 },
  disc: { w: 69, h: 48 },
  flag: { w: 68, h: 68 },
  bg: { w: 280, h: 190 },
  defconSpace: { w: 60, h: 60 },
};

export const getPercentage = memoize(
  (m, dim, bigSizeProp = 'board') => {
    const big = dim === 'hor' ? tdSizes[bigSizeProp].w : tdSizes[bigSizeProp].h;
    const number = Math.round((m / big) * 10000) / 100;
    return `${number}%`;
  },
  (m, dim, bigSizeProp) => {
    return `${m}:${dim}:${bigSizeProp}`;
  }
);

// Calculate style to be inserted in a React style prop.
export const getStyle = memoize(
  (markerData) => {
    const { rect, rotate } = markerData;
    const { x, y, w, h } = rect;

    const result = {
      left: getPercentage(x, 'hor'),
      top: getPercentage(y, 'ver'),
      width: getPercentage(w, 'hor'),
      height: getPercentage(h, 'ver'),
    };
    if (rotate) {
      result.transform = `rotate(${rotate}deg)`;
    }
    return result;
  },
  (markerData) => {
    return markerData.name;
  }
);

const closeupStartX = 62;
const closeupStartY = 600;

const msx = 1405;
const msy = 1021;
const tox = -4;
const toy = -63;

const psx = msx + 64;
const psy = msy - 3;

const wsx = psx + 64;
const wsy = psy - 3;

const spaceSize = tdSizes.defconSpace.w;

const data = {
  'pr-5': {
    name: 'pr-5',
    hide: true,
    rect: {
      x: 724,
      y: 74,
      w: 40,
      h: 40,
    },
  },
  'pr-4': {
    name: 'pr-4',
    hide: true,
    rect: {
      x: 776,
      y: 75,
      w: 40,
      h: 40,
    },
  },
  'pr-3': {
    name: 'pr-3',
    hide: true,
    rect: {
      x: 829,
      y: 75,
      w: 40,
      h: 40,
    },
  },
  'pr-2': {
    name: 'pr-2',
    hide: true,
    rect: {
      x: 881,
      y: 76,
      w: 40,
      h: 40,
    },
  },
  'pr-1': {
    name: 'pr-1',
    hide: true,
    rect: {
      x: 933,
      y: 76,
      w: 40,
      h: 40,
    },
  },
  'pr0': {
    name: 'pr0',
    hide: true,
    rect: {
      x: 986,
      y: 77,
      w: 40,
      h: 40,
    },
  },
  'pr1': {
    name: 'pr1',
    hide: true,
    rect: {
      x: 1037,
      y: 77,
      w: 40,
      h: 40,
    },
  },
  'pr2': {
    name: 'pr2',
    hide: true,
    rect: {
      x: 1090,
      y: 78,
      w: 40,
      h: 40,
    },
  },
  'pr3': {
    name: 'pr3',
    hide: true,
    rect: {
      x: 1142,
      y: 79,
      w: 40,
      h: 40,
    },
  },
  'pr4': {
    name: 'pr4',
    hide: true,
    rect: {
      x: 1194,
      y: 79,
      w: 40,
      h: 40,
    },
  },
  'pr5': {
    name: 'pr5',
    hide: true,
    rect: {
      x: 1246,
      y: 80,
      w: 40,
      h: 40,
    },
  },
  'l': {
    name: 'l',
    rotate: -0.5,
    isBg: true,
    rect: {
      x: 1019,
      y: 773,
      w: 280,
      h: 190,
    },
  },
  'a': {
    name: 'a',
    isBg: true,
    rect: {
      x: 427,
      y: 400,
      w: 280,
      h: 190,
    },
  },
  'b': {
    name: 'b',
    isBg: true,
    rotate: -2,
    rect: {
      x: 732,
      y: 174,
      w: 280,
      h: 190,
    },
  },
  'c': {
    name: 'c',
    rotate: 0.5,
    isBg: true,
    rect: {
      x: 198,
      y: 733,
      w: 280,
      h: 190,
    },
  },
  'k': {
    name: 'k',
    rotate: -1,
    isBg: true,
    rect: {
      x: 95,
      y: 385,
      w: 280,
      h: 190,
    },
  },
  'i': {
    name: 'i',
    isBg: true,
    rect: {
      x: 1005,
      y: 419,
      w: 280,
      h: 190,
    },
  },
  'v': {
    name: 'v',
    isBg: true,
    rect: {
      x: 583,
      y: 856,
      w: 280,
      h: 190,
    },
  },
  't': {
    name: 't',
    rotate: -0.5,
    isBg: true,
    rect: {
      x: 1048,
      y: 180,
      w: 280,
      h: 190,
    },
  },
  'u': {
    name: 'u',
    isBg: true,
    rotate: -0.5,
    rect: {
      x: 690,
      y: 608,
      w: 280,
      h: 190,
    },
  },
  'r1': {
    name: 'r1',
    hide: true,
    rect: {
      x: 123,
      y: 1018,
      w: 51,
      h: 52,
    },
  },
  'r2': {
    name: 'r2',
    hide: true,
    rect: {
      x: 193,
      y: 1013,
      w: 44,
      h: 43,
    },
  },
  'r3': {
    name: 'r3',
    hide: true,
    rect: {
      x: 259,
      y: 999,
      w: 50,
      h: 49,
    },
  },
  'ra': {
    name: 'ra',
    hide: true,
    rect: {
      x: 320,
      y: 960,
      w: 101,
      h: 101,
    },
  },
  'u:agenda': {
    name: 'u:agenda',
    rect: {
      x: 18,
      y: 742,
      w: 77,
      h: 258,
    },
  },
  'af': {
    name: 'af',
    rect: {
      x: 1031,
      y: 1049,
      w: 252,
      h: 92,
    },
  },
  's:agenda': {
    name: 's:agenda',
    rect: {
      x: 1554,
      y: 63,
      w: 87,
      h: 245,
    },
  },
  'm': {
    name: 'm',
    rotate: -3,
    hide: true,
    rect: {
      x: 1379,
      y: 469,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'p': {
    name: 'p',
    rotate: -3,
    hide: true,
    rect: {
      x: 1440,
      y: 463,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'w': {
    name: 'w',
    rotate: -3,
    hide: true,
    rect: {
      x: 1502,
      y: 463,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'm0': {
    name: 'm0',
    track: 'm',
    value: 0,
    rect: {
      x: msx + 0 * tox,
      y: msy + 0 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'm1': {
    name: 'm1',
    track: 'm',
    value: 1,
    rect: {
      x: msx + 1 * tox,
      y: msy + 1 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'm2': {
    name: 'm2',
    track: 'm',
    value: 2,
    rect: {
      x: msx + 2 * tox,
      y: msy + 2 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'm3': {
    name: 'm3',
    track: 'm',
    value: 3,
    rect: {
      x: msx + 3 * tox,
      y: msy + 3 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'm4': {
    name: 'm4',
    track: 'm',
    value: 4,
    rect: {
      x: msx + 4 * tox,
      y: msy + 4 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'm5': {
    name: 'm5',
    track: 'm',
    value: 5,
    rect: {
      x: msx + 5 * tox,
      y: msy + 5 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'm6': {
    name: 'm6',
    track: 'm',
    value: 6,
    rect: {
      x: msx + 6 * tox,
      y: msy + 6 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'm7': {
    name: 'm7',
    track: 'm',
    value: 7,
    rect: {
      x: msx + 7 * tox,
      y: msy + 7 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'p0': {
    name: 'p0',
    track: 'p',
    value: 0,
    rect: {
      x: psx + 0 * tox,
      y: psy + 0 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'p1': {
    name: 'p1',
    track: 'p',
    value: 1,
    rect: {
      x: psx + 1 * tox,
      y: psy + 1 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'p2': {
    name: 'p2',
    track: 'p',
    value: 2,
    rect: {
      x: psx + 2 * tox,
      y: psy + 2 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'p3': {
    name: 'p3',
    track: 'p',
    value: 3,
    rect: {
      x: psx + 3 * tox,
      y: psy + 3 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'p4': {
    name: 'p4',
    track: 'p',
    value: 4,
    rect: {
      x: psx + 4 * tox,
      y: psy + 4 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'p5': {
    name: 'p5',
    track: 'p',
    value: 5,
    rect: {
      x: psx + 5 * tox,
      y: psy + 5 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'p6': {
    name: 'p6',
    track: 'p',
    value: 6,
    rect: {
      x: psx + 6 * tox,
      y: psy + 6 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'p7': {
    name: 'p7',
    track: 'p',
    value: 7,
    rect: {
      x: psx + 7 * tox,
      y: psy + 7 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'w0': {
    name: 'w0',
    track: 'w',
    value: 0,
    rect: {
      x: wsx,
      y: wsy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'w1': {
    name: 'w1',
    track: 'w',
    value: 1,
    rect: {
      x: wsx + 1 * tox,
      y: wsy + 1 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'w2': {
    name: 'w2',
    track: 'w',
    value: 2,
    rect: {
      x: wsx + 2 * tox,
      y: wsy + 2 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'w3': {
    name: 'w3',
    track: 'w',
    value: 3,
    rect: {
      x: wsx + 3 * tox,
      y: wsy + 3 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'w4': {
    name: 'w4',
    track: 'w',
    value: 4,
    rect: {
      x: wsx + 4 * tox,
      y: wsy + 4 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'w5': {
    name: 'w5',
    track: 'w',
    value: 5,
    rect: {
      x: wsx + 5 * tox,
      y: wsy + 5 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'w6': {
    name: 'w6',
    track: 'w',
    value: 6,
    rect: {
      x: wsx + 6 * tox,
      y: wsy + 6 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'w7': {
    name: 'w7',
    track: 'w',
    value: 7,
    rect: {
      x: wsx + 7 * tox,
      y: wsy + 7 * toy,
      w: spaceSize,
      h: spaceSize,
    },
  },
  'wholeDefcon': {
    name: 'wholeDefcon',
    wholeDefcon: true,
    rotate: -3,
    rect: {
      x: msx + 7 * tox + 16,
      y: msy + 7 * toy,
      w: spaceSize * 3,
      h: spaceSize * 8 + 16,
    },
  },
};

['m', 'p', 'w'].forEach((track, index) => {
  for (const value of rangeInclusive(0, 7)) {
    data[`${track}${value}`].closeupRect = {
      x: closeupStartX - index * toy + value * (tox + 1),
      y: closeupStartY + index * (tox + 0.5) + value * toy,
      w: spaceSize,
      h: spaceSize,
    };
  }
});

const byName = {};

for (const [name, markerData] of Object.entries(data)) {
  const { hide, isBg, track, value, rect, closeupRect } = markerData;

  const style = getStyle(markerData);
  byName[name] = {
    name,
    hide,
    isBg,
    track,
    value,
    rect,
    closeupRect,
    style,
  };
}

export const tdWidgetNames = Object.entries(byName)
  .filter(([name, md]) => !md.hide && !md.isBg && !md.track && !md.wholeDefcon)
  .map(([name, md]) => name);

export const tdDefconSpaceNames = `m7 p7 w7
m6 p6 w6
m5 p5 w5
m4 p4 w4
m3 p3 w3
m2 p2 w2
m1 p1 w1
m0 p0 w0`.split(/\s+/);

// Order is a visual order of the bgs as if reading.
export const tdBattlegroundWidgetNames = 'b t k a i u c l v'.split(' ');

export function getMarkerDataByName(name) {
  return byName[name];
}

// Offsets are in a fraction of the w/h of the image.
export const getTrackerStyle = memoize(
  (markerName, trackerKind, options = {}) => {
    const markerData = byName[markerName];
    const { offset = { x: 0, y: 0 }, bigSizeProp, rectProp = 'rect' } = options;

    const rect = markerData[rectProp];

    const { x, y, w, h } = rect;

    const ww = tdSizes[trackerKind].w;
    const hh = tdSizes[trackerKind].h;

    const xx = x - (ww - w) / 2 + offset.x * ww;
    const yy = y - (hh - h) / 2 + offset.y * hh;

    const result = {
      left: getPercentage(xx, 'hor', bigSizeProp),
      top: getPercentage(yy, 'ver', bigSizeProp),
      width: getPercentage(ww, 'hor', bigSizeProp),
      height: getPercentage(hh, 'ver', bigSizeProp),
    };
    return result;
  },
  (markerName, trackerKind, options = {}) => {
    const { offset = { x: 0, y: 0 }, bigSizeProp, rectProp = 'rect' } = options;

    return `${markerName}:${trackerKind}:${offset.x}:${offset.y}:${bigSizeProp}:${rectProp}`;
  }
);

export const defconSymbol = '☢';

export function roleForHumans(role) {
  return role === 'u' ? 'USA' : 'USSR';
}

function trackForHumans(track) {
  return { m: 'Military', p: 'Political', w: 'World Opinion' }[track];
}

function parentheticalList(...args) {
  const actual = args.filter((a) => a !== null);
  if (actual.length === 0) {
    return '';
  }

  return `(${actual.join(', ')})`;
}

export function modeForHumans(mode, textCode) {
  switch (mode) {
    case 'u':
      return 'USA';
    case 's':
      return 'USSR';
    case 'aftermath':
      return 'Aftermath';
    case 'discard':
      return 'Discard';
    case 'play':
      return 'Play';
    case 'remove':
      return 'Remove';
    case 'place':
      return 'Place';
    case 'effect':
      return 'Effect';
    case 'command':
      return 'Command';
    case 'command+pl':
      return 'Command + Personal Letter';
    case 'event':
      return 'Event';
    case 'opp-command':
      return 'Opp Event then Command';
    case 'opp-command+pl':
      return 'Opp Event then Command + Personal Letter';
    case true:
      return 'Yes';
    case false:
      return 'No';
    default: {
      console.warn('cannot translate unrecognized mode', mode, textCode);
      return `?${mode}`;
    }
  }
}

export const yourRequestTemplates = {
  'choose-agenda-card': 'Choose your agenda.',
  'choose-initiative-player': 'Choose who gets initiative:',
  'choose-card-to-play': 'Choose a card to play',
  'choose-mode-of-play': (textArgs) => {
    return ['Play %c for...', { c: textArgs[0] }];
  },
  'choose-command-t': (textArgs) => {
    return ['Command %cubes', { cubes: textArgs[0] }];
  },
  'choose-command-onto-t': (textArgs) => {
    return ['Command %cubes (add only)', { cubes: textArgs[0] }];
  },
  'choose-place-influence-t': (textArgs) => {
    const [max, maxPer, defcon, exactAmount] = textArgs;
    const howMany = exactAmount ? '' : 'up to ';
    const after = parentheticalList(
      maxPer ? `max ${maxPer}/bg` : null,
      defcon ? defconSymbol : null
    );
    return [`Place ${howMany}%cubes ${after}`, { cubes: max }];
  },
  'choose-remove-influence-t': (textArgs) => {
    const [whose, max, policy, defcon] = textArgs;

    let cubesText;
    if (policy === 'single-bg' || policy === 'many-bgs') {
      const word = `${roleForHumans(whose)} cube`;
      cubesText = `up to ${pluralize(max, word)}`;
    } else {
      cubesText = `half the ${roleForHumans(whose)}'s cubes`;
    }
    const after = parentheticalList(defcon ? defconSymbol : null);
    return [`Remove %n ${after}`, { n: cubesText }];
  },
  'want-event?': (textArgs) => {
    return ['Resolve event of %c?', { c: textArgs[0] }];
  },
  'choose-change-defcon-t': (textArgs, yourRole) => {
    const [whose, maxTracks, allowedTracks] = textArgs;
    const intermission = whose === yourRole ? '' : `${roleForHumans(whose)} `;
    const which =
      allowedTracks.length === 3
        ? 'any'
        : allowedTracks.length === 2
        ? `${trackForHumans(allowedTracks[0])} or ${trackForHumans(
            allowedTracks[1]
          )}`
        : `${trackForHumans(allowedTracks[0])}`;
    const template =
      maxTracks === 2
        ? `Change any 2 ${intermission}DEFCON tracks`
        : `Change ${which} ${intermission}DEFCON track`;
    return [template, {}];
  },

  'aftermath-or-discard': (textArgs) => {
    return ['Send %c...', { c: textArgs[0] }];
  },
  'intrep-play-or-discard': (textArgs) => {
    return ['%c', { c: textArgs[0] }];
  },
  'summee-choose-cards-to-discard': 'Choose cards to discard',
  'airstr-remove-or-place': 'Remove or place influence?',
  'baofpi-remove-or-effect':
    'Remove cubes from Alliances or suffer the effect?',
};

export const otherRequestTemplates = {
  'choose-agenda-card': '%r chooses an agenda.',
  'choose-initiative-player': '%r chooses who gets initiative.',
  'choose-card-to-play': '%r chooses a card to play.',
  'choose-mode-of-play': '%r chooses how to play the card.',
  'choose-command-t': '%r chooses how to Command cubes.',
  'choose-command-onto-t': '%r chooses how to Command cubes.',
  'choose-place-influence-t': '%r chooses how to place cubes.',
  'choose-remove-influence-t': '%r chooses how to remove cubes.',
  'want-event?': '%r chooses whether to resolve the event.',
  'choose-change-defcon-t': '%r chooses how to change DEFCON.',
  'aftermath-or-discard':
    '%r chooses whether to send the card to Aftermath or Discard.',
  'intrep-play-or-discard': '%r chooses whether to play or discard the card.',
  'summee-choose-cards-to-discard': '%r chooses cards to discard.',
  'airstr-remove-or-place': '%r chooses whether to remove or place influence.',
  'baofpi-remove-or-effect':
    '%r chooses whether to remove influence or suffer the effect.',
};

export const bothPlayersRequestTemplates = {
  'choose-agenda-card': 'Both players choose an agenda.',
};

function bgForHumans(bg) {
  const bgs = {
    b: 'Berlin',
    t: 'Turkey',
    k: 'Cuba (Political)',
    a: 'Atlantic',
    i: 'Italy',
    u: 'United Nations',
    c: 'Cuba (Military)',
    l: 'Alliances',
    v: 'Television',
  };
  return bgs[bg];
}

export function phaseForHumans(phase) {
  const phases = {
    'escalate-defcon': 'Increase DEFCON tracks',
    'draw-agendas': 'Draw agendas',
    'strategy-cards': 'Strategy cards',
    'save-for-aftermath': 'Save cards for aftermath',
    'wo-bonus': 'World opinion bonus',
    'resolve-agendas': 'Resolve agendas',
    'check-war': 'Check for nuclear war',
    'advance-round': 'Advance round marker',
  };
  return phases[phase] || '?';
}

export function tdGetOutcomeTranslation(outcome) {
  const [mode, submode, winner] = outcome;

  let prefix;
  const args = {};
  if (mode === 'tie') {
    prefix = 'Tie';
  } else if (mode === 'win') {
    prefix = '%n1 wins the game';
    args['n1'] = winner;
  } else if (mode === 'abandoned') {
    prefix = 'The game is abandoned by %n1';
    args['n1'] = winner;
  } else {
    prefix = 'Unrecognized outcome: %n1';
    args['n1'] = mode;
  }

  let suffix;
  if (submode === 'both-war') {
    suffix = 'both side caused the nuclear war.';
  } else if (submode === 'war') {
    suffix = 'opponent caused the nuclear war.';
  } else if (submode === 'more-prestige') {
    suffix = 'more prestige.';
  } else if (submode === 'has-letter') {
    suffix = 'they have the Personal Letter.';
  }

  const result = [prefix, suffix].filter((x) => !!x).join(': ');
  return [result, args];
}

export function warReasonForHumans(reason) {
  if (reason === 'any-marker-in-1-area') {
    return 'marker in DEFCON 1 area';
  } else if (reason === 'all-markers-in-2-area') {
    return 'all markers in DEFCON 2 area';
  } else {
    return '';
  }
}

export const tdLogItemTemplates = {
  'game-starts': `Game starts: @1n plays as ${roleForHumans(
    'u'
  )}, @2n plays as ${roleForHumans('s')}.`,
  'round-starts': (textArgs) => {
    return [`%headerL`, { headerL: `Round ${textArgs[0]}` }];
  },
  'phase-starts': (textArgs) => {
    return ['%header', { header: `${phaseForHumans(textArgs[0])}` }];
  },
  'turn-starts': (textArgs) => {
    return [
      `%hr%header`,
      { header: `${roleForHumans(textArgs[0])} turn ${textArgs[1]}` },
    ];
  },
  'aftermath-starts': 'Aftermath',
  'choosing-initiative-player': '@1r chooses a player to get initiative.',
  'initiative-player': '@1r gets initative.',
  'play-card': (textArgs) => {
    return [`@1r plays @2c for @3mode.`];
  },
  'resolving-event': `Resolving event of @2c:`,
  'skip-event': `@1r chooses not to resolve the event.`,
  'tv-wo-bonus': `@1r gets Television bonus: escalate or degrade one DEFCON track.`,
  'un-wo-bonus': `@1r gets United Nations bonus: take Personal Letter.`,
  'no-wo-bonus': (textArgs) => {
    return [
      `Nobody gets the World Opinion bonus for dominating %n`,
      {
        n: bgForHumans(textArgs[0]),
      },
    ];
  },
  'alliances-wo-bonus': `@1r gets Alliances bonus: additional aftermath card.`,
  'resolve-agenda': (textArgs) => {
    const [arg] = textArgs;
    const cid = arg.cid;
    const winner = arg.winner;
    const baseAward = arg['base-award'];
    const totalBonus = arg['total-bonus'];
    const total = arg.total;

    const prefix = `Resolving %c: `;
    let suffix = winner
      ? `%r dominates for %n1+%n2=%n3 prestige.`
      : `no one dominates.`;
    return [
      `${prefix}${suffix}`,
      {
        c: cid,
        r: winner,
        n1: baseAward,
        n2: totalBonus,
        n3: Math.abs(total),
      },
    ];
  },
  'no-war': `Nuclear war is averted.`,
  'count-aftermath': (textArgs) => {
    const hash = textArgs[0];
    const sCubes = hash['s-cubes'];
    const uCubes = hash['u-cubes'];
    const dominator = hash['dominator'];
    const suffix = dominator ? `%r3 gets the award.` : 'no one gets the award.';
    return [
      `Counting aftermath cubes: %r1 has %cubes1, %r2 has %cubes2: ${suffix}`,
      { r1: 's', cubes1: sCubes, r2: 'u', cubes2: uCubes, r3: dominator },
    ];
  },
  'no-influence-change': `@1r doesn't add or remove any cubes.`,
  'no-cubes-placed': `@1r doesn't place any cubes.`,
  'no-cubes-removed': `@1r doesn't remove any cubes.`,
  'influence-changed': (textArgs) => {
    const [r, bg, change, nowU, nowS] = textArgs;
    const verb = change > 0 ? 'adds' : 'removes';
    const prep = change > 0 ? 'to' : 'from';
    return [
      `%r ${verb} %cubes ${prep} %n1, now at %n2 USA/%n3 USSR.`,
      {
        r,
        cubes: Math.abs(change),
        n1: bgForHumans(bg),
        n2: nowU,
        n3: nowS,
        highlightClass: r,
      },
    ];
  },
  'adds-to-aftermath': `@1r adds the card to aftermath.`,
  'discards': `@1r discards @2c.`,
  'defcon-changed': (textArgs) => {
    const [r, changes] = textArgs;
    const prefix = roleForHumans(r);
    const args = {};
    const parts = changes.map(([track, change, now], index) => {
      const t = trackForHumans(track);
      const verb = change > 0 ? 'escalated' : 'deflated';
      const changeVar = `n${index + 1}`;
      const nowVar = `n${(index + 1) * 10}`;
      args[changeVar] = Math.abs(change);
      args[nowVar] = now;
      return `${t} track is ${verb} by %${changeVar} (now %${nowVar})`;
    });
    args.highlightClass = r;
    const pp = parts.join(', ');
    return [`${prefix} ${pp}.`, args];
  },
  'no-defcon-changed': `@1r doesn't change DEFCON.`,
  'prestige-changed': (textArgs) => {
    const [change, now] = textArgs;
    const r = change > 0 ? 's' : 'u';
    return [
      `%r gains %n1 prestige, now at %n2.`,
      { r, n1: Math.abs(change), n2: now },
    ];
  },
  'intrep-got': `Intelligence Reports gets @1c.`,
  'summee-no-cards-discarded': `@1r doesn't discard any cards.`,
  'discards-many': `@1r discards @2cc.`,
  'fiffif-no-dominator': 'Nobody dominates Television',
  'inofcu-max-defcon': 'USA Military track is maximally escalated already.',
  'deck-shuffled': (textArgs) => {
    return [
      `The %n deck is shuffled.`,
      { n: textArgs[0] === 'agenda-deck' ? 'agenda' : 'strategy' },
    ];
  },
  'game-finished': (textArgs) => {
    const [mode, submode, winner] = textArgs;
    const outcome = [mode, submode, winner];

    const [template, args] = tdGetOutcomeTranslation(outcome);

    return [template, args];
  },
  'effect-started': `Effect of @2c has started on @1r.`,
  'precondition-not-met': 'The precondition of @1c is not met.',
  'player-reveals-agenda': `@1r reveals @2c.`,
  'has-agenda-options': `@1r has agenda options: @2cc.`,
  'war-danger-warning': (textArgs) => {
    const [r, reason] = textArgs;
    return [
      `${defconSymbol} %r risks triggering the nuclear war: ${warReasonForHumans(
        reason
      )}.`,
      { r, highlightClass: r },
    ];
  },
};

export function getVar(cs, varName) {
  return cs['vars'][varName];
}

export function getDefconVarName(role, track) {
  return `d:${role}:${track}`;
}

export function playerInWarDanger(role, cs) {
  const tracks = ['m', 'p', 'w'];
  let n2 = 0;
  for (const track of tracks) {
    const value = getVar(cs, getDefconVarName(role, track));
    const area = value <= 2 ? 3 : value <= 5 ? 2 : 1;
    if (area === 1) {
      return true;
    } else if (area === 2) {
      ++n2;
    }
  }
  return n2 === 3;
}

export function getCommandSpread(cs, role, bg, cubes, mode) {
  const reserve = getVar(cs, `c:${role}`);
  const inf = getVar(cs, `i:${role}:${bg}`);
  const lower = mode === 'onto' ? 0 : -Math.min(cubes, inf);
  const higher = Math.min(cubes, reserve, 5 - inf);
  return [lower, higher];
}

export const tdEffectDescriptors = [
  {
    name: 'tothbr',
    short: 'TtB',
    title: 'To the Brink',
    explanation: '-1 inf on Command',
  },
  {
    name: 'sops',
    short: 'SOPs',
    title: 'SOPs',
    explanation: '+1 inf on Command',
  },
  {
    name: 'contai',
    short: 'Cont.',
    title: 'Containment',
    explanation: 'Events you play cannot deflate DEFCON',
  },
  {
    name: 'baofpi',
    short: 'BoP',
    title: 'Bay of Pigs',
    explanation: 'Events you play cannot deflate DEFCON',
  },
];

export function getNCardsInZone(cs, zoneName) {
  const zone = cs['zones'][zoneName];

  const obj = zone['cards'];
  if (Number.isInteger(obj)) {
    return obj;
  } else {
    return obj.length;
  }
}

export function getCubeImgSrc(role, tag = 'straight') {
  if (tag === 'straight') {
    return `/td/markers/${role}-cube.png`;
  } else {
    return `/td/markers/${role}-cube-${tag}.png`;
  }
}

export function getYourRequest(cs) {
  const yourRole = cs['your-role'];
  const requestMap = cs['request-map'];
  if (cs.status !== 'running') {
    return null;
  }
  return requestMap[yourRole];
}

export function parseTextDescr(descr) {
  if (Array.isArray(descr)) {
    return descr;
  } else {
    return [descr];
  }
}

export const roles = ['s', 'u'];

export function getYourHandZoneName(cs) {
  const yourRole = cs['your-role'];
  return `${yourRole}:hand`;
}

export function getZoneCids(cs, zoneName) {
  const zone = cs['zones'][zoneName];
  return zone['cards'];
}

export function getInvisibleCardCids(cs) {
  const yourRole = cs['your-role'];

  let knownZones = [];
  if (yourRole === '*observer*') {
    knownZones = ['strategy-discard', 'in-play', 'aftermath-reveal'];
  } else {
    knownZones = [
      'strategy-discard',
      'in-play',
      'aftermath-reveal',
      `${yourRole}:hand`,
      `${yourRole}:aftermath`,
    ];
  }
  const allCids = new Set(allStrategyCids);
  for (const zoneName of knownZones) {
    for (const cid of cs['zones'][zoneName]['cards']) {
      const chopped = chopHead(cid);
      allCids.delete(chopped);
    }
  }

  return [...allCids];
}

export function getYourHandCids(cs) {
  const yourRole = cs['your-role'];
  if (yourRole === '*observer*') {
    return [];
  }

  return getZoneCids(cs, getYourHandZoneName(cs));
}

export function getStrategyDiscardPileCids(cs) {
  return getZoneCids(cs, 'strategy-discard');
}

export function getInPlayCids(cs) {
  return getZoneCids(cs, 'in-play');
}

export function tdFormatTiming(timing) {
  const { 'active-player': activePlayer, phase, round, turn } = timing;

  function r(role) {
    return role === 'u' ? 'USA' : 'USSR';
  }

  function p(phase) {
    switch (phase) {
      case 'agenda':
        return 'Agenda';
      case 'strategy':
        return 'Strategy';
      case 'wo-bonus':
        return 'World Opinion';
      case 'aftermath':
        return 'Aftermath';
      default:
        return '';
    }
  }

  const roundPart = `Round ${round}`;
  const secondPart = phase
    ? turn
      ? ` – ${r(activePlayer)} card ${turn}`
      : p(phase)
      ? ` – ${p(phase)}`
      : ''
    : '';
  const str = round === 'a' ? 'Aftermath' : `${roundPart}${secondPart}`;
  return str;
}
